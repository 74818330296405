import { ChainId } from '@/app-constants/chains';
import { TokenInfo } from '@/app-cores/api/bff';
import { isNativeToken } from '@/app-helpers/address';
import { calculatePriceImpact, isRouteParamsEmpty } from '@/app-hooks/swap';
import { ArgsGetRoute, FEE_ACCOUNTS, SwapAbstract, UsdRouteInfo } from '@/app-hooks/swap/type';
import { ExtractRouteInfo, SwapProvider } from '@/app-hooks/swap/type';
import { SelectedRoute } from '@/app-store/swap';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { ethers } from 'ethers';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';
import { uniqueId } from '@/app-helpers/random';
import { formatUnits, formatUsd } from '@/app-helpers/number';
import { calcMinAmountOutFromSlippage, calcRateSwap, filterParams, getMinAmount } from '@/app-hooks/swap/helper';
import { getNativeToken } from '@/app-helpers/token';
import { Asset, Factory, JettonRoot, MAINNET_FACTORY_ADDR, PoolType, ReadinessStatus, VaultJetton } from '@dedust/sdk';
import { Address } from '@ton/ton';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { useUserSettingsStore } from '@/app-store/settings';
import { getPendingSwapTxs } from '@/app-store/transaction-watcher/evmWatcher';
import { useTransactionWatcherStore } from '@/app-store';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import nacl from 'tweetnacl';
import { getEnvironment } from '@/app-helpers';

const API_DOMAIN = 'https://api.dedust.io';

const getQueryId = () => {
	const tobiSignature = (0xdb61b632).toString(16);
	const value = Buffer.concat([Buffer.from(tobiSignature, 'hex'), nacl.randomBytes(4)]);
	return BigInt('0x' + value.toString('hex'));
};

const getAddressString = (address: string) =>
	isNativeToken(address) ? 'native' : `jetton:${Address.parse(address).toRawString()}`;

class Dedust extends SwapAbstract<RouteDedust> {
	async getRoute(paramsSwap: ArgsGetRoute, signal?: AbortSignal) {
		const payload = getRouteParamsSwap(paramsSwap, signal);
		if (!payload) return;
		const { data } = await axios(payload);
		if (!data?.[0]) throw new Error('Empty route');
		return formatRoute({ ...paramsSwap, data: data?.[0] });
	}
	extractRoute(params: SelectedRoute<RouteDedust>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}
}
export const DedustSwap = new Dedust();

const getRouteParamsSwap = (args: ArgsGetRoute, signal?: AbortSignal) => {
	const { tokenIn, tokenOut, amountIn } = args;

	if (isRouteParamsEmpty(args)) return;
	const params = {
		from: getAddressString(tokenIn.address),
		to: getAddressString(tokenOut.address),
		amount: amountIn,
	};

	filterParams(params);
	const config: AxiosRequestConfig = { url: `${API_DOMAIN}/v2/routing/plan`, data: params, signal, method: 'POST' };
	return config;
};

type Pool = {
	address: string;
	isStable: boolean;
	assets: string[];
	reserves: string[];
};

type Trade = {
	pool: Pool;
	assetIn: string;
	assetOut: string;
	tradeFee: string;
	amountIn: string;
	amountOut: string;
};

export type RouteDedust = {
	data: Trade[];
	// custom
	tokenIn: TokenInfo;
	tokenOut: TokenInfo;
	amountIn: string;
};

const formatRoute = (routeData: RouteDedust): SelectedRoute<RouteDedust> =>
	routeData
		? {
				...routeData,
				route: routeData,
				routerAddress: '',
				id: uniqueId(),
				provider: SwapProvider.DEDUST,
				timestamp: Date.now(),
		  }
		: undefined;

const getExtractRoute = (
	selectedRoute: SelectedRoute<RouteDedust>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo = {},
): ExtractRouteInfo => {
	const routeSwap = selectedRoute?.route;
	const data = routeSwap?.data || [];
	const tokenIn = selectedRoute?.tokenIn;
	const tokenOut = selectedRoute?.tokenOut;

	const amountIn = data[0]?.amountIn;
	const amountOut = data[data.length - 1]?.amountOut;
	const native = getNativeToken(tokenIn?.chainId);
	const rate = calcRateSwap({ amountIn, amountOut, tokenIn, tokenOut });
	const amountInUsd = usdPriceIn * +ethers.formatUnits(amountIn, tokenIn?.decimals);
	const amountOutUsd = usdPriceOut * +ethers.formatUnits(amountOut, tokenOut?.decimals);

	const minNative = getMinAmount(SwapProvider.DEDUST, isNativeToken(tokenIn?.address));
	const gasUsd = usdPriceNative ? +ethers.formatUnits(minNative, native?.decimals) * usdPriceNative : undefined;

	return {
		amountInUsd,
		amountOutUsd,
		rate,
		amountOut,
		amountIn,
		priceImpact: calculatePriceImpact(amountInUsd, amountOutUsd),
		tokenIn,
		tokenOut,
		gasUsd,
		gasNative: minNative,
		gasDisplay: gasUsd
			? `max ${formatUsd(gasUsd)} (${formatUnits(minNative, native?.decimals)} TON)`
			: `max ${formatUnits(minNative, native?.decimals)} TON`,
		dappInfo: {
			logo: '/icons/brands/dedust.png',
			domain: 'Dedust.io',
		},
	};
};

type SwapParam = Parameters<typeof VaultJetton.createSwapPayload>[0];
async function createPayload({
	data,
	slippage,
	tokenOut,
	index,
}: {
	data: Trade[];
	slippage;
	tokenOut;
	index: number;
}): Promise<SwapParam | undefined> {
	if (index >= data.length) {
		return undefined;
	}
	const { pool, amountOut } = data[index];
	const isLastStep = index === data.length - 1;

	const rs: SwapParam = {
		poolAddress: Address.parse(pool.address),
		swapParams: { referralAddress: Address.parse(FEE_ACCOUNTS.TON) },
	};

	if (isLastStep) {
		rs.limit = calcMinAmountOutFromSlippage({ amountOut, tokenOut, slippage });
	} else rs.limit = BigInt(amountOut);
	const next = await createPayload({ data, slippage, tokenOut, index: index + 1 });
	if (next) rs.next = next;
	return rs;
}

export const useExecuteRouteDedust = () => {
	const { slippage } = useUserSettingsStore();
	const { addPendingTonTransaction } = useTransactionWatcherStore();
	const response = useMutation({
		mutationKey: ['build-route-dedust'],
		mutationFn: async ({ route: routeData }: { route: SelectedRoute<RouteDedust> }) => {
			const { tokenOut, route, tokenIn } = routeData;
			const { amountIn, data } = route;
			const { contract: wallet, client: tonClient } = await TonWallet.create('mainnet');
			const factory = tonClient.open(Factory.createFromAddress(MAINNET_FACTORY_ADDR));
			const sender = wallet.sender();

			const forwardPayload = await createPayload({
				data,
				index: 0,
				slippage: +slippage === AUTO_SLIPPAGE ? 5 : +slippage,
				tokenOut,
			});

			if (isNativeToken(tokenIn.address)) {
				const tonVault = tonClient.open(await factory.getNativeVault());
				await tonVault.sendSwap(sender, {
					amount: BigInt(amountIn),
					gasAmount: getMinAmount(SwapProvider.DEDUST, true),
					...forwardPayload,
					queryId: getQueryId(),
				});
			} else {
				// swap jetton to others
				const userAddress = Address.parse(MpcWallet.getWalletAddress().tonAddress);
				const tokenInAddress = Address.parse(tokenIn.address);
				const vault = tonClient.open(await factory.getJettonVault(tokenInAddress));
				const root = tonClient.open(JettonRoot.createFromAddress(tokenInAddress));

				const tokenWallet = tonClient.open(await root.getWallet(userAddress));

				await tokenWallet.sendTransfer(sender, getMinAmount(SwapProvider.DEDUST, false), {
					amount: BigInt(amountIn),
					destination: vault.address,
					responseAddress: userAddress, // return gas to user
					forwardAmount: getMinAmount(SwapProvider.DEDUST, true),
					forwardPayload: VaultJetton.createSwapPayload(forwardPayload),
					queryId: getQueryId(),
				});
			}
			return await wallet.getSeqno();
		},
		onSuccess: (seqno, { route }) => {
			addPendingTonTransaction({
				seqno,
				transaction: getPendingSwapTxs(route, {
					hash: seqno?.toString(),
					chainId: ChainId.TON,
					contract: '',
				}),
				trackingData: route,
			});
		},
	});
	return response;
};
