import { joinSignature } from '@ethersproject/bytes';
import { TypedDataDomain } from 'viem';
import { PublicKey } from '@solana/web3.js';
import { TypedDataField, ethers } from 'ethers';
import { isNil } from 'lodash-es';
import { EddsaKeyshare, Keyshare, KeyshareV0 } from '@telifi/dkls-wasm';
import { TronWeb } from 'tronweb';
import { STORAGE_KEYS, USE_BACKUP_KEYSHARE_VALUE } from '@/app-constants/storage';
import { AuthClient } from '@/app-cores/auth';
import { compareAddress } from '@/app-helpers/address';
import { decimalizeAsNumber } from '@/app-helpers/number';
import { MpcWalletStatus, VALIDATE_KEYSHARE_CODE } from '.';
import MpcClient from '../mpc';
import { decodeHex, encodeHex } from '../mpc/lib';
import {
	compareUint8Arrays,
	getUnsecuredKeyStore,
	getWalletAddress,
	setRequireImportPreBackupKeyShare,
} from './helper';
import keyShareManager from './keyshare-manager';
import { MpcWalletProvider } from './provider';
import { ChainId } from '@/app-constants/chains';
import { WalletContractV4 } from '../ton/WalletContractV4';
import { UserWallet } from './abstract-keyshare-manager';
import mpc from '../mpc';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';

export type TChainId = string | number | ChainId;

class MpcWallet {
	#providers: Map<number, MpcWalletProvider> = new Map();
	public async initialize(authFn?: () => Promise<string>) {
		console.time('initialized_mpc');
		await MpcClient.init(authFn);
		console.timeEnd('initialized_mpc');
	}

	public async createWallet() {
		const [keyShare, keyShare2] = await MpcClient.keygen();
		keyShareManager.setKeyShare(keyShare);

		return {
			address: getWalletAddress(ethers.hexlify(keyShare.publicKey())),
			tronAddress: this.getTronWalletAddress(),
			keyShare: keyShare,
			keyShare2: keyShare2,
		};
	}
	public isCreateEddsaWallet() {
		return !keyShareManager.keyShares?.eddsaKeyShare;
	}
	public async createEddsaWallet() {
		const [keyShare, keyShare2] = await MpcClient.eddsa_keygen();
		const wallet = WalletContractV4.create({
			workchain: 0,
			publicKey: Buffer.from(keyShare.publicKey()),
		});

		keyShareManager.setEddsaKeyShare(keyShare);
		return {
			tonAddress: wallet.address.toString({
				bounceable: false,
			}),
			solAddress: this.getSolanaWalletAddress(),
			keyShare: keyShare,
			keyShare2: keyShare2,
		};
	}

	public storeUnSecuredKeyShare({
		ecdsaKeyShare2,
		eddsaKeyShare2,
	}: {
		ecdsaKeyShare2?: Keyshare;
		eddsaKeyShare2: EddsaKeyshare;
	}) {
		localStorage.setItem(
			STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE,
			JSON.stringify({
				ecdsaKeyShare2: ecdsaKeyShare2 ? encodeHex(ecdsaKeyShare2.toBytes()) : '',
				eddsaKeyShare2: encodeHex(eddsaKeyShare2.toBytes()),
			}),
		);
	}
	public storeUnSecuredEddsaKeyShare(eddsaKeyShare2) {
		const preEcdsaKeyShare = getUnsecuredKeyStore || '';
		setRequireImportPreBackupKeyShare(preEcdsaKeyShare ? 0 : 1);
		localStorage.setItem(
			STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE,
			JSON.stringify({
				ecdsaKeyShare2: preEcdsaKeyShare,
				eddsaKeyShare2: encodeHex(eddsaKeyShare2.toBytes()),
			}),
		);
	}

	public getClientKeyShares() {
		const keyShare = keyShareManager.keyShareV0;
		const unsecuredKey = getUnsecuredKeyStore();
		if (keyShareManager.keyShareV0Hex === unsecuredKey) {
			return {
				errorCode: VALIDATE_KEYSHARE_CODE.KEY_SHARES_ARE_THE_SAME,
			};
		}

		if (!keyShare) {
			return {
				errorCode: VALIDATE_KEYSHARE_CODE.INVALID_KEY_SHARE,
			};
		}

		if (isNil(unsecuredKey)) {
			return {
				errorCode: VALIDATE_KEYSHARE_CODE.INVALID_KEY_SHARE_2,
			};
		}

		const keyShareBytes = decodeHex(unsecuredKey);
		if (!keyShareBytes) {
			return {
				errorCode: VALIDATE_KEYSHARE_CODE.INVALID_KEY_SHARE_2,
			};
		}
		const keyshare2 = KeyshareV0.fromBytes(keyShareBytes);
		if (!keyshare2) {
			return {
				errorCode: VALIDATE_KEYSHARE_CODE.INVALID_KEY_SHARE_2,
			};
		}
		if (!compareUint8Arrays(keyShare?.publicKey(), keyshare2?.publicKey())) {
			return {
				errorCode: VALIDATE_KEYSHARE_CODE.DIFFERENT_PUBLIC_KEY,
			};
		}
		return {
			errorCode: VALIDATE_KEYSHARE_CODE.VALID,
			keyShare: keyShare,
			keyShare2: keyshare2,
		};
	}
	public async migrateKeyshare() {
		const keyShare = keyShareManager.keyShareV0;
		const unsecuredKey = getUnsecuredKeyStore();

		if (!keyShare) {
			throw new Error('Invalid key share');
		}

		if (isNil(unsecuredKey)) {
			throw new Error('Invalid key share 2');
		}

		const keyShareBytes = decodeHex(unsecuredKey);
		if (!keyShareBytes) {
			throw new Error('Invalid key share 2');
		}
		const keyshare2 = KeyshareV0.fromBytes(keyShareBytes);
		if (!keyshare2) {
			throw new Error('Invalid key share 2');
		}
		if (!compareUint8Arrays(keyShare?.publicKey(), keyshare2?.publicKey())) {
			throw new Error('Migrate with different public keys');
		}
		const [share, share2] = await MpcClient.keyMigrate(keyShare, keyshare2);
		keyShareManager.setKeyShare(share2);
		keyShareManager.removeLocalMetadata();
		// Save keyShare2 to local storage
		// Should be removed after user successfully backs up the key
		localStorage.setItem(STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE, encodeHex(share.toBytes()));
	}

	public async recoverKeyShare(keyShare: string, { evmWallet, tonWallet }: { evmWallet: string; tonWallet: string }) {
		localStorage.setItem(keyShareManager.KEY_SHARE, keyShare);
		const walletStatus = await this.walletStatus({
			evmWallet: evmWallet,
			tonWallet: tonWallet,
		});
		if (walletStatus !== MpcWalletStatus.VALIDATED) {
			this.reset();
			throw new Error('Invalid key share');
		}
		console.log('recovered addresses', evmWallet, keyShareManager.getAddress());
		if (typeof evmWallet === 'string' && !compareAddress(evmWallet, keyShareManager.getAddress().ecdsaAddress)) {
			this.reset();
			throw new Error('Wrong public key');
		}

		localStorage.setItem(STORAGE_KEYS.TOBI_USE_BACKUP_KEYSHARE, USE_BACKUP_KEYSHARE_VALUE);
		await AuthClient.authenticate();
	}

	public async refreshKeyShares(keyshareObj: { ecdsaKeyShare: string; eddsaKeyShare: string }) {
		const ecdsaKeyShare = Keyshare.fromBytes(decodeHex(keyshareObj.ecdsaKeyShare));
		const eddsaKeyShare = EddsaKeyshare.fromBytes(decodeHex(keyshareObj.eddsaKeyShare));
		const [ecdsaWallet, eddsaWallet] = await Promise.allSettled([
			MpcClient.refreshEcdsaKeyshare([ecdsaKeyShare]),
			MpcClient.refreshEddsaKeyshare([eddsaKeyShare]),
		]);
		if (ecdsaWallet.status === 'fulfilled' && eddsaWallet.status === 'fulfilled') {
			keyShareManager.setKeyShare(ecdsaWallet.value[0]);
			keyShareManager.setEddsaKeyShare(eddsaWallet.value[0]);
			dataDogAddAction(DATADOG_ACTIONS.RECOVERY_NEW_TG_WALLET.SUCCESS);
			return [ecdsaWallet.value, eddsaWallet.value];
		}
		if (ecdsaWallet.status === 'rejected' && eddsaWallet.status === 'rejected') {
			dataDogAddAction(DATADOG_ACTIONS.RECOVERY_NEW_TG_WALLET.ERROR);
			throw ecdsaWallet.reason;
		}
		if (ecdsaWallet.status === 'fulfilled') {
			const replacementEddsaWallet = [eddsaKeyShare, eddsaKeyShare];
			keyShareManager.setKeyShare(ecdsaWallet.value[0]);
			keyShareManager.setEddsaKeyShare(replacementEddsaWallet[0]);
			dataDogAddAction(DATADOG_ACTIONS.RECOVERY_NEW_TG_WALLET.EDDSA_ERROR);
			return [ecdsaWallet.value, replacementEddsaWallet];
		}
		if (eddsaWallet.status === 'fulfilled') {
			const replacementEcdsaWallet = [ecdsaKeyShare, ecdsaKeyShare];
			keyShareManager.setKeyShare(replacementEcdsaWallet[0]);
			keyShareManager.setEddsaKeyShare(eddsaWallet.value[0]);
			dataDogAddAction(DATADOG_ACTIONS.RECOVERY_NEW_TG_WALLET.ECDSA_ERROR);
			return [replacementEcdsaWallet, eddsaWallet.value];
		}
	}

	public persistKeyShare() {
		keyShareManager.persistKeyShare().then(() => {
			console.log('Upload keyshare to TG Cloud Success');
		});
	}

	public persistEddsaKeyShare() {
		keyShareManager.persistEddsaKeyShare();
	}

	public async reset() {
		keyShareManager.removeKeyShare();
	}

	public getProvider(chainId: TChainId) {
		const provider = this.#providers.get(decimalizeAsNumber(chainId));
		if (!provider) {
			throw new Error('No approriate provider found for chainId: ' + chainId);
		}
		return provider;
	}

	public async walletStatus({ evmWallet, tonWallet }: UserWallet) {
		try {
			await keyShareManager.preComputeKeyShare({
				evmWallet,
				tonWallet,
			});
			if (!keyShareManager.keyShares.ecdsaKeyShare && !keyShareManager.keyShareV0)
				return MpcWalletStatus.MISSING_LOCAL_KEY;
			if (
				(typeof keyShareManager.keyShares.ecdsaKeyShare?.publicKey !== 'function' ||
					!keyShareManager.keyShares?.ecdsaKeyShare?.publicKey()) &&
				(typeof keyShareManager.keyShareV0?.publicKey !== 'function' ||
					!keyShareManager.keyShareV0?.publicKey())
			)
				return MpcWalletStatus.ERROR;

			// TODO for checking key share on server side
			return MpcWalletStatus.VALIDATED;
		} catch (error) {
			console.log('validate wallet error', error);
			return MpcWalletStatus.ERROR;
		}
	}
	public getTonWalletAddress() {
		const wallet = WalletContractV4.create({
			workchain: 0,
			publicKey: Buffer.from(keyShareManager.keyShares.eddsaKeyShare.publicKey()),
		});
		return wallet.address.toString({
			bounceable: false,
		});
	}
	public getSolanaWalletAddress() {
		const publicKey = new PublicKey(keyShareManager.keyShares.eddsaKeyShare.publicKey());
		return publicKey.toBase58();
	}
	public getEvmWalletAddress() {
		const { ecdsaKeyShare } = keyShareManager.keyShares;
		return getWalletAddress(encodeHex(ecdsaKeyShare.publicKey()));
	}
	public getTronWalletAddress() {
		return TronWeb.address.fromHex(this.getEvmWalletAddress());
	}

	public getWalletAddress(): {
		evmAddress: string;
		tonAddress: string;
		solAddress: string;
		tronAddress: string;
	} {
		if (this.getWalletAddressV0())
			return {
				evmAddress: this.getWalletAddressV0(),
				tonAddress: null,
				solAddress: null,
				tronAddress: null,
			};
		const { ecdsaKeyShare, eddsaKeyShare } = keyShareManager.keyShares;

		return {
			evmAddress: ecdsaKeyShare ? this.getEvmWalletAddress() : null,
			tonAddress: eddsaKeyShare ? this.getTonWalletAddress() : null,
			solAddress: eddsaKeyShare ? this.getSolanaWalletAddress() : null,
			tronAddress: eddsaKeyShare ? this.getTronWalletAddress() : null,
		};
	}

	public getWalletAddressV0(): string {
		if (!keyShareManager?.keyShareV0?.publicKey()) return null;
		return getWalletAddress(ethers.hexlify(keyShareManager?.keyShareV0?.publicKey()));
	}

	public async signMessage(data: string): Promise<string> {
		const keyShare = keyShareManager.keyShares.ecdsaKeyShare;
		if (!keyShare) {
			throw new Error('Invalid key share');
		}
		const signatureBytes = await MpcClient.sign(keyShare, ethers.hashMessage(data));
		return ethers.hexlify(signatureBytes);
	}
	public async verifyWallet(data: string): Promise<string> {
		const keyShare = keyShareManager.keyShares.ecdsaKeyShare;
		if (!keyShare) {
			throw new Error('Invalid key share');
		}
		const signatureBytes = await MpcClient.publicSign(keyShare, ethers.hashMessage(data));
		return ethers.hexlify(signatureBytes);
	}
	public async signRawTransaction(data: string): Promise<string> {
		const keyShare = keyShareManager.keyShares.ecdsaKeyShare;
		if (!keyShare) {
			throw new Error('Invalid key share');
		}
		const signatureBytes = await MpcClient.sign(keyShare, data);
		return ethers.hexlify(signatureBytes);
	}

	public async signTypedData(
		domain: TypedDataDomain,
		types: Record<string, Array<TypedDataField>>,
		value: Record<string, any>,
	): Promise<string> {
		const keyShare = keyShareManager.keyShares.ecdsaKeyShare;
		if (!keyShare) {
			throw new Error('Invalid key share');
		}
		const signatureBytes = await MpcClient.sign(keyShare, ethers.TypedDataEncoder.hash(domain, types, value));
		return joinSignature(signatureBytes);
	}

	public async signEddsaMessage(data: Buffer): Promise<Buffer> {
		const keyShare = keyShareManager.keyShares.eddsaKeyShare;
		if (!keyShare) {
			throw new Error('Invalid key share');
		}
		const signatureBytes = await MpcClient.eddsa_sign(keyShare, data);

		return Buffer.from(signatureBytes);
	}

	public async isBackedUp(): Promise<boolean> {
		// TODO: Call API to check if user has backup wallet (isBackup = true | false)
		// Temp check via local storage
		const unsecuredKey = getUnsecuredKeyStore();
		return isNil(unsecuredKey);
	}

	public getUnsecuredKeyShare2(): string {
		const unsecuredKey = getUnsecuredKeyStore();
		if (!unsecuredKey) {
			throw Error('Unsecured key invalid');
		}
		return unsecuredKey;
	}

	public removeUnsecuredKeyShare2(): void {
		localStorage.removeItem(STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE);
		localStorage.removeItem(STORAGE_KEYS.TOBI_UNSECURED_KEY_STORE_OLD);
	}

	public async removeCloudKeyShare(): Promise<void> {
		keyShareManager.removeCloudKeyShare();
	}

	public removeLocalMetadata(): void {
		keyShareManager.removeLocalMetadata();
	}

	public saveToCloudIfNeeded(): void {
		keyShareManager.saveToCloudIfNeeded();
	}
	public getKeyShares() {
		return keyShareManager.getKeyShares();
	}
}

export type IMpcWallet = typeof MpcWallet;

export default new MpcWallet();
