import { useMutation } from '@tanstack/react-query';
import { TonConnectParams } from '../types';
import { Address, beginCell, storeStateInit } from '@ton/core';

import {
	CHAIN,
	ConnectEventSuccess,
	ConnectEventError,
	CONNECT_EVENT_ERROR_CODES,
	ConnectItemReply,
} from '@tonconnect/protocol';
import { httpBridgeService } from '../services/httpBridgeService';
import {
	getDeviceInfo,
	tonConnectProofPayload,
	toTonProofItemReply,
	verifyConnectRequest,
} from '../services/connectService';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { encodeHex } from '@/app-cores/mpc-wallet/mpc/lib';

export const useConnectMutation = () => {
	return useMutation({
		mutationKey: ['mutation-connect'],
		mutationFn: async (params: TonConnectParams) => {
			try {
				verifyConnectRequest(params.request);
				const { wallet } = await TonWallet.create('mainnet');
				const result = [] as ConnectItemReply[];
				for (const item of params.request.items) {
					if (item.name === 'ton_addr') {
						result.push({
							name: 'ton_addr',
							address: wallet.address.toRawString(),
							network: CHAIN.MAINNET,
							walletStateInit: beginCell()
								.storeWritable(storeStateInit(wallet.init))
								.endCell()
								.toBoc()
								.toString('base64'),

							publicKey: encodeHex(wallet.publicKey),
						});
					}
					if (item.name === 'ton_proof') {
						const proof = tonConnectProofPayload(
							params.request.manifestUrl,
							Address.parse(MpcWallet.getTonWalletAddress()).toRawString(),
							item.payload,
						);
						result.push(
							await toTonProofItemReply({
								proof,
							}),
						);
					}
				}
				const message: ConnectEventSuccess = {
					event: 'connect',
					id: Date.now(),
					payload: {
						items: result,
						device: getDeviceInfo('1.0.0'),
					},
				};

				await httpBridgeService.sendEventToBridge({
					messages: message,
					clientSessionId: params.clientSessionId,
					sessionKeyPair: params.sessionKeyPair,
				});
			} catch (error) {
				console.error('Ton connect error', error?.message);
				const message: ConnectEventError = {
					event: 'connect_error',
					id: Date.now(),
					payload: {
						code: CONNECT_EVENT_ERROR_CODES.UNKNOWN_ERROR,
						message: error?.message || 'Connect error',
					},
				};
				await httpBridgeService.sendEventToBridge({
					messages: message,
					clientSessionId: params.clientSessionId,
					sessionKeyPair: params.sessionKeyPair,
				});
				throw new Error(error.message);
			}
		},
	});
};

export const useRejectMutation = () => {
	return useMutation({
		mutationKey: ['mutation-reject-connect'],
		mutationFn: async (params: TonConnectParams) => {
			const message: ConnectEventError = {
				id: Date.now(),
				event: 'connect_error',
				payload: {
					code: CONNECT_EVENT_ERROR_CODES.USER_REJECTS_ERROR,
					message: 'Reject Request',
				},
			};
			try {
				return await httpBridgeService.sendEventToBridge({
					messages: message,
					clientSessionId: params.clientSessionId,
					sessionKeyPair: params.sessionKeyPair,
				});
			} catch (error) {
				console.error('reject ton connect error: ' + error);
			}
		},
	});
};
