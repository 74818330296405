import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import { compareTobiToken, compareToken } from '@/app-helpers/address';
import { RouteSton } from '@/app-hooks/swap/ston';
import { RouteKyber } from '@/app-hooks/swap/kyberswap';

import { create } from 'zustand';
import { RouteRocketX } from '@/app-hooks/swap/rocketx';
import { RouteJupiter } from '@/app-hooks/swap/jupiter';
import { RouteDebridge } from '@/app-hooks/swap/debridge';
import { InternalStep, SwapProvider } from '@/app-hooks/swap/type';
import { RouteSwing } from '@/app-hooks/swap/swing';
import { RouteLifi } from '@/app-hooks/swap/lifi';
import { RouteBera } from '@/app-hooks/swap/bera';
import { RouteDedust } from '@/app-hooks/swap/dedust';
import { RouteRetroBridge } from '@/app-hooks/swap/retrobridge';
import { getTokenInfo } from '@/app-helpers/token';
import { RouteSunSwap } from '@/app-hooks/swap/sunswap';

export type GenericRoute =
	| RouteKyber
	| RouteLifi
	| RouteSton
	| RouteRocketX
	| RouteJupiter
	| RouteDebridge
	| RouteSwing
	| RouteBera
	| RouteDedust
	| RouteRetroBridge
	| RouteSunSwap;

export enum SwapDisableType {
	LOCATION = 'location',
	MIN_AMOUNT = 'min_amount',
}

export type SelectedRoute<T extends GenericRoute = GenericRoute> = {
	id: string;
	routerAddress: string; // address need to approve
	route: T;
	allRoutes?: SelectedRoute<T>[]; // alter native route

	subRoutes?: [SelectedRoute<RouteRocketX>, SelectedRoute<RouteDedust>];
	executed?: boolean;

	disabled?: SwapDisableType;
	disableReason?: any;

	bestReturn?: boolean;
	lowestFee?: boolean;

	tokenIn: TokenInfo;
	tokenOut: TokenInfo;

	provider: SwapProvider;
	timestamp: number;
	checkGasByUsd?: boolean; // else check by native balance

	metadata?: any;
};

export enum InputMode {
	AMOUNT = 'Token',
	USD = 'USD',
}

const initializeData = {
	tokenIn: undefined,
	tokenOut: undefined,
	tokenInfoOut: undefined,
	tokenInfoIn: undefined,
	amount: '',
	inputMode: InputMode.USD,
	amountUsd: '',
	selectedRoute: undefined,
	routeExecuting: undefined,
};

export type RouteExecuting = {
	status?: InternalStep;
	error?: string;
	routeInfo?: any;
};
interface ISwapStore {
	routeExecuting: RouteExecuting;
	tokenIn: ITokenSearch | undefined;
	tokenOut: ITokenSearch | undefined;
	tokenInfoIn: TokenInfo;
	tokenInfoOut: TokenInfo;
	amount: string;
	amountUsd: string;
	inputMode: InputMode;
	selectedRoute: SelectedRoute;
	setAmount: (amount: string, usdAmount: string) => void;
	setInputMode: (v: InputMode) => void;
	setTokenIn: (token: ITokenSearch | undefined) => void;
	setTokenOut: (token: ITokenSearch | undefined) => void;
	setPair: (tokenIn: ITokenSearch | undefined, tokenOut: ITokenSearch | undefined) => void;
	setSelectedRoute: (v: SelectedRoute) => void;
	setRouteExecuting: (v: RouteExecuting) => void;
	reset: () => void;
}

const getToken = (oldToken: ITokenSearch, newToken: ITokenSearch) =>
	compareTobiToken(oldToken, newToken) ? oldToken : newToken;

const setterFn = (set) => ({
	...initializeData,
	setInputMode: (inputMode) => {
		set({ inputMode });
	},
	setRouteExecuting: (routeExecuting) => {
		set({ routeExecuting });
	},
	setAmount: (amount, usdAmount) => {
		set((state) => {
			return { ...state, amount, amountUsd: usdAmount };
		});
	},
	setTokenIn: (tk) =>
		set((state) => {
			const token = getToken(state.tokenIn, tk);
			return {
				tokenIn: token,
				tokenInfoIn: getTokenInfo(token),
			};
		}),
	setTokenOut: (tk) =>
		set((state) => {
			const token = getToken(state.tokenOut, tk);
			return {
				tokenOut: token,
				tokenInfoOut: getTokenInfo(token),
			};
		}),
	setPair: (tokenIn, tokenOut) =>
		set(() => {
			return {
				tokenIn,
				tokenOut,
				chainIdIn: tokenIn?.chainId,
				chainIdOut: tokenOut?.chainId,
				tokenInfoIn: getTokenInfo(tokenIn),
				tokenInfoOut: getTokenInfo(tokenOut),
			};
		}),
	setSelectedRoute: (data) => set({ selectedRoute: data, tokenIn: data.tokenIn, tokenOut: data.tokenOut }),
	reset: () =>
		set((state) => ({
			...state,
			...initializeData,
		})),
});

// for main swap page
const _useSwapStore = create<ISwapStore>()(setterFn);
// for swap popup
const _useQuickSwapStore = create<ISwapStore>()(setterFn);

export const useSwapStore = (quickSwap: boolean) => {
	const swapStore = _useSwapStore();
	const quickSwapStore = _useQuickSwapStore();
	return quickSwap ? quickSwapStore : swapStore;
};
