import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const TokenTradingIcon: React.FC<IProps> = ({ width = 90, height = 90, ...rest }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 91 90" fill="none">
			<g clipPath="url(#clip0_7479_12927)">
				<path
					d="M53.0938 22.3768C51.0371 9.50959 40.0682 0 27.043 0C21.2947 0 15.7049 2.02131 11.2227 5.44922H0.5L3.04883 15.627C1.51936 19.0545 0.5 22.7987 0.5 26.543C0.5 34.5584 4.26172 42.0469 10.4844 47.0566L12.1191 48.375L13.8066 47.0566C17.498 43.9979 22.2443 42.4684 27.043 42.4684C30.998 42.4684 34.8475 43.5231 38.2227 45.6326C38.4334 45.6854 38.6447 45.8434 38.8027 45.949L43.6017 49.1129L42.916 43.418C42.8633 43.1016 42.8633 42.7324 42.8633 42.3633C42.8633 35.4023 46.2912 28.916 51.9861 25.0135L53.1465 24.2227L53.0938 22.3768Z"
					fill="url(#paint0_linear_7479_12927)"
				/>
				<path
					d="M63.9571 68.7305C58.7093 68.7305 53.6264 67.1933 49.2595 64.2809L48.1626 63.5497L48.0906 62.2313C47.73 55.6859 44.2591 49.5884 38.8054 45.9218L37.8063 45.2498L37.6622 44.055C37.5952 43.5349 37.5901 42.9478 37.5901 42.3633C37.5901 34.9588 43.8574 15.9961 63.9573 15.9961C78.4953 15.9961 90.5002 27.8253 90.5002 42.3633C90.5002 46.1174 89.5057 49.8742 87.9453 53.299L90.5001 63.457H79.7929C75.2867 66.8637 69.7043 68.7305 63.9571 68.7305Z"
					fill="url(#paint1_linear_7479_12927)"
				/>
				<path
					d="M41.7557 41.5195C37.3789 38.6191 32.2108 37.1953 27.043 37.1953C21.084 37.1953 15.0721 39.1468 10.4315 42.9435C4.26172 48.006 0.5 55.4943 0.5 63.4572C0.5 67.2012 1.51936 70.9453 3.04883 74.3732L0.5 84.551H11.2227C15.7049 87.9785 21.2947 90.0002 27.043 90.0002C41.5977 90.0002 53.4102 78.0119 53.4102 63.4572C53.4102 54.8088 49.0859 46.4766 41.7557 41.5195Z"
					fill="url(#paint2_linear_7479_12927)"
				/>
				<path
					opacity="0.64"
					d="M19.6478 59.6121C20.103 56.9974 22.3725 55.0889 25.0264 55.0889H35.0395C37.1947 55.0889 38.8476 57.0019 38.5348 59.1343L36.9725 69.784C36.533 72.7798 33.9631 75.0002 30.9352 75.0002H21.9334C19.3413 75.0002 17.3757 72.6629 17.8203 70.1092L19.6478 59.6121Z"
					fill="#22222D"
				/>
				<path
					d="M18.0423 58.0061C18.4975 55.3915 20.767 53.4829 23.421 53.4829H33.9093C35.6542 53.4829 37.0013 55.0173 36.7754 56.7475L35.1859 68.9211C34.8518 71.4801 32.6714 73.3943 30.0907 73.3943H20.3279C17.7358 73.3943 15.7703 71.0569 16.2148 68.5033L18.0423 58.0061Z"
					fill="#00E9DB"
				/>
				<path
					d="M17.7884 58.007C18.2432 55.392 20.5129 53.4829 23.1673 53.4829H32.3044C34.0491 53.4829 35.3961 55.017 35.1705 56.7471L33.6617 68.3141C33.3905 70.3935 31.6189 71.9491 29.5218 71.9491H20.3274C17.7357 71.9491 15.7701 69.6122 16.2142 67.0588L17.7884 58.007Z"
					fill="#22222D"
				/>
				<path
					d="M21.465 60.5479C21.465 61.08 21.0336 61.5114 20.5015 61.5114C19.9694 61.5114 19.5381 61.08 19.5381 60.5479C19.5381 60.0158 19.9694 59.5845 20.5015 59.5845C21.0336 59.5845 21.465 60.0158 21.465 60.5479Z"
					fill="#00E9DB"
				/>
				<path
					d="M33.0268 60.5479C33.0268 61.08 32.5954 61.5114 32.0633 61.5114C31.5312 61.5114 31.0999 61.08 31.0999 60.5479C31.0999 60.0158 31.5312 59.5845 32.0633 59.5845C32.5954 59.5845 33.0268 60.0158 33.0268 60.5479Z"
					fill="#00E9DB"
				/>
				<path
					d="M23.2661 61.9818C23.0978 61.7911 23.2457 61.5117 23.5 61.5117H28.7443C28.9986 61.5117 29.1465 61.7911 28.9782 61.9818C27.4718 63.6883 24.7725 63.6883 23.2661 61.9818Z"
					fill="#00E9DB"
				/>
				<path
					d="M23.5529 55.0889C20.0844 52.6481 16.6481 54.0719 15.3635 55.0889C17.5474 55.6027 18.7356 56.8552 18.8962 57.8186L23.5529 55.0889Z"
					fill="#22222D"
				/>
				<path
					d="M30.1387 64.2807C28.7401 65.8579 29.1045 67.2575 29.7099 68.0798C29.9747 68.4395 30.4153 68.6001 30.8612 68.626C32.773 68.7372 35.8061 68.7372 36.6017 68.7372C37.501 68.7372 38.154 69.9148 38.3681 70.343V64.8834C37.5973 63.8557 36.8694 63.7059 36.6017 63.7594H31.4C30.9259 63.7594 30.4532 63.9259 30.1387 64.2807Z"
					fill="#00E9DB"
				/>
				<path d="M56.0469 34.4531H71.8672V39.7266H56.0469V34.4531Z" fill="black" fillOpacity="0.48" />
				<path d="M63.957 34.4531H71.8672V39.7266H63.957V34.4531Z" fill="black" fillOpacity="0.48" />
				<path d="M56.0469 45H71.8672V50.2734H56.0469V45Z" fill="black" fillOpacity="0.48" />
				<path d="M63.957 45H71.8672V50.2734H63.957V45Z" fill="black" fillOpacity="0.48" />
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_7479_12927"
					x1="26.8232"
					y1="0"
					x2="26.8232"
					y2="49.1129"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF4D00" />
					<stop offset="1" stopColor="#C93B02" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_7479_12927"
					x1="64.0452"
					y1="15.9961"
					x2="64.0452"
					y2="68.7305"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#4B7DFF" />
					<stop offset="1" stopColor="#1652F0" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_7479_12927"
					x1="26.9551"
					y1="37.1953"
					x2="26.9551"
					y2="90.0002"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00FFF0" />
					<stop offset="1" stopColor="#07C2B7" />
				</linearGradient>
				<clipPath id="clip0_7479_12927">
					<rect width="90" height="90" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};
