import React from 'react';

interface Iprops {
	width?: number;
	height?: number;
	className?: string;
}

export const GroupIcon: React.FC<Iprops> = ({ className, width = 24, height = 24 }) => {
	return (
		<svg
			width={width}
			height={height}
			className={className}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.6086 12.3804H10.4709C7.81268 12.3804 5.65783 14.4157 5.65783 16.9262L5.58398 18.4545C5.58398 19.2914 6.30229 19.9697 7.18831 19.9697H16.8145C17.7006 19.9697 18.4188 19.2913 18.4188 18.4545L18.4927 16.9932C18.4927 14.4457 16.3061 12.3804 13.6086 12.3804Z"
				fill="currentColor"
			/>
			<path
				d="M0.75 16.726V17.2724C0.75 17.9175 1.3125 18.4488 1.99553 18.4488H3.53838C3.46606 18.2059 3.434 17.9555 3.434 17.6898V16.984C3.434 15.5269 3.97239 14.1911 4.87239 13.1362H4.55096C2.45365 13.1362 0.75 14.7452 0.75 16.726Z"
				fill="currentColor"
			/>
			<path
				d="M23.2503 16.726V17.2724C23.2503 17.9175 22.6878 18.4488 22.0048 18.4488H20.4619C20.5343 18.2059 20.5663 17.9555 20.5663 17.6898V16.984C20.5663 15.5269 20.0279 14.1911 19.1279 13.1362H19.4494C21.5467 13.1362 23.2503 14.7452 23.2503 16.726Z"
				fill="currentColor"
			/>
			<path
				d="M11.9975 10.8612C13.7727 10.8612 15.2118 9.33212 15.2118 7.44596C15.2118 5.5598 13.7727 4.03076 11.9975 4.03076C10.2223 4.03076 8.7832 5.5598 8.7832 7.44596C8.7832 9.33212 10.2223 10.8612 11.9975 10.8612Z"
				fill="currentColor"
			/>
			<path
				d="M18.8341 11.621C19.9436 11.621 20.843 10.6016 20.843 9.34418C20.843 8.08674 19.9436 7.06738 18.8341 7.06738C17.7246 7.06738 16.8252 8.08674 16.8252 9.34418C16.8252 10.6016 17.7246 11.621 18.8341 11.621Z"
				fill="currentColor"
			/>
			<path
				d="M5.16712 11.621C6.27662 11.621 7.17605 10.6016 7.17605 9.34418C7.17605 8.08674 6.27662 7.06738 5.16712 7.06738C4.05763 7.06738 3.1582 8.08674 3.1582 9.34418C3.1582 10.6016 4.05763 11.621 5.16712 11.621Z"
				fill="currentColor"
			/>
		</svg>
	);
};
