import { ButtonHome } from '@/app-components/common';
import TobiTabs from '@/app-components/common/TobiTabs';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { ITokenSearch } from '@/app-cores/api/bff';
import { useNavigateToLimitPage } from '@/app-helpers/navigate';
import { toQueryString } from '@/app-helpers/url';

import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';

import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import HeaderActions from '@/app-views/swap/components/HeaderActions';
import SelectTokenGroup, { SelectTokenGroupProps } from '@/app-views/swap/components/SelectTokenGroup';

import { Box, Flex, Text, transition } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { TOUR_GUIDE_STEPS_TARGET } from '@/app-views/wallet/components/TourGuide/stepConfigs';

export type SwapHeaderProps = SelectTokenGroupProps & {
	showHeaderShadow: boolean;
	content?: ReactNode;
};
export function NavigateHeader({ tokenOut }: { tokenOut: ITokenSearch }) {
	const navigate = useNavigate();
	const navigateToLimit = useNavigateToLimitPage();
	const { pathname } = useLocation();

	const tabsOption = [
		{
			label: 'Swap',
			onClick: () => navigate(toQueryString(NAVIGATE_PATHS.Swap.Main, { transition: 0 }), { replace: true }),
			value: NAVIGATE_PATHS.Swap.Main,
		},
		{
			label: 'Limit',
			onClick: () => navigateToLimit({ replace: true }),
			value: NAVIGATE_PATHS.Limit.Main,
		},
	];

	const isLimitPage = pathname.startsWith(NAVIGATE_PATHS.Limit.Main);
	const isSupportLimitOrder = useQueryFeatureConfig(FeatureConfig.LIMIT_ORDER);
	return (
		<Flex borderBottom={`1px solid ${BASE_BORDER_COLOR}`} pb={3} justifyContent={'space-between'} px={4} gap={6}>
			{isSupportLimitOrder ? (
				<TobiTabs
					containerStyle={{ flex: 1 }}
					tabs={tabsOption}
					value={isLimitPage ? NAVIGATE_PATHS.Limit.Main : NAVIGATE_PATHS.Swap.Main}
				/>
			) : (
				<ButtonHome showByAction>
					<Text>Trade </Text>
				</ButtonHome>
			)}
			<HeaderActions tokenOut={tokenOut} isLimitPage={isLimitPage} />
		</Flex>
	);
}

export default function Header({ showHeaderShadow, content, ...props }: SwapHeaderProps) {
	const { tokenOut } = props;
	return (
		<Flex
			flexDirection={'column'}
			gap={'12px'}
			transition={'0.2s'}
			sx={
				showHeaderShadow
					? { boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.08)`, paddingBottom: '8px' }
					: undefined
			}
		>
			<NavigateHeader tokenOut={tokenOut} />
			<SelectTokenGroup
				{...props}
				wrapperIds={{
					from: TOUR_GUIDE_STEPS_TARGET.TRADE.FROM,
					to: TOUR_GUIDE_STEPS_TARGET.TRADE.TO,
				}}
			/>
			{content}
		</Flex>
	);
}
