import { ChainId } from '@/app-constants/chains';
import { ActivitiesServiceAPI, IActivity, TxDetailTokenSend, TxStatus } from '@/app-cores/api/activities';
import { TonCenterServiceAPI } from '@/app-cores/api/toncenter/service-toncenter';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { delay } from '@/app-helpers';

export const waitForTonTransaction = async (seqno: number | string, callback?: (status: boolean) => void) => {
	const { contract } = await TonWallet.create('mainnet');
	let currentSeqno = seqno;
	const timestamp = Date.now();
	console.log('waiting for ton transaction...');
	while (currentSeqno + '' == seqno + '') {
		if (timestamp - Date.now() > 30_000) return callback(false);
		await delay(1500);
		currentSeqno = await contract.getSeqno();
	}
	console.log('Ton transaction submitted...');
	return callback(true);
};

const base64ToHex = (value: string) => {
	const binary = atob(value);

	return [...binary].map((item) => `0${item.charCodeAt(0).toString(16)}`.slice(-2)).join('');
};

export const handleTonActivities = async ({ time, memo }: IActivity) => {
	const address = MpcWallet.getWalletAddress().tonAddress;
	const transactions = await TonCenterServiceAPI.getTransactions({
		address,
	});
	const transaction = transactions.find((transaction) => transaction.utime > time);
	return { hash: transaction ? base64ToHex(transaction.transaction_id.hash) : '', chainId: ChainId.TON, memo };
};
