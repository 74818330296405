import { ChainId } from '@/app-constants/chains';
import { axiosBotAPI } from '@/app-cores/api/axios';

type Token = {
	chainId: string;
	contractAddress: string;
	user: string;
};

export type AddRemoveTokenPayload = {
	chainId: ChainId | string;
	contractAddress: string;
	type: 'spam' | 'include' | 'exclude';
};

export type ImportTokenInfo = {
	includedTokens: Token[];
	excludedTokens: Token[];
	spamTokens: Token[];
};

export const ServiceToken = {
	addTokens: async (payload: AddRemoveTokenPayload) => {
		const response = await axiosBotAPI.post('/portfolio/user-tokens/me', [payload]);
		return response.data;
	},

	removeTokens: async (payload: AddRemoveTokenPayload) => {
		const response = await axiosBotAPI.post('/portfolio/user-tokens/me/remove', [payload]);
		return response.data;
	},

	// get tokens: hidden/spam/import
	getListTokens: async (): Promise<ImportTokenInfo> => {
		const response = await axiosBotAPI.get('/portfolio/user-tokens/me');
		return response.data;
	},
};
