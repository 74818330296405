import { isEmpty, set } from 'lodash-es';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ethers } from 'ethers';
import { useQueryGasPrice } from '../api/transactions/useQueryGasPrice';
import { isNativeTobiToken, isNativeToken } from '@/app-helpers/address';
import { IEstimateGasFee, IEstimateGasFeeParams } from '@/app-types';
import { NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { EIP155GasPrice } from '@/app-cores/api/infura/type';
import { getDecimalCount, isEvmChain, parseValueToGwei, roundDownToDecimal, toGwei } from '@/app-helpers/token';
import { getSigner } from '@/app-helpers/web3';
import { getErc20Contract } from '@/app-hooks/wallet';

// Incase api infura error will use default by provider
export function setDefaultGasFeeData(gasPriceData: EIP155GasPrice, gas: ethers.FeeData) {
	set(gasPriceData, 'low.suggestedMaxPriorityFeePerGas', gas.maxPriorityFeePerGas || gas.gasPrice);
	set(gasPriceData, 'low.suggestedMaxFeePerGas', gas.maxFeePerGas || gas.gasPrice);
	set(gasPriceData, 'medium.suggestedMaxPriorityFeePerGas', gas.maxPriorityFeePerGas || gas.gasPrice);
	set(gasPriceData, 'medium.suggestedMaxFeePerGas', gas.maxFeePerGas || gas.gasPrice);
	set(gasPriceData, 'high.suggestedMaxPriorityFeePerGas', gas.maxPriorityFeePerGas || gas.gasPrice);
	set(gasPriceData, 'high.suggestedMaxFeePerGas', gas.maxFeePerGas || gas.gasPrice);
	set(gasPriceData, 'networkCongestion', 30);
	set(gasPriceData, 'latestPriorityFeeRange', ['0', '0']);
}

export const estimateGasFeeSendToken = async (
	{ to, amount, token, data }: IEstimateGasFeeParams,
	gasPriceData: EIP155GasPrice,
) => {
	const { chainId, decimals, address } = token;
	try {
		const { provider } = getSigner(chainId);
		const defaultGasLimit = ethers.toBigInt(21000);
		const decimal = getDecimalCount(amount, decimals);
		const amountRounded = roundDownToDecimal(amount, decimal);
		const amountInWei = parseValueToGwei(amountRounded, decimals);
		let gaslimit;

		try {
			if (!isNativeToken(address)) {
				const erc20Contract = getErc20Contract(chainId, address);

				gaslimit = await erc20Contract.transfer.estimateGas(to || NATIVE_TOKEN_ADDRESS, amountInWei ?? 0);
			} else {
				gaslimit = await provider.estimateGas({
					to,
					value: amountInWei,
					chainId,
					data: data ? ethers.hexlify(ethers.toUtf8Bytes(data)) : '0x',
				});
			}
		} catch (error) {
			if (!isNativeToken(address)) {
				gaslimit = BigInt(500000n);
			}
			console.error('estimate gas error', error);
		}
		gaslimit = gaslimit || defaultGasLimit;

		// Incase api infura error will use default by provider
		if (isEmpty(gasPriceData)) {
			const gas = await provider.getFeeData();
			setDefaultGasFeeData(gasPriceData, gas);
		}

		const rs: IEstimateGasFee = {
			low: {
				maxPriorityFeePerGas: toGwei(gasPriceData.low.suggestedMaxPriorityFeePerGas),
				maxFeePerGas: toGwei(gasPriceData.low.suggestedMaxFeePerGas),
				gasLimit: gaslimit,
				gasFeeInNativeToken: ethers.formatEther(toGwei(gasPriceData.low.suggestedMaxFeePerGas) * gaslimit),
			},
			market: {
				maxPriorityFeePerGas: toGwei(gasPriceData.medium.suggestedMaxPriorityFeePerGas),
				maxFeePerGas: toGwei(gasPriceData.medium.suggestedMaxFeePerGas),
				gasLimit: gaslimit,
				gasFeeInNativeToken: ethers.formatEther(toGwei(gasPriceData.medium.suggestedMaxFeePerGas) * gaslimit),
			},
			aggressive: {
				maxPriorityFeePerGas: toGwei(gasPriceData.high.suggestedMaxPriorityFeePerGas),
				maxFeePerGas: toGwei(gasPriceData.high.suggestedMaxFeePerGas),
				gasLimit: gaslimit,
				gasFeeInNativeToken: ethers.formatEther(toGwei(gasPriceData.high.suggestedMaxFeePerGas) * gaslimit),
			},
			networkStatus: {
				baseFee: 1,
				networkCongestion: gasPriceData.networkCongestion,
				latestPriorityFeeRange: gasPriceData.latestPriorityFeeRange,
			},
		};
		return rs;
	} catch (error) {
		console.log('Estimate gas fee error: ' + error);
		throw error;
	}
};

// use for send for now
export const useEstimateGasFee = (params: IEstimateGasFeeParams) => {
	const { to, token } = params;
	const { chainId, address } = token ?? {};
	const { data: gasPriceData, isSuccess } = useQueryGasPrice(+chainId);
	const response = useQuery({
		queryKey: ['estimate-gas-fee', to, chainId, address],
		queryFn: async () => {
			return estimateGasFeeSendToken(params, gasPriceData);
		},
		staleTime: 1000 * 15,
		enabled: !!token && !!address && isSuccess && isEvmChain(chainId) && !!gasPriceData,
		placeholderData: keepPreviousData,
	});
	return response;
};
