import { CTAButton, SpeedUpTransaction, Toast } from '@/app-components/common';
import AppLayout from '@/app-components/layout/PageLayout';
import i18n from 'i18next';

import { isEvmChain, isSolanaChain, isTonChain, isTronChain } from '@/app-helpers/token';
import { usePendingEvmTransaction, useTransactionWatcherStore } from '@/app-store';
import { ITransactionStatus, TransactionType } from '@/app-types';
import CancelSpeedUpButtons from '@/app-views/txs-result/components/CancelSpeedUpButtons';
import Status from '@/app-views/txs-result/components/Status';
import { Box, Button, Flex } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getSigner } from '../../../app-helpers/web3';
import CancelActivity from '../../wallet/components/Activities/Cancel';
import { useActivitiesState } from '../../wallet/components/Activities/state';
import { SentResult } from '../components/SentResult';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { TxStatus } from '@/app-cores/api/activities';
import { ChainId } from '@/app-constants/chains';
import { Actions } from '@/app-features/app-bot-connector/hooks';

const mapContentComponentByType = {
	[TransactionType.Send]: SentResult,
};

const formatTxsError = (error: string, transactionType: TransactionType) => {
	error && console.log('got error', error);
	return parseErrorMessage(error, i18n.t('transactionResult.error'));
};

export const useWatchTxsResult = () => {
	const { addPendingEvmTransaction, addPendingTonTransaction, addPendingSolTransaction, addPendingTronTransaction } =
		useTransactionWatcherStore();

	return useCallback(
		({
			chainId,
			txHash,
			onResponse,
			transactionType,
		}: {
			chainId: ChainId | string;
			txHash: string;
			transactionType: TransactionType;
			onResponse: (data: { status: TxStatus; msg?: string }) => void;
		}) => {
			if (!txHash || !chainId || !transactionType || !onResponse) return;

			if (isSolanaChain(chainId)) {
				return addPendingSolTransaction({ hash: txHash, callback: onResponse });
			}
			if (isTonChain(chainId)) {
				return addPendingTonTransaction({
					seqno: txHash,
					callback: onResponse,
				});
			}
			if (isTronChain(chainId)) {
				return addPendingTronTransaction({
					hash: txHash,
					callback: onResponse,
				});
			}
			const { provider } = getSigner(chainId);
			provider.getTransaction(txHash).then((receipt) => {
				addPendingEvmTransaction({
					transaction:
						receipt || useTransactionWatcherStore.getState().pendingEvmTransactions[txHash]?.metadata,
					callback: onResponse,
					metadata: { transactionType },
				});
			});
		},
		[addPendingEvmTransaction, addPendingSolTransaction, addPendingTonTransaction, addPendingTronTransaction],
	);
};

export const TransactionResult = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { chainId, txHash, type } = useParams<{ chainId: any; txHash: string; type: TransactionType }>();
	const [searchParams] = useSearchParams();
	const disabledSpeedUpCancel = searchParams.get('disabledSpeedUpCancel');

	const transactionType = type as TransactionType;
	const transaction = usePendingEvmTransaction(txHash);
	const activitiesState = useActivitiesState();
	const [status, setStatus] = useState<ITransactionStatus>(ITransactionStatus.PROCESSING);
	const [openSpeedUp, setOpenSpeedUp] = useState(false);
	const isCanceling = useRef(false);

	const [errorMsg, setErrorMSg] = useState('');

	const onWatch = useWatchTxsResult();
	useEffect(() => {
		if (!chainId || !txHash) return;
		const onResponse = ({ status, msg }: { status: TxStatus; msg?: string }) => {
			if (status === TxStatus.Success) {
				activitiesState.cancelActivity();
				setStatus(ITransactionStatus.SUCCESS);
				if (isCanceling.current) {
					return toast(
						<Toast
							type="success"
							title="Success"
							message={t('transactionResult.successAndCanNotCancel')}
						/>,
					);
				}
				toast(<Toast type="success" title="Success" message={msg || t('transactionResult.success')} />);
			} else {
				setStatus(ITransactionStatus.ERROR);
				const error = formatTxsError(msg, transactionType);
				toast(<Toast type="error" message={error} />);
				setErrorMSg(error);
			}
		};

		onResponse({ status: TxStatus.Success, msg: t('transactionResult.submitted') });
		onWatch({ chainId, txHash, onResponse, transactionType });
		// eslint-disable-next-line
	}, [onWatch, chainId, txHash, transactionType]);

	const handleCancelTransaction = () => {
		activitiesState.cancelActivity(transaction, transactionType);
	};
	const handleSpeedUpTransaction = () => {
		setOpenSpeedUp(true);
	};

	const propsContent = { status };
	const component = mapContentComponentByType[transactionType];

	const onBackHome = () => {
		navigate('/', { replace: true });
	};

	const supportCancelSpeedUpdate =
		[TransactionType.Send].includes(transactionType) && isEvmChain(chainId) && !disabledSpeedUpCancel;

	return (
		<AppLayout
			header={'Transaction Result'}
			footer={
				<Box p={4}>
					{status === ITransactionStatus.SUCCESS && (
						<Button
							mb={2}
							size="lg"
							fontWeight="medium"
							width="100%"
							onClick={() => {
								navigate(`/?page=/&action=${Actions.ACTIVITIES}`);
							}}
							colorScheme="gray"
						>
							{t('button.viewActivity')}
						</Button>
					)}
					{status === ITransactionStatus.ERROR && (
						<Button
							variant="outline"
							mb={2}
							size="lg"
							fontWeight="medium"
							width="100%"
							onClick={() => navigate(-2)} // txs result => confirm page => sent page
						>
							{t('button.tryAgain')}
						</Button>
					)}
					<CTAButton
						colorScheme="cyan"
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={() => {
							onBackHome();
						}}
					>
						{t('button.goToHome')}
					</CTAButton>
				</Box>
			}
		>
			<Flex overflow="auto" height="100%" px={4} flexDirection={'column'} justifyContent={'center'} gap={3}>
				{component && (
					<>
						{React.createElement(component, propsContent)}
						<Status status={status} errorMsg={errorMsg} />
						{supportCancelSpeedUpdate && (
							<CancelSpeedUpButtons
								status={status}
								onCancel={handleCancelTransaction}
								onSpeedUp={handleSpeedUpTransaction}
							/>
						)}
					</>
				)}
			</Flex>
			<CancelActivity
				onStatusChange={(status) => {
					setStatus(status);
					isCanceling.current = true;
				}}
				onClickButtonCancel={() => {}}
			/>
			{openSpeedUp && (
				<SpeedUpTransaction
					isOpen={true}
					txHash={txHash}
					transactionType={transactionType}
					onClose={() => setOpenSpeedUp(false)}
					onStatusChange={(status) => setStatus(status)}
				/>
			)}
		</AppLayout>
	);
};
