import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { toast } from 'react-toastify';
import { Box, Center, Container, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ethers } from 'ethers';
import { PublicKey } from '@solana/web3.js';
import { Toast } from '@/app-components/common';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import CreateWalletAnimationData from '@/assets/animations/create-wallet.json';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { useRecoveryWalletStore, useWalletStore } from '@/app-store';
import { useMutationSetBackupType } from '@/app-hooks/api/user/useMutationSetBackupType';
import { RECOVERY_TYPE } from '@/app-constants/backup';
import { restoreEncryptUnsecuredKeyShare } from '@/app-helpers/backup';
import { WalletType } from '@/app-cores/api';
import { AccountType } from '../RecoveryForNewTGAccount';
import { useMutationRecoveryUserWallet } from '@/app-hooks/api/mpc-wallet';
import { AuthClient } from '@/app-cores/auth';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { getWalletAddress } from '@/app-cores/mpc-wallet/wallet/helper';
import { WalletContractV4 } from '@/app-cores/mpc-wallet/ton/WalletContractV4';
import { TelegramCore } from '@/app-cores/telegram';
import { STORAGE_KEYS } from '@/app-constants/storage';

interface RecoveryByFileCallbackProps {}

export const RecoveryByFileCallback: React.FC<RecoveryByFileCallbackProps> = () => {
	const { recoveryFile } = useRecoveryWalletStore();
	const animateRef = useRef<LottieRefCurrentProps>(null);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const secretKey = searchParams.get('secretKey');
	const accountType = searchParams.get('accountType') as AccountType;

	const { data: userProfile } = useQueryUserProfile();
	const { mutateAsync: setBackupType } = useMutationSetBackupType();
	const [isRecoverySuccess, setIsRecoverySuccess] = useState(false);
	const { mutateAsync: recoveryUserWallet } = useMutationRecoveryUserWallet();
	const { setIsNonWallet } = useWalletStore();

	useEffect(() => {
		async function refreshKeyshare(keyShare: string) {
			const keyshareObj = JSON.parse(keyShare) as {
				ecdsaKeyShare: string;
				eddsaKeyShare: string;
			};
			const [ecdsaWallet, eddsaWallet] = await MpcWallet.refreshKeyShares({
				ecdsaKeyShare: keyshareObj.ecdsaKeyShare,
				eddsaKeyShare: keyshareObj.eddsaKeyShare,
			});
			localStorage.removeItem(STORAGE_KEYS.TOBI_USE_BACKUP_KEYSHARE);
			MpcWallet.storeUnSecuredKeyShare({
				ecdsaKeyShare2: ecdsaWallet[1],
				eddsaKeyShare2: eddsaWallet[1],
			});
			MpcWallet.persistKeyShare();
			try {
				const TonWallet = WalletContractV4.create({
					workchain: 0,
					publicKey: Buffer.from(eddsaWallet[0].publicKey()),
				});

				const payload = JSON.stringify(
					TelegramCore.withInitData({
						address: {
							evm: getWalletAddress(ethers.hexlify(ecdsaWallet[0].publicKey())),
							'ton-base': TonWallet.address.toString({
								bounceable: false,
							}),
							'solana': new PublicKey(eddsaWallet[0].publicKey()).toBase58(),
						},
						timestamp: Date.now(),
					}),
				);
				const signature = await MpcWallet.verifyWallet(payload);
				await recoveryUserWallet({
					payload,
					signature: signature,
				});
				try {
					await AuthClient.authenticate();
				} catch (error) {
					console.error('reauthenticate error', error);
				}
			} catch (error) {
				console.error('update user wallet after refresh error', error);
			}
			navigate(NAVIGATE_PATHS.CreateWallet.StoreBackupKey);
		}
		async function recoveryWallet() {
			if (!recoveryFile) return;
			const wallets = userProfile?.wallets || [];
			const mpcWallet = wallets.find((wallet) => !wallet.isAccountAbstraction);
			const tonWallet = wallets.find((wallet) => wallet.type === WalletType.TON);
			const reader = new FileReader();
			reader.onload = async function (event) {
				try {
					animateRef.current?.play();
					const keyShareEncryptedData = event.target.result as string;
					const keyShare = restoreEncryptUnsecuredKeyShare(keyShareEncryptedData, secretKey);

					if (accountType === 'newAccount') {
						await refreshKeyshare(keyShare);
					} else {
						await MpcWallet.recoverKeyShare(keyShare, {
							evmWallet: mpcWallet.address,
							tonWallet: tonWallet?.formatedAddress,
						});
					}
					setIsRecoverySuccess(true);
					setIsNonWallet(false);
				} catch (error) {
					dataDogAddError(error, {
						tags: {
							name: DATADOG_ERROR_TAGS.RECOVERY_WALLET,
							function: 'recoveryByUploadFile',
						},
					});
					console.error('recovery wallet by file upload error: ' + error);
					toast(
						<Toast
							type="error"
							title="Recovery Failed"
							message={`Failed to backup: Please check your secret key or backup file.`}
						/>,
					);
					navigate(-1);
				}
			};
			reader.readAsText(recoveryFile);
		}
		recoveryWallet();
		// eslint-disable-next-line
	}, [navigate, recoveryFile, secretKey, userProfile?.wallets, accountType]);

	useEffect(() => {
		const handleRecoverySuccess = async () => {
			try {
				if (!isRecoverySuccess) return;
				if (accountType === 'newAccount') {
					return navigate(NAVIGATE_PATHS.CreateWallet.StoreBackupKey);
				}
				if (userProfile && !userProfile.backupType) {
					// Just for the old user. Should not be call by new user
					await setBackupType({
						type: RECOVERY_TYPE.EMAIL,
						email: '',
					});
					MpcWallet.removeUnsecuredKeyShare2();
				}
				dataDogAddAction(DATADOG_ACTIONS.RECOVERY_BY_EMAIL_SUCCESS);
				navigate(NAVIGATE_PATHS.Home);
			} catch (error) {
				dataDogAddError(error, {
					tags: {
						name: DATADOG_ERROR_TAGS.RECOVERY_WALLET,
						function: 'updateBackupType',
					},
				});
				console.log('update backup method error', error);
			}
		};
		handleRecoverySuccess();
	}, [isRecoverySuccess, navigate, setBackupType, accountType, userProfile]);

	return (
		<PageNavigation>
			<Container>
				<Box height="100vh">
					<Center height="100%" flexDirection="column">
						<Lottie
							width={180}
							high={180}
							loop={false}
							autoplay={false}
							lottieRef={animateRef as any}
							animationData={CreateWalletAnimationData}
							style={{
								width: '180px',
								height: '180px',
							}}
						/>
						<Text fontSize="sm" mt={6}>
							Just one sec, Tobi is on its way back!
						</Text>
					</Center>
				</Box>
			</Container>
		</PageNavigation>
	);
};
