import { TelegramCore } from '@/app-cores/telegram';

const TELIFI = 'Telifi';
export const PREFIX = `@${TELIFI}`;

export const USE_BACKUP_KEYSHARE_VALUE = '1';

function storageKeyWithTgId(key: string) {
	return `${TelegramCore.getUserId()}@TOBI_${key}`;
}

export const STORAGE_KEYS = {
	TOBI_AUTH_TOKEN: storageKeyWithTgId('AUTH_TOKEN'),

	TOBI_KEYSHARE: storageKeyWithTgId('KEYSHARE'),
	TOBI_USE_BACKUP_KEYSHARE: storageKeyWithTgId('USE_BACKUP_KEYSHARE'),

	TOBI_UNSECURED_KEY_STORE: storageKeyWithTgId('UNSECURED_KEY_STORE'),
	TOBI_UNSECURED_KEY_STORE_OLD: `${PREFIX}:UnsecuredKeyStore`,

	TOBI_FIRST_TIME_VIEW_DAPP_PAGE: storageKeyWithTgId('FIRST_TIME_VIEW_DAPP_PAGE'),
	TOBI_TEST_NETS: storageKeyWithTgId('TEST_NETS'),
	TOBI_PAIRING_TOPICS: storageKeyWithTgId('PAIRING_TOPICS'),
	TOBI_PAIRING_ACTIVE_TIMES: storageKeyWithTgId('PAIRING_ACTIVE_TIMES'),
	TOBI_DAPP_HOSTNAME: storageKeyWithTgId('DAPP_HOSTNAME'),
	TOBI_WALLET_CONNECT_CLIENT_ID: storageKeyWithTgId('WALLET_CONNECT_CLIENT_ID'),
	TOBI_SHOW_INSTRUCTION_SELECT_CONTACT: storageKeyWithTgId('SHOW_INSTRUCTION_SELECT_CONTACT'),
	TOBI_IOS_DECIMAL_SEPARATOR: storageKeyWithTgId('IOS_DECIMAL_SEPARATOR'),
	TOBI_USER_SETTINGS_STORAGE: storageKeyWithTgId('USER_SETTINGS_STORAGE'),
	TOBI_TRANSACTION_WATCHER_STORAGE: storageKeyWithTgId('TRANSACTION_WATCHER_STORAGE'),
	TOBI_TON_CONNECT_LAST_EVENT_ID: storageKeyWithTgId('TON_CONNECT_LAST_EVENT_ID'),
	TOBI_T_APP_CONNECTIONS: storageKeyWithTgId('T_APP_CONNECTIONS'),
	TOBI_RETURN_STRATEGY_LOCAL_STORE_KEY: storageKeyWithTgId('TON_CONNECT_RETURN_STRATEGIES'),
	TOBI_REQUIRE_IMPORT_PRE_BACKUP_KEY_SHARE: storageKeyWithTgId('REQUIRE_IMPORT_PRE_BACKUP_KEY_SHARE'),
};
