import {
	CHAIN_CONFIG,
	CHAIN_CONFIG_MAP,
	ChainId,
	ID_TOBI_NATIVE_TOKENS,
	NATIVE_TOKEN_ADDRESS,
	NOT_SUPPORT_CHAINS,
	TEST_NETS,
	TOBI_CHAIN_ID_MAP,
	TOBI_CHAIN_NAME,
} from '@/app-constants/chains';
import { ethers, formatUnits } from 'ethers';
import { formatUnits as appFormatUnits } from '@/app-helpers/number';
import BigNumber from 'bignumber.js';
import {
	ITokenSearch,
	TokenImmutableDataDto,
	TokenInfo,
	TokenMarketDataDto,
	TokenMutableDataDto,
} from '@/app-cores/api/bff';
import { compareChain, isNativeToken } from '../address';
import { ChainType } from '@/app-contexts/wallet-provider/type';
import { truncateToFixed } from '@/app-helpers/number';

export const isEvmChain = (chainId: string | number) => {
	return CHAIN_CONFIG[chainId].type === ChainType.EVM;
};

export const isTonChain = (chainId: string | number) => {
	return compareChain(chainId, ChainId.TON);
};

export const isSolanaChain = (chainId: string | number) => {
	return compareChain(chainId, ChainId.SOL);
};
export const isTronChain = (chainId: string | number) => {
	return compareChain(chainId, ChainId.TRON);
};

export const isChainNeedApprove = (chainId: string | number) => {
	return isEvmChain(chainId) || isTronChain(chainId);
};

export const isTestnetChain = (chainId: string | number | ITokenSearch) => {
	const value: string = chainId && typeof chainId === 'object' ? getTokenInfo(chainId).chainId : (chainId as string);
	return TEST_NETS.some((e) => compareChain(e.id, value));
};

export function getNativeToken(chainId: string | ChainId) {
	if (!chainId)
		return {
			symbol: '',
			decimals: 18,
			name: '',
			address: '',
			chainId: '',
		};
	return CHAIN_CONFIG[chainId].nativeToken;
}
export function getWrapNativeToken(chainId: string | number) {
	if (!chainId)
		return {
			address: '',
		};
	return CHAIN_CONFIG[chainId].wrapNativeToken;
}

export function getRPC(chainId: string) {
	return {
		rpc: CHAIN_CONFIG[chainId]?.rpcUrls?.default.http || '',
		explorer: CHAIN_CONFIG[chainId].explorerUrl || '',
	};
}

export function getDecimalCount(amount = '', fixNumber = 8) {
	if (!amount) return 0;
	const decimalCount = String(amount)?.split('.')?.[1]?.length;
	return decimalCount > fixNumber ? fixNumber : decimalCount;
}
export function toGwei(gas: string | bigint) {
	if (typeof gas === 'bigint') return gas;
	const fixDecimal = getDecimalCount(gas, 8);
	const gasRounded = parseFloat(gas).toFixed(fixDecimal);
	return ethers.parseUnits(gasRounded, 'gwei');
}

// todo combine roundDownToDecimal vs parseValueToGwei to prevent a mistake
export function roundDownToDecimal(number: number | string, decimalPlaces: number): bigint {
	if (!number) return 0n;
	const numBig = new BigNumber(number);
	const rounded = numBig.toFixed(decimalPlaces, BigNumber.ROUND_DOWN);
	return BigInt(BigNumber(rounded).multipliedBy(1e18).toString(10));
}

export function parseValueToGwei(value: bigint, decimal: number): bigint {
	return (value * BigInt(Math.pow(10, decimal))) / BigInt(1e18);
}

export function getAmountCanSend(token: TokenInfo, gasFee: number, buffer = 1.5) {
	const amount = formatUnits(token.balance, token.decimals);
	if (!isNativeToken(token?.address)) return truncateToFixed(amount, token.decimals);
	const totalGasFee = gasFee * buffer;
	const maxAmountCanSent = +amount - totalGasFee;
	if (maxAmountCanSent < 0) return '0';
	return +amount < maxAmountCanSent ? amount.toString() : maxAmountCanSent.toString();
}

export function getSendBalance(amount: string, decimals: number) {
	const decimal = getDecimalCount(amount, decimals);
	const amountRounded = roundDownToDecimal(amount, decimal);
	return parseValueToGwei(amountRounded, decimals);
}

// get info of chain both support and not support
export const getChainInfo = (chainId: string | ChainId) => {
	const { name, logo } = CHAIN_CONFIG?.has(chainId) ? CHAIN_CONFIG[chainId] : NOT_SUPPORT_CHAINS[chainId] ?? {};
	return { name: name || chainId, logo };
};

export const getTobiChainName = (chainId: string | ChainId) => {
	return Object.keys(TOBI_CHAIN_ID_MAP).find((e) => compareChain(TOBI_CHAIN_ID_MAP[e], chainId));
};

export const getChainConfigByChainName = (chainName: string) => {
	return CHAIN_CONFIG_MAP[TOBI_CHAIN_ID_MAP[chainName]];
};

export const getNativeTobiId = (chainId: ChainId | string) => {
	return ID_TOBI_NATIVE_TOKENS[getTobiChainName(chainId)];
};

const getTokenAddressByPlatform = (platforms: Record<string, string>) => {
	let allTokens;
	if (platforms.address) {
		allTokens = [{ ...platforms, tobiChainName: getTobiChainName(platforms.chainId) }];
	} else {
		allTokens = Object.keys(platforms)
			.map((slug) => ({
				chainId: TOBI_CHAIN_ID_MAP[slug] as ChainId,
				address: platforms[slug],
				tobiChainName: slug,
			}))
			.filter((e) => e.tobiChainName);
	}
	const supportTokens = allTokens.filter((e) => e.chainId);

	return {
		allTokens,
		supportTokens,
		tokensDetails: supportTokens,
		// .concat(allTokens.find((e) => e.tobiChainName === TOBI_CHAIN_NAME.TRON))
		//.filter(Boolean), // token show in token details page, this list is = supportTokens + whitelist chain token: ex TRON
	};
};

type ParamAddress = { chainId: ChainId | string; address: string };
type ParamChainId = { chainId: ChainId | string; tobiId: string };
export const getTokenId = (token: ParamAddress | ParamChainId | ITokenSearch) => {
	if ((token as ITokenSearch)?.tokenImmutableDataDto) {
		const { chainId, idTobi } = getTokenInfo(token as ITokenSearch);
		const chain = chainId || token.chainId;
		return `${TOBI_CHAIN_ID_MAP[chain] || chain}${idTobi}`;
	}
	const { chainId, address, tobiId } = (token || {}) as any;
	if (tobiId) return (TOBI_CHAIN_ID_MAP[chainId] || chainId) + tobiId;
	return chainId + address?.toLowerCase();
};

type SimpleToken = { chainId: ChainId; address: string; tobiChainName: string };
export const getTokenInfo = (
	token: ITokenSearch,
): TokenImmutableDataDto &
	TokenMutableDataDto &
	TokenMarketDataDto & {
		chainTokens: SimpleToken[];
		allTokens: SimpleToken[];
		tokensDetails: SimpleToken[];
		balance?: string;
		logo: string;
		usdValue?: number;
		chainId: string | ChainId;
	} => {
	const { tokenImmutableDataDto, tokenMarketDataDto, tokenMutableDataDto, chainId, ...rest } = token ?? {};
	const {
		allTokens,
		supportTokens: chainTokens,
		tokensDetails,
	} = getTokenAddressByPlatform(tokenMutableDataDto?.platforms ?? {});
	const tokenInfo = {
		...token,
		...tokenMutableDataDto,
		...tokenMarketDataDto,
		...tokenImmutableDataDto,
		...rest,
		chainId: chainTokens?.length === 1 ? (chainTokens[0].chainId as any) : TOBI_CHAIN_ID_MAP[chainId] || chainId,
		chainTokens,
		allTokens,
		tokensDetails,
		logo: tokenMutableDataDto?.imageUrl,
	};
	if (tokenInfo?.decimals) {
		tokenInfo.decimals = +tokenInfo.decimals;
	}
	if (tokenImmutableDataDto?.isNative) tokenInfo.address = NATIVE_TOKEN_ADDRESS;
	else if (!tokenInfo?.address && tokenInfo?.platforms) {
		tokenInfo.address = tokenInfo?.platforms[getTobiChainName(tokenInfo.chainId)] ?? '';
	}
	return tokenInfo;
};

export const tokenHasBalance = (token: ITokenSearch) => !!token?.balance && token?.balance !== '0';

// if have a list of token same tobiId => combine them to one token with platforms = all platforms
const groupPlatform = (tokens: ITokenSearch[]): ITokenSearch[] => {
	const result = [];
	const pushed: Record<string, ITokenSearch> = {};
	tokens.forEach((token) => {
		const { idTobi, platforms, balance, chainId } = getTokenInfo(token);
		const existInfo = pushed[idTobi];
		if (existInfo) {
			existInfo.tokenMutableDataDto.platforms = {
				...existInfo.tokenMutableDataDto.platforms,
				...platforms,
			};
			if (balance) existInfo.balances[chainId] = balance;
			return;
		}

		const newToken = { ...token, balances: { [chainId]: balance } };
		delete newToken.balance;
		delete newToken.usdValue;
		pushed[idTobi] = newToken;
		result.push(newToken);
	});
	return result;
};

// map to children array and map balance to children
export const populateChildrenForToken = (result: ITokenSearch[]): ITokenSearch[] => {
	return groupPlatform(result).map((token) => {
		const { platforms, decimals, priceUsd } = getTokenInfo(token);
		const children = Object.keys(platforms).map((chain) => {
			const address = platforms[chain];
			if (!TOBI_CHAIN_ID_MAP[chain]) return null; // filter out chain not supported swap/send
			const balance = token?.balances?.[TOBI_CHAIN_ID_MAP[chain]] || '0';
			const usdValue = +(appFormatUnits(balance, decimals, { withFormat: false }) || 0) * priceUsd;
			const { chainId, ...rest } = token;
			return {
				...rest,
				balance,
				usdValue,
				tokenImmutableDataDto: { ...token.tokenImmutableDataDto, isNative: isNativeToken(address) },
				tokenMutableDataDto: { ...token.tokenMutableDataDto, platforms: { [chain]: platforms[chain] } },
			};
		});
		return { ...token, children: children.filter(Boolean) } as ITokenSearch;
	});
};

export const selectTokenDefaultFromUniversalToken = (token: ITokenSearch): ITokenSearch => {
	if (!token) return token;
	const result = populateChildrenForToken([token]);
	return result?.[0]?.children?.[0] || result?.[0] || token;
};
