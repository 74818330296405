import { ButtonHome } from '@/app-components/common';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';

import { useTokenLabels } from '@/app-hooks/api/tokens/useUserTokenSettings';
import { TokenLabel } from '@/app-views/portfolio/components/ListCrypto';
import { SettingToken } from '@/app-views/portfolio/pages/token/components/SettingToken';
import { FiDollarIcon, FiShareIcon } from '@/assets/icons';
import { Box, Center, Flex, Image, Skeleton, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Actions } from '../../../../../app-features/app-bot-connector/hooks';
import { useSharingStore } from '../../../../../app-components/common/Sharing';
import { TelegramCore, toStartParam } from '../../../../../app-cores/telegram';
import { formatUsd } from '../../../../../app-helpers/number';
import { useQueryUserInviteCodes } from '@/app-hooks/api/user/useQueryUserInviteCodes';
import { PriceChange } from '@/app-views/portfolio/pages/token/components/Balance';
import { ITokenSearch } from '@/app-cores/api/bff';
import { useWalletStore } from '@/app-store';
import { getTokenInfo } from '@/app-helpers/token';

interface BasicInformationProps {
	chainId: string;
	contractAddress: string;
	showSetting?: boolean;
	data: ITokenSearch | undefined;
}

export const BasicInformation: React.FunctionComponent<BasicInformationProps> = ({
	chainId,
	contractAddress,
	showSetting = true,
	data,
}) => {
	const {
		symbol,
		percentChange24h,
		name,
		imageUrl,
		priceUsd,
		volume24hUsd,
		fullyDilutedValueUsd,
		marketCapUsd,
		idTobi,
	} = getTokenInfo(data) || {};
	const percent24h = percentChange24h;

	const labels = useTokenLabels({ chainId, address: contractAddress });
	const { onOpen } = useSharingStore();
	const { t } = useTranslation();
	const { isNonWallet } = useWalletStore();
	const { data: inviteData } = useQueryUserInviteCodes();

	const onShareToken = () => {
		const message = t('sharing.tokenDetail', {
			symbol: symbol.toUpperCase(),
			name,
			usdPrice: priceUsd ? formatUsd(priceUsd) : 'Unknown',
			marketCapUsd: marketCapUsd ? formatUsd(marketCapUsd) : 'Unknown',
			fdvUsd: fullyDilutedValueUsd ? formatUsd(fullyDilutedValueUsd) : 'Unknown',
			volume24hUsd: volume24hUsd ? formatUsd(volume24hUsd) : 'Unknown',
		});

		const startParams = toStartParam({
			act: Actions.TOKEN_DETAIL,
			tobiId: idTobi,
			inviter: !isNonWallet ? TelegramCore.getUserId() : undefined,
			invitationCode: inviteData?.code?.code,
		});

		onOpen({
			link: `https://t.me/${import.meta.env.VITE_BOT_USERNAME}/wallet?startapp=${startParams}`,
			message,
		});
	};

	return (
		<Box display="flex" alignItems="center" justifyContent="center" flexDirection={'column'} gap={2} m={1} py={3}>
			<Center position={'relative'} width={'100%'}>
				<ButtonHome position="absolute" left={0} />
				<TokenLogo size={32} symbol={symbol} logo={imageUrl} />
				<Box position={'absolute'} right={0} display={'flex'} gap={3}>
					<Box cursor={'pointer'} onClick={onShareToken}>
						<FiShareIcon height={'16px'} />
					</Box>
					{showSetting && (
						<SettingToken
							{...{
								isSpam: labels?.includes('spam'),
								isHidden: labels?.includes('hidden'),
								contractAddress,
								chainId,
								style: {},
							}}
						/>
					)}
				</Box>
			</Center>

			<Flex
				as="span"
				alignItems={'center'}
				fontWeight={'500'}
				fontSize={'14px'}
				gap={'4px'}
				flexWrap={'wrap'}
				justifyContent={'center'}
			>
				{name ?? '??'} ({symbol || '??'}){' '}
				{labels?.length > 0 && (
					<Flex gap={'4px'}>
						{labels.map((e) => (
							<TokenLabel key={e} type={e} />
						))}
					</Flex>
				)}
			</Flex>

			<Flex gap={'6px'}>
				<Box as="span" transform="translateY(0.875rem)">
					<FiDollarIcon />
				</Box>
				<Text as="span" minHeight={'72px'} fontSize={'5xl'}>
					{formatUsd(priceUsd, { showDollar: false })}{' '}
					{Boolean(priceUsd && percent24h) && <PriceChange price={percent24h} fontSize="16px" />}
				</Text>
			</Flex>
		</Box>
	);
};
