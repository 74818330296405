import { BffServiceAPI } from '@/app-cores/api/bff';
import { QueryClientProvider } from '@tanstack/react-query';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import bs58 from 'bs58';
import { ToastContainer } from 'react-toastify';
import { Loading } from './app-components/common';
import AppContextProvider from './app-contexts';
import { WalletProvider } from './app-contexts/wallet-provider';
import { UserProfile, WalletType } from './app-cores/api';
import { MpcWallet, MpcWalletStatus } from './app-cores/mpc-wallet/wallet';
import { queryClient } from './app-cores/query-client';
import { compareAddress } from './app-helpers/address';
import { useQueryUserProfile } from './app-hooks/api/user/useQueryUserProfile';
import { useMutationInitApp } from './app-hooks/app';
import { useOnEventCallback } from './app-hooks/common';
import { MainRouter } from './app-router';
import { ErrorViewCreateWallet } from '@/app-components/common/ErrorView';
import { Maintenance } from './app-views/wallet/components/Maintenance';
import { useSyncUserWallet } from './app-hooks/api/mpc-wallet';
import { AuthClient } from './app-cores/auth';
import { DATADOG_ACTIONS, dataDogAddAction } from './app-services/monitor/datadog';
import { TelegramCore } from './app-cores/telegram';
import { RefreshWallet } from './app-views/wallet/components/RefreshKeyshareWarning';
import { useQueryMaintenanceNotice } from './app-hooks/api/configuration';
import i18n from './app-cores/i18n';
import { BackgroundApp } from './app-components/layout/BackGround';
import { isAndroid, isIOS } from 'react-device-detect';

console.log('App version: ', import.meta.env.VITE_VERSION);

interface MainProps {
	userProfile: UserProfile;
	isGetUserProfileError: boolean;
}

const Main = React.memo(({ isGetUserProfileError, userProfile }: MainProps) => {
	const [isInitWalletCompleted, setIsInitWalletCompleted] = useState(false);
	const [isWalletExisted, setIsWalletExisted] = useState(true);
	const [walletStatus, setWalletStatus] = useState<MpcWalletStatus>(null);
	const [isNeedToRefreshWallet, setIsNeedToRefreshWallet] = useState(false);
	const { mutateAsync: syncUserWallet } = useSyncUserWallet();
	const initRef = useRef(false);
	const trackWallet = () => {
		BffServiceAPI.getPortfolioBalance(true).catch(() => {});
	};

	const onInitWallet = useOnEventCallback(async () => {
		console.time('precomputed_wallet');
		const userTonAddress = userProfile.wallets?.find((w) => w.type === WalletType.TON)?.formatedAddress;
		const userEvmAddress = userProfile.wallets?.find((w) => w.type === WalletType.EVM)?.formatedAddress;
		const walletStatus = await MpcWallet.walletStatus({
			evmWallet: userEvmAddress,
			tonWallet: userTonAddress,
		});
		setWalletStatus(walletStatus);

		if (walletStatus === MpcWalletStatus.VALIDATED) {
			const localWallet = MpcWallet.getWalletAddress();
			const localEvmAddress = localWallet?.evmAddress;

			//TODO: Will remove after all users already sync sol wallet
			const syncUserSolWallet = async () => {
				const payload = JSON.stringify(
					TelegramCore.withInitData({
						address: {
							evm: localWallet.evmAddress,
							'ton-base': localWallet.tonAddress,
							'solana': localWallet.solAddress,
						},
						timestamp: Date.now(),
					}),
				);
				const signature = await MpcWallet.signEddsaMessage(Buffer.from(payload, 'utf-8'));
				await syncUserWallet({
					payload,
					signature: bs58.encode(signature),
				});
				await AuthClient.authenticate();
			};

			if (!compareAddress(localEvmAddress, userEvmAddress)) {
				await MpcWallet.removeCloudKeyShare();
				setIsWalletExisted(false);
			} else {
				MpcWallet.saveToCloudIfNeeded();
				const userSolAddress = userProfile.wallets?.find((w) => w.type === WalletType.SOL)?.formatedAddress;
				if (!userSolAddress && localWallet.solAddress) {
					try {
						await syncUserSolWallet();
						dataDogAddAction(DATADOG_ACTIONS.SYNC_USER_WALLETS.SOLANA.SUCCESS);
					} catch (error) {
						console.error('Sync sol wallet error', error);
						dataDogAddAction(DATADOG_ACTIONS.SYNC_USER_WALLETS.SOLANA.ERROR);
					}
				}
			}
		}
		setIsInitWalletCompleted(true);
		trackWallet();
		console.timeEnd('precomputed_wallet');
	});

	useEffect(() => {
		if (userProfile && !isGetUserProfileError && !initRef.current) {
			initRef.current = true;
			if (userProfile.isLegacyKeyshare) return setIsNeedToRefreshWallet(true);
			onInitWallet();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userProfile, isGetUserProfileError]);

	if (isNeedToRefreshWallet)
		return (
			<RefreshWallet
				onClose={() => {
					setIsNeedToRefreshWallet(false);
					setIsInitWalletCompleted(true);
				}}
				isOpen={isNeedToRefreshWallet}
			/>
		);

	if (!isInitWalletCompleted) {
		return <Loading />;
	}
	return (
		<AnimatePresence mode="wait">
			<>
				<RouterProvider router={MainRouter} />
				<BackgroundApp isWalletExisted={isWalletExisted} walletStatus={walletStatus} />
			</>
		</AnimatePresence>
	);
});

const Setup = React.memo(() => {
	const [initError, setInitError] = useState(false);
	const [initiated, setInitiated] = useState(false);
	const { isEnableMaintenance, isLoading: isLoadingMaintenance } = useQueryMaintenanceNotice();
	const { data: userProfile, isError: isGetUserProfileError } = useQueryUserProfile();
	console.log('render setup');
	const { mutateAsync: initApp } = useMutationInitApp({
		onCompleted: () => {
			setInitiated(true);
		},
	});
	const whiteListMaintenance = i18n.t('appConfigs.whiteListMaintenance') || '';
	const isWhiteListMaintenance =
		whiteListMaintenance.includes(TelegramCore.getUserId().toString()) ||
		whiteListMaintenance.includes(TelegramCore.getUser()?.username);

	useEffect(() => {
		async function getInitAppResult() {
			const success = await initApp();
			setInitError(!success || isGetUserProfileError);
		}
		getInitAppResult();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGetUserProfileError]);

	if (!initiated || isLoadingMaintenance) return <Loading />;

	if (initError) return <ErrorViewCreateWallet />;

	if (isEnableMaintenance && !isLoadingMaintenance && !isWhiteListMaintenance) return <Maintenance />;

	return <Main userProfile={userProfile} isGetUserProfileError={isGetUserProfileError} />;
});

const App = () => {
	useLayoutEffect(() => {
		if (isIOS) {
			document.body.style.overflowY = 'hidden';
		}
		if (isAndroid) {
			document.body.classList.add('tobi_android');
		}
	}, []);
	return (
		<WebAppProvider>
			<TonConnectUIProvider
				manifestUrl={`${import.meta.env.VITE_APP_URL}/assets/miniapp/manifest.json`}
				uiPreferences={{ theme: THEME.LIGHT }}
				actionsConfiguration={{
					twaReturnUrl: `https://t.me/${import.meta.env.VITE_BOT_USERNAME}/wallet`,
				}}
			>
				<QueryClientProvider client={queryClient}>
					<WalletProvider>
						<AppContextProvider>
							<Setup />
							<ToastContainer
								position="top-center"
								hideProgressBar
								bodyClassName="small"
								closeButton={false}
								newestOnTop
								draggableDirection="y"
								draggablePercent={50}
								icon={false}
								closeOnClick
								autoClose={3000}
							/>
						</AppContextProvider>
					</WalletProvider>
				</QueryClientProvider>
			</TonConnectUIProvider>
		</WebAppProvider>
	);
};

export default App;
