import dayjs from 'dayjs';
import bs58 from 'bs58';
import { ethers, isAddress, isHexString, toUtf8String } from 'ethers';
import { STORAGE_KEYS } from '@/app-constants/storage';
import { formatCurrency, formatNumber } from '@/app-helpers/number';
import { CHAIN_CONFIG } from '@/app-constants/chains';
import { set } from 'lodash-es';
import { ConnectionType } from '@/app-types/connection-type';

/**
 * Converts hex to utf8 string if it is valid bytes
 */
function convertHexToUtf8(value: string) {
	if (isHexString(value)) {
		return toUtf8String(value);
	}

	return value;
}

/**
 * Gets message from various signing request methods by filtering out
 * a value that is not an address (thus is a message).
 * If it is a hex string, it gets converted to utf8 string
 */
export function getSignParamsMessage(params: string[]) {
	const message = params.filter((p) => !isAddress(p))[0];
	try {
		return convertHexToUtf8(message);
	} catch (error) {
		return message;
	}
}

/**
 * Gets data from various signTypedData request methods by filtering out
 * a value that is not an address (thus is data).
 * If data is a string convert it to object
 */
export function getSignTypedDataParamsData(params: string[]) {
	const data = params.filter((p) => !isAddress(p))[0];

	if (typeof data === 'string') {
		return JSON.parse(data);
	}

	return data;
}

export function getHostName(url: string) {
	if (!url) return '';
	const domain = new URL(url);
	return domain.hostname || url;
}

export const paringTopicManager = {
	setTopic: (pairingTopic: string) => {
		const hostName = paringTopicManager.getHostName();
		if (!hostName) return;
		const topics = paringTopicManager.getTopics() || {};
		topics[hostName] = pairingTopic;
		localStorage.setItem(STORAGE_KEYS.TOBI_PAIRING_TOPICS, JSON.stringify(topics));
	},
	getTopics: () => {
		const topicsStr = localStorage.getItem(STORAGE_KEYS.TOBI_PAIRING_TOPICS);
		if (!topicsStr) return {};
		const topics = JSON.parse(topicsStr);
		return topics;
	},
	getTopic: (hostName: string) => {
		const topics = paringTopicManager.getTopics();
		return topics?.[hostName] || '';
	},
	setHostName: (hostname: string) => {
		console.log('🚀 ~ hostname:', hostname);
		if (!hostname) return;
		localStorage.setItem(STORAGE_KEYS.TOBI_DAPP_HOSTNAME, hostname);
	},
	getHostName: () => {
		return localStorage.getItem(STORAGE_KEYS.TOBI_DAPP_HOSTNAME);
	},
	removeTopic: (hostname) => {
		const paringTopics = paringTopicManager.getTopics();
		delete paringTopics[hostname];
		localStorage.setItem(STORAGE_KEYS.TOBI_PAIRING_TOPICS, JSON.stringify(paringTopics));
	},
};

export const paringTrackingManager = {
	setActiveTime: (type: ConnectionType, pairingTopic: string) => {
		const activeTimes = paringTrackingManager.getParingActiveTimes();
		set(activeTimes, [type, pairingTopic], dayjs().format('MMM DD, YYYY - HH:mm'));
		localStorage.setItem(STORAGE_KEYS.TOBI_PAIRING_ACTIVE_TIMES, JSON.stringify(activeTimes));
	},
	removeParingTopic: (type: ConnectionType, pairingTopic: string) => {
		const activeTimes = paringTrackingManager.getParingActiveTimes();
		if (activeTimes[type] && activeTimes[type][pairingTopic]) {
			delete activeTimes[type][pairingTopic];
		}
		localStorage.setItem(STORAGE_KEYS.TOBI_PAIRING_ACTIVE_TIMES, JSON.stringify(activeTimes));
	},
	getParingActiveTimes: () => {
		const activeTimes = localStorage.getItem(STORAGE_KEYS.TOBI_PAIRING_ACTIVE_TIMES);
		if (!activeTimes) return {};
		return JSON.parse(activeTimes);
	},
};
export function getChainId(chain: string = '') {
	const chainIds = chain.split(':');
	if (['solana', 'tron'].includes(chainIds[0])) return chainIds[0];
	return chainIds[1];
}

export function getTransactionFee(gas: string, gasPrice: bigint, chainId: string) {
	if (!gas || !gasPrice || !chainId) return '';
	const fee = BigInt(gas) * gasPrice;
	const feeByEther = ethers.formatEther(fee);
	return feeByEther;
}

export function getSolanaSignParamsMessage(message: string) {
	try {
		const buffer = bs58.decode(message);
		const utf8String = buffer.toString('utf-8');
		return utf8String;
	} catch (error) {
		return message;
	}
}
