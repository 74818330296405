import { FiEmptyBoxIcon } from '@/assets/icons';
import { Button, Center, Text } from '@chakra-ui/react';
interface EmptyConnectionProps {
	onAddConnection: () => void;
}
export const EmptyConnection: React.FC<EmptyConnectionProps> = ({ onAddConnection }) => {
	return (
		<Center flexDirection="column" height="100vh" px={5}>
			<FiEmptyBoxIcon />
			<Text mt={3} mb={5} fontSize="sm">
				you don’t have any dApp connections set up. Click below to establish a new connection.
			</Text>
			<Button width="100%" size="lg" colorScheme="cyan" onClick={onAddConnection}>
				New Connection
			</Button>
		</Center>
	);
};
