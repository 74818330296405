import { getBlockChainNameByAddress } from '@/app-helpers/address';
import { useDebounce } from '@/app-hooks/common';
import { useQuery } from '@tanstack/react-query';
import { getDefaultProvider, isAddress } from 'ethers';
import { Connection } from '@solana/web3.js';
import { getDomainKeySync, NameRegistryState } from '@bonfida/spl-name-service';
import { solana } from '@/app-constants/chains';
const provider = getDefaultProvider('mainnet');
async function getAddressFromENS(domainName: string) {
	try {
		if (!domainName.includes('.')) return null;
		return await provider.resolveName(domainName);
	} catch (error) {
		return null;
	}
}
async function getPublicKeyFromSolDomain(domain: string): Promise<string> {
	if (!domain.includes('.')) return null;
	const SOLANA_CONNECTION = new Connection(solana.rpcUrls.default.http);
	const { pubkey } = await getDomainKeySync(domain);
	const owner = (await NameRegistryState.retrieve(SOLANA_CONNECTION, pubkey)).registry.owner.toBase58();
	return owner;
}

const useEns = (ens: string, debounceTime = 500) => {
	const ensDebounce = useDebounce(ens.toLowerCase(), debounceTime);
	const { data, isFetching } = useQuery({
		queryKey: ['use-ens', ensDebounce],
		queryFn: () => getAddressFromENS(ensDebounce),
		enabled: !!ensDebounce,
		staleTime: Infinity,
	});
	return { address: data, isFetching };
};

const useSns = (sns: string, debounceTime = 500) => {
	const ensDebounce = useDebounce(sns.toLowerCase(), debounceTime);
	const { data, isFetching } = useQuery({
		queryKey: ['use-sns', ensDebounce],
		queryFn: () => getPublicKeyFromSolDomain(ensDebounce),
		enabled: !!ensDebounce,
		staleTime: Infinity,
	});
	return { address: data, isFetching };
};

/**
 *
 * @param value 0x... or vitalik.eth
 * @param debounceTime
 * @returns address: 0x...
 */
export const useExtractAddress = (value: string, debounceTime: number) => {
	const { address, isFetching } = useEns(isAddress(value) ? '' : value, debounceTime);
	const { address: solAddress, isFetching: isFetchingSolAddress } = useSns(
		isAddress(value) ? '' : value,
		debounceTime,
	);
	return {
		address: getBlockChainNameByAddress(value) ? value : address || solAddress,
		isFetching: isAddress(value) ? false : isFetching || isFetchingSolAddress,
	};
};
