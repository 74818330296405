import { TelegramCore } from '@/app-cores/telegram';
import { datadogRum } from '@datadog/browser-rum';

if (window.location.host !== 'telifi.local:3001') {
	console.log('initializing datadog');
	datadogRum.init({
		applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
		clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
		site: 'ap1.datadoghq.com',
		service: import.meta.env.VITE_DATADOG_SERVICE_NAME,
		version: import.meta.env.VITE_VERSION,
		env: import.meta.env.VITE_DATADOG_ENV,
		sessionSampleRate: +import.meta.env.VITE_DATADOG_SESSION_SAMPLE_RATE,
		sessionReplaySampleRate: +import.meta.env.VITE_DATADOG_SESSION_REPLAY_SAMPLE_RATE,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: false,
		defaultPrivacyLevel: 'mask-user-input',
		beforeSend(event) {
			if ((event?.error as any)?.type === 'TypeError') {
				return false;
			}
			return true;
		},
	});

	datadogRum.startSessionReplayRecording();
}

export function dataDogAddUserInfo(wallet?: { evm: string; ton: string; sol: string }) {
	try {
		datadogRum.setUser({
			id: TelegramCore?.getUserId()?.toString(),
			name: TelegramCore?.getUser().username,
			...(wallet && {
				evmWallet: wallet?.evm,
				solWallet: wallet?.sol,
				tonWallet: wallet?.ton,
			}),
		});
	} catch (error) {
		console.log('datadog setUser error:', error);
	}
}
export function dataDogAddError(error: unknown, context?: object) {
	try {
		datadogRum.addError(error, context);
	} catch (error) {
		console.log('datadog addError failed:', error);
	}
}

export function dataDogAddAction(name: string, context?: object) {
	try {
		datadogRum.addAction(name, context);
	} catch (error) {
		console.log('datadog addAction failed:', error);
	}
}

export const DATADOG_ERROR_TAGS = {
	CREATE_WALLET: 'createWallet',
	RECOVERY_WALLET: 'recoveryWallet',
	BOT_REDIRECT: 'botRedirect',
	TRADE: 'trade',
	SEND: 'send',
	DEPOSIT: 'deposit',
};
export const DATADOG_ACTIONS = {
	FROM_INVITE_CODE: 'from_invite_code',
	CREATE_NEW_ACCOUNT: 'create_new_account',
	CREATE_ACCOUNT_SUCCESS: 'create_account_success',
	BACKUP_METHOD: {
		'email': 'backup_selected_email_method',
		'google-drive': 'backup_selected_google_drive_method',
	},
	BACKUP_CREATE_PASSWORD: 'backup_create_password',
	BACKUP_VERIFY_OTP: 'backup_verify_otp',
	BACKUP_SENT_MAIL: 'backup_sent_mail',
	BACKUP_SENT_TELEGRAM: 'backup_sent_telegram',
	BACKUP_CONFIRM_RECEIVE_EMAIL: 'backup_confirm_receive_email',
	BACKUP_GG_DRIVER: 'backup_gg_driver',
	BACKUP_GG_SUCCESS: 'backup_gg_success',
	BACKUP_GG_FAIL: 'backup_gg_fail',
	BACKUP_GG_RETRY: 'backup_gg_retry',
	BACKUP_TG_CLOUD: {
		SUCCESS: 'backup_tg_cloud_success',
		ERROR: 'backup_tg_cloud_error',
	},

	RECOVERY_ACCOUNT: 'recovery_account',
	RECOVERY_BY_EMAIL_SUCCESS: 'recovery_by_email_success',
	RECOVERY_BY_EMAIL_FAIL: 'recovery_by_email_fail',
	RECOVERY_BY_GG_DRIVER_SUCCESS: 'recovery_by_gg_driver_success',
	RECOVERY_BY_GG_DRIVER_FAIL: 'recovery_by_gg_driver_fail',
	START_APP: {
		RECOMPUTE_LOCAL_KEYSHARE: 'start_app_recompute_local_keyshare',
		RECOMPUTE_LOCAL_KEYSHARE_SUCCESS: 'start_app_recompute_local_keyshare_success',
		RECOMPUTE_LOCAL_KEYSHARE_ERROR: 'start_app_recompute_local_keyshare_error',
		RECOMPUTE_TG_KEYSHARE: 'start_app_recompute_tg_keyshare',
		RECOMPUTE_TG_KEYSHARE_SUCCESS: 'start_app_recompute_tg_keyshare_success',
		RECOMPUTE_TG_KEYSHARE_ERROR: 'start_app_recompute_tg_keyshare_error',
	},

	TRADE: 'trade',
	BOT_TRADE: 'trade_bot',
	TRADE_SELECT_TOKEN_IN: 'trade_select_token_in',
	TRADE_SELECT_TOKEN_OUT: 'trade_select_token_out',
	TRADE_USE_MAX: 'trade_use_max',
	TRADE_SWITCH_INPUT_MODE: 'trade_switch_input_mode',
	TRADE_SELECT_ROUTE: 'trade_select_route',
	TRADE_SWAP: 'trade_swap',
	TRADE_CONFIRM_TRANSACTION: 'trade_confirm_transaction',
	TRADE_TRANSACTION_SUCCESS: 'trade_transaction_success',
	TRADE_TRANSACTION_ERROR: 'trade_transaction_error',
	TRADE_SIGN_AND_BROADCAST_ERROR: 'trade_sign_and_broadcast_error',
	TRADE_LIMIT_SIGN_AND_BROADCAST_ERROR: 'trade_limit_sign_and_broadcast_error',
	TRADE_TRY_AGAIN: 'trade_try_again',
	TRADE_GO_TO_HOME: 'trade_go_to_home',
	TRADE_GO_TO_ACTIVITY: 'trade_go_to_activity',
	TRADE_DEBUG: 'trade_debug',

	SEND: 'send',
	SEND_VIEW_DETAIL: 'send_view_detail',
	SEND_CONFIRM_TRANSACTION: 'send_confirm_transaction',
	SEND_TRANSACTION_SUCCESS: 'send_transaction_success',
	SEND_TRANSACTION_ERROR: 'send_transaction_error',
	SEND_TRY_AGAIN: 'send_try_again',
	SEND_GO_TO_HOME: 'send_go_to_home',
	SEND_GO_TO_ACTIVITY: 'send_go_to_activity',

	DEPOSIT: 'deposit',
	DEPOSIT_TOP_UP_GAS: 'deposit_top_up_gas',
	DEPOSIT_TOP_UP_GAS_BY_DEPOSIT: 'deposit_top_up_gas_by_deposit',
	DEPOSIT_USE_TON_CONNECT: 'deposit_use_ton_connect',
	DEPOSIT_TON_CONNECT_SUCCESS: 'deposit_ton_connect_success',
	DEPOSIT_TON_CONNECT_FAIL: 'deposit_ton_connect_fail',
	DEPOSIT_TON_CONNECT_SIGN_TRANSACTION: {
		SUCCESS: 'deposit_ton_connect_sign_transaction_success',
		FAIL: 'deposit_ton_connect_sign_transaction_fail',
	},
	DEPOSIT_TON_DISCONNECT: 'deposit_ton_disconnect',

	DEPOSIT_USE_WALLET_CONNECT: 'deposit_use_wallet_connect',
	DEPOSIT_WALLET_CONNECT_SUCCESS: 'deposit_wallet_connect_success',
	DEPOSIT_WALLET_CONNECT_FAIL: 'deposit_wallet_connect_fail',
	DEPOSIT_WALLET_CONNECT_SIGN_TRANSACTION: {
		SUCCESS: 'deposit_wallet_connect_sign_transaction_success',
		FAIL: 'deposit_wallet_connect_sign_transaction_fail',
	},
	DEPOSIT_WALLET_CONNECT_DISCONNECT: 'deposit_wallet_connect_disconnect',

	DEPOSIT_USE_PHANTOM: 'deposit_use_phantom',
	DEPOSIT_PHANTOM_CONNECT_SUCCESS: 'deposit_phantom_connect_success',
	DEPOSIT_PHANTOM_CONNECT_FAIL: 'deposit_phantom_connect_fail',
	DEPOSIT_PHANTOM_CONNECT_SIGN_TRANSACTION: {
		SUCCESS: 'deposit_phantom_connect_sign_transaction_success',
		FAIL: 'deposit_phantom_connect_sign_transaction_fail',
	},
	DEPOSIT_PHANTOM_DISCONNECT: 'deposit_phantom_disconnect',

	DEPOSIT_BY_COPY_ADDRESS: 'deposit_by_copy_address',
	DEPOSIT_BY_SCAN_QR_CODE: 'deposit_by_scan_qr_code',

	DEPOSIT_BY_COPY_CHAIN_ADDRESS: 'deposit_by_copy_chain_address',
	DEPOSIT_BY_SCAN_CHAIN_QR_CODE: 'deposit_by_scan_chain_qr_code',

	DEPOSIT_BY_TRANSAK: 'deposit_by_transak',
	DEPOSIT_BY_TRANSAK_SELECTED: {
		TON: 'deposit_by_transak_ton_assets',
		SOL: 'deposit_by_transak_solana_assets',
		EVM: 'deposit_by_transak_evm_assets',
	},
	DEPOSIT_BY_TRANSAK_RESULT: {
		BACK: 'deposit_by_transak_click_back',
		SUCCESS: 'deposit_by_transak_success',
		FAILED: 'deposit_by_transak_failed',
		REDIRECT_BACK: 'deposit_by_transak_redirect_back',
	},

	DEPOSIT_TRADE: {
		BUY_WITH_TRANSAK: 'deposit_trade_buy_with_transak',
		GET: {
			CLICK_GET: 'deposit_trade_click_get',
			TRANSAK: 'deposit_trade_get_or_top_up_by_transak',
			DEPOSIT: 'deposit_trade_get_or_top_up_by_deposit',
		},
		TOP_UP_GAS: 'deposit_trade_top_up-gas',
	},

	BOT_ADD_TO_CHAT: 'bot_add_to_chat',
	BOT_LEAR_MORE: 'bot_learn_more',

	PORTFOLIO: 'portfolio',
	PORTFOLIO_PULL_TO_REFRESH: 'portfolio_pull_to_refresh',
	PORTFOLIO_REFRESH: 'portfolio_refresh',

	RECOVERY_NEW_TG_WALLET: {
		RECOVERY: 'recovery_my_account',
		SUCCESS: 'recovery_my_account_success',
		ERROR: 'recovery_my_account_error',
		ECDSA_ERROR: 'recovery_my_account_ecdsa_error',
		EDDSA_ERROR: 'recovery_my_account_eddsa_error',
	},

	SYNC_USER_WALLETS: {
		SOLANA: {
			SUCCESS: 'sync_sol_wallet_success',
			ERROR: 'sync_sol_wallet_error',
		},
	},
};
export { datadogRum };
