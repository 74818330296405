import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { useTokenLabels } from '@/app-hooks/api/tokens/useUserTokenSettings';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { TokenLabel } from '@/app-views/portfolio/components/ListCrypto';
import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import { formatUnits, formatUsd } from '../../../../../app-helpers/number';
import { useTokensBalance } from '../../../../../app-hooks/api/portfolio/usePortfolioBalance';
import { getTokenInfo } from '@/app-helpers/token';
import { ITokenSearch } from '@/app-cores/api/bff';
import truncate from 'lodash/truncate';

interface BasicInformationProps {
	chainId: string;
	contractAddress: string;
	tobiId: string;
	data: ITokenSearch;
}

// todo move to another file
export const PriceChange = ({
	price,
	fontSize,
	bracket = false,
}: {
	bracket?: boolean;
	fontSize?: string;
	price?: number;
}) => {
	return (
		<Text
			as="span"
			color={!price ? undefined : price > 0 ? 'green.200' : 'red.600'}
			fontWeight={'500'}
			fontSize={fontSize}
		>
			{bracket && '('}
			{price > 0 ? '+' : '-'}
			{+Math.abs(price).toFixed(2)}%{bracket && ')'}
		</Text>
	);
};

export const TokenBalance: React.FunctionComponent<BasicInformationProps> = ({
	chainId,
	contractAddress,
	tobiId,
	data,
}) => {
	const { data: tokens } = useTokensBalance(tobiId, data);

	const labels = useTokenLabels({ chainId, address: contractAddress });

	if (!tokens?.length) return null;

	const hasTag = labels.length > 0;
	return (
		<Box mt={8}>
			<Box display="flex" marginY={3} justifyContent="space-between">
				<Text fontWeight={500}>My Holding</Text>
			</Box>

			<Box border={`1px solid ${BASE_BORDER_COLOR}`} borderRadius={'1rem'}>
				{tokens.map((token, i) => {
					const { logo, symbol, name, priceUsd, percentChange24h, decimals, balance, usdValue, chainId } =
						getTokenInfo(token);
					return (
						<Fragment key={i}>
							<Box
								display="flex"
								px={4}
								py={3}
								borderRadius={'1rem'}
								alignItems={'center'}
								justifyContent={'space-between'}
								fontSize={'.75rem'}
							>
								<TokenLogo size={32} symbol={symbol} logo={logo} chainId={chainId} />
								<Box display="flex" flexDirection="column" flex={1} ms={3} gap={'4px'}>
									<Flex gap={'4px'} alignItems={'center'}>
										<Text as="b" fontSize={'14px'}>
											{hasTag ? symbol : truncate(name, { length: 30 })}
										</Text>
										{labels.map((e) => (
											<TokenLabel type={e} key={e} />
										))}
									</Flex>
									<Flex as="span" color={'gray'} alignItems={'center'} gap={'4px'} fontWeight={'500'}>
										{formatUsd(priceUsd)}
										<PriceChange price={percentChange24h ?? 0} />
									</Flex>
								</Box>
								<Box display="flex" flexDirection="column" alignItems={'flex-end'} gap={'4px'}>
									<Flex as="b" gap={'4px'} alignItems={'flex-end'}>
										{formatUsd(usdValue)}
									</Flex>
									<Text as="span" fontSize={'12px'}>
										<Text as="span" color={'gray'} fontWeight={'500'}>
											{balance && formatUnits(balance, decimals)} {symbol}
										</Text>
									</Text>
								</Box>
							</Box>
							{i !== tokens?.length - 1 && <Divider borderColor={'gray.200'} variant={'dashed'} />}
						</Fragment>
					);
				})}
			</Box>
		</Box>
	);
};
