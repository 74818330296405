import { TransactionType } from '@/app-types';

export const NAVIGATE_PATHS = {
	Home: '/',
	CreateWallet: {
		Onboarding: '/on-boarding',
		CreatePassword: '/create-password',
		StoreBackupKey: '/store-backup-key',
		BackupGdriveCallback: '/backup-gdrive-callback',
	},
	Recovery: {
		SelectMethod: '/recovery',
		InputSecretKey: '/recovery/input-secret-key',
		GDriveCallback: '/recovery/gdrive-callback',
		ICloudCallback: '/recovery/icloud-callback',
		FileUploadCallBack: '/recovery/file-upload-callback',
		NewTGAccount: '/recovery/new-tg-count',
	},

	RefreshKeyshare: '/refresh-keyshare',
	ProcessRefreshKeyshare: '/refresh-keyshare/processing',
	RefreshKeyshareMethod: '/refresh-keyshare/select-method',
	AllSet: '/all-set',
	CommingSoon: '/comming-soon',
	Deposit: {
		DepositMethod: '/deposit',
		Amount: '/deposit/amount',
		ConfirmTransaction: '/deposit/transaction',
		Transak: '/deposit/transak',
		DepositResult: '/deposit/transaction-result',
	},
	AccountSettings: {
		Menu: '/settings',
		ContactSupport: '/settings/contact-support',
		InvitationCode: '/settings/invitation',
		InvitationCodeDetail: '/settings/invitation/detail',
		TermOfService: '/settings/term-of-service',
		PrivacyPolicy: '/settings/privacy-policy',
	},
	Portfolio: {
		Main: '/portfolio',
		asset: 'portfolio/detail',
	},
	Swap: {
		Main: '/swap',
		ConfirmTransaction: '/swap/confirm-transaction',
	},
	Limit: {
		Main: '/limit',
		LimitHistory: '/limit/history',
		ConfirmTransaction: '/limit/confirm-transaction',
	},
	Wallet: {
		ScanQRCode: '/scan-qrcode',
		Receive: {
			SelectToken: '/receive/select-token',
		},
		Sent: {
			Sent: '/sent',
			ConfirmTransaction: '/sent/confirm-transaction',
		},
		WalletConnect: '/wallet-connect',
		WCconnection: '/wc-connection',
	},
	transactionResult: (type: TransactionType | string, chainId: string, hash: string) =>
		`/transaction-result/${type}/${chainId}/${hash}`,
	Dapp: {
		DappBrowser: '/dapp-browser',
		TobiDapp: '/tobi-dapp',
		GoogleSearchControl: '/google-search-control',
	},
	CancelPendingTransactions: '/cancel-pending-transactions',
	TOBI_FARM: '/tobi-farm',
	Bot: {
		ConfirmSendTransaction: '/bot-confirm-send-transaction',
	},
	RequestFund: {
		Main: '/request-fund',
		EditAmount: '/request-fund/edit-amount',
		Confirm: '/request-fund/confirm',
		Result: '/request-fund/result',
		Activity: '/request-fund/history',
		requestDetail: (id: string) => `/request-fund/${id}`,
	},
	Testing: {
		AppIcon: '/icons',
		TronNetwork: '/tron-network',
	},
};
