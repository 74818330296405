import { BlockHeader } from '@/app-components/common';
import { Link } from 'react-router-dom';
import { Box, Image, Skeleton, Text } from '@chakra-ui/react';
import React from 'react';
import { SnapList, SnapItem } from 'react-snaplist-carousel';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useTranslation } from 'react-i18next';
import { useQueryDapps } from '@/app-hooks/api/dapps/useQueryDapp';
import { TDapp } from '@/app-cores/api/dapp/type';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
interface DappItemProps {
	data: TDapp;
}
const DappItem: React.FC<DappItemProps> = ({ data }) => {
	return (
		<Link to={`${NAVIGATE_PATHS.Dapp.TobiDapp}/?dappUrl=${data.url}&parentUrl=/`}>
			<Box
				backgroundColor="gray.100"
				width={20}
				display="flex"
				justifyItems="center"
				alignItems="center"
				flexDirection="column"
				padding={3}
				gap={'8px'}
				borderRadius="1rem"
			>
				<Image src={data.logoUrl} width={8} height={8} borderRadius="50%" loading="lazy" />
				<Text fontSize="xs" whiteSpace="nowrap">
					{data.name}
				</Text>
			</Box>
		</Link>
	);
};

export const Dapp = () => {
	const { t } = useTranslation();
	const { data, isLoading } = useQueryDapps({
		featuredWeightGte: '1',
	});
	const isShowDappBrowser = useQueryFeatureConfig(FeatureConfig.DAPP_BROWSER);

	return (
		<Box mt={7}>
			<BlockHeader
				leftContent={t('dapp.dAppsConnect')}
				rightContent={isShowDappBrowser ? t('dapp.dappBrowser') : ''}
				to={NAVIGATE_PATHS.Dapp.DappBrowser}
			/>
			<SnapList direction="horizontal">
				{isLoading
					? new Array(8).fill('').map((_, el) => (
							<SnapItem key={el} snapAlign="center" margin={{ right: '8px' }}>
								<Skeleton endColor={'gray.200'} height="82px" width="82px" borderRadius="1rem" />
							</SnapItem>
					  ))
					: data?.Dapps?.map((dapp, index) => {
							return (
								<SnapItem
									key={index}
									snapAlign="center"
									margin={{
										right: '8px',
									}}
								>
									<DappItem data={dapp} />
								</SnapItem>
							);
					  })}
			</SnapList>
		</Box>
	);
};
