import { MAINNET_CHAINS, TEST_NETS } from '@/app-constants/chains';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { useMemo } from 'react';

const useChainList = () => {
	const isSupportTestnet = useQueryFeatureConfig(FeatureConfig.BERA_TESTNET_SUPPORT);
	return useMemo(() => {
		const chainsSupport = isSupportTestnet ? MAINNET_CHAINS.concat(TEST_NETS) : MAINNET_CHAINS;
		const chainsOptions = chainsSupport.map((el) => ({ value: el.id.toString(), label: el.name }));
		return { chainsSupport, chainsOptions };
	}, [isSupportTestnet]);
};
export default useChainList;
