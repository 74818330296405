import React from 'react';

interface Iprops {
	width?: number;
	height?: number;
	className?: string;
}

export const MegaphoneIcon: React.FC<Iprops> = ({ className, width = 24, height = 24 }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			className={className}
			viewBox="0 0 24 24"
			fill="none"
		>
			<g clipPath="url(#clip0_7338_4091)">
				<path
					d="M12.4845 22.1751L11.8028 22.3574C11.2796 22.4965 10.7276 22.2951 10.4205 21.8534L7.50226 17.6916L12.408 18.2147L13.3391 20.4325C13.6365 21.1525 13.2383 21.9734 12.4845 22.1751ZM3.01426 9.02761L1.43026 9.45473C0.403131 9.73298 -0.211494 10.789 0.0671314 11.8165L1.07026 15.5605C1.34401 16.5876 2.40001 17.1974 3.43201 16.9187L5.01601 16.4965L3.01426 9.02761ZM18.7995 16.5696L14.9164 2.07548C14.7998 1.63935 14.2819 1.45673 13.9174 1.72223L4.40438 8.65748L6.40501 16.1237L18.111 17.374C18.5595 17.422 18.9165 17.0046 18.7995 16.5696ZM19.3886 4.56736C19.2791 4.56736 19.1693 4.5396 19.068 4.4811C18.7605 4.30373 18.6555 3.91073 18.8329 3.6036L19.7618 1.99523C19.9388 1.68736 20.3329 1.58235 20.6393 1.7601C20.9468 1.93748 21.0518 2.33048 20.8744 2.6376L19.9455 4.24598C19.8263 4.45223 19.6103 4.56736 19.3886 4.56736ZM20.3059 9.04073C20.022 9.04073 19.7625 8.85136 19.6856 8.56448C19.5941 8.22173 19.7974 7.86961 20.1401 7.77736L21.9341 7.29698C22.2788 7.20548 22.6294 7.40798 22.7216 7.75148C22.8131 8.09423 22.6099 8.44636 22.2671 8.53861L20.4731 9.01898C20.4165 9.03398 20.361 9.04073 20.3059 9.04073ZM23.3569 14.3024C23.2474 14.3024 23.1375 14.2746 23.0363 14.2161L21.4279 13.2872C21.1204 13.1099 21.0154 12.7169 21.1928 12.4097C21.3701 12.1019 21.7639 11.9969 22.0703 12.1746L23.6786 13.1035C23.9861 13.2809 24.0911 13.6739 23.9138 13.981C23.7949 14.1872 23.5785 14.3024 23.3569 14.3024Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_7338_4091">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
