import { Container } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '@/app-components/common';
import { CryptoSearchDrawer } from '@/app-components/common/crypto-search';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { useResetStorageData } from '@/app-hooks/common';
import TrendingTokens from '@/app-views/wallet/components/Portfolio/TrendingTokens';
import PageNavigation from '../../../app-components/layout/PageWrapper/PageWrapper';
import { FeatureConfigWrap } from '../../../app-helpers/components';
import { FeatureConfig } from '../../../app-hooks/api/configuration';
import { CreateEddsaWalletAlert } from '../components/CreateEddsaWallet';
import { Dapp } from '../components/Dapp';
import { Header } from '../components/Header';
import { MenuAction } from '../components/MenuAction';
import { MyPortfolio } from '../components/Portfolio';
import { TokenTrading } from '../components/Portfolio/TokenTrading';
import { WalletBalance } from '../components/WalletBalance';
import { ELEMENT_IDS } from '@/app-constants';
import { RefreshLoading } from '../components/RefreshLoading';
import TobiFarm from '@/app-views/wallet/components/Portfolio/TobiFarm';
import { tourGuideManager } from '../components/TourGuide/helper';
import { HomeTourGuide } from '../components/TourGuide';
import { getTokenInfo } from '@/app-helpers/token';
import { toQueryString } from '@/app-helpers/url';
import { navigateToTokenDetail } from '@/app-helpers/navigate';

const Wallet: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	useResetStorageData();
	const { t } = useTranslation();
	const { data: userProfile } = useQueryUserProfile();
	const [searchState, setSearchState] = useState(false);
	useEffect(() => {
		if (location.state && 'search' in location.state) {
			setSearchState(true);
		} else {
			setSearchState(false);
		}
	}, [location.state]);

	const openSearch = useCallback(() => {
		const search = new URLSearchParams(location.search);
		search.delete('page');
		search.delete('action');
		navigate(
			{
				...location,
				search: search.toString(),
			},
			{
				state: {
					search: '',
				},
			},
		);
	}, [location, navigate]);

	const closeSearch = useCallback(() => {
		const search = new URLSearchParams(location.search);
		navigate(
			{
				...location,
				search: search.toString(),
			},
			{
				state: undefined,
			},
		);
	}, [location, navigate]);

	const handleSelectToken = useCallback((token) => {
		const { idTobi } = getTokenInfo(token);
		navigateToTokenDetail({ tobiId: idTobi });
	}, []);

	if (!userProfile) {
		return <Loading />;
	}

	return (
		<PageNavigation backable={false}>
			<Container py={4} id={ELEMENT_IDS.HOME_CONTAINER} className="overflow-y-auto hide-scrollbar">
				{tourGuideManager.isEnableHomePage() && (
					<FeatureConfigWrap feature={FeatureConfig.TOBI_HOME_WORK_THROUGH}>
						<HomeTourGuide />
					</FeatureConfigWrap>
				)}
				<RefreshLoading />
				<CreateEddsaWalletAlert />
				<CryptoSearchDrawer
					syncKeyWordToUrl
					isOpen={searchState}
					onClose={closeSearch}
					onSelectToken={handleSelectToken}
				/>
				<Header userProfile={userProfile} openSearch={openSearch} />
				<WalletBalance />
				<MenuAction />
				<FeatureConfigWrap feature={FeatureConfig.TRADING_TOKEN}>
					<TokenTrading />
				</FeatureConfigWrap>
				<FeatureConfigWrap
					feature={FeatureConfig.DAPP_HOMEPAGE}
					deviceFeatures={{
						android: FeatureConfig.SHOW_DAPP_ON_ANDROID_DEVICE,
						forceShowOnAndroid: false,
					}}
				>
					<Dapp />
				</FeatureConfigWrap>
				<FeatureConfigWrap feature={FeatureConfig.TRENDING_TOKEN}>
					<TrendingTokens />
				</FeatureConfigWrap>
				<FeatureConfigWrap feature={FeatureConfig.TOBI_FARM}>
					<TobiFarm />
				</FeatureConfigWrap>
				<MyPortfolio />
			</Container>
		</PageNavigation>
	);
};

export default Wallet;
