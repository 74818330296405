import { create } from 'zustand';

export interface IWalletStore {
	isNonWallet: boolean;
	mpc_progressLabel: string;
	mpc_progress: number;
	mpcAddress: string;
	setProgress: (label: string, progress: number) => void;
	setIsNonWallet: (isNonWallet: boolean) => void;
	setMPCAddress: (address: string) => void;
}

export const useWalletStore = create<IWalletStore>((set) => ({
	isNonWallet: false,
	/** Properties */
	mpc_progressLabel: '',
	mpc_progress: 0,
	mpcAddress: '',

	/** Actions */
	setIsNonWallet: (isNonWallet: boolean) => {
		return set(() => ({ isNonWallet }));
	},

	setProgress: (label: string, progress: number) => {
		return set(() => ({ mpc_progressLabel: label, mpc_progress: progress }));
	},

	setMPCAddress: (address: string) => {
		return set(() => ({ mpcAddress: address }));
	},
}));

export * from './selector-hooks';
