export const useScanTransaction = () => {};
import { useQuery } from '@tanstack/react-query';
import { TEvmScanTransaction } from '../../services/BlowFish/type';
import { BlowFistClientAPI } from '../../services/BlowFish/browser';

export const useScanEvmTransaction = (requestId: number, payload: TEvmScanTransaction) => {
	const res = useQuery({
		queryKey: ['blowfish-scan-evm-transaction', requestId],
		queryFn: async () => {
			const data = await BlowFistClientAPI.scanEvmTransaction(payload);
			return data;
		},
	});
	return res;
};
