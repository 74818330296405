import { escapeScriptHtml } from '@/app-helpers/string';
import { Box, Collapse, Divider, Flex, Image, Text } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatUsd } from '../../../../../app-helpers/number';
import { ITokenSearch } from '@/app-cores/api/bff';
import { BASE_BACKGROUND_COLOR } from '@/app-theme/theme';
import { CHAIN_CONFIG } from '@/app-constants/chains';
import { getShortAddress, isNativeToken } from '@/app-helpers/address';
import { CopyToClipboard } from '@/app-components/common';
import { getChainInfo, getTokenId, getTokenInfo } from '@/app-helpers/token';
import { ChainLogo } from '@/app-components/common/Avatar/TokenLogo';
import { ChevronDownIcon } from '@/assets/images/svg/chevron-down-icon';
interface BasicInformationProps {
	data: ITokenSearch | undefined;
}

const TokenContract = ({ contractAddress, chainId }) => {
	const { name } = getChainInfo(chainId);
	return (
		<Flex
			as="span"
			fontSize={'14px'}
			gap={'6px'}
			fontStyle={'14px'}
			alignItems={'center'}
			background={BASE_BACKGROUND_COLOR}
			padding={'6px 16px'}
			borderRadius={'100px'}
		>
			<ChainLogo chainId={chainId} style={{ width: '16px', height: '16px' }} />
			{name}
			{!isNativeToken(contractAddress) ? (
				<>
					:
					<CopyToClipboard text={contractAddress} copyText="Copy Address" containerStyle={{ flex: 1 }}>
						<Text color={'gray.400'} flex={1}>
							{getShortAddress(contractAddress, { start: 10, end: 10 })}
						</Text>
					</CopyToClipboard>
				</>
			) : (
				<Text color={'gray.400'} flex={1}>
					Native
				</Text>
			)}
		</Flex>
	);
};

export const TokenInformation: React.FunctionComponent<BasicInformationProps> = ({ data }) => {
	const { t } = useTranslation();
	const {
		marketCapUsd,
		volume24hUsd,
		fullyDilutedValueUsd,
		description,
		name,
		totalSupply,
		circulatingSupply,
		tokensDetails,
	} = getTokenInfo(data) || {};

	const fields = useMemo(
		() => [
			{ name: 'Volume (24h)', value: volume24hUsd, dollar: true },
			{ name: 'Market cap', value: marketCapUsd, dollar: true },
			{ name: 'FDV', value: fullyDilutedValueUsd, dollar: true },
			{ name: 'Circulating supply', value: circulatingSupply },
			{ name: 'Total supply', value: totalSupply },
		],
		[data],
	);

	const [expand, setExpand] = useState(false);
	const listToken = tokensDetails;
	const showToken = 1;
	const shouldShowExpand = listToken?.length > showToken;

	return (
		<Box my={8}>
			<Box display="flex" marginY={3} justifyContent="space-between">
				<Text fontWeight={500}>
					{t('About')} {name}
				</Text>
			</Box>

			<Box
				gap={'20px'}
				display="flex"
				padding={5}
				border={'1px'}
				borderRadius={'1rem'}
				borderColor={'#00000014'}
				marginTop={2}
				flexDirection={'column'}
			>
				<Flex flexDirection={'column'} gap={'12px'}>
					<Text as="span" fontSize={'12px'} fontWeight={'500'} color={'gray.300'}>
						STATS
					</Text>
					{fields.map((el) => (
						<Flex justifyContent={'space-between'} fontSize={'xs'} key={el.name}>
							<Text as="span">{el.name}</Text>
							<Text as="b">
								{!el.value ? '--' : formatUsd(el.value, { showDollar: el.dollar || false })}
							</Text>
						</Flex>
					))}
				</Flex>

				{listToken?.length > 0 && (
					<>
						<Divider borderColor={'gray.200'} variant={'dashed'} />
						<Flex flexDirection={'column'} gap={'12px'}>
							<Text as="span" fontSize={'12px'} fontWeight={'500'} color={'gray.300'}>
								CONTRACT ADDRESSES
							</Text>

							<Flex flexDirection={'column'} gap={'12px'}>
								{listToken.slice(0, expand ? listToken.length : showToken)?.map((el) => (
									<TokenContract
										chainId={el.chainId || el.tobiChainName}
										contractAddress={el.address}
										key={getTokenId(el)}
									/>
								))}
							</Flex>
						</Flex>

						{shouldShowExpand && (
							<Flex justify={'center'}>
								<ChevronDownIcon
									onClick={() => setExpand((prev) => !prev)}
									style={{ transform: `rotate(${!expand ? 0 : '180deg'})` }}
								/>
							</Flex>
						)}
					</>
				)}

				{!!description && (
					<>
						<Divider borderColor={'gray.200'} variant={'dashed'} />
						<Flex flexDirection={'column'} gap={'12px'}>
							<Text as="span" fontSize={'12px'} fontWeight={'500'} color={'gray.300'}>
								INTRODUCTION
							</Text>
							<Text
								fontSize={'xs'}
								dangerouslySetInnerHTML={{
									__html: escapeScriptHtml(
										description?.replace(/(\\r\\n\\r\\n)/g, '<br/>')?.replace(/(\\")/g, '"'),
									),
								}}
							/>
						</Flex>
					</>
				)}
			</Box>
		</Box>
	);
};
