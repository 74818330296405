import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import {
	compareChain,
	compareTobiToken,
	compareToken,
	isNativeTobiToken,
	isNativeToken,
	isStableCoin,
} from '@/app-helpers/address';
import { countDecimals, formatUsd, formatNumber, formatCurrency } from '@/app-helpers/number';
import { KyberSwap, useExecuteRouteKyberSwap, BuildRouteDataKyber } from '@/app-hooks/swap/kyberswap';

import { getNativeToken, getTokenId, getTokenInfo, tokenHasBalance } from '@/app-helpers/token';
import { isSupportSignTxs } from '@/app-helpers/web3';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { usePriceNativeToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { useDebounce } from '@/app-hooks/common';
import { JupiterSwap, RouteJupiter, useExecuteRouteJupiter } from '@/app-hooks/swap/jupiter';
import { LifiSwap, RouteLifi, useExecuteRouteLifi } from '@/app-hooks/swap/lifi';
import { RocketXSwap, RouteRocketX, useExecuteRouteRocketX } from '@/app-hooks/swap/rocketx';
import { RouteSton, StonSwap, useExecuteRouteSton } from '@/app-hooks/swap/ston';
import {
	ArgsGetRoute,
	ExtractRouteInfo,
	MAX_FEE_JUPITER_SWAP,
	MinAmountError,
	PayloadExtractRoute,
	SwapAbstract,
	SwapErrorType,
	SwapProvider,
	SwapProviderTracking,
	SwapStepInfo,
	SwapType,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import useWrapUnWrapToken from '@/app-hooks/wallet/useWrapUnWrapToken';
import { useUserSettingsStore } from '@/app-store/settings';
import { GenericRoute, InputMode, SelectedRoute, SwapDisableType, useSwapStore } from '@/app-store/swap';
import { AlertStatus } from '@chakra-ui/react';
import { fromNano, toNano } from '@ton/core';
import axios, { AxiosRequestConfig } from 'axios';
import { DebridgeSwap, RouteDebridge, useExecuteRouteDebridge } from '@/app-hooks/swap/debridge';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useExecuteRouteSwing, RouteSwing, SwingSwap } from '@/app-hooks/swap/swing';
import { getEnvironment } from '@/app-helpers';
import {
	calcAmount,
	combineRouteMultiProvider,
	filterRouteSolTon,
	getMinAmount,
	getSwapType,
	promiseWithTimeout,
	tryParseAmount,
} from '@/app-hooks/swap/helper';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ONE_MINUTE } from '@/app-hooks/api/portfolio/constant';
import { QUERY_KEYS } from '@/app-constants';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { BeraSwap } from '@/app-hooks/swap/bera';
import { useExecuteRouteBera } from '@/app-hooks/swap/bera';
import { RouteBera } from '@/app-hooks/swap/bera';
import { ContractSwap } from '@/app-hooks/swap/contract';
import { DedustSwap } from '@/app-hooks/swap/dedust';
import { useExecuteRouteDedust } from '@/app-hooks/swap/dedust';
import { RouteDedust } from '@/app-hooks/swap/dedust';
import { RetroBridgeSwap, RouteRetroBridge, useExecuteRouteRetroBridge } from '@/app-hooks/swap/retrobridge';
import Sol2TcatSwap, { useExecuteRouteSol2Tcat } from '@/app-hooks/swap/sol_2_tcat';
import SelectToken from '@/app-components/common/SelectToken';
import { TransactionType } from '@/app-types';
import { parseUrlSearchParams } from '@/app-helpers/url';
import i18n from '@/app-cores/i18n';
import { RouteSunSwap, SunSwap, useExecuteRouteSunSwap } from '@/app-hooks/swap/sunswap';

export const displayGasFeeSol = (usdPriceNative: number | undefined) =>
	!usdPriceNative
		? `max ${MAX_FEE_JUPITER_SWAP} SOL`
		: `max ${formatUsd(usdPriceNative * MAX_FEE_JUPITER_SWAP)} (${MAX_FEE_JUPITER_SWAP} SOL)`;

export const isShowGasWarning = ({
	compareUsd,
	parseNativeBalance,
	gasNative,
	gasUsd,
	balanceNativeUsd,
	tokenOut,
}: {
	compareUsd: boolean;
	parseNativeBalance: bigint;
	gasNative: bigint;
	gasUsd: string | number;
	balanceNativeUsd: number;
	tokenOut: ITokenSearch | undefined;
}) => {
	try {
		if (!tokenOut) return false;
		if (compareUsd) {
			return !parseNativeBalance || (parseNativeBalance && Number(balanceNativeUsd) < Number(gasUsd));
		}
		return !parseNativeBalance || (parseNativeBalance && parseNativeBalance < gasNative);
	} catch (error) {
		return false;
	}
};

export const calculatePriceImpact = (
	amountInUsd: number | string | undefined,
	amountOutUsd: number | string | undefined,
) => {
	if (!amountInUsd || !amountOutUsd) return;
	amountInUsd = Number(amountInUsd || 0);
	amountOutUsd = Number(amountOutUsd || 0);
	const priceImpact = !amountOutUsd ? NaN : ((amountInUsd - amountOutUsd) * 100) / amountInUsd;
	return priceImpact;
};

// priceImpact is invalid if it's not finite (NaN, Infinity)
// priceImpact is undefined or null means it's not provided
const checkPriceImpact = (
	priceImpact: number | undefined | null,
): {
	isInvalid: boolean;
	isHigh: boolean;
} => {
	return {
		// priceImpact < 0 is still VALID. That's when you swap $10, but receive back $12
		isInvalid: typeof priceImpact === 'number' && !Number.isFinite(priceImpact),
		isHigh: !!priceImpact && priceImpact > 10,
	};
};

export const getPriceImpactWarningMsg = ({
	priceImpact,
	amountInUsd,
	swapType,
}: {
	priceImpact: number;
	amountInUsd: number | string;
	swapType: SwapType;
}): ErrorMsg => {
	const { isHigh, isInvalid } = checkPriceImpact(priceImpact);
	if (
		+amountInUsd >= 10 &&
		![SwapType.WRAP_EVM, SwapType.UNWRAP_EVM, SwapType.NOT_SUPPORT].includes(swapType) &&
		(isHigh || isInvalid)
	) {
		const format = formatNumber(priceImpact, { decimals: 2 });
		return {
			type: 'warning',
			msg: i18n.t('tokenTrading.priceImpactWarningDesc', { percent: format }),
			title: i18n.t('tokenTrading.priceImpactWarningTitle', { percent: format }),
		};
	}
};

export type ErrorMsg =
	| {
			type: 'warning' | 'error'; // to disabled button
			uiType?: AlertStatus; // to display by color
			msg: string;
			errorType?: SwapErrorType;
			title?: string;
	  }
	| undefined;
export type SwapErrorInfo = { hasError: boolean; messages: ErrorMsg[] };
export const useValidateInput = ({
	tokenIn,
	parsedAmount,
	parseNativeBalance,
	priceImpact,
	swapType,
	parseTokenInBalance,
	amountInUsd,
	quickSwap,
	showGasWarning,
	gettingRoute,
	getRouteError,
	usdPriceTokenIn,
	amountInDebounced,
	hasAlternativeRoutes,
	route,
}: {
	amountInDebounced: string;
	tokenIn: TokenInfo | undefined;
	priceImpact: number | undefined;
	parsedAmount: bigint;
	parseNativeBalance: bigint | undefined;
	parseTokenInBalance: bigint;
	swapType: SwapType;
	amountInUsd: number | string | undefined;
	quickSwap: boolean;
	showGasWarning: boolean;
	gettingRoute: boolean;
	getRouteError: Error;
	usdPriceTokenIn: number | undefined;
	hasAlternativeRoutes: boolean;
	route?: SelectedRoute;
}): SwapErrorInfo => {
	const { t } = useTranslation();
	return useMemo(() => {
		if (!tokenIn) return { hasError: false, messages: [] };

		const chainId = tokenIn?.chainId;

		const errors: ErrorMsg[] = [];
		const needCheckBalance = quickSwap ? true : !!parsedAmount;

		if (amountInDebounced && countDecimals(amountInDebounced) > tokenIn?.decimals) {
			errors.push({ type: 'error', msg: '', errorType: SwapErrorType.VALIDATE_AMOUNT });
		}

		const minAmountErr = (route?.disableReason || getRouteError) as MinAmountError;
		const isGenericMinAmountError = minAmountErr?.isGeneral;
		const minAmountValue =
			parsedAmount && minAmountErr?.minAmountError
				? `${usdPriceTokenIn ? formatUsd(minAmountErr?.minAmount * +usdPriceTokenIn) : ''} ≈ ${formatCurrency(
						minAmountErr?.minAmount,
				  )} ${tokenIn?.symbol}`
				: null;

		const hasMinAmountError = minAmountValue || isGenericMinAmountError;

		if (hasMinAmountError || !route) {
			errors.push({
				type: 'error',
				msg: isGenericMinAmountError
					? t(`tokenTrading.minAmountGeneral`)
					: hasAlternativeRoutes
					? minAmountValue
						? t(`tokenTrading.minAmountTryAlternative`, {
								amount: minAmountValue,
						  })
						: t('tokenTrading.routeNotFoundTryAlternative')
					: minAmountValue
					? t(`tokenTrading.minAmount`, {
							amount: minAmountValue,
					  })
					: t('tokenTrading.routeNotFound'),
				errorType: SwapErrorType.ROUTE,
			});
		}

		if (
			!hasMinAmountError &&
			(parseTokenInBalance < parsedAmount || (tokenIn && !parseTokenInBalance && needCheckBalance))
		) {
			const error: ErrorMsg = {
				type: 'error',
				msg: t('tokenTrading.notEnoughToken', {
					token: tokenIn?.symbol,
					chainName: CHAIN_CONFIG[tokenIn?.chainId].name,
				}),
				errorType: SwapErrorType.FUND,
			};
			errors.push(error);
		}

		if (chainId && !isSupportSignTxs(chainId)) {
			errors.push({ type: 'error', msg: 'This chain is not supported.' });
		} else if (!hasMinAmountError && showGasWarning && !!parsedAmount && !gettingRoute) {
			const error: ErrorMsg = {
				type: 'error',
				msg: t('tokenTrading.notEnoughGas', {
					token: getNativeToken(tokenIn?.chainId)?.symbol,
					chainName: CHAIN_CONFIG[tokenIn?.chainId].name,
				}),
				errorType: SwapErrorType.GAS,
			};
			errors.push(error);
		}

		if (parsedAmount === 0n)
			errors.push({
				type: 'error',
				msg: '',
				errorType: SwapErrorType.VALIDATE_AMOUNT,
			});

		if (needCheckBalance) {
			const error = getPriceImpactWarningMsg({ priceImpact, swapType, amountInUsd });
			if (error) errors.push(error);
		}

		if (route?.subRoutes) {
			errors.push({
				type: 'warning',
				uiType: 'success',
				title: t('tokenTrading.specialDirectRoute'),
				msg: t('tokenTrading.specialDirectRouteDesc'),
				errorType: SwapErrorType.ROUTE,
			});
		}

		const minValue = getMinAmount(route?.provider, isNativeToken(tokenIn?.address));
		if (parsedAmount && minValue && parseNativeBalance < minValue) {
			const msgSton = t(`tokenTrading.minBalance`, {
				amount: `${fromNano(minValue)} ${getNativeToken(tokenIn?.chainId)?.symbol}`,
			});
			errors.push({ type: 'error', msg: msgSton, errorType: SwapErrorType.ROUTE });
		}

		return { hasError: errors.some((e) => e.type === 'error'), messages: errors };
	}, [
		priceImpact,
		parsedAmount,
		tokenIn,
		swapType,
		parseTokenInBalance,
		amountInUsd,
		quickSwap,
		showGasWarning,
		gettingRoute,
		getRouteError,
		usdPriceTokenIn,
		t,
		parseNativeBalance,
		amountInDebounced,
		hasAlternativeRoutes,
		route,
	]);
};

export const isRouteParamsEmpty = ({
	tokenIn,
	tokenOut,
	amountIn,
}: {
	tokenIn: TokenInfo;
	amountIn: string;
	tokenOut: TokenInfo;
}) => {
	return !tokenIn || !tokenOut || compareToken(tokenIn, tokenOut) || !amountIn || amountIn === '0';
};

export const getSwapParams = (data: ArgsGetRoute): ArgsGetRoute => {
	if (isRouteParamsEmpty(data)) return;
	return data;
};

export const useGetSwapParam = ({ amountIn, tokenIn, tokenOut, slippage, amountInDefault }: ArgsGetRoute) => {
	const params = useMemo(
		() => getSwapParams({ amountIn, tokenIn, tokenOut, slippage, amountInDefault }),
		[tokenIn, tokenOut, amountIn, slippage, amountInDefault],
	);
	return useDebounce(params, 400);
};

export const getRouteKey = (getRouteParams: ArgsGetRoute) => {
	const { amountIn, tokenIn, tokenOut, slippage } = getRouteParams || {};
	return [amountIn, getTokenId(tokenIn), getTokenId(tokenOut), slippage].join(',');
};

export const useFetchRoute = ({
	getRouteParams,
	refetchInterval,
	lastRoute,
}: {
	getRouteParams: ArgsGetRoute;
	refetchInterval: number;
	lastRoute: SelectedRoute | undefined;
}) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_ROUTE_SWAP, getRouteKey(getRouteParams)],
		queryFn: async (option) => {
			try {
				const data = await SwapService.fetchRouteBySwapType(getRouteParams, option.signal, true);
				if (lastRoute && data) {
					const { tokenIn, tokenOut, provider } = lastRoute;
					if (
						compareToken(tokenIn, data.tokenIn) &&
						compareToken(tokenOut, data.tokenOut) &&
						data.provider !== provider &&
						data.allRoutes?.length > 1
					) {
						const route = data.allRoutes.find((e) => e.provider === provider);
						if (route) return { ...route, allRoutes: data.allRoutes };
					}
				}
				return data || null;
			} catch (error) {
				!getEnvironment('prod') && console.log('get route err', error);
				dataDogAddError(error, {
					tags: {
						name: DATADOG_ERROR_TAGS.TRADE,
						function: 'getRoute',
					},
				});
				throw error;
			}
		},
		gcTime: refetchInterval,
		staleTime: refetchInterval,
		refetchInterval,
		enabled: !!getRouteParams,
		refetchOnMount: true,
	});
	return response;
};

// get route and popup late token info to consistent for all provider
export async function commonGetRoute(
	payload: AxiosRequestConfig,
	{ tokenIn, tokenOut }: { tokenIn: TokenInfo; tokenOut: TokenInfo },
) {
	const response = await axios(payload);
	const resp = response?.data?.data || response?.data;
	resp.tokenIn = { ...tokenIn, ...resp.tokenIn };
	resp.tokenOut = { ...tokenOut, ...resp.tokenOut };
	return resp;
}

const mapSwapInstance: { [key in SwapProvider]: any } = {
	[SwapProvider.LIFI]: LifiSwap,
	[SwapProvider.ROCKET]: RocketXSwap,
	[SwapProvider.DEBRIDGE]: DebridgeSwap,
	[SwapProvider.KYBER]: KyberSwap,
	[SwapProvider.STON]: StonSwap,
	[SwapProvider.JUPITER]: JupiterSwap,
	[SwapProvider.SWING]: SwingSwap,
	[SwapProvider.BERA]: BeraSwap,
	[SwapProvider.CONTRACT]: ContractSwap,
	[SwapProvider.DEDUST]: DedustSwap,
	[SwapProvider.RETROBRIDGE]: RetroBridgeSwap,
	[SwapProvider.Sol2Tcat]: Sol2TcatSwap,
	[SwapProvider.SUNSWAP]: SunSwap,
};

const getRouteWithRetry = async ({
	swapFns,
	paramsSwap,
	signal,
	timeout = 10_000,
	filterFn,
	withRetry,
	forceSortAmount = false,
}: {
	swapFns: Array<SwapAbstract<any>>;
	paramsSwap: ArgsGetRoute;
	signal;
	withRetry: boolean;
	timeout?: number;
	filterFn?: (v: SelectedRoute[]) => SelectedRoute[];
	forceSortAmount?: boolean;
}) => {
	let routes: SelectedRoute;
	let originError;
	try {
		routes = await combineRouteMultiProvider(
			swapFns.map((el) => el.getRoute(paramsSwap, signal)),
			timeout,
			filterFn,
			forceSortAmount,
		);
	} catch (error) {
		if (!withRetry) throw error;
		originError = error;
	}
	if (routes || !withRetry) return routes;
	try {
		const { amountInDefault, amountIn, ...rest } = paramsSwap;
		const newParams = { ...rest, amountIn: amountInDefault };
		routes = await combineRouteMultiProvider(
			swapFns.map((el) => el.getRoute(newParams, signal)),
			timeout,
			filterFn,
			forceSortAmount,
		);
		if (routes) {
			const props = {
				disabled: SwapDisableType.MIN_AMOUNT,
				disableReason: new MinAmountError({ isGeneral: true }),
			};
			routes = { ...routes, ...props, allRoutes: routes?.allRoutes?.map((e) => ({ ...e, ...props })) };
		}
		return routes;
	} catch (error) {
		throw originError || error;
	}
};

export class SwapService {
	static initRoute() {
		RocketXSwap.init();
		DebridgeSwap.init();
		SwingSwap.init();
		RetroBridgeSwap.init();
	}
	static async fetchRouteBySwapType(
		paramsSwap: ArgsGetRoute,
		signal?: AbortSignal,
		withRetry?: boolean,
	): Promise<SelectedRoute | null> {
		if (!paramsSwap) return null;
		const swapType = getSwapType(paramsSwap.tokenIn, paramsSwap.tokenOut);
		switch (swapType) {
			case SwapType.CROSSCHAIN_EVM: {
				return getRouteWithRetry({
					swapFns: [LifiSwap, DebridgeSwap, SwingSwap, RetroBridgeSwap],
					paramsSwap,
					signal,
					withRetry,
				});
			}

			case SwapType.CROSSCHAIN_EVM_SOL:
			case SwapType.CROSSCHAIN_SOL_EVM: {
				return getRouteWithRetry({
					swapFns: [DebridgeSwap, SwingSwap, RetroBridgeSwap],
					paramsSwap,
					signal,
					withRetry,
				});
			}

			case SwapType.CROSSCHAIN_SOL_TRON:
			case SwapType.CROSSCHAIN_EVM_TRON:
			case SwapType.CROSSCHAIN_TON_TRON:
			case SwapType.CROSSCHAIN_TRON_EVM:
			case SwapType.CROSSCHAIN_TRON_SOL:
			case SwapType.CROSSCHAIN_TRON_TON:
				return getRouteWithRetry({
					swapFns: [RocketXSwap, RetroBridgeSwap],
					paramsSwap,
					signal,
					withRetry,
					timeout: 40_000,
					forceSortAmount: true,
				});

			case SwapType.CROSSCHAIN_TON_SOL:
			case SwapType.CROSSCHAIN_SOL_TON:
			case SwapType.CROSSCHAIN_EVM_TON:
			case SwapType.CROSSCHAIN_TON_EVM: {
				if (Sol2TcatSwap.isMyRoute(paramsSwap)) {
					return Sol2TcatSwap.getRoute(paramsSwap, signal);
				}
				let routeSolTon;
				try {
					routeSolTon = await combineRouteMultiProvider(
						[
							RetroBridgeSwap.getRoute(paramsSwap, signal, true),
							RocketXSwap.getRoute(paramsSwap, signal, false),
						],
						40_000,
						filterRouteSolTon,
					);
				} catch (error) {
					routeSolTon = await RocketXSwap.getRoute(paramsSwap, signal, true);
				}
				return routeSolTon;
			}

			case SwapType.SWAP_EVM: {
				return getRouteWithRetry({
					swapFns: [KyberSwap, LifiSwap, DebridgeSwap, SwingSwap],
					paramsSwap,
					signal,
					withRetry,
				});
			}
			case SwapType.SWAP_SOL: {
				return getRouteWithRetry({
					swapFns: [JupiterSwap, DebridgeSwap],
					paramsSwap,
					signal,
					withRetry,
				});
			}

			case SwapType.SWAP_TON: {
				return getRouteWithRetry({
					swapFns: [StonSwap, DedustSwap],
					paramsSwap,
					signal,
					timeout: 40_000,
					withRetry,
				});
			}
			case SwapType.WRAP_EVM:
			case SwapType.UNWRAP_EVM: {
				return ContractSwap.getRoute(paramsSwap);
			}

			case SwapType.SWAP_BERA:
				return BeraSwap.getRoute(paramsSwap, signal);

			case SwapType.SWAP_TRON:
				return SunSwap.getRoute(paramsSwap, signal);

			default: {
				return null;
			}
		}
	}
	static async buildRoute(data: {
		slippage: number;
		userAmount: string;
		route: SelectedRoute;
	}): Promise<SelectedRoute> {
		const route = await mapSwapInstance[data.route.provider].buildRoute(data);
		if (!route) throw new Error('Empty route data');
		return route;
	}
	static changeRoute(oldRoute: SelectedRoute, newRoute: SelectedRoute): SelectedRoute | undefined {
		return {
			...newRoute,
			allRoutes: oldRoute.allRoutes,
		};
	}
	static extractRouteInfo({ route, ...pricesInfo }: PayloadExtractRoute): ExtractRouteInfo {
		return mapSwapInstance[route?.provider]?.extractRoute(route, pricesInfo) || {};
	}
	static formatSteps(provider, ...args): SwapStepInfo[] {
		return mapSwapInstance[provider]?.formatSteps(...args) ?? [];
	}
}

export const useExecuteRoute = (quickSwap: boolean) => {
	const { selectedRoute, amount } = useSwapStore(quickSwap);
	const { tokenIn } = selectedRoute ?? {};

	const { mutateAsync: wrapUnWrapToken } = useWrapUnWrapToken();
	const { mutateAsync: execSwapLifi } = useExecuteRouteLifi(tokenIn?.chainId);
	const { mutateAsync: execSwapSton } = useExecuteRouteSton();
	const { mutateAsync: execSwapRocket } = useExecuteRouteRocketX(tokenIn?.chainId);
	const { mutateAsync: execSwapKyber } = useExecuteRouteKyberSwap(tokenIn?.chainId);
	const { mutateAsync: execSwapDebridge } = useExecuteRouteDebridge(tokenIn?.chainId);
	const { mutateAsync: execSwapJupiter } = useExecuteRouteJupiter();
	const { mutateAsync: execSwapSwing } = useExecuteRouteSwing();
	const { mutateAsync: execRouteBera } = useExecuteRouteBera();
	const { mutateAsync: execRouteDedust } = useExecuteRouteDedust();
	const { mutateAsync: execRouteRetroBridge } = useExecuteRouteRetroBridge();
	const { mutateAsync: execRouteSol2Tcat } = useExecuteRouteSol2Tcat(tokenIn?.chainId);
	const { mutateAsync: execRouteSunSwap } = useExecuteRouteSunSwap();

	return async (provider: SwapProvider, onUpdate: (data: any) => void): Promise<{ hash: string }> => {
		switch (provider) {
			case SwapProvider.LIFI:
				return execSwapLifi({ route: selectedRoute as SelectedRoute<RouteLifi>, onUpdate });
			case SwapProvider.STON: {
				const resp = await execSwapSton(selectedRoute as SelectedRoute<RouteSton>);
				return { hash: resp.seqno.toString() };
			}
			case SwapProvider.DEDUST: {
				const seqno = await execRouteDedust({ route: selectedRoute as SelectedRoute<RouteDedust> });
				return { hash: seqno.toString() };
			}
			case SwapProvider.ROCKET: {
				return execSwapRocket({ route: selectedRoute as SelectedRoute<RouteRocketX> });
			}
			case SwapProvider.DEBRIDGE: {
				return execSwapDebridge(selectedRoute as SelectedRoute<RouteDebridge>);
			}
			case SwapProvider.SWING: {
				return execSwapSwing({ route: selectedRoute as SelectedRoute<RouteSwing>, onUpdate });
			}
			case SwapProvider.KYBER: {
				return execSwapKyber({
					...selectedRoute,
					route: selectedRoute?.route as BuildRouteDataKyber,
					amount,
				});
			}
			case SwapProvider.CONTRACT: {
				const swapType = getSwapType(selectedRoute.tokenIn, selectedRoute.tokenOut);
				switch (swapType) {
					case SwapType.UNWRAP_EVM:
					case SwapType.WRAP_EVM:
						return wrapUnWrapToken({
							...selectedRoute,
							amount: tryParseAmount(amount, tokenIn?.decimals)?.toString(),
						});
					default:
						return;
				}
			}
			case SwapProvider.JUPITER: {
				return execSwapJupiter(selectedRoute as SelectedRoute<RouteJupiter>);
			}
			case SwapProvider.BERA: {
				return execRouteBera(selectedRoute as SelectedRoute<RouteBera>);
			}
			case SwapProvider.Sol2Tcat: {
				return execRouteSol2Tcat({ route: selectedRoute as SelectedRoute<any>, onUpdate });
			}
			case SwapProvider.RETROBRIDGE: {
				return execRouteRetroBridge({ route: selectedRoute as SelectedRoute<RouteRetroBridge> });
			}
			case SwapProvider.SUNSWAP: {
				return execRouteSunSwap(selectedRoute as SelectedRoute<RouteSunSwap>);
			}
			default: {
				throw new Error('Unknown swap method: ' + provider);
			}
		}
	};
};

const points = {
	[ChainId.BASE]: 1000,
	[ChainId.ARB]: 900,
	[ChainId.OP]: 800,
	[ChainId.AVAX]: 700,
	[ChainId.POLYGON]: 600,
	[ChainId.BNB]: 500,
};

const getTopTokens = (balances: ITokenSearch[], maxRoute?: number) => {
	const pointResult = {};

	balances.forEach((e) => {
		pointResult[getTokenId(e)] =
			(points[getTokenInfo(e).chainId] || 0) +
			(isNativeTobiToken(e) ? 10 : isStableCoin(getTokenInfo(e)) ? 5 : 0);
	});

	const rs = balances.sort((a, b) => pointResult[getTokenId(b)] - pointResult[getTokenId(a)]);
	return maxRoute ? rs.slice(0, maxRoute) : rs;
};

const maxSuggestRoute = 3;

const fetchListRoute = async (paramsDebounce: ArgsGetRoute[], signal: AbortSignal) => {
	const data = await Promise.allSettled(
		paramsDebounce.map((e) => promiseWithTimeout<SelectedRoute>(SwapService.fetchRouteBySwapType(e, signal))),
	);
	const routes = data
		.map((e) => (e.status === 'fulfilled' ? e.value : null))
		.filter(Boolean)
		.sort((a, b) => (a.disabled ? 1 : b.disabled ? -1 : 0));
	return { routes };
};

const useGetParamsSuggestRoute = (tokenOutParam: ITokenSearch | undefined) => {
	const tokenOut = getTokenInfo(tokenOutParam);
	const { slippage } = useUserSettingsStore();

	const {
		data: { mainTokens: balances },
	} = usePortfolioBalanceByCategories();
	const params = useMemo(() => {
		if (!tokenOut?.chainId) return [];

		const filterFn = (e: ITokenSearch) =>
			tokenHasBalance(e) &&
			compareChain(getTokenInfo(e).chainId, tokenOut.chainId) &&
			!isNativeTobiToken(e) &&
			!compareTobiToken(tokenOut as any, e);

		const result = getTopTokens(balances.filter(filterFn), maxSuggestRoute);

		const paramsSwap: ArgsGetRoute[] = result.map((e) => ({
			amountIn: e.balance,
			tokenIn: getTokenInfo(e),
			tokenOut,
			slippage,
		}));

		let paramsCrossChain: ArgsGetRoute[] = [];

		if (paramsSwap.length < maxSuggestRoute) {
			const filterFn = (e: ITokenSearch) =>
				tokenHasBalance(e) && !compareChain(getTokenInfo(e).chainId, tokenOut.chainId);
			const resultCrossChain = getTopTokens(balances.filter(filterFn));

			paramsCrossChain = resultCrossChain
				.map((e) => ({ amountIn: e.balance, tokenIn: getTokenInfo(e), tokenOut, slippage }))
				.filter((e) => getSwapType(e.tokenIn, e.tokenOut) !== SwapType.NOT_SUPPORT)
				.slice(0, maxSuggestRoute - paramsSwap.length);
		}

		return paramsSwap.concat(paramsCrossChain);
	}, [tokenOut, balances, slippage]);

	const paramsDebounce = useDebounce(params, 500);
	return paramsDebounce;
};

export const useGetSuggestedRoutes = ({ tokenOut }: { tokenOut: ITokenSearch | undefined }) => {
	const params = useGetParamsSuggestRoute(tokenOut);
	return useQuery({
		queryKey: [
			'suggested-route',
			params.map((e) => `${e.amountIn}${getTokenId(e.tokenIn)}${getTokenId(e.tokenOut)}`).join(''),
		],
		queryFn: async ({ signal }) => fetchListRoute(params, signal),
		refetchInterval: 60_000,
		enabled: !!tokenOut,
	});
};

export const useGetAllPriceSwap = ({
	tokenIn,
	tokenOut,
	disabled,
}: {
	tokenIn: TokenInfo | undefined;
	tokenOut: TokenInfo | undefined;
	disabled?: boolean;
}) => {
	const { data: usdPriceNative } = usePriceNativeToken({ chainId: disabled ? null : tokenIn?.chainId });
	return { usdPriceNative, usdPriceTokenIn: tokenIn?.priceUsd, usdPriceTokenOut: tokenOut?.priceUsd };
};

export type AmountPayload = {
	amount?: string | number | undefined;
	amountUsd?: string | number | undefined;
	token: ITokenSearch | undefined;
	formatUsd?: boolean;
};

export const useChangeInputAmount = (quickSwap?: boolean) => {
	const { setAmount } = useSwapStore(quickSwap);

	const onChangeAmount = useCallback(
		(payload: AmountPayload) => {
			const { amount, amountUsd } = calcAmount(payload);
			setAmount(amount, amountUsd);
		},
		[setAmount],
	);
	return onChangeAmount;
};

const getNonNull = (obj) => {
	const key = Object.keys(obj).find((e) => !!obj[e]);
	return obj[key] ?? '';
};
export const getPayloadSwapTracking = (routeParams: SelectedRoute, payload?: any) => {
	const selectedRoute = routeParams?.subRoutes ? routeParams?.subRoutes[0] : routeParams;
	const route = selectedRoute.route as any;
	const { tokenIn, tokenOut, provider, metadata } = selectedRoute;
	const ids = {
		rocketXRequestId: route?.requestId ?? '',
		debridgeOrderId: route?.orderId ?? '',
		retroBridgeTxsId: metadata?.transaction_id,
		swingTxsId: metadata?.txsId ?? '',
	};
	return {
		...ids,
		swapProvider: SwapProviderTracking[provider] ?? '',
		rawProvider: provider ?? '',
		isSolToTcat: metadata?.isSolToTcat || payload?.isSolToTcat,
		providerId: getNonNull(ids),
		swapType: compareChain(tokenIn.chainId, tokenOut.chainId)
			? TransactionType.Swap
			: TransactionType.SwapCrossChain,
		...payload,
	};
};

/**
 * for dev debug purpose
 */
export const trackingSwapDebugData = (selectedRoute: SelectedRoute) => {
	dataDogAddAction(DATADOG_ACTIONS.TRADE_DEBUG, getPayloadSwapTracking(selectedRoute));
	console.log(DATADOG_ACTIONS.TRADE_DEBUG, getPayloadSwapTracking(selectedRoute));
};
