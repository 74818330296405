import { BASE_BACKGROUND_COLOR, BASE_BORDER_COLOR } from '@/app-theme/theme';
import {
	CloseButton,
	Collapse,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	FormControl,
	FormErrorMessage,
	FormLabel,
	InputGroup,
	InputRightAddon,
	Stack,
	Text,
} from '@chakra-ui/react';
import { isAddress } from 'ethers';
import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ChainSelect from '../../../../app-components/common/Select/ChainSelect';
import { compareAddress } from '../../../../app-helpers/address';
import { useQrCodeReaderStore } from '../../../../app-store';
import { FiScanIcon } from '../../../../assets/icons';
import Warning from '@/app-components/common/Warning';
import { BaseInput, ButtonPrimary, CommonInput } from '@/app-components/common';
import { useDebounce } from '@/app-hooks/common';
import { useMutationAddTokens } from '@/app-hooks/api/tokens/useMutationUserSettingTokens';
import { ChainId } from '@/app-constants/chains';
import { getTokenInfo } from '@/app-helpers/token';
import { useSearchSingleToken } from '@/app-hooks/api/portfolio/useSearchToken';

const ImportToken: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
	const { setOpen, setOnScan } = useQrCodeReaderStore();
	const { mutateAsync } = useMutationAddTokens();
	const { t } = useTranslation();
	const { handleChange, values, setValues, setFieldValue, submitForm, resetForm, errors, setFieldError } = useFormik({
		initialValues: {
			network: '1',
			contractAddress: '',
			name: '',
			symbol: '',
			decimals: '',
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			if (!values.name) {
				return;
			}

			try {
				await mutateAsync({
					chainId: values.network as ChainId,
					contractAddress: values.contractAddress,
					type: 'include',
				});
				onClose();
			} catch (error) {
				console.error('Update group error:', error);
			}
		},
	});

	useEffect(() => {
		if (isOpen) {
			resetForm();
		}
	}, [resetForm, isOpen]);

	const query = useDebounce(values.contractAddress, 500);
	const { data, isLoading } = useSearchSingleToken(
		{
			address: query,
			chainId: values.network,
		},
		{
			enabled: !!query,
		},
	);

	useEffect(() => {
		if (isLoading) {
			setFieldError('contractAddress', undefined);

			return;
		}

		const tokenInfo = getTokenInfo(data);

		if (!tokenInfo) {
			if (values.contractAddress) {
				setFieldError('contractAddress', 'Invalid address');
			}
			return;
		}

		setValues({
			contractAddress: values.contractAddress,
			decimals: `${tokenInfo?.decimals || ''}`,
			name: tokenInfo?.name || '',
			network: values.network,
			symbol: tokenInfo?.symbol || '',
		});
	}, [setValues, data, setFieldError, isLoading, values.contractAddress, values.network]);

	const onScanHandler = useCallback(() => {
		setOpen(true);
		setOnScan((data) => {
			if (data && isAddress(data)) {
				setFieldValue('contractAddress', data);
				setOpen(false);
			}
		});
	}, [setFieldValue, setOnScan, setOpen]);

	return (
		<Drawer placement="bottom" onClose={onClose} isOpen={isOpen} trapFocus={false}>
			<DrawerOverlay />
			<DrawerContent borderRadius={'8px 8px 0 0'}>
				<DrawerHeader
					py={3}
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					borderBottomWidth="1px"
					borderColor={'gray.100'}
					textAlign={'center'}
				>
					<Text flex={1} fontSize={'16px'}>
						{t('cryptos.importToken')}
					</Text>
					<CloseButton onClick={onClose} />
				</DrawerHeader>
				<DrawerBody p={5}>
					<Stack gap={6}>
						<Warning background="gray.100" status="warning" msg={t('cryptos.importTitle')} />
						<FormControl>
							<FormLabel fontSize={'.75rem'} color={'gray.400'}>
								{t('cryptos.network')}
							</FormLabel>
							<ChainSelect
								placement="bottom-start"
								allChain={false}
								value={values.network}
								onChange={(v) => setFieldValue('network', v)}
								style={{
									fontSize: '14px',
									borderRadius: '12px',
									background: BASE_BACKGROUND_COLOR,
									width: '100%',
									height: '42px',
								}}
							/>
						</FormControl>
						<FormControl isInvalid={!!errors.contractAddress}>
							<FormLabel fontSize={'.75rem'} color={'gray.400'}>
								{t('cryptos.contractAddress')}
							</FormLabel>
							<InputGroup>
								<InputGroup>
									<BaseInput
										isInvalid={!!errors.contractAddress}
										style={{
											fontSize: '14px',
											border: 'none',
											background: BASE_BACKGROUND_COLOR,
											borderRadius: '12px 0 0 12px',
											borderRight: `1px solid ${BASE_BORDER_COLOR}`,
										}}
										value={values.contractAddress}
										onChange={handleChange}
										name="contractAddress"
										placeholder="0x..."
										borderTopRightRadius={0}
										borderBottomRightRadius={0}
									/>
								</InputGroup>
								<InputRightAddon
									style={{
										border: 'none',
										background: BASE_BACKGROUND_COLOR,
										borderRadius: '0 12px 12px 0',
									}}
									onClick={onScanHandler}
									cursor={'pointer'}
									bg="transparent"
								>
									<FiScanIcon />
								</InputRightAddon>
							</InputGroup>
							<Collapse in={!!errors.contractAddress} animateOpacity>
								<FormErrorMessage>Invalid address</FormErrorMessage>
							</Collapse>
						</FormControl>
						<FormControl>
							<FormLabel fontSize={'.75rem'} color={'gray.400'}>
								{t('cryptos.name')}
							</FormLabel>
							<CommonInput
								value={values.name}
								onChange={handleChange}
								disabled
								type="name"
								placeholder={t('cryptos.tokenName')}
							/>
						</FormControl>
						<FormControl>
							<FormLabel fontSize={'.75rem'} color={'gray.400'}>
								{t('cryptos.symbol')}
							</FormLabel>
							<CommonInput
								disabled
								value={values.symbol}
								onChange={handleChange}
								name="symbol"
								placeholder={t('cryptos.tokenSymbol')}
							/>
						</FormControl>
						<FormControl>
							<FormLabel fontSize={'.75rem'} color={'gray.400'}>
								{t('cryptos.decimals')}
							</FormLabel>
							<CommonInput
								disabled
								value={values.decimals}
								onChange={handleChange}
								name="decimals"
								placeholder={t('cryptos.tokenDecimals')}
							/>
						</FormControl>
					</Stack>
				</DrawerBody>
				<DrawerFooter borderTop={`1px solid ${BASE_BORDER_COLOR}`} px={5}>
					<ButtonPrimary isDisabled={!values.name} type="submit" width={'100%'} onClick={submitForm}>
						{t('cryptos.import')}
					</ButtonPrimary>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
};

export default ImportToken;
