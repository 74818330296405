import { lazy } from 'react';
import { Outlet, createBrowserRouter, useLocation } from 'react-router-dom';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TonConnect } from '@/app-features/app-ton-connect/views';
import { TransactionResult } from '@/app-views/txs-result/pages/TransactionResult';

import { BotConnector } from '../app-features/app-bot-connector/views/WalletConnect';
import { OnboardingRouter } from './onboarding.router';
import { PortfolioRouter } from './portfolio-router';
import { TradingRouter } from './swap.router';
import { WalletRouter } from './wallet.router';
import { SharingDrawer } from '../app-components/common/Sharing';
import { Loading, RequireBackup } from '@/app-components/common';
import { useWalletAddressStore } from '@/app-store';
import WalletAddressPopup from '@/app-components/common/AddressByChain/AddressByChainPopup';
import { getEnvironment } from '@/app-helpers';
import { useMonitorView } from '@/app-hooks/monitor';
import { BotRouter } from './bot.router';
import { useDepositStore } from '@/app-features/deposit/store';
import { Deposit } from '@/app-features/deposit';
import { RouterErrorBoundary } from '@/app-components/layout/RouterErrorBoudary';
import { WalletConnect } from '@/app-features/app-wallet-connect/views/WalletConnect';
import { ScanQrCodePopup } from '@/app-features/app-wallet-connect/views/ScanQrCodePopup';
import { GlobalToast } from '@/app-components/common/Toast/GlobalToast';
import { useModalStore } from '@/app-features/app-wallet-connect/store/ModalStore';
import { FeatureConfigWrap } from '@/app-helpers/components';
import { FeatureConfig } from '@/app-hooks/api/configuration';
import { AppCopilot, CopilotFallback } from '@/app-features/copilot';

const Icons = lazy(() => import('@/app-views/testing/Icons'));

const Layout = () => {
	const { isOpen: isOpenDeposit } = useDepositStore();
	const { isOpen: isOpenWalletPopup } = useWalletAddressStore();
	const { isWaiting: isWaitingWalletConnection } = useModalStore();
	useMonitorView();
	return (
		<>
			<BotConnector />
			<TonConnect />
			{isOpenDeposit && <Deposit />}
			<SharingDrawer />
			<RequireBackup />
			{isOpenWalletPopup && <WalletAddressPopup />}
			{isWaitingWalletConnection && <Loading opacity="0.8" />}
			<WalletConnect />
			<ScanQrCodePopup />
			<GlobalToast />
			<FeatureConfigWrap feature={FeatureConfig.IN_APP_COPILOT} fallbackComponent={<CopilotFallback />}>
				<AppCopilot />
			</FeatureConfigWrap>
			<Outlet />
		</>
	);
};

const AppRouter = [
	{
		path: NAVIGATE_PATHS.transactionResult(':type', ':chainId', ':txHash'),
		element: <TransactionResult />,
	},
];

if (getEnvironment('dev')) {
	AppRouter.push({
		path: NAVIGATE_PATHS.Testing.AppIcon,
		element: <Icons />,
	});
}

const MainRouter = createBrowserRouter([
	{
		element: <Layout />,
		errorElement: <RouterErrorBoundary />,
		children: [
			...OnboardingRouter,
			...TradingRouter,
			...WalletRouter,
			...PortfolioRouter,
			...AppRouter,
			...BotRouter,
		],
	},
]);

export { MainRouter };
