import { Avatar, Box, Button, Center } from '@chakra-ui/react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { InputSearch } from '@/app-components/common';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { UserProfile } from '@/app-cores/api';
import { useOnEventCallback } from '@/app-hooks/common';
import { FeatureConfigWrap } from '../../../../app-helpers/components';
import { FeatureConfig } from '../../../../app-hooks/api/configuration';
import { FiLinkIcon } from '../../../../assets/icons';
import { ScanWalletConnect } from './ScanWalletConnect';
import { CopyAddress } from './CopyAddress';
import { TOUR_GUIDE_STEPS_TARGET } from '../TourGuide/stepConfigs';

interface HeaderProps {
	userProfile: UserProfile;
	openSearch: () => void;
}
export const Header: React.FC<HeaderProps> = ({ userProfile, openSearch }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const ref = useRef<HTMLInputElement>(null);
	return (
		<Box display="flex" alignItems="center" justifyContent="space-between" gap={4} mb={4}>
			<Link to={NAVIGATE_PATHS.AccountSettings.Menu}>
				<Box padding="0.25rem" border="1px" borderColor="gray.100" borderRadius="100%">
					<Avatar
						id={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.AVATAR}
						name={userProfile.firstName || userProfile.lastName}
						src={userProfile.avatar}
						size="sm"
					/>
				</Box>
			</Link>
			<InputSearch
				onClick={() => {
					openSearch();
					ref.current?.focus();
				}}
				ref={ref}
				placeholder={t('search')}
				background={'gray.100'}
				border="none"
				flex={1}
				id={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.SEARCH_TOKEN}
			/>
			<Center gap={4}>
				<FeatureConfigWrap feature={FeatureConfig.DAPP_BROWSER}>
					<Button
						onClick={() => navigate(NAVIGATE_PATHS.Dapp.DappBrowser)}
						px={0}
						backgroundColor="transparent"
						_hover={{
							backgroundColor: 'transparent',
						}}
						minWidth={5}
						maxWidth={5}
					>
						<FiLinkIcon />
					</Button>
				</FeatureConfigWrap>
				<CopyAddress />
				<ScanWalletConnect />
			</Center>
		</Box>
	);
};
