import { ITokenSearch } from '@/app-cores/api/bff';
import { CSSProperties, memo, useCallback } from 'react';
import { List } from '@chakra-ui/react';
import { ListCryptoItem } from '@/app-views/portfolio/components/ListCrypto';
import { getTokenId } from '@/app-helpers/token';

export const SearchResult = memo(
	({
		visibleTokens,
		onSelectToken,
		selectedToken,
		hasChildren,
	}: {
		visibleTokens: ITokenSearch[];
		onSelectToken: (v: ITokenSearch) => void;
		selectedToken: ITokenSearch | undefined;
		hasChildren;
	}) => {
		return (
			<div style={{ flex: 1 }}>
				<List spacing={1}>
					{visibleTokens.map((item) => (
						<ListCryptoItem
							showChildren={hasChildren}
							style={{ minHeight: '55px' }}
							px={5}
							data={item}
							key={getTokenId(item)}
							selectedToken={selectedToken}
							onClick={onSelectToken}
						/>
					))}
				</List>
			</div>
		);
	},
);
