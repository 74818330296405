import { useMutation } from '@tanstack/react-query';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeToken } from '@/app-helpers/address';
// import { useTransactionWatcherStore } from '../../../app-store';
import { tronWallet } from '@/app-cores/mpc-wallet/tron';
import { TransactionType, TSentTronTransaction } from '@/app-types';
import { getTronTransferBalance } from '@/app-helpers/tron';
import { BroadcastReturn, SignedTransaction } from 'node_modules/tronweb/lib/esm/types';
import { useTransactionWatcherStore } from '@/app-store';
import { getPendingSendTxs } from '@/app-store/transaction-watcher/evmWatcher';
import { ChainId } from '@/app-constants/chains';

async function sendTron(sendData: TSentTronTransaction) {
	try {
		// if u want to auto deduct fee, please careful with this, swap txs is also use this function
		const amount = getTronTransferBalance(sendData.amount, sendData.token.decimals) as any;

		const transaction = await tronWallet.tronWeb.transactionBuilder.sendTrx(
			sendData.to,
			amount,
			MpcWallet.getTronWalletAddress(),
		);
		const signedTransaction = await tronWallet.signTransaction(transaction);
		const txResults = await tronWallet.sendRawTransaction(signedTransaction);
		return txResults;
	} catch (error) {
		console.error('sendTron error:', error);
		throw error;
	}
}
async function sendTRC20(sendData: TSentTronTransaction) {
	try {
		const unsignedTransaction = await tronWallet.tronWeb.transactionBuilder.triggerSmartContract(
			sendData.token.address,
			'transfer(address,uint256)',
			{
				feeLimit: 50000000,
				callValue: 0,
			},
			[
				{ type: 'address', value: sendData.to },
				{ type: 'uint256', value: getTronTransferBalance(sendData.amount, sendData.token.decimals) as any },
			],
			MpcWallet.getTronWalletAddress(),
		);

		const signedTransaction = await tronWallet.signTransaction(unsignedTransaction.transaction);
		const txResults = await tronWallet.sendRawTransaction(signedTransaction);
		return txResults;
	} catch (error) {
		console.error('TransferTrc20 error:', error);
		throw error;
	}
}

export function useSentTronToken() {
	const { addPendingTronTransaction } = useTransactionWatcherStore();
	const { mutateAsync: sendTransaction, ...result } = useMutation({
		mutationKey: ['sent-tron-transaction'],
		mutationFn: async (params: TSentTronTransaction) => {
			let resp: BroadcastReturn<SignedTransaction>;
			if (isNativeToken(params.token.address)) {
				resp = await sendTron(params);
			} else {
				resp = await sendTRC20(params);
			}
			if (resp.code) throw resp.code;
			return resp;
		},
		onSuccess: (data, { to, token, message, amount, metadata, transactionType = TransactionType.Send }) => {
			const hash = data.transaction.txID;
			addPendingTronTransaction({
				hash: data.transaction.txID,
				transaction: getPendingSendTxs({
					memo: message,
					to,
					token,
					hash,
					chainId: ChainId.TRON,
					amount,
					transactionType,
				}),
				trackingData: metadata,
			});
		},
	});
	return {
		...result,
		sendTransaction,
	};
}
