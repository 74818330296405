import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiXIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.5896 4.41107C15.915 4.73651 15.915 5.26414 15.5896 5.58958L5.58958 15.5896C5.26414 15.915 4.73651 15.915 4.41107 15.5896C4.08563 15.2641 4.08563 14.7365 4.41107 14.4111L14.4111 4.41107C14.7365 4.08563 15.2641 4.08563 15.5896 4.41107Z"
				fill="black"
				fillOpacity="0.64"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.41107 4.41107C4.73651 4.08563 5.26414 4.08563 5.58958 4.41107L15.5896 14.4111C15.915 14.7365 15.915 15.2641 15.5896 15.5896C15.2641 15.915 14.7365 15.915 14.4111 15.5896L4.41107 5.58958C4.08563 5.26414 4.08563 4.73651 4.41107 4.41107Z"
				fill="black"
				fillOpacity="0.64"
			/>
		</svg>
	);
};
