import { useWalletAddressStore } from '@/app-store';
import { FiCopyIcon } from '@/assets/icons';
import { Box } from '@chakra-ui/react';

export const CopyAddress = () => {
	const { setOpen } = useWalletAddressStore();
	return (
		<Box role="button" onClick={() => setOpen(true)}>
			<FiCopyIcon width={20} height={20} />
		</Box>
	);
};
