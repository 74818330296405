import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Center, Container, Text } from '@chakra-ui/react';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useOnEventCallback } from '@/app-hooks/common';
import { RecoveryType } from '../../components/RecoveryType';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import { FiGoogleDriverIcon, FiRestoreIcon } from '@/assets/icons';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { UploadFile } from '../../components/UploadFile';
import { RECOVERY_TYPE } from '@/app-constants/backup';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { MAPPING_BACKUP_TYPE } from '@/app-views/account-setting/AccountSetting';

function capitalizeFirstLetter(text) {
	if (!text) return '';
	return text.charAt(0).toUpperCase() + text.slice(1);
}
const RECOVERY_TYPES = [
	{
		img: <FiGoogleDriverIcon />,
		type: RECOVERY_TYPE.DRIVE,
		title: 'recovery.methods.googleDrive.title',
		description: 'recovery.methods.googleDrive.description',
	},
];

interface RecoverySelectMethodProps {}

const RecoverySelectMethod: React.FunctionComponent<RecoverySelectMethodProps> = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { data: userProfile } = useQueryUserProfile();

	const onRecovery = useOnEventCallback((type: string) => {
		navigate(`${NAVIGATE_PATHS.Recovery.InputSecretKey}?recoveryType=${type}`);
	});

	const recoveryType = userProfile?.backupType
		? RECOVERY_TYPES.find((rType) => rType.type === userProfile?.backupType)
		: null;
	const isAlreadyBackup = !!userProfile?.backupType;
	const backupIdentifier = userProfile?.backupType === 'telegram' ? '@' : '';
	const backupInfo = userProfile?.backupEmail ? `: ${backupIdentifier}${userProfile.backupEmail}` : '';
	return (
		<PageTransition>
			<PageNavigation>
				<Container p={8} height="100vh" overflow="auto">
					<Center>
						<FiRestoreIcon />
					</Center>
					{isAlreadyBackup ? (
						<Text as="h1" textAlign="center" fontSize="28px" fontWeight={500} my={2}>
							{t('recovery.title')}
						</Text>
					) : (
						<Text as="h3" textAlign="center" fontSize="16px" fontWeight={500} my={2}>
							{t('recovery.notBackupYet')}
						</Text>
					)}
					<Text
						mt={isAlreadyBackup ? 4 : 2}
						textAlign="center"
						fontSize="xs"
						fontWeight={400}
						color="gray.400"
					>
						{isAlreadyBackup ? t('recovery.alreadyBackup') : t('recovery.backupSuggestion')}
					</Text>
					<Box mt={6}>
						{recoveryType ? (
							<RecoveryType
								email={userProfile?.backupEmail}
								recoveryType={recoveryType}
								onClick={() => onRecovery(recoveryType.type)}
							/>
						) : null}
					</Box>
					{isAlreadyBackup && (
						<>
							<Text my={2} fontSize="xs" fontWeight={500} textAlign="center">
								Or
							</Text>
							<UploadFile
								showIcon
								title={t('recovery.methods.uploadFile.title')}
								description={t('recovery.methods.uploadFile.description', {
									backupMethod:
										userProfile.backupType === 'drive'
											? 'Google Drive'
											: capitalizeFirstLetter(userProfile.backupType),
								})}
								email={
									userProfile?.backupEmail
										? `(${t(MAPPING_BACKUP_TYPE[userProfile?.backupType])}${backupInfo})`
										: ''
								}
							/>
						</>
					)}
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
export default RecoverySelectMethod;
