import React from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	Center,
	Text,
} from '@chakra-ui/react';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { FiUpArrowCircleIcon } from '@/assets/icons';
import { MainRouter } from '@/app-router';
import { useTranslation } from 'react-i18next';

interface RefreshWalletProps {
	isOpen: boolean;
	onClose: () => void;
}

export const RefreshWallet: React.FC<RefreshWalletProps> = ({ isOpen, onClose }) => {
	const cancelRef = React.useRef();
	const { t } = useTranslation();
	const onUpdate = () => {
		onClose();
		MainRouter.navigate(NAVIGATE_PATHS.CreateWallet.Onboarding);
	};
	return (
		<AlertDialog
			size="xs"
			isCentered
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			autoFocus={false}
			trapFocus={false}
			closeOnEsc={false}
			closeOnOverlayClick={false}
		>
			<AlertDialogOverlay>
				<AlertDialogContent borderRadius="1.5rem">
					<AlertDialogBody py={10} px={8}>
						<Center flexDirection="column" textAlign="center">
							<FiUpArrowCircleIcon />
							<Text mt={4} fontSize="1.25rem">
								{t('refreshWallet.title')}
							</Text>
							<Text fontSize="xs" mt={3}>
								{t('refreshWallet.description1')}
							</Text>
							<Text mt={5} fontSize="xs">
								{t('refreshWallet.description2')}
							</Text>
							<Button
								onClick={onUpdate}
								mt={6}
								ref={cancelRef}
								size="lg"
								fontWeight="medium"
								colorScheme="cyan"
								width="100%"
							>
								{t('refreshWallet.refreshAccount')}
							</Button>
						</Center>
					</AlertDialogBody>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
