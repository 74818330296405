import { BffServiceAPI } from '@/app-cores/api/bff';
import {
	navigateToTokenDetail,
	StartAppParamSend,
	useFetchRouteAndRedirectToSwapConfirm,
	useNavigateToSentPage,
	useNavigateToSwapPage,
	useNavigateToSwapPageFromUrl,
} from '@/app-helpers/navigate';
import { MainRouter } from '@/app-router';
import { NAVIGATE_PATHS } from '../../../app-constants/router';
import { useParseBotStartRedirectParams } from '../../../app-cores/telegram/useStartAppParam';
import { toQueryString } from '@/app-helpers/url';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { ChainId, ID_TOBI_NATIVE_TOKENS, TON_TCAT_TOBI_ID } from '@/app-constants/chains';
import { populateChildrenForToken, selectTokenDefaultFromUniversalToken } from '@/app-helpers/token';

export enum Actions {
	SWAP = 'botSw',
	SEND = 'send',
	SWAP_CONFIRM = 'swapConfirm',
	TOKEN_DETAIL = 'tokenDetail',
	REFERRAL = 'referral',
	REFERRAL_INVITE = 'referral_invite',
	REFERRAL_DETAIL = 'referral_detail',
	REQUEST_FUND = 'request_fund',
	SOL_TCAT = 'sol_tcat',
	ACTIVITIES = 'activity',
}

export function useBotRedirect() {
	const { action, data, toChain, fromChain, toToken, fromToken, query, amount, amountUsd, requestId, tobiId } =
		useParseBotStartRedirectParams();

	const navigateToSend = useNavigateToSentPage();
	const navigateToSwap = useNavigateToSwapPage(toChain);
	const navigateToSwapWithPair = useNavigateToSwapPageFromUrl(
		toQueryString(NAVIGATE_PATHS.Swap.Main, {
			tokenIn: fromToken,
			tokenOut: toToken,
			chainIn: fromChain,
			chainOut: toChain,
		}),
	);
	const navigateToSwapWithPairSolTCat = useNavigateToSwapPageFromUrl(
		toQueryString(NAVIGATE_PATHS.Swap.Main, {
			tokenIn: ID_TOBI_NATIVE_TOKENS.SOL,
			tokenOut: TON_TCAT_TOBI_ID,
			chainIn: ChainId.SOL,
			chainOut: ChainId.TON,
		}),
	);

	const navigateToConfirm = useFetchRouteAndRedirectToSwapConfirm();

	// todo refactor, split func
	// todo chop chop HOME
	return async (replace: boolean) => {
		try {
			switch (action) {
				case Actions.SWAP: {
					let [tokenOut, tokenIn] = await BffServiceAPI.searchExactListTokens({
						query: [
							{ chainId: toChain, address: toToken, tobiId },
							{ chainId: fromChain, address: fromToken },
						].filter((e) => Object.values(e).some((e) => e)),
					});
					const payload = {
						queryTokenOut: query,
						amount,
						amountUsd,
						replace,
					};
					if (tokenOut) {
						tokenOut = selectTokenDefaultFromUniversalToken(tokenOut);
					}
					if (tokenIn) {
						tokenIn = selectTokenDefaultFromUniversalToken(tokenIn);
					}
					// only 2 cases for now: only token out, both tokenIn and tokenOut
					if (tokenIn && tokenOut) {
						dataDogAddAction(DATADOG_ACTIONS.BOT_TRADE);
						navigateToSwapWithPair(payload, replace);
					} else if (tokenOut) {
						dataDogAddAction(DATADOG_ACTIONS.BOT_TRADE);
						navigateToSwap({ ...payload, tokenOut }, replace);
					}
					break;
				}
				case Actions.SWAP_CONFIRM: {
					if ([toChain, fromChain, toToken, fromToken].some((e) => !e)) return;
					navigateToConfirm({ toChain, fromChain, toTobiId: toToken, fromTobiId: fromToken, amount });
					break;
				}
				case Actions.TOKEN_DETAIL: {
					if (!tobiId) return;
					navigateToTokenDetail({ tobiId }, { replace });
					break;
				}
				case Actions.REFERRAL: {
					MainRouter.navigate(NAVIGATE_PATHS.AccountSettings.InvitationCode);
					break;
				}
				case Actions.REFERRAL_DETAIL: {
					MainRouter.navigate(NAVIGATE_PATHS.AccountSettings.InvitationCodeDetail);
					break;
				}
				case Actions.REFERRAL_INVITE: {
					MainRouter.navigate(`${NAVIGATE_PATHS.AccountSettings.InvitationCode}?autoInvite=1`);
					break;
				}
				case Actions.REQUEST_FUND: {
					MainRouter.navigate(
						requestId
							? NAVIGATE_PATHS.RequestFund.requestDetail(requestId)
							: NAVIGATE_PATHS.RequestFund.Main,
					);
					break;
				}
				case Actions.SEND: {
					navigateToSend(data);
					break;
				}
				case Actions.SOL_TCAT: {
					navigateToSwapWithPairSolTCat();
					break;
				}
				default:
					break;
			}
		} catch (error) {
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.BOT_REDIRECT,
					function: 'botRedirect',
				},
			});
		}
	};
}
