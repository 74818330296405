import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiLinkIcon: React.FC<IProps> = ({ width = 20, height = 20, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 7.45768C4.11594 7.45768 3.2681 7.80887 2.64298 8.43399C2.01786 9.05911 1.66667 9.90696 1.66667 10.791C1.66667 11.6751 2.01786 12.5229 2.64298 13.148C2.95251 13.4576 3.31997 13.7031 3.72439 13.8706C4.12881 14.0381 4.56226 14.1243 5 14.1243H7.5C7.96024 14.1243 8.33333 14.4974 8.33333 14.9577C8.33333 15.4179 7.96024 15.791 7.5 15.791H5C4.34339 15.791 3.69321 15.6617 3.08658 15.4104C2.47995 15.1591 1.92876 14.7908 1.46447 14.3265C0.526784 13.3889 0 12.1171 0 10.791C0 9.46493 0.526784 8.19316 1.46447 7.25548C2.40215 6.3178 3.67392 5.79102 5 5.79102H7.5C7.96024 5.79102 8.33333 6.16411 8.33333 6.62435C8.33333 7.08459 7.96024 7.45768 7.5 7.45768H5ZM11.6667 6.62435C11.6667 6.16411 12.0398 5.79102 12.5 5.79102H15C15.6566 5.79102 16.3068 5.92034 16.9134 6.17162C17.52 6.42289 18.0712 6.79119 18.5355 7.25548C18.9998 7.71977 19.3681 8.27097 19.6194 8.8776C19.8707 9.48423 20 10.1344 20 10.791C20 11.4476 19.8707 12.0978 19.6194 12.7044C19.3681 13.3111 18.9998 13.8623 18.5355 14.3265C18.0712 14.7908 17.52 15.1591 16.9134 15.4104C16.3068 15.6617 15.6566 15.791 15 15.791H12.5C12.0398 15.791 11.6667 15.4179 11.6667 14.9577C11.6667 14.4974 12.0398 14.1243 12.5 14.1243H15C15.4377 14.1243 15.8712 14.0381 16.2756 13.8706C16.68 13.7031 17.0475 13.4576 17.357 13.148C17.6666 12.8385 17.9121 12.471 18.0796 12.0666C18.2471 11.6622 18.3333 11.2288 18.3333 10.791C18.3333 10.3533 18.2471 9.91982 18.0796 9.5154C17.9121 9.11099 17.6666 8.74352 17.357 8.43399C17.0475 8.12446 16.68 7.87893 16.2756 7.71142C15.8712 7.5439 15.4377 7.45768 15 7.45768H12.5C12.0398 7.45768 11.6667 7.08459 11.6667 6.62435Z"
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.8335 10.7909C5.8335 10.3306 6.20659 9.95752 6.66683 9.95752H13.3335C13.7937 9.95752 14.1668 10.3306 14.1668 10.7909C14.1668 11.2511 13.7937 11.6242 13.3335 11.6242H6.66683C6.20659 11.6242 5.8335 11.2511 5.8335 10.7909Z"
				fill="#00E9DB"
			/>
		</svg>
	);
};
