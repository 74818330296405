import { Box, Button, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';
import { useState } from 'react';
import { useConnect } from '../hooks';
import { useTonConnect } from '@/app-features/app-ton-connect/hooks/useConnect';
import { InputCustom } from '@/app-components/common';
import { useTranslation } from 'react-i18next';

interface ConnectFormProps {
	onSubmit?: () => void;
}
export const ConnectForm: React.FC<ConnectFormProps> = ({ onSubmit }) => {
	const [uri, setUri] = useState('');
	const { onConnect, isLoading } = useConnect();
	const { onConnect: onTonConnect } = useTonConnect();
	const { t } = useTranslation();

	return (
		<Box border="1px solid" borderColor="gray.100" borderRadius={16} padding={3}>
			<InputGroup>
				<InputCustom
					placeholder={t('walletConnect.connectUri')}
					fontSize="sm"
					border="none"
					_focus={{
						boxShadow: 'none',
					}}
					value={uri}
					pl={0}
					onChange={(e) => setUri(e.target.value)}
				/>

				<InputRightElement cursor="pointer" width="auto" position="relative" marginLeft="auto">
					<Button
						width="100%"
						colorScheme="cyan"
						isDisabled={!uri}
						minWidth={24}
						onClick={async () => {
							if (uri.startsWith('tc://')) {
								onTonConnect(uri);
							} else {
								await onConnect(uri);
							}

							onSubmit?.();
							setUri('');
						}}
					>
						{isLoading ? <Spinner size="sm" /> : t('walletConnect.connect')}
					</Button>
				</InputRightElement>
			</InputGroup>
		</Box>
	);
};
