import { ITokenSearch } from '@/app-cores/api/bff';
import { useTokenBalance } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { NumberFormatValues, OnValueChange, SourceInfo } from 'react-number-format';
import { formatCurrency, formatUnits, formatUsd } from '../../../app-helpers/number';
import { CSSProperties, ReactNode, useRef } from 'react';
import { Box, Flex, Input, Text } from '@chakra-ui/react';

import { BaseInput, Numeric } from '@/app-components/common';
import { FiSwapIcon } from '@/assets/icons';
import { InputMode } from '@/app-store/swap';
import { BASE_BACKGROUND_COLOR, BASE_BORDER_COLOR } from '@/app-theme/theme';
import { FiCloseIcon } from '@/assets/icons/fi-close-icon';
import { ethers } from 'ethers';
import { getTokenInfo } from '@/app-helpers/token';

export const SelectTokenLabel = ({
	text,
	onClick,
	style,
}: {
	text: ReactNode;
	onClick?: () => void;
	style?: CSSProperties;
}) =>
	text && (
		<Box
			fontSize={'12px'}
			onClick={onClick}
			cursor={onClick ? 'pointer' : undefined}
			fontWeight={'500'}
			style={style}
		>
			{text}
		</Box>
	);

export const TokenAmountLabelBalance = ({
	token,
	inputMode,
	label,
	onMax,
	usdPrice,
}: {
	token: ITokenSearch | undefined;
	inputMode: InputMode;
	label: ReactNode;
	usdPrice?: string | number;
	onValueChange?: (amount: NumberFormatValues) => void;
	onMax?: () => void;
}) => {
	const { address, chainId, decimals, symbol } = getTokenInfo(token);
	const { data: balance } = useTokenBalance({ tokenAddress: address ?? '', chainId });
	const displayBalance = balance ? formatUnits(balance?.balance, decimals) : '0.00';
	const usdBalance =
		usdPrice && balance ? formatUsd(+ethers.formatUnits(balance?.balance, decimals) * +usdPrice) : '$0.00';

	return (
		<Flex justifyContent={'space-between'}>
			<SelectTokenLabel text={label} />
			<Flex>
				{token && (
					<SelectTokenLabel
						onClick={onMax}
						text={
							<Text as="span">
								<Text as={'span'} color={'gray.400'}>
									Available:{' '}
								</Text>
								{inputMode === InputMode.AMOUNT
									? `${displayBalance} ${symbol ?? ''}`
									: +usdPrice
									? usdBalance
									: `0`}
							</Text>
						}
						style={{ paddingRight: '10px' }}
					/>
				)}
				<SelectTokenLabel
					onClick={onMax}
					text={'Max'}
					style={{ borderLeft: `1px solid ${BASE_BORDER_COLOR}`, paddingLeft: '12px' }}
				/>
			</Flex>
		</Flex>
	);
};

export const TokenAmountInput = (props: {
	value: string;
	token: ITokenSearch | undefined;
	disabled?: boolean;
	usdValue?: string | number;
	usdPrice?: string | number;
	onValueChange?: OnValueChange;
	toggleInputMode?: () => void;
	onMax?: () => void;
	inputStyle?: React.CSSProperties;
	showBalance?: boolean;
	label?: ReactNode;
	inputMode?: InputMode;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	prefix?: string;
	allowSwitchAmountMode?: boolean;
	wrapperId?: string;
}) => {
	const {
		disabled,
		token,
		value,
		usdValue,
		usdPrice,
		onValueChange,
		inputStyle,
		showBalance = true,
		onMax,
		inputMode = InputMode.USD,
		toggleInputMode,
		onChange,
		label,
		prefix,
		allowSwitchAmountMode = true,
		wrapperId,
	} = props;
	const align = 'left';

	const { symbol } = getTokenInfo(token);

	const inputRef = useRef<HTMLInputElement>(null);
	const showUsd = value && Number(usdValue) > 0;
	const tokenAmountMode = inputMode === InputMode.AMOUNT;

	return (
		<Flex gap={'10px'} flexDirection={'column'} id={wrapperId} p={1}>
			{showBalance && (
				<TokenAmountLabelBalance
					token={token}
					inputMode={inputMode}
					label={
						label ||
						(inputMode === InputMode.AMOUNT
							? `${`Amount ${token ? `in ${symbol}` : ''}`}`
							: 'Amount in USD')
					}
					onMax={onMax}
					usdPrice={usdPrice}
				/>
			)}
			<Flex
				style={{
					backgroundColor: BASE_BACKGROUND_COLOR,
					padding: '8px 16px',
					borderRadius: '12px',
					justifyContent: 'space-between',
				}}
			>
				<Flex
					flexDirection={'column'}
					gap={'4px'}
					sx={{
						maxWidth: window.innerWidth < 400 ? '150px' : 'unset',
						flex: 1,
					}}
				>
					<Numeric
						prefix={tokenAmountMode ? undefined : '$'}
						allowNegative={false}
						value={value}
						allowLeadingZeros
						placeholder="Amount"
						inputMode="decimal"
						inputType={inputMode === InputMode.USD ? 'usd' : 'number'}
						getInputRef={(ref) => (inputRef.current = ref)}
						style={{
							padding: !showUsd ? '11px 0' : 0,
							fontWeight: '500',
							fontSize: '14px',
							border: 'none',
							outline: 'none',
							boxShadow: 'none',
							textAlign: align,
							maxWidth: '100%',
							height: '21px',
							flex: 1,
							...inputStyle,
							backgroundColor: inputStyle?.backgroundColor ?? 'inherit',
						}}
						disabled={disabled}
						onChange={onChange}
						onValueChange={(value, source) => onValueChange?.(value, source)}
					/>
					{showUsd && (
						<Text
							textAlign={align}
							onClick={() => inputRef.current?.focus()}
							as="span"
							fontSize={'12px'}
							whiteSpace={'nowrap'}
							lineHeight={'18px'}
						>
							{!showUsd
								? ''
								: tokenAmountMode
								? `≈ ${formatUsd(usdValue)}`
								: `≈ ${formatCurrency(usdValue)} ${symbol}`}
						</Text>
					)}
				</Flex>

				<Flex gap={'10px'} alignItems={'center'}>
					{value && (
						<FiCloseIcon
							size={16}
							onClick={() => {
								onValueChange({ value: '', formattedValue: '', floatValue: 0 }, {
									source: 'event',
								} as SourceInfo);
							}}
						/>
					)}
					{prefix && (
						<Text fontSize={'14px'} color={'gray.400'}>
							{prefix}
						</Text>
					)}
					{allowSwitchAmountMode && (
						<Flex
							width={'70px'}
							userSelect={'none'}
							cursor={'pointer'}
							alignItems={'center'}
							gap={'8px'}
							height={'44px'}
							paddingLeft={'14px'}
							borderLeft={`1px solid ${BASE_BORDER_COLOR}`}
							onClick={toggleInputMode}
						>
							<Text
								fontSize={'12px'}
								fontWeight={'500'}
								as="span"
								display={'inline-block'}
								verticalAlign={'middle'}
								paddingTop={'2px'}
							>
								{inputMode}
							</Text>
							<FiSwapIcon width={14} height={14} />
						</Flex>
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export const AmountInput = (props: {
	value: string;
	token: ITokenSearch | undefined;
	disabled?: boolean;
	onValueChange?: OnValueChange;
	inputStyle?: React.CSSProperties;
	label: ReactNode;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	action?: ReactNode;
}) => {
	const { disabled, token, value, onValueChange, inputStyle, onChange, label, placeholder, action } = props;
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<Flex gap={'10px'} flexDirection={'column'}>
			<Flex justifyContent={'space-between'}>
				<SelectTokenLabel text={label} />
				{action}
			</Flex>
			<Flex
				style={{
					backgroundColor: BASE_BACKGROUND_COLOR,
					padding: '8px 16px',
					borderRadius: '12px',
				}}
			>
				<Flex flexDirection={'column'} gap={'4px'} flex={1}>
					<Numeric
						allowNegative={false}
						value={value}
						allowLeadingZeros
						placeholder={placeholder || 'Amount'}
						inputMode="decimal"
						inputType={'number'}
						getInputRef={(ref) => (inputRef.current = ref)}
						style={{
							padding: '11px 0',
							fontWeight: '500',
							fontSize: '14px',
							border: 'none',
							outline: 'none',
							boxShadow: 'none',
							maxWidth: !token ? '160px' : 'unset',
							height: '21px',
							flex: 1,
							...inputStyle,
							backgroundColor: inputStyle?.backgroundColor ?? 'inherit',
						}}
						disabled={disabled}
						onChange={onChange}
						onValueChange={(value, source) => onValueChange?.(value, source)}
					/>
				</Flex>

				{value && (
					<Flex gap={'12px'} alignItems={'center'}>
						<FiCloseIcon
							size={18}
							onClick={() => {
								onValueChange({ value: '', formattedValue: '', floatValue: 0 }, {
									source: 'event',
								} as SourceInfo);
							}}
						/>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};
