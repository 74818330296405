import { useMutation } from '@tanstack/react-query';

import { CHAIN_CONFIG, ChainId, NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { isNativeToken } from '@/app-helpers/address';
import { SelectedRoute, SwapDisableType } from '@/app-store/swap';

import { isRouteParamsEmpty } from '@/app-hooks/swap';
import { bufferGas, useSentTonToken, useSubmitEVMTransaction } from '@/app-hooks/transactions';
import { formatCurrency, formatUnits, formatUsd } from '@/app-helpers/number';
import { BffServiceAPI, TokenInfo } from '@/app-cores/api/bff';
import { ExtractRouteInfo, MinAmountError, SwapAbstract, SwapProvider, UsdRouteInfo } from '@/app-hooks/swap/type';
import { getNativeToken, isEvmChain, isSolanaChain, isTonChain, isTronChain } from '@/app-helpers/token';
import axios, { AxiosRequestConfig } from 'axios';
import { ArgsGetRoute } from '@/app-hooks/swap/type';
import { parseUnits } from 'ethers';
import { TTransactionRequest, TransactionType } from '@/app-types';
import { useSentSolToken } from '@/app-hooks/transactions/sol/useMutationSentSolAsset';
import { AUTO_SLIPPAGE } from '@/app-views/swap/components/SlippageSetting';

import { useTranslation } from 'react-i18next';
import { uniqueId } from '@/app-helpers/random';
import { formatListRoutesBestReturn, getMyWalletAddressByChain } from '@/app-hooks/swap/helper';
import { estimateTonGasFee } from '@/app-hooks/transactions/ton/useEstimateFeeSendTonAsset';
import { useSentTronToken } from '@/app-hooks/transactions/tron/useMutationSendTronAsset';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';

const axiosClient = axios.create({
	baseURL: 'https://api.rocketx.exchange',
	headers: { 'x-api-key': 'f3d23d38-f471-42d1-86fd-85d570efd5e2' },
});

type ChainInfo = { id: string; chainId: string; enabled: number; sell_enabled: number };
class RocketX extends SwapAbstract<RouteRocketX> {
	#chains: Record<string, ChainInfo> = {};
	#initTask: Promise<any>;
	#isReady: boolean = false;

	async init() {
		try {
			if (this.#isReady) return;
			this.checkIP(SwapProvider.ROCKET);
			this.#initTask = axiosClient.get('/v1/configs');
			const { data } = await this.#initTask;
			const { configs, supported_network = [] } = data;
			this.#chains = this._formatChains(supported_network);
			this.#isReady = !configs.is_maintenance_mode_enabled && configs.is_rocket_live;
		} catch (error) {
			throw new Error('RocketX is not ready');
		}
	}

	private _formatChains(chains: ChainInfo[]) {
		return chains.reduce((rs, item) => {
			if (item.chainId === 'ton') rs[ChainId.TON] = item;
			if (item.chainId === 'tron') rs[ChainId.TRON] = item;
			else if (item.id === 'solana') rs[ChainId.SOL] = item;
			else if (item.chainId.startsWith('0x') && CHAIN_CONFIG.has(parseInt(item.chainId, 16))) {
				rs[parseInt(item.chainId, 16)] = item;
			}
			return rs;
		}, {});
	}

	async getRoute(
		params: ArgsGetRoute,
		signal: AbortSignal,
		retryWhenMinAmount = false,
	): Promise<SelectedRoute<RouteRocketX>> {
		await this.#initTask;
		if (!this.#isReady) throw new Error('Rocket X is not ready');
		const formatParams = getRouteParamsCrossChainRocketX(params, this.#chains);
		if (!formatParams) return;
		try {
			const data = await axiosClient.get<RoutesResponse>(`/v1/quotation`, { params: formatParams, signal });
			return formatListRouteCrossChainRocketX(data?.data, params);
		} catch (error) {
			const { amountInDefault, ...rest } = params;
			if ((error as MinAmountError).minAmountError && retryWhenMinAmount && amountInDefault) {
				const route: SelectedRoute<RouteRocketX> = await this.getRoute(
					{ ...rest, amountIn: amountInDefault },
					signal,
				);
				const props = { disabled: SwapDisableType.MIN_AMOUNT, disableReason: error };
				return { ...route, ...props, allRoutes: route.allRoutes?.map((e) => ({ ...e, ...props })) };
			}
			throw error;
		}
	}

	async buildRoute({
		route: { route, tokenOut, tokenIn },
		slippage,
		userAmount,
	}: {
		slippage: number;
		route: SelectedRoute<RouteRocketX>;
		userAmount: string;
	}) {
		const params: BuildRouteArgs = {
			sender: getMyWalletAddressByChain(tokenIn?.chainId),
			recipient: getMyWalletAddressByChain(tokenOut?.chainId),
			routeSummary: route,
			slippage,
		};
		const { data } = await axiosClient(getBuildRouteParams(params));
		data.fromTokenInfo.price = route.fromTokenInfo.price;
		data.toTokenInfo.price = route.toTokenInfo.price;
		return formatRouteCrossChainRocketX({ ...route, ...data }, { tokenIn, tokenOut, metadata: { userAmount } });
	}

	extractRoute(params: SelectedRoute<RouteRocketX>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRouteCrossChainRocketX(params, prices);
	}

	async getStatus({ txId, requestId }: { txId: string; requestId: string }) {
		try {
			const data = await axiosClient.get('/v1/status', { params: { txId, requestId } });
			const resp = data?.data;
			return resp
				? {
						amount_out: resp?.actualAmount,
						status: resp.status === 'success' ? 'Completed' : resp.status,
						destination_tx_hash: resp?.destinationTransactionHash,
				  }
				: undefined;
		} catch (error) {}
	}
}

export const RocketXSwap = new RocketX();

type RocketXRouteRequest = {
	toToken: string;
	amount: string;
	fromToken: string;
	toNetwork: string;
	fromNetwork: string;
	userAddress: string;
};

export type RouteRocketX = {
	allowanceAddress?: string;
	err?: string;
	exchangeInfo: {
		id: number;
		title: string;
		logo: string;
		exchange_type: string;
		allow_diff_wallet: boolean;
		override: boolean;
	};
	fromTokenInfo: {
		id: number;
		contract_address: string;
		token_decimals: number;
		token_symbol: string;
		token_name: string;
		network_symbol: string;
		icon_url: string;
		network_name: string;
		chainId: string;
		network_shorthand: string;
		network_logo: string;
		network_type: string;
		block_explorer_url: string;
		isTaxToken: boolean;
		network_id: string;
		price: number;
		is_native_token: number;
	};
	toTokenInfo: {
		id: number;
		contract_address: string;
		token_decimals: number;
		token_symbol: string;
		token_name: string;
		network_symbol: string;
		icon_url: string;
		network_name: string;
		chainId: string;
		network_shorthand: string;
		network_logo: string;
		network_type: string;
		block_explorer_url: string;
		network_id: string;
		price: number;
		is_native_token: number;
	};
	routes: {
		from: string;
		to: string;
		pair: string;
		operation: string;
		vol24hUsd: number;
		price: number;
		quotedPrice: number;
	}[];
	estTimeInSeconds: {
		avg: number;
		min: number;
		max: number;
	};
	type: string;
	fromAmount: number;
	toAmount: number;
	platformFeeUsd: number;
	platformFeeInPercent: number;
	excludingFee: number;
	includingFee: number;
	discount: number;
	isTxnAllowed: boolean;
	gasFeeUsd: number;
	additionalInfo: {
		avgPrice: {
			from: number;
			to: number;
		};
		priceImpact: number;
		priceImpactWithoutGasFee: number;
		totalFeeUsd: number;
		savingUsd: number;
		minRecieved: number;
	};
	// build route
	swap: {
		tx: { gas: string; value: string; gasPrice: string; to: string; from: string; data: string; memo?: string };
		fromAmount: number;
		toAmount: number;
		depositAddress: string;
	};
	requestId: string;
};

const getRouteParamsCrossChainRocketX = (
	args: ArgsGetRoute,
	chainInfoMap: Record<string, ChainInfo>,
): RocketXRouteRequest | undefined => {
	const { amountIn, tokenIn, tokenOut } = args;
	const chainInfoIn = chainInfoMap[tokenIn?.chainId];
	const chainInfoOut = chainInfoMap[tokenOut?.chainId];
	if (isRouteParamsEmpty(args) || !chainInfoIn?.enabled || !chainInfoOut?.enabled) return;
	return {
		toToken: isNativeToken(tokenOut.address) ? null : tokenOut.address,
		fromToken: isNativeToken(tokenIn.address) ? null : tokenIn.address,
		amount: formatUnits(amountIn, tokenIn.decimals, { withFormat: false }),
		toNetwork: chainInfoOut.id,
		fromNetwork: chainInfoIn.id,
		userAddress: getMyWalletAddressByChain(tokenIn?.chainId),
	};
};

const formatTokenRocketX = (token: any, tokenCatalog: TokenInfo): TokenInfo | undefined => {
	if (!token) return;
	const isNative = token?.is_native_token === 1;
	const chainId = tokenCatalog.chainId;
	const rs: TokenInfo = Object.assign(tokenCatalog || token, {
		...token,
		logo: tokenCatalog?.logo || token.icon_url,
		usdPrice: tokenCatalog?.priceUsd || +token.price,
		symbol: token.token_symbol,
		decimals: tokenCatalog.decimals,
		chainId,
		isNative,
		address: isNative ? NATIVE_TOKEN_ADDRESS : token.contract_address,
	} as TokenInfo);
	return rs;
};

const getExtractRouteCrossChainRocketX = (
	{ route, tokenIn: tokenInCatalog, tokenOut: tokenOutCatalog }: SelectedRoute<RouteRocketX>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo,
): ExtractRouteInfo => {
	const tokenIn = formatTokenRocketX(route?.fromTokenInfo, tokenInCatalog);
	const tokenOut = formatTokenRocketX(route?.toTokenInfo, tokenOutCatalog);
	const swapInfo = route?.swap;

	const fromAmount = swapInfo?.fromAmount || route?.fromAmount;
	const toAmount = swapInfo?.toAmount || route?.toAmount;

	const dappInfo = route?.exchangeInfo;
	const amountInUsd = (usdPriceIn || tokenIn?.priceUsd) * fromAmount;
	const amountOutUsd = (usdPriceOut || tokenOut?.priceUsd) * toAmount;

	let rate, amountIn, amountOut, gasNative, gasUsd;
	try {
		amountOut = parseUnits(toAmount?.toFixed(tokenOut.decimals), tokenOut.decimals).toString();
		amountIn = parseUnits(fromAmount?.toString(), tokenIn.decimals).toString();
		rate = toAmount / fromAmount;

		const native = getNativeToken(tokenIn?.chainId);
		gasUsd = route ? route?.gasFeeUsd + route?.platformFeeUsd : undefined;
		gasNative =
			usdPriceNative && gasUsd
				? parseUnits((gasUsd / usdPriceNative)?.toFixed(native.decimals), native.decimals)
				: undefined;
	} catch (error) {}

	return {
		amountInUsd,
		amountOutUsd,
		minAmountOut: route?.additionalInfo?.minRecieved?.toString(),
		rate,
		duration: route?.estTimeInSeconds?.avg,
		amountOut,
		amountIn,
		priceImpact: route?.additionalInfo?.priceImpact,
		gasUsd,
		gasNative,
		tokenIn,
		tokenOut,
		dappInfo: {
			logo: dappInfo?.logo,
			domain: dappInfo?.title,
		},
	};
};

type RoutesResponse = {
	quotes: RouteRocketX[];
};
const formatRouteCrossChainRocketX = (
	route: RouteRocketX,
	{ tokenIn, tokenOut, metadata }: { tokenIn: TokenInfo; tokenOut: TokenInfo; metadata?: any },
): SelectedRoute<RouteRocketX> | undefined => {
	return {
		route,
		id: uniqueId(),
		tokenIn: formatTokenRocketX(route.fromTokenInfo, tokenIn),
		tokenOut: formatTokenRocketX(route.toTokenInfo, tokenOut),
		routerAddress: route?.allowanceAddress,
		provider: SwapProvider.ROCKET,
		disabled: RocketXSwap.isBlocked ? SwapDisableType.LOCATION : undefined,
		timestamp: Date.now(),
		checkGasByUsd: true,
		metadata,
	};
};

const formatListRouteCrossChainRocketX = (
	route: RoutesResponse,
	params: ArgsGetRoute,
): SelectedRoute<RouteRocketX> | undefined => {
	const filterRoute = route?.quotes?.filter((e) => !e.err);

	if (!filterRoute?.length) {
		throw new MinAmountError(route.quotes?.[0] || { err: 'empty route' });
	}
	const allRoutes = formatListRoutesBestReturn<RouteRocketX>(
		filterRoute.map((e) => formatRouteCrossChainRocketX(e, params)),
	);
	const bestRoute = allRoutes?.[0];
	if (!bestRoute) return;
	return { ...bestRoute, allRoutes };
};

type BuildRouteArgs = {
	routeSummary: RouteRocketX | undefined;
	slippage: number;
	sender: string;
	recipient: string;
};

const getBuildRouteParams = (args: BuildRouteArgs): AxiosRequestConfig => {
	const { routeSummary, slippage, sender, recipient } = args;
	const { fromTokenInfo, toTokenInfo, fromAmount } = routeSummary;

	const payload = {
		fromTokenId: fromTokenInfo.id,
		toTokenId: toTokenInfo.id,
		userAddress: sender,
		destinationAddress: recipient,
		fee: 0,
		amount: fromAmount,
		slippage: +slippage === AUTO_SLIPPAGE ? 1 : +slippage,
	};

	return { url: `/v1/swap`, method: 'POST', data: payload };
};

export const useExecuteRouteRocketX = (chainId: string) => {
	const { sentTransaction } = useSubmitEVMTransaction(chainId);
	const { sendTransaction: sendSolTxs } = useSentSolToken();
	const { sendTransaction: sendTonTxs } = useSentTonToken();
	const { sendTransaction: sendTronTxs } = useSentTronToken();
	const { t } = useTranslation();

	const response = useMutation({
		mutationKey: ['exe-route-rocketx'],
		mutationFn: async ({
			route: routeData,
			skipAddPendingTxs,
		}: {
			route: SelectedRoute<RouteRocketX>;
			skipAddPendingTxs?: boolean;
		}) => {
			const { tokenIn, route: routeSwap, metadata } = routeData;
			const userAmount = metadata?.userAmount;
			const res = routeSwap.swap.tx;
			const { exchange_type } = routeSwap.exchangeInfo;
			if (routeSwap.fromAmount && +userAmount !== +routeSwap.fromAmount) {
				console.log(`Amount mismatched, please try again: ${userAmount} vs ${routeSwap.fromAmount}`);
				throw new Error(t('errors.swap.rateStale'));
			}

			if (!['CEX', 'DEX'].includes(exchange_type)) throw new Error('Unknown exchange_type: ' + exchange_type);
			const depositAddress = routeSwap?.swap?.depositAddress;

			let data;
			if (isEvmChain(tokenIn?.chainId)) {
				const payload: TTransactionRequest = {
					from: MpcWallet.getEvmWalletAddress(),
					data: res.data,
					// gasLimit: bufferGas(BigInt(parseInt(res.gas, 16))),
					chainId: tokenIn.chainId,
					// gasPrice: BigInt(res.gasPrice),
					gasLevel: 'low',
					metadata: routeData,
					transactionType: TransactionType.Swap,
					skipAddPendingTxs,
				};

				if (exchange_type === 'CEX') {
					if (isNativeToken(tokenIn.address)) {
						payload.value = BigInt(res.value || '0');
						payload.to = depositAddress;
					} else {
						payload.to = routeSwap.fromTokenInfo.contract_address;
					}
				}
				if (exchange_type === 'DEX') {
					payload.to = depositAddress;
					payload.value = BigInt(res.value || '0');
				}

				if (!payload.to) throw new Error('Unknown to address');
				data = await sentTransaction(payload);
			}
			if (!depositAddress) throw new Error('Unknown to depositAddress');
			if (isSolanaChain(tokenIn?.chainId)) {
				const hash = await sendSolTxs({
					token: tokenIn,
					amount: routeSwap.fromAmount?.toString(),
					to: depositAddress,
					message: res.memo,
					autoDeductFee: false,
					transactionType: TransactionType.Swap,
					metadata: routeData,
					skipAddPendingTxs,
				});
				data = { hash };
			}
			if (isTonChain(tokenIn?.chainId)) {
				const payload = {
					token: tokenIn,
					amount: routeSwap.fromAmount?.toString(),
					to: depositAddress,
					message: res.memo,
					autoDeductFee: false,
				};
				const gasFee = await estimateTonGasFee(payload);
				const { seqno } = await sendTonTxs({
					...payload,
					gasFee,
					transactionType: TransactionType.Swap,
					metadata: routeData,
					skipAddPendingTxs,
				});
				data = { hash: seqno };
			}
			if (isTronChain(tokenIn?.chainId)) {
				const resp = await sendTronTxs({
					token: tokenIn,
					amount: routeSwap.fromAmount?.toString(),
					to: depositAddress,
					message: res.memo,
				});
				data = { hash: resp.transaction.txID };
			}
			RocketXSwap.getStatus({ txId: data.hash, requestId: routeSwap.requestId });
			return data;
		},
	});
	return response;
};
