import { ServiceUser } from '@/app-cores/api/bot';
import { useMutation } from '@tanstack/react-query';

export const useMutationSetBackupType = () => {
	return useMutation({
		mutationKey: ['mutation-set-backup-type'],
		mutationFn: async (type: Parameters<typeof ServiceUser.setBackupType>[0]) => {
			return ServiceUser.setBackupType(type);
		},
	});
};

export const useMutationDeleteBackupType = () => {
	return useMutation({
		mutationKey: ['mutation-delete-backup-type'],
		mutationFn: async () => {
			return ServiceUser.deleteBackupType();
		},
	});
};
