import { navigateOnboardingFromBotRedirect } from '@/app-components/common/CreateAccount';
import { ITokenSearch } from '@/app-cores/api/bff';
import { useMutationAddTokens, useMutationRemoveTokens } from '@/app-hooks/api/tokens/useMutationUserSettingTokens';
import { useTokenLabels, useUserTokenSettings } from '@/app-hooks/api/tokens/useUserTokenSettings';
import { useDebounce } from '@/app-hooks/common';
import { FiInfoIcon } from '@/assets/icons';
import { Alert, AlertDescription, AlertIcon, Collapse, Switch, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
interface Props {
	chainId: string;
	contractAddress: string;
	isNonWallet: boolean;
	data: ITokenSearch;
}
export default function WhitelistedInfo({ chainId, contractAddress, isNonWallet, data: tokenInfo }: Props) {
	const { data, isFetching, isFetched } = useUserTokenSettings();

	const { mutateAsync: addToken } = useMutationAddTokens();
	const { mutateAsync: removeToken } = useMutationRemoveTokens();

	const labels = useTokenLabels({ chainId, address: contractAddress });

	const [value, setValue] = useState(false);
	const isIndexed = true; // tokenInfo?.isIndexed;

	const isWhitelisted = (() => {
		if (labels.includes('imported')) return true;
		if (labels.includes('hidden')) return false;
		return isIndexed;
	})();

	useEffect(() => {
		setValue(isWhitelisted);
	}, [isWhitelisted]);

	const shouldHide = isIndexed || isFetching || !data || !tokenInfo;
	const shouldHideDebounced = useDebounce(shouldHide, 400);
	const navigate = useNavigate();

	return (
		<Collapse in={!(isFetched ? shouldHideDebounced : shouldHide)} animateOpacity>
			<Alert status="info" colorScheme={'gray'} borderRadius={'12px'} alignItems={'flex-start'}>
				<AlertIcon>
					<FiInfoIcon
						style={{
							height: '24px',
							minWidth: '24px',
						}}
					/>
				</AlertIcon>
				<AlertDescription flexDirection={'column'} display={'flex'} gap={'6px'}>
					<Text lineHeight={'18px'} fontSize={'12px'}>
						Token is not listed by Tobi. It will be added to your token list if you switch on.
					</Text>
					<Switch
						colorScheme="green"
						size="lg"
						isChecked={value}
						onChange={(e) => {
							if (isNonWallet) {
								navigate(navigateOnboardingFromBotRedirect);
							} else {
								setValue(e.target.checked);
								isWhitelisted
									? removeToken({ chainId, contractAddress, type: 'include' })
									: addToken({ chainId, contractAddress, type: 'include' });
							}
						}}
					/>
				</AlertDescription>
			</Alert>
		</Collapse>
	);
}
