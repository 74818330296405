import { AuthClient } from '@/app-cores/auth';
import { initI18n } from '@/app-cores/i18n';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useMutation } from '@tanstack/react-query';
import { dataDogAddUserInfo } from '@/app-services/monitor/datadog';

export const useMutationInitApp = ({ onCompleted }: { onCompleted?: () => void }) => {
	return useMutation({
		mutationKey: ['mutation-init-app'],
		mutationFn: async () => {
			try {
				console.time('initialized');
				dataDogAddUserInfo();
				await AuthClient.init();

				if (!AuthClient.tokenManager.exist()) {
					await AuthClient.authenticate();
				}
				await Promise.all([initI18n(), MpcWallet.initialize(() => AuthClient.authHeader())]);
				await onCompleted?.();
				console.timeEnd('initialized');
				return true;
			} catch (error) {
				console.error('InitApp Error', error);
				return false;
			}
		},
	});
};
