import { memo, useEffect, useState } from 'react';
import { StepContent } from './StepContent';
import { TOUR_GUIDE_STEPS_TARGET } from './stepConfigs';
import { tourGuideManager } from './helper';
import { Step, TobiTourGuide } from './TourGuide';

const steps: Step[] = [
	{
		target: TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.COPILOT,
		content: <StepContent title="tourGuide.home.step1.title" description="tourGuide.home.step1.description" />,
		skipActiveState: true,
	},
	{
		target: TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.DEPOSIT,
		content: <StepContent title="tourGuide.home.step2.title" description="tourGuide.home.step2.description" />,
		offset: {
			left: -60,
			top: 40,
		},
	},
	{
		target: TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.TRADE,
		content: <StepContent title="tourGuide.home.step3.title" description="tourGuide.home.step3.description" />,
		offset: {
			left: -60,
			top: 40,
		},
	},
	{
		target: TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.SEARCH_TOKEN,
		content: <StepContent title="tourGuide.home.step4.title" description="tourGuide.home.step4.description" />,
		offset: {
			left: -60,
			top: 40,
		},
	},
	{
		target: TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.COPILOT,
		content: <StepContent title="tourGuide.home.step5.title" description="tourGuide.home.step5.description" />,
		skipActiveState: true,
	},
];

const TourGuide = () => {
	const [run, setRun] = useState(false);

	useEffect(() => {
		setRun(true);
	}, []);

	return (
		<TobiTourGuide
			run={run}
			steps={steps}
			initIconPosition={{
				top: document.body.clientHeight - 90,
				left: document.body.clientWidth - 60,
			}}
			onComplete={() => {
				tourGuideManager.disableHomePage();
				setRun(false);
			}}
		/>
	);
};
export const HomeTourGuide = memo(TourGuide);
