import { Collapse, Divider, Flex, FlexProps } from '@chakra-ui/react';

import ApproveModal from '@/app-components/ApproveModal';

import { InputMode, SelectedRoute } from '@/app-store/swap';
import { TransactionType } from '@/app-types';

import ListRoute from '@/app-views/swap/components/ListRoute';
import BuyToken from '@/app-views/swap/components/BuyToken';
import { TokenAmountInput } from '@/app-components/common/SelectToken/TokenAmount';
import Warning from '@/app-components/common/Warning';

import { SwapErrorInfo, SwapService } from '@/app-hooks/swap';
import { ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import { OnValueChange } from 'react-number-format';
import { SwapErrorType } from '@/app-hooks/swap/type';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { TOUR_GUIDE_STEPS_TARGET } from '@/app-views/wallet/components/TourGuide/stepConfigs';

export type SwapFormProps = {
	onMax: () => void;
	usdPriceTokenIn: string | number | undefined;
	errorMsg: SwapErrorInfo;
	onChangeAmount: OnValueChange;
	openApprove: boolean;
	gettingRoute: boolean;
	fetchingSuggestRoute: boolean;
	onChangeRoute: (v: SelectedRoute) => void;
	onClickRoute: (v: SelectedRoute) => void;
	suggestRoutes: SelectedRoute[];
	selectedRoute: SelectedRoute | undefined;
	hideApproveModal: () => void;
	parsedAmount: bigint | undefined;
	toggleInputMode: () => void;
	tokenAmountInfo: {
		amountIn: string;
		usdAmount: string | number;
	};
	quickSwap: boolean;
	tokenIn: ITokenSearch | undefined;
	tokenOut: ITokenSearch | undefined;
	tokenInfoIn: TokenInfo | undefined;
	tokenInfoOut: TokenInfo | undefined;
	inputMode: InputMode;
	isNonWallet: boolean;
	containerProps: FlexProps;
	refetch: () => Promise<any>;
};

export default function SwapForm(props: SwapFormProps) {
	const {
		onMax,
		errorMsg,
		onChangeAmount,
		openApprove,
		gettingRoute,
		fetchingSuggestRoute,
		onChangeRoute,
		suggestRoutes,
		selectedRoute,
		onClickRoute,
		hideApproveModal,
		parsedAmount,
		usdPriceTokenIn,
		toggleInputMode,
		tokenAmountInfo,
		quickSwap,
		tokenIn,
		tokenOut,
		tokenInfoIn,
		tokenInfoOut,
		inputMode,
		isNonWallet,
		containerProps,
		refetch,
	} = props;
	const { dappInfo } = SwapService.extractRouteInfo({ route: selectedRoute });
	const routerAddress = selectedRoute?.routerAddress;
	return (
		<>
			<Flex flexDirection={'column'} flex={1} gap={'16px'} {...containerProps}>
				<TokenAmountInput
					token={tokenIn}
					value={tokenAmountInfo.amountIn}
					usdValue={tokenAmountInfo.usdAmount}
					onValueChange={onChangeAmount}
					onMax={() => {
						onMax?.();
						dataDogAddAction(DATADOG_ACTIONS.TRADE_USE_MAX);
					}}
					usdPrice={usdPriceTokenIn}
					inputMode={inputMode}
					toggleInputMode={() => {
						toggleInputMode?.();
						dataDogAddAction(DATADOG_ACTIONS.TRADE_SWITCH_INPUT_MODE);
					}}
					inputStyle={{
						color: errorMsg?.messages?.some((e) => e.errorType === SwapErrorType.VALIDATE_AMOUNT)
							? 'red'
							: undefined,
					}}
					wrapperId={TOUR_GUIDE_STEPS_TARGET.TRADE.AMOUNT}
				/>

				<Collapse in={!!errorMsg?.messages.length} style={quickSwap ? { overflow: 'unset' } : undefined}>
					<Flex flexDirection="column" gap="14px">
						{errorMsg.messages.map((e) =>
							[SwapErrorType.ROUTE, SwapErrorType.VALIDATE_AMOUNT].includes(e.errorType) ? null : [
									SwapErrorType.FUND,
									SwapErrorType.GAS,
							  ].includes(e.errorType) && !quickSwap ? (
								<BuyToken
									errorMsg={e}
									key={e.msg}
									tokenInfoOut={tokenInfoOut}
									tokenInfoIn={tokenInfoIn}
									tokenIn={tokenIn}
									buyNative={e.errorType === SwapErrorType.GAS}
									isNonWallet={isNonWallet}
								/>
							) : (
								<Warning key={e.msg} msg={e.msg} title={e?.title} status={e?.type} />
							),
						)}
					</Flex>
				</Collapse>

				<Divider variant={'dashed'} />
				<ListRoute
					errorMsg={errorMsg}
					tokenIn={tokenInfoIn}
					tokenOut={tokenInfoOut}
					loadingRouteByUserInput={gettingRoute}
					loadingSuggestedRoutes={fetchingSuggestRoute}
					suggestedRoutes={suggestRoutes}
					onClickRoute={onClickRoute}
					onChangeRoute={onChangeRoute}
					selectedRoute={selectedRoute}
					amountIn={parsedAmount}
					refetch={refetch}
				/>
			</Flex>
			<ApproveModal
				isOpen={openApprove}
				approveInfo={{
					chainId: tokenInfoIn?.chainId,
					contract: routerAddress,
					amount: parsedAmount?.toString() ?? '',
					token: tokenInfoIn,
					type: TransactionType.Swap,
				}}
				dappInfo={dappInfo}
				onClose={hideApproveModal}
			/>
		</>
	);
}
