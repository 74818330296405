import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiHotIcon: React.FC<IProps> = ({ width = 24, height = 24, ...rest }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<g clipPath="url(#clip0_10773_6128)">
				<path
					d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
					fill="#00A560"
				/>
				<path
					d="M6.25061 15.6904C6.12043 15.6898 5.99213 15.6594 5.87561 15.6013C5.5667 15.4499 5.38998 15.1227 5.44576 14.8044C5.47682 14.6048 5.56469 14.4183 5.69889 14.2673C6.97905 12.9669 8.26155 11.6891 9.23608 10.7221C9.31315 10.6406 9.4057 10.5753 9.50833 10.53C9.61095 10.4846 9.72157 10.4602 9.83373 10.4582C10.0601 10.4582 10.2786 10.5566 10.4651 10.7432L12.6683 12.9463L15.6683 9.94632H15.3655C14.9806 9.94632 14.4533 9.94163 14.1509 9.93882C13.6747 9.93507 13.3409 9.60319 13.3386 9.13163C13.3359 9.02362 13.3546 8.91614 13.3935 8.81535C13.4324 8.71457 13.4909 8.62245 13.5655 8.54429C13.6417 8.4699 13.732 8.41138 13.8311 8.37212C13.9301 8.33285 14.036 8.31362 14.1425 8.31554C14.7519 8.31319 15.3612 8.31226 15.9481 8.31226C16.5598 8.31226 17.1697 8.31226 17.7608 8.31554C18.2169 8.31554 18.5501 8.64366 18.553 9.09179C18.5614 10.3419 18.5609 11.5762 18.553 12.7583C18.5501 13.2074 18.2094 13.5337 17.743 13.5337H17.7336C17.2705 13.5294 16.9419 13.2027 16.9344 12.7396C16.9287 12.3763 16.9297 12.0069 16.9311 11.6502C16.9311 11.494 16.9311 11.3377 16.9311 11.1815V11.0877C16.9194 11.098 16.9086 11.1083 16.8983 11.1187C15.8839 12.1374 14.87 13.1565 13.8565 14.1758L13.4347 14.5977C13.1815 14.8523 12.9448 14.976 12.7109 14.976C12.477 14.976 12.2469 14.8551 12.0003 14.6066L9.83186 12.4387L9.65608 12.6173C9.46858 12.8048 9.28951 12.981 9.11608 13.1554L8.30139 13.9752C7.83264 14.4477 7.34795 14.9366 6.86936 15.4157C6.78978 15.5005 6.69403 15.5684 6.58776 15.6156C6.4815 15.6628 6.36686 15.6882 6.25061 15.6904Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_10773_6128">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
