import { isEmpty } from 'lodash-es';
import { KeyshareV0 } from '@telifi/dkls-wasm';
import CryptoJS from 'crypto-js';
import { decodeHex, encodeHex } from '../mpc/lib';
import { AbstractKeyShareManager, MAX_ITEM_SIZE } from './abstract-keyshare-manager';
import { TelegramCore } from '@/app-cores/telegram';
import { TGCloudStorage } from './tg-cloud-storage';
import { PRE_COMPUTE_KEYSHARE_ERROR } from './constant';
import { compareAddress } from '@/app-helpers/address';
import { getWalletAddress } from './helper';
import { STORAGE_KEYS, USE_BACKUP_KEYSHARE_VALUE } from '@/app-constants/storage';
import { ServiceUser } from '@/app-cores/api';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';

class KeyShareManagerV2 extends AbstractKeyShareManager {
	readonly KEY_SHARE_VERSION = 2;

	constructor() {
		super();
	}

	getKeyshareVersion(): number {
		return this.KEY_SHARE_VERSION;
	}
	syncBackupTgCloud() {
		ServiceUser.syncBackupTgCloud({
			tgBackupVer: this.KEY_SHARE_VERSION.toString(),
		})
			.then(() => {
				console.log('backup tgCloud successfully');
			})
			.catch((e) => {
				console.log('backup tgCloud error', e);
			});
	}
	async saveToCloud(force: boolean = false) {
		const isUseBackupKeyshare =
			localStorage.getItem(STORAGE_KEYS.TOBI_USE_BACKUP_KEYSHARE) === USE_BACKUP_KEYSHARE_VALUE;
		if (isUseBackupKeyshare) return;
		const metadataStr = localStorage.getItem(this.TC_KEY_SHARE_METADATA);
		if (!isEmpty(metadataStr) && !force) {
			const metadata = JSON.parse(metadataStr);
			const parts: Promise<any>[] = [];
			for (let i = 0; i < metadata.totalParts; i++) {
				const keySharePart = localStorage.getItem(`${this.TC_KEY_SHARE_PART}${i + 1}`);
				if (!isEmpty(keySharePart)) {
					parts.push(
						TGCloudStorage.setItem(`${this.TC_KEY_SHARE_PART}${i + 1}`, keySharePart, () => {
							localStorage.removeItem(`${this.TC_KEY_SHARE_PART}${i + 1}`);
						}),
					);
				}
			}

			Promise.all(parts).then((values) => {
				if (values.filter((value) => !value).length == 0) {
					TGCloudStorage.setItem(this.TC_KEY_SHARE_METADATA, JSON.stringify(metadata)).then(() => {
						dataDogAddAction(DATADOG_ACTIONS.BACKUP_TG_CLOUD.SUCCESS);
						this.syncBackupTgCloud();
					});
				} else {
					dataDogAddAction(DATADOG_ACTIONS.BACKUP_TG_CLOUD.ERROR);
				}
			});
		} else {
			const keyShareObj = {
				ecdsaKeyShare: encodeHex(this.keyShares.ecdsaKeyShare.toBytes()),
				eddsaKeyShare: encodeHex(this.keyShares.eddsaKeyShare.toBytes()),
			};
			const keyShareStore = JSON.stringify(keyShareObj);
			const keyShareStoreEncrypted = CryptoJS.AES.encrypt(
				keyShareStore,
				TelegramCore.getUserId().toString(),
			).toString();

			const parts: string[] = [];
			for (let i = 0; i < keyShareStoreEncrypted.length; i += MAX_ITEM_SIZE) {
				const chunk = keyShareStoreEncrypted.slice(i, i + MAX_ITEM_SIZE);
				parts.push(chunk);
			}

			const metadata = {
				version: this.getKeyshareVersion(),
				totalParts: parts.length,
			};
			localStorage.setItem(this.TC_KEY_SHARE_METADATA, JSON.stringify(metadata));

			Promise.all(
				parts.map((item, index) => {
					const partKey = `${this.TC_KEY_SHARE_PART}${index + 1}`;
					localStorage.setItem(partKey, item);
					return TGCloudStorage.setItem(partKey, item, () => {
						localStorage.removeItem(partKey);
					});
				}),
			).then((values) => {
				if (values.filter((value) => !value).length == 0) {
					TGCloudStorage.setItem(this.TC_KEY_SHARE_METADATA, JSON.stringify(metadata)).then(() => {
						dataDogAddAction(DATADOG_ACTIONS.BACKUP_TG_CLOUD.SUCCESS);
						this.syncBackupTgCloud();
					});
				} else {
					dataDogAddAction(DATADOG_ACTIONS.BACKUP_TG_CLOUD.ERROR);
				}
			});
		}
	}

	async loadFromCloud(version: number, totalParts: number): Promise<string> {
		switch (version) {
			case 0:
				return this.loadFromCloudVersion0(totalParts);
			case 1:
				return this.loadFromCloudVersion1(totalParts);
			case 2:
				return this.loadFromCloudVersion2(totalParts);
			default:
				await TGCloudStorage.removeItems([
					this.TC_KEY_SHARE_METADATA,
					...Array.from({ length: totalParts }).map((_, i) => `${this.TC_KEY_SHARE_PART}${i + 1}`),
				]);
				throw Error('Unsupported KeyShare Version');
		}
	}
	async loadFromCloudVersion0(totalParts: number): Promise<string> {
		const parts = await Promise.all(
			Array.from({ length: totalParts }).map((_, i) =>
				TGCloudStorage.getItem(`${this.TC_KEY_SHARE_PART}${i + 1}`, (value: string) => {
					return {
						part: i + 1,
						data: value,
					};
				}),
			),
		);

		if (parts.filter((p) => p.part <= 0).length > 0) throw Error('Error retrieving the cloud keyshare');
		parts.sort((a, b) => a.part - b.part);

		return parts.reduce((a, c) => (a += c.data), '');
	}

	async loadFromCloudVersion1(totalParts: number): Promise<string> {
		const parts = await Promise.all(
			Array.from({ length: totalParts }).map((_, i) =>
				TGCloudStorage.getItem(`${this.TC_KEY_SHARE_PART}${i + 1}`, (value: string) => {
					return {
						part: i + 1,
						data: value,
					};
				}),
			),
		);

		if (parts.filter((p) => p.part <= 0).length > 0) throw Error('Error retrieving the cloud keyshare');
		parts.sort((a, b) => a.part - b.part);

		const encryptedData = parts.reduce((a, c) => (a += c.data), '');
		return CryptoJS.AES.decrypt(encryptedData, TelegramCore.getUserId().toString()).toString(CryptoJS.enc.Utf8);
	}
	async loadFromCloudVersion2(totalParts: number): Promise<string> {
		const parts = await Promise.all(
			Array.from({ length: totalParts }).map((_, i) =>
				TGCloudStorage.getItem(`${this.TC_KEY_SHARE_PART}${i + 1}`, (value: string) => {
					return {
						part: i + 1,
						data: value,
					};
				}),
			),
		);

		if (parts.filter((p) => p.part <= 0).length > 0) throw Error('Error retrieving the cloud keyshare');
		parts.sort((a, b) => a.part - b.part);

		const encryptedData = parts.reduce((a, c) => (a += c.data), '');
		const keyshareEncrypted = CryptoJS.AES.decrypt(encryptedData, TelegramCore.getUserId().toString()).toString(
			CryptoJS.enc.Utf8,
		);
		return keyshareEncrypted;
	}

	async preComputeKeyShareByPreviousVersion(defaultWallet?: string): Promise<KeyshareV0 | undefined> {
		let keyShareHex = localStorage.getItem(this.KEY_SHARE_V0);

		// If can load keyshare, but it's not the address in our server
		// which mean we already reset the keyshare
		// remove it from local storage & try to get from cloud
		if (keyShareHex) {
			const keyShareBytes = decodeHex(keyShareHex);
			if (!keyShareBytes) {
				throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
			}
			const keyshare = KeyshareV0.fromBytes(keyShareBytes);
			if (!keyshare) {
				throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
			}

			if (!compareAddress(getWalletAddress(encodeHex(keyshare.publicKey())), defaultWallet) || !defaultWallet) {
				localStorage.removeItem(this.KEY_SHARE);
				keyShareHex = '';
			}
		}

		if (!keyShareHex) {
			try {
				const metadata = await TGCloudStorage.getItem<string>(this.TC_KEY_SHARE_METADATA);

				if (isEmpty(metadata)) {
					throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
				} else {
					const { version, totalParts } = JSON.parse(metadata);

					// await this.removeCloudKeyShare();

					keyShareHex = await this.loadFromCloud(version, totalParts);
					localStorage.setItem(this.KEY_SHARE_V0, keyShareHex);
				}
			} catch (error) {
				throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
			}
		}
		try {
			const keyShareBytes = decodeHex(keyShareHex);
			this.keyShareV0Hex = keyShareHex;
			if (!keyShareBytes) {
				throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
			}
			const keyshare = KeyshareV0.fromBytes(keyShareBytes);
			if (!keyshare) {
				throw new Error(PRE_COMPUTE_KEYSHARE_ERROR);
			}

			return keyshare;
		} catch (error) {
			console.error('Error in getKeyShare:', error);
			return undefined;
		}
	}

	shouldMigrate(): boolean {
		return !this.keyShares.ecdsaKeyShare;
	}
}

export default new KeyShareManagerV2();
