import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	SlideOptions,
	Text,
} from '@chakra-ui/react';
import { CSSProperties, ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { InputSearch, LocalLoader } from '@/app-components/common';
import NoData from '@/app-components/common/NoData';
import ChainSelect from '@/app-components/common/Select/ChainSelect';
import { SearchResult } from '@/app-components/common/crypto-search/SearchResult';
import { EMPTY_ARRAY } from '@/app-constants';
import { ITokenSearch } from '@/app-cores/api/bff';
import { compareAddress, compareChain, compareTobiToken, compareToken } from '@/app-helpers/address';
import { useSearchToken } from '@/app-hooks/api/portfolio';
import { useDebounce } from '@/app-hooks/common';
import NoAsset from '@/app-views/wallet/components/Portfolio/NoAsset';
import { ChevronRightIcon } from '@/assets/images/svg';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { get, uniqBy } from 'lodash';
import { CHAIN_CONFIG, ChainId, TOBI_CHAIN_ID_MAP } from '@/app-constants/chains';
import { colors } from '@/app-theme/theme';
import { getTokenId, getTokenInfo, populateChildrenForToken, tokenHasBalance } from '@/app-helpers/token';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { formatUnits } from '@/app-helpers/number';

const sortUsdValueFn = (x: ITokenSearch, y: ITokenSearch) =>
	!x.usdValue && !y.usdValue ? 0 : -(x.usdValue || 0) + (y.usdValue || 0);

const groupTokens = ({
	mode,
	balances,
	tokens,
	chainId,
}: {
	balances: ITokenSearch[];
	tokens: ITokenSearch[];
	mode: SearchMode;
	chainId: ChainId | string;
}) => {
	// group token by tobiId
	const result: ITokenSearch[] = populateChildrenForToken(tokens);
	const balanceResult = populateChildrenForToken(balances);

	// group token by address if possible
	balanceResult.forEach((element) => {
		if (element.children.length > 1) {
			const existToken = result.findIndex((e) => getTokenInfo(e).idTobi === getTokenInfo(element).idTobi);
			if (existToken !== -1) {
				result[existToken] = element;
			} else result.push(element);
			return;
		}
		const token = element.children[0];
		const existToken = result
			.map((e) => e.children)
			.flat()
			.find((e) => {
				const { address: address1 } = getTokenInfo(token);
				const { address: address2 } = getTokenInfo(e);
				return compareAddress(address1, address2);
			});
		if (existToken) {
			existToken.balance = token.balance;
			existToken.usdValue = token.usdValue;
		} else result.push(element);
	});

	result.forEach((e) => {
		e.usdValue = e.children.reduce((acc, e) => acc + e.usdValue, 0);
	});

	return result
		.sort(sortUsdValueFn)
		.map((e) => ({
			...e,
			children: e.children?.filter((e) => {
				const value = mode === SearchMode.MY_BALANCE ? tokenHasBalance(e) : true;
				return value && (chainId ? compareChain(e.chainId, chainId) : true);
			}),
		}))
		.filter((e) => (chainId ? compareChain(getTokenInfo(e).chainId, chainId) : true))
		.map((e) => {
			if (e.children.length === 1) return e.children[0];
			return e;
		});
};

const isEqual = (value: string, keyword: string) => value?.toLowerCase().includes(keyword);

export enum SearchMode {
	GLOBAL, // show trending by default
	MY_BALANCE, // show list portfolio token by default
	SELECT_ANY_TOKEN, // show list portfolio + trending token by default
}

type Props = {
	onSelectToken: (token: ITokenSearch) => void;
	chainIds?: ChainId[];
	chainId?: ChainId | string;
	mode?: SearchMode;
	inputStyle?: CSSProperties;
	syncKeyWordToUrl?: boolean;
	autoFocus?: boolean;
	defaultQuery?: string;
	inputPlaceholder?: string;
	selectedToken?: ITokenSearch;
	hasChildren?: boolean;
};
const CryptoSearch = ({
	onSelectToken,
	chainId: chainIdProps,
	chainIds,
	mode,
	inputStyle,
	syncKeyWordToUrl,
	autoFocus = true,
	defaultQuery,
	inputPlaceholder,
	selectedToken,
	hasChildren,
}: Props) => {
	const location = useLocation();
	const [keyword, setKeyword] = useState(defaultQuery || location.state?.search || '');
	const [chainId, setChain] = useState(chainIdProps || '');
	const { t } = useTranslation();
	const keywordDebounced = useDebounce(keyword || '', 400);

	useEffect(() => {
		setChain(chainIdProps);
	}, [chainIdProps]);

	const portfolioBalanceOnly = mode === SearchMode.MY_BALANCE;
	const navigate = useNavigate();

	const wrapOnSelectToken = useCallback(
		(token: ITokenSearch) => {
			if (syncKeyWordToUrl)
				navigate(location, {
					state: { search: keywordDebounced },
					replace: true,
				});
			onSelectToken(token);
		},
		[onSelectToken, navigate, syncKeyWordToUrl, keywordDebounced, location],
	);

	const { data: tokenCatalogsByQuery = EMPTY_ARRAY, isFetching: isFetchingCatalog } = useSearchToken({
		query: keywordDebounced.trim(),
		chainId,
		limit: 50,
		chainIds,
	});

	const filterFn = useCallback(
		(e: ITokenSearch) => {
			const { symbol, address } = getTokenInfo(e);
			return isEqual(symbol, keywordDebounced) || isEqual(address, keywordDebounced);
		},
		[keywordDebounced],
	);

	const {
		data: { mainTokensHasBalance: portfolioBalances, spamTokens, hiddenTokens },
		isFetched,
	} = usePortfolioBalanceByCategories({
		enabled: mode !== SearchMode.GLOBAL,
		retry: false,
	});

	const isFetchingPortfolio = !isFetched;

	const tokenCatalogs = useMemo(() => {
		let result: ITokenSearch[] = tokenCatalogsByQuery;
		result = uniqBy(result, (item) => getTokenId(item));
		return result.filter((e) => {
			return (
				!spamTokens.some((token) => compareTobiToken(token, e)) &&
				!hiddenTokens.some((token) => compareTobiToken(token, e))
			);
		});
	}, [tokenCatalogsByQuery, hiddenTokens, spamTokens]);

	const isFetching = portfolioBalanceOnly ? isFetchingPortfolio : isFetchingCatalog;

	const tokensSearchFromPortfolio = useMemo(() => {
		const result = chainId || keywordDebounced ? portfolioBalances.filter(filterFn) : portfolioBalances;
		return result;
	}, [portfolioBalances, chainId, keywordDebounced, filterFn]);

	const visibleTokens = useMemo(() => {
		const tokens =
			mode === SearchMode.MY_BALANCE
				? groupTokens({ balances: tokensSearchFromPortfolio, tokens: [], mode, chainId })
				: groupTokens({ balances: tokensSearchFromPortfolio, tokens: tokenCatalogs, mode, chainId });

		let filterToken = tokens;
		if (chainIds?.length) {
			filterToken = filterToken.filter((tk) =>
				chainIds?.some((chain) => getTokenInfo(tk).chainTokens.some((tok) => compareChain(chain, tok.chainId))),
			);
		}

		return filterToken.slice(0, 100);
	}, [mode, tokenCatalogs, tokensSearchFromPortfolio, chainIds, chainId]);

	const onKeywordChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
		setKeyword(e.target.value);
	}, []);

	const showNotfound = !visibleTokens.length && !isFetching;

	const noAsset = portfolioBalanceOnly && !isFetching && !portfolioBalances?.length;
	return (
		<Flex flexDirection={'column'} sx={{ gap: '8px', height: '100%' }} flex={1}>
			<Flex direction={'column'} px={5} gap={'12px'}>
				{noAsset ? (
					<NoAsset />
				) : (
					<>
						<InputSearch
							placeholder={inputPlaceholder || t('cryptos.searchPlaceholder')}
							background={colors.gray[100]}
							border="none"
							value={keyword}
							autoFocus={autoFocus}
							onChange={onKeywordChange}
							onClear={() => setKeyword('')}
							fontSize="sm"
							style={inputStyle}
						/>
						<Flex justifyContent={'space-between'} alignItems={'center'}>
							<Text fontSize={'small'} color={'gray'} fontWeight={'500'}>
								{portfolioBalanceOnly
									? t('cryptos.yourTokens')
									: keywordDebounced
									? `${visibleTokens.length} ${t('cryptos.results')}`
									: t('cryptos.topCrypto')}
							</Text>
							<ChainSelect
								placement="bottom-end"
								logoSize="22px"
								value={chainId}
								onChange={setChain}
								chainIds={chainIds}
								style={{
									borderRadius: 105,
									border: 'none',
									background: colors.gray[100],
									fontSize: '14px',
									padding: '6px 12px',
								}}
								menuStyle={{
									overflowY: 'scroll',
									maxHeight: '420px',
								}}
							/>
						</Flex>
					</>
				)}
			</Flex>
			{!noAsset && (
				<Flex flexDirection={'column'} gap={'12px'} flex={1}>
					{isFetching ? (
						<Box height={'104px'}>
							<LocalLoader />
						</Box>
					) : showNotfound ? (
						<NoData msg={t('cryptos.notFoundToken')} />
					) : (
						<SearchResult
							{...{ visibleTokens, onSelectToken: wrapOnSelectToken, selectedToken, hasChildren }}
						/>
					)}
				</Flex>
			)}
		</Flex>
	);
};

export const CryptoSearchDrawer = ({
	onSelectToken,
	onClose,
	isOpen,
	chainId,
	chainIds,
	mode = SearchMode.GLOBAL,
	syncKeyWordToUrl = false,
	autoFocus,
	defaultQuery,
	placement,
	inputPlaceholder,
	selectedToken,
	hasChildren,
}: {
	onClose: () => void;
	isOpen: boolean;
	mode?: SearchMode;
	syncKeyWordToUrl?: boolean;
	placement?: SlideOptions['direction'];
} & Props) => {
	const selectOnlyPortfolio = mode === SearchMode.MY_BALANCE;
	const { t } = useTranslation();

	return (
		<Drawer
			isOpen={isOpen}
			onClose={onClose}
			size={'full'}
			placement={placement || selectOnlyPortfolio ? 'bottom' : undefined}
			trapFocus={false}
		>
			<DrawerOverlay />
			<DrawerContent background={'white'}>
				{selectOnlyPortfolio || placement === 'bottom' ? (
					<>
						<DrawerCloseButton />
						<DrawerHeader fontSize={'14px'} textAlign={'center'}>
							{t('cryptos.selectToken')}
						</DrawerHeader>
					</>
				) : (
					<DrawerCloseButton
						left={'0px'}
						top={'18px'}
						sx={{
							':focus': { boxShadow: 'none' },
							':hover': { background: 'none' },
						}}
					>
						<ChevronRightIcon width={30} height={30} style={{ transform: 'scaleX(-1) translateX(3px)' }} />
					</DrawerCloseButton>
				)}
				<DrawerBody
					className="hide-scrollbar"
					style={{ paddingTop: selectOnlyPortfolio ? '2px' : '12px' }}
					px={0}
				>
					<CryptoSearch
						inputStyle={!selectOnlyPortfolio ? { marginLeft: '10px' } : undefined}
						{...{
							mode,
							onSelectToken,
							chainId,
							chainIds,
							autoFocus,
							defaultQuery,
							inputPlaceholder,
							selectedToken,
							syncKeyWordToUrl,
							hasChildren,
						}}
					/>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
