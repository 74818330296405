import { useMutation } from '@tanstack/react-query';
import { Address, Cell, SendMode, fromNano, internal, loadStateInit } from '@ton/ton';
import {
	SIGN_DATA_ERROR_CODES,
	SendTransactionRpcResponseError,
	SendTransactionRpcResponseSuccess,
	TonConnectTAppRequest,
} from '../types';

import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { Maybe } from '@ton/core/dist/utils/maybe';
import { ChainId } from '../../../app-constants/chains';
import { MpcWallet } from '../../../app-cores/mpc-wallet/wallet';
import { useTransactionWatcherStore } from '../../../app-store';
import { TransactionType } from '../../../app-types';
import { httpBridgeService } from '../services/httpBridgeService';
import { IActivity, TxDetailTokenContractInteraction, TxStatus } from '@/app-cores/api/activities';
import { SelectedRoute } from '@/app-store/swap';
import { SwapService } from '@/app-hooks/swap';
import { getPendingSwapTxs } from '@/app-store/transaction-watcher/evmWatcher';

export const toStateInit = (stateInit?: string): { code: Maybe<Cell>; data: Maybe<Cell> } | undefined => {
	if (!stateInit) {
		return undefined;
	}
	const { code, data } = loadStateInit(Cell.fromBase64(stateInit).asSlice());
	return {
		code,
		data,
	};
};

export const useMutationSentTransaction = () => {
	return useMutation({
		mutationKey: ['mutation-sent-transaction'],
		mutationFn: async (params: TonConnectTAppRequest) => {
			const { contract } = await TonWallet.create('mainnet');
			const seqno = await contract.getSeqno();
			const data = JSON.parse(params.request.params[0] as any);

			try {
				const transfer = await contract.createTransfer({
					seqno,
					sendMode: SendMode.PAY_GAS_SEPARATELY + SendMode.IGNORE_ERRORS,
					messages: data.messages.map((item) => {
						const isBounceable = Address.parseFriendly(item.address).isBounceable;
						return internal({
							to: item.address,
							bounce: isBounceable,
							value: fromNano(item.amount),
							init: toStateInit(item.stateInit),
							body: item.payload ? Cell.fromBase64(item.payload) : undefined,
						});
					}),
				});
				const cell = await contract.sign(transfer);
				const transactionRes = await contract.send(cell);
				const messages: SendTransactionRpcResponseSuccess = {
					id: params.request.id,
					result: 'Transfer success.',
				};
				await httpBridgeService.sendEventToBridge({
					messages: messages,
					clientSessionId: params.clientSessionId,
					sessionKeyPair: params.connection.sessionKeyPair,
				});
				return transactionRes;
			} catch (error) {
				const messages: SendTransactionRpcResponseError = {
					id: params.request.id,
					error: {
						code: SIGN_DATA_ERROR_CODES.UNKNOWN_ERROR,
						message: 'Unknown error',
					},
				};
				await httpBridgeService.sendEventToBridge({
					messages: messages,
					clientSessionId: params.clientSessionId,
					sessionKeyPair: params.connection.sessionKeyPair,
				});
				throw new Error(error);
			}
		},
	});
};

// generic function, can use for all kind of txs
export const useSubmitTonTransaction = () => {
	const { addPendingTonTransaction } = useTransactionWatcherStore();
	return useMutation({
		mutationKey: ['mutation-submit-transaction'],
		mutationFn: async (params: {
			to: string;
			value: string;
			body: Cell;
			bounce?: boolean;
			metadata: any;
			transactionType: TransactionType;
		}) => {
			const { to, body, bounce = true, value } = params;
			const { contract } = await TonWallet.create('mainnet');
			const seqno = await contract.getSeqno();
			const payload = {
				seqno,
				messages: [internal({ bounce, to, body, value })],
			};
			const transfer = await contract.createTransfer(payload);
			const cell = await contract.sign(transfer);
			const data = await contract.send(cell);
			return { data, seqno };
		},
		onSuccess: ({ seqno }, { metadata = {}, to, transactionType }) => {
			addPendingTonTransaction({
				seqno,
				transaction:
					transactionType === TransactionType.Swap
						? getPendingSwapTxs(metadata, { contract: to, hash: seqno?.toString(), chainId: ChainId.TON })
						: undefined,
				trackingData: metadata,
			});
		},
	});
};

export const useMutationRejectTransaction = () => {
	return useMutation({
		mutationKey: ['mutation-reject-transaction'],
		mutationFn: async (params: TonConnectTAppRequest) => {
			await httpBridgeService.sendEventToBridge({
				messages: {
					id: params.request.id,
					error: {
						code: SIGN_DATA_ERROR_CODES.USER_REJECTS_ERROR,
						message: 'User declined the transaction',
					},
				},
				clientSessionId: params.clientSessionId,
				sessionKeyPair: params.connection.sessionKeyPair,
			});
		},
	});
};

export const useMutationOutdateTransaction = () => {
	return useMutation({
		mutationKey: ['mutation-outdate-transaction'],
		mutationFn: async (params: TonConnectTAppRequest) => {
			await httpBridgeService.sendEventToBridge({
				messages: {
					id: params.request.id,
					error: {
						code: SIGN_DATA_ERROR_CODES.BAD_REQUEST_ERROR,
						message: params.message || 'Transaction is outdated.',
					},
				},
				clientSessionId: params.clientSessionId,
				sessionKeyPair: params.connection.sessionKeyPair,
			});
		},
	});
};
