import { axiosBotAPI } from '@/app-cores/api/axios';
import { IActivity, ObserverTransaction, WalletActivity } from './types';
import { parseStartParams } from '../../telegram';
import { TEST_NETS } from '@/app-constants/chains';
import { isEmpty } from 'lodash';
import { delay } from '@/app-helpers';
import { ITokenSearch } from '../bff';

export type GetActivityParams = {
	chainId?: string;
	tokenAddress?: string;
	includeSpam?: boolean;
	includeTestnet?: boolean;
};
export type GetActivityDetailParams = {
	activityKeys: {
		tobiId: string;
		chainId: string;
	}[];
};
export type GetWalletActivityParams = {
	includeSpam?: boolean;
	excludeChainIds?: string[];
};
class ActivitiesService {
	async getActivities({ includeTestnet, ...payload }: GetActivityParams = {}): Promise<IActivity[]> {
		const response = await axiosBotAPI.get(`/transaction/transactions/me`, {
			params: {
				offset: 0,
				limit: 50,
				excludeChainIds:
					includeTestnet || includeTestnet === undefined ? undefined : TEST_NETS.map((e) => e.id).join(','),
				...payload,
			},
		});
		return response.data.data?.transactions;
	}
	async getWalletActivities({ includeTestnet, ...payload }: GetActivityParams = {}): Promise<WalletActivity[]> {
		const response = await axiosBotAPI.post(`/v2/wallet/portfolio/activity`, {});

		return response.data.data;
	}

	async observerTransaction(payload: ObserverTransaction) {
		const startParams = parseStartParams();
		if (startParams.actor === 'bot') {
			payload.inviter = `${startParams.data.get('inviter')}`;
		}
		if (!isEmpty(payload)) {
			try {
				// await axiosBotAPI.post('/transaction/off-chain-data', payload);
			} catch (error) {
				console.log('error off-chain-data', error);
			}
		}
	}
}

export const ActivitiesServiceAPI = new ActivitiesService();
