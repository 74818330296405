import { CopyToClipboard } from '@/app-components/common';
import { CHAIN_CONFIG_MAP, NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';
import { getShortAddress } from '@/app-helpers/address';
import { formatCurrency, formatUsd } from '@/app-helpers/number';
import { usePriceNativeToken, useTokenPriceSingleToken } from '@/app-hooks/api/portfolio/useTokenPrices';
import { Box, Center, Image, Skeleton, Text } from '@chakra-ui/react';
import { TransactionItemInfo } from './TransactionItemInfo';

interface CommonTransactionInfoProps {
	chainId: string;
	walletAddress: string;
	gasFee: string;
}

export const CommonTransactionInfo: React.FC<React.PropsWithChildren<CommonTransactionInfoProps>> = ({
	chainId,
	walletAddress,
	gasFee,
	children,
}) => {
	const network = CHAIN_CONFIG_MAP[chainId];
	const { data: tokenPrice } = usePriceNativeToken({
		chainId: chainId,
	});
	return (
		<>
			<TransactionItemInfo
				title="Chain"
				value={
					<Center gap={2}>
						<Text>{network?.name}</Text>
						<Image borderRadius="100%" src={network?.logo} width={6} height={6}></Image>
					</Center>
				}
			/>
			<TransactionItemInfo
				title="Account"
				value={
					<CopyToClipboard text={walletAddress}>
						{getShortAddress(walletAddress, {
							start: 16,
							end: 8,
						})}
					</CopyToClipboard>
				}
			/>

			<TransactionItemInfo
				title="Gas Fee"
				value={
					<Box>
						{!gasFee ? (
							<Skeleton opacity="0.1" height={'21px'} width="160px" />
						) : (
							`${formatCurrency(gasFee)} ${network?.nativeToken?.symbol} ≈ ${formatUsd(
								+gasFee * (tokenPrice || 0),
							)} `
						)}
					</Box>
				}
			/>

			{children}
		</>
	);
};
