import { CSSProperties, forwardRef, ReactNode } from 'react';
import { InputGroup, InputRightElement, InputProps, InputLeftElement, Flex } from '@chakra-ui/react';
import { FiSearchIcon } from '@/assets/icons';
import { BaseInput } from '@/app-components/common';
import { FiCloseIcon } from '@/assets/icons/fi-close-icon';

export interface InputSearchProps extends InputProps {
	rightElement?: React.ReactNode;
	onClear?: () => void;
}

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
	({ className, rightElement, onClear, id, ...props }, ref) => {
		const { value } = props;
		return (
			<InputGroup id={id} borderRadius="24px">
				<BaseInput ref={ref} {...props} borderRadius={20} paddingRight={'34px'} />
				<InputRightElement height={'100%'}>
					{value && onClear ? <FiCloseIcon size={18} onClick={onClear} /> : rightElement || <FiSearchIcon />}
				</InputRightElement>
			</InputGroup>
		);
	},
);

type InputSearchPropsV2 = InputSearchProps & {
	selectedNodes: ReactNode;
	inputStyle?: CSSProperties;
};
export const InputSearchSelect = forwardRef<HTMLInputElement, InputSearchPropsV2>(
	({ className, rightElement, onClear, id, selectedNodes, style, inputStyle, ...props }, ref) => {
		const { value } = props;
		return (
			<Flex direction={'column'} style={style} p={2}>
				<InputGroup id={id}>
					<InputLeftElement height={'100%'}>
						<FiSearchIcon />
					</InputLeftElement>
					<BaseInput
						ref={ref}
						{...props}
						sx={{
							...inputStyle,
							':focus-visible': {
								borderColor: 'transparent',
								boxShadow: 'none',
							},
						}}
						paddingLeft={'40px'}
					/>
					<InputRightElement height={'100%'}>
						{value && onClear && <FiCloseIcon size={18} onClick={onClear} />}
					</InputRightElement>
				</InputGroup>
				{selectedNodes && (
					<Flex gap={1} flexWrap={'wrap'}>
						{selectedNodes}
					</Flex>
				)}
			</Flex>
		);
	},
);
