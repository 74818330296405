import { ButtonSecondary, QrCodeAddress, Toast } from '@/app-components/common';
import { navigateOnboardingFromBotRedirect } from '@/app-components/common/CreateAccount';
import Warning from '@/app-components/common/Warning';
import { CHAIN_CONFIG, ChainId, NATIVE_TOKEN_ADDRESS, TOBI_CHAIN_ID_MAP } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { BffServiceAPI, ITokenBalance, ITokenSearch, TokenInfo } from '@/app-cores/api/bff';
import { isNativeToken } from '@/app-helpers/address';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useSelectTokenMaxPortfolio } from '@/app-helpers/navigate';
import { getNativeTobiId, getNativeToken, getTobiChainName, getTokenInfo, isTonChain } from '@/app-helpers/token';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { useBuyOnRamp, useBuyOnTransak } from '@/app-hooks/common';
import { ErrorMsg, useChangeInputAmount } from '@/app-hooks/swap';
import { useSwapStore } from '@/app-store/swap';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import SwapFormMini from '@/app-views/swap/pages';
import { ConfirmTransaction } from '@/app-views/swap/pages/ConfirmTransaction';
import { FiArrowLeftIcon, FiDownLeftIcon, FiShoppingIcon, FiSwapIcon } from '@/assets/icons';
import { FiTransakIcon } from '@/assets/icons/fi-transak-icon';
import { ChevronRightIcon } from '@/assets/images/svg';
import {
	Box,
	Card,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { formatUnits } from 'ethers';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { watchSingleTransaction } from '../../../app-store/transaction-watcher/evmWatcher';
import { FeatureConfigWrap } from '../../../app-helpers/components';
import { FeatureConfig } from '../../../app-hooks/api/configuration';
import { useWalletAddressStore } from '@/app-store';
import { FiGasIcon } from '@/assets/icons/fi-gas-icon';
import WalletAddressPopup from '@/app-components/common/AddressByChain/AddressByChainPopup';
import { getMyWalletAddressByChain } from '@/app-hooks/swap/helper';
import { useDepositStore } from '@/app-features/deposit/store';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { useSearchSingleToken } from '@/app-hooks/api/portfolio';

const MethodItem = ({
	title,
	desc,
	icon,
	onClick,
}: {
	title: string;
	desc: string;
	icon?: ReactNode;
	onClick: () => void;
}) => {
	return (
		<Card
			gap={'16px'}
			display={'flex'}
			flexDirection={'row'}
			padding={'16px 24px'}
			alignItems={'center'}
			onClick={onClick}
			cursor={'pointer'}
		>
			<Flex gap={'16px'}>
				{icon}
				<Flex flexDirection={'column'} gap={'2px'}>
					<Text fontWeight={'500'}>{title}</Text>
					<Text fontSize={'12px'} color={'gray.400'}>
						{desc}
					</Text>
				</Flex>
			</Flex>
			<ChevronRightIcon width={60} />
		</Card>
	);
};

const WrapIcon = ({ icon }: { icon: ReactNode }) => {
	return (
		<Box
			background="cyan.600"
			display="flex"
			justifyContent="center"
			alignItems="center"
			borderRadius="50%"
			minWidth={'40px'}
			height={'40px'}
			padding={0}
		>
			{icon}
		</Box>
	);
};

const BuyOptions = ({
	tokenIn,
	tokenOut,
	urlBuyToken,
	targetToken,
	isAllowBuy,
	setStep,
	onCloseBuy,
	children,
	buyNative,
}: {
	targetToken: ITokenSearch | undefined;
	tokenOut: TokenInfo | undefined;
	tokenIn: TokenInfo | undefined;
	urlBuyToken: string;
	isAllowBuy: boolean;
	setStep: (v: Steps) => void;
	onCloseBuy: () => void;
	children?: React.ReactNode;
	buyNative: boolean;
}) => {
	const chainId = tokenIn?.chainId;
	const chainName = CHAIN_CONFIG[chainId]?.name;
	const { isOpen, onOpen, onClose } = useDisclosure();

	const {
		data: { mainTokensHasBalance },
	} = usePortfolioBalanceByCategories();

	const getTokenIn = useSelectTokenMaxPortfolio();

	const { setPair } = useSwapStore(true);
	const changeInputAmount = useChangeInputAmount(true);
	const swapToTargetToken = async () => {
		try {
			const tokenOut = buyNative
				? await BffServiceAPI.searchExactSingleToken({
						tobiId: getNativeTobiId(chainId),
						chainId,
				  })
				: targetToken;

			const tokenMaxBalance = getTokenIn(tokenOut, chainId);

			if (tokenMaxBalance) {
				setPair(tokenMaxBalance, tokenOut);
				changeInputAmount({
					amount: formatUnits(tokenMaxBalance.balance, getTokenInfo(tokenMaxBalance).decimals).toString(),
					token: tokenMaxBalance,
				});
			}
			setStep(Steps.SWAP);
		} catch (error) {}
	};
	const navigate = useNavigate();
	const showSwap = mainTokensHasBalance.length !== 0;

	const swapTo = tokenOut?.symbol ? ` to ${tokenOut?.symbol}` : '';

	const tokenInfo = getTokenInfo(targetToken);
	return (
		<Flex flexDirection={'column'} gap={'12px'} p={4}>
			<Flex flexDirection={'column'} gap={'8px'}>
				{isAllowBuy && (
					<FeatureConfigWrap feature={FeatureConfig.TRANSAK_DEPOSIT}>
						<MethodItem
							icon={<FiTransakIcon style={{ minWidth: 40 }} />}
							onClick={() => {
								navigate(urlBuyToken);
								dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_TRADE.GET.TRANSAK);
							}}
							title={`Buy ${tokenInfo?.symbol}`}
							desc={`Purchase ${tokenInfo?.symbol} on the ${chainName} network through Transak and proceed to Swap${swapTo}.`}
						/>
					</FeatureConfigWrap>
				)}
				{showSwap && (
					<MethodItem
						icon={<WrapIcon icon={<FiSwapIcon height={20} />} />}
						onClick={swapToTargetToken}
						title={`Swap to ${tokenInfo?.symbol}`}
						desc={`Swap your assets to ${tokenInfo?.symbol} on the ${chainName} network, and process to Swap${swapTo}`}
					/>
				)}
				<MethodItem
					icon={<WrapIcon icon={<FiDownLeftIcon />} />}
					onClick={onOpen}
					title={`Receive ${tokenInfo?.symbol}`}
					desc={`Receive ${tokenInfo?.symbol} on the ${chainName} network and process to Swap${swapTo}`}
				/>
				{targetToken && (
					<QrCodeAddress
						onClose={() => {
							onClose();
							onCloseBuy();
						}}
						isOpen={isOpen}
						address={getMyWalletAddressByChain(tokenInfo.chainId)}
						token={tokenInfo}
					/>
				)}
				{children}
			</Flex>
		</Flex>
	);
};

enum Steps {
	SELECT_OPTION,
	SWAP,
	SWAP_CONFIRM,
}

const TopupTonOption = ({ token }: { token: ITokenSearch }) => {
	const { onOpen, setInitToken } = useDepositStore();
	return (
		<MethodItem
			icon={<WrapIcon icon={<FiShoppingIcon />} />}
			onClick={() => {
				setInitToken(token);
				dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_TRADE.GET.DEPOSIT);
				onOpen();
			}}
			title={`Deposit`}
			desc={`Top-up ton via ton connect or onramp provider`}
		/>
	);
};

export default function BuyToken({
	tokenInfoOut,
	tokenInfoIn,
	buyNative,
	isNonWallet,
	errorMsg,
	tokenIn,
}: {
	tokenIn: ITokenSearch | undefined;
	tokenInfoOut: TokenInfo | undefined;
	tokenInfoIn: TokenInfo | undefined;
	buyNative: boolean;
	isNonWallet: boolean;
	errorMsg: ErrorMsg;
}) {
	const [step, setStep] = useState(Steps.SELECT_OPTION);

	const { isOpen, onClose, onOpen } = useDisclosure();

	const { data: native } = useSearchSingleToken({
		tobiId: getNativeTobiId(tokenInfoIn?.chainId),
		chainId: tokenInfoIn?.chainId,
	});

	const targetToken = buyNative ? native : tokenIn;

	const tokenInfo = getTokenInfo(targetToken);

	const { buildTransakURL } = useBuyOnTransak();
	const { isAllowBuy } = useBuyOnRamp(tokenInfoIn?.chainId, tokenInfo?.symbol, NAVIGATE_PATHS.Swap.Main);

	const isTonToken = isNativeToken(tokenInfo?.address) && isTonChain(tokenInfoIn?.chainId);

	const isSelectOpt = step === Steps.SELECT_OPTION;
	const isFillSwap = step === Steps.SWAP;

	const navigate = useNavigate();

	const wrapOnClose = () => {
		onClose();
		setStep(Steps.SELECT_OPTION);
	};
	const goBack = () => {
		if (isSelectOpt) return;
		setStep((step) => (step === Steps.SWAP_CONFIRM ? Steps.SWAP : Steps.SELECT_OPTION));
	};

	const { t } = useTranslation();
	const onSwapSuccess = async ({ hash, chainId }: { hash: string; chainId: string }) => {
		try {
			const receipt = await watchSingleTransaction({ txHash: hash, chainId });
			if (receipt?.status !== 1) throw new Error('Transaction Failed');
			toast(<Toast type="success" message={t('transactionResult.success')} />);
			wrapOnClose();
		} catch (e) {
			console.error(e);
			toast(<Toast type="error" message={`Failed to swap: ${parseErrorMessage(e)}`} />);
		}
	};

	const onBuildRouteSuccess = () => {
		setStep(Steps.SWAP_CONFIRM);
	};

	return (
		<>
			<Warning
				icon={buyNative ? <FiGasIcon /> : undefined}
				action={
					<ButtonSecondary
						background={'white'}
						fontSize={'12px'}
						size={'sm'}
						onClick={
							isNonWallet
								? () => navigate(navigateOnboardingFromBotRedirect)
								: () => {
										onOpen();
										const action = buyNative
											? DATADOG_ACTIONS.DEPOSIT_TRADE.TOP_UP_GAS
											: DATADOG_ACTIONS.DEPOSIT_TRADE.GET.CLICK_GET;
										dataDogAddAction(action);
								  }
						}
					>
						{buyNative ? t('cryptos.topUp') : `Get ${tokenInfo?.symbol}`}
					</ButtonSecondary>
				}
				background={buyNative ? undefined : 'gray.100'}
				status={buyNative ? 'warning' : 'error'}
				title={errorMsg?.title}
				msg={errorMsg?.msg}
			/>
			<Drawer isOpen={isOpen} placement="bottom" onClose={wrapOnClose} isFullHeight>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader
						display={'flex'}
						textAlign={'center'}
						fontSize={'14px'}
						padding={3}
						borderColor={BASE_BORDER_COLOR}
						borderWidth={'1px'}
						justifyContent={'space-between'}
					>
						<FiArrowLeftIcon
							height={'20px'}
							onClick={goBack}
							style={{ visibility: !isSelectOpt ? 'visible' : 'hidden' }}
						/>
						{isSelectOpt
							? `Options to get ${tokenInfo?.symbol}`
							: isFillSwap
							? `Swap to ${tokenInfo?.symbol}`
							: `Confirmation Swap`}
						<FiArrowLeftIcon height={'20px'} style={{ visibility: 'hidden' }} />
					</DrawerHeader>
					<DrawerBody p={0} display={'flex'} flexDirection={'column'}>
						{isSelectOpt ? (
							<BuyOptions
								{...{
									tokenOut: tokenInfoOut,
									tokenIn: tokenInfoIn,
									buyNative,
									setStep,
									onCloseBuy: wrapOnClose,
									targetToken,
									urlBuyToken: buildTransakURL({
										chainId: tokenInfoIn?.chainId,
										tokenSymbol: tokenInfoIn?.symbol,
										callbackUrl: NAVIGATE_PATHS.Swap.Main,
									}),
									isAllowBuy,
								}}
							>
								{isTonToken && <TopupTonOption token={tokenInfoIn as any} />}
							</BuyOptions>
						) : isFillSwap ? (
							<SwapFormMini quickSwap onBuildRouteSuccess={onBuildRouteSuccess} />
						) : (
							<ConfirmTransaction quickSwap onSwapSuccess={onSwapSuccess} />
						)}
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
