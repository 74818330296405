import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Center, Container, Spinner, Text } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CTAButton, Toast } from '@/app-components/common';
import ErrorView from '@/app-components/common/ErrorView';
import { RECOVERY_TYPE } from '@/app-constants/backup';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { ServiceGoogle } from '@/app-cores/api';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { TelegramCore } from '@/app-cores/telegram';
import { buildEncryptUnsecuredKeyShare, createGoogleAuthUrl } from '@/app-helpers/backup';
import { useMutationSetBackupType } from '@/app-hooks/api/user/useMutationSetBackupType';
import { useOnEventCallback } from '@/app-hooks/common';
import { GDriveService } from '@/app-services/gdrive';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { ENCRYPT_KEY } from '../StoreBackup/StoreBackup';
import { tourGuideManager } from '@/app-views/wallet/components/TourGuide/helper';

interface BackupGDriveCallbackProps {}

const BackupGDriveCallback: React.FC<BackupGDriveCallbackProps> = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [progressText, setProgressText] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const authTokenWait = useRef<boolean>(false);
	const stateRef = useRef<string>(searchParams.get('state') || '');
	const encryptedPassword = sessionStorage.getItem(ENCRYPT_KEY);

	const { mutateAsync: setBackupType } = useMutationSetBackupType();

	const onCancel = useOnEventCallback(() => {
		TelegramCore.showConfirm(t('storeBackup.confirmCancel'), {
			onOk: () => {
				navigate(-1);
			},
		});
	});

	const waitForAuthToken = useOnEventCallback(async (state: string) => {
		return new Promise<string>((resolve, reject) => {
			const _poll = async () => {
				try {
					const accessToken = await ServiceGoogle.getAccessTokenByAuthState(state);

					if (!accessToken && authTokenWait.current) {
						await new Promise((resolve) => setTimeout(resolve, 2000));
						return requestAnimationFrame(_poll);
					}

					resolve(accessToken);
				} catch (e) {
					dataDogAddError(e, {
						tags: {
							name: DATADOG_ERROR_TAGS.CREATE_WALLET,
							function: 'authGoogleDrive',
						},
					});
					reject(e);
				}
			};
			_poll();
		});
	});

	const handleBackup = useOnEventCallback(async () => {
		try {
			setErrorMessage(null);
			const state = stateRef.current;

			if (!state) {
				throw new Error('State not found');
			}

			setProgressText('Waiting for auth token...');
			authTokenWait.current = true;
			const accessToken = await waitForAuthToken(state);
			authTokenWait.current = false;

			setProgressText('Uploading backup...');
			const gdriveSession = await GDriveService.createSession(accessToken);
			console.log('GDrive Session', gdriveSession);

			const backupFolder = await gdriveSession.getOrCreateOneBackupFolder();
			console.log('GDrive Backup Folder', backupFolder);

			const walletAddress = MpcWallet.getWalletAddress().evmAddress;
			const keyShareStore = buildEncryptUnsecuredKeyShare(encryptedPassword);
			const backupFile = await gdriveSession.uploadBackupKey(walletAddress, keyShareStore, backupFolder.id);
			console.log('GDrive Backup File', backupFile);

			const [backupFound, profile] = await Promise.all([
				gdriveSession.findBackupFileByWalletAddress(walletAddress),
				gdriveSession.getUserInfo(),
			]);
			if (!backupFound.length) {
				throw new Error('Backup file not found on drive');
			}
			// Remove unsecured key
			await setBackupType({
				type: RECOVERY_TYPE.DRIVE,
				email: profile.data.email,
			});
			MpcWallet.removeUnsecuredKeyShare2();
			dataDogAddAction(DATADOG_ACTIONS.BACKUP_GG_SUCCESS);
			toast(<Toast type="success" title="Wallet backup successful!" message={t('toast.backupSuccess')} />);
			tourGuideManager.enable();
			navigate(NAVIGATE_PATHS.Home);
		} catch (e) {
			dataDogAddError(e, {
				tags: {
					name: DATADOG_ERROR_TAGS.CREATE_WALLET,
					function: 'authGoogleDrive',
				},
			});
			dataDogAddAction(DATADOG_ACTIONS.BACKUP_GG_FAIL);
			console.error(e);
			// toast.error(<Alert variant="danger">Failed to backup: {parseErrorMessage(e)}</Alert>);
			// navigate(-1);
			// setErrorMessage(parseErrorMessage(e));
			// TODO: Parse error message and mapping with the translation instead of hard-coded
			setErrorMessage('errors.googleDriveError');
		}
	});

	const onBackupRetry = useOnEventCallback(async () => {
		try {
			const { url, state: newState } = await createGoogleAuthUrl();
			stateRef.current = newState;
			dataDogAddAction(DATADOG_ACTIONS.BACKUP_GG_RETRY);
			TelegramCore.openExternalURL(url);
			setTimeout(() => {
				handleBackup();
			}, 0);
		} catch (error) {
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.CREATE_WALLET,
					function: 'retryBackup',
				},
			});
		}
	});

	useEffect(() => {
		handleBackup();
		return () => {
			authTokenWait.current = false;
		};
		// eslint-disable-next-line
	}, []);

	if (errorMessage) {
		return (
			<ErrorView
				title={t('storeBackup.googleDrive')}
				message={t(errorMessage)}
				onRetry={onBackupRetry}
				backButtonText={t('storeBackup.selectAnotherMethod')}
				onBack={() => navigate(-1)}
			/>
		);
	}

	return (
		<Container className="pageContent">
			<Box className="pageFixed" py={6} px={4}>
				<Center flexDirection="column" overflow="hidden" flexGrow={1}>
					<Spinner color="cyan.400" />
					<Text fontSize={14} mt={2}>
						{progressText}
					</Text>
				</Center>

				<CTAButton size="lg" fontWeight="medium" width="100%" onClick={onCancel}>
					{t('button.cancel')}
				</CTAButton>
			</Box>
		</Container>
	);
};

export default BackupGDriveCallback;
