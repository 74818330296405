import { Block, ButtonSecondary, CTAButton, TextSmall, Toast, TooltipInfo } from '@/app-components/common';

import { Avatar, Box, Button, Card, Center, Flex, Image, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import { formatCurrency, formatNumber, formatUnits, formatUsd } from '@/app-helpers/number';
import { useNavigate } from 'react-router';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { TokenInfo } from '@/app-cores/api/bff';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { useUserSettingsStore } from '@/app-store/settings';
import { SwapService, trackingSwapDebugData, getPriceImpactWarningMsg, useGetAllPriceSwap } from '@/app-hooks/swap';
import { TransactionType } from '@/app-types';
import ProviderNote from '@/app-views/swap/components/ProviderNote';
import { useExecuteRoute } from '@/app-hooks/swap';
import { ArrowUpIcon } from '@/assets/images/svg';
import { GenericRoute, RouteExecuting, SelectedRoute, useSwapStore } from '@/app-store/swap';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import Warning from '@/app-components/common/Warning';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { renderFeeRoute } from '@/app-views/swap/components/ListRoute/RouteItem';
import { getNativeToken } from '@/app-helpers/token';
import { toQueryString } from '@/app-helpers/url';
import { formatTimeDuration } from '@/app-helpers/time';
import { displaySlippage } from '@/app-helpers/display';
import { InternalStep, SwapProvider, SwapType } from '@/app-hooks/swap/type';
import { getSwapType } from '@/app-hooks/swap/helper';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { TxStatus } from '@/app-cores/api/activities';
import TradeLayout from '@/app-views/swap/components/TradeLayout';
import { NavigateHeader } from '@/app-views/swap/components/Header';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmModal from '@/app-components/common/ConfirmModal';
import { useMutationAddTokens } from '@/app-hooks/api/tokens/useMutationUserSettingTokens';
import { compareChain } from '@/app-helpers/address';
import { TransactionSteps } from '@/app-views/swap/components/TransactionSteps';
import { useWatchTxsResult } from '@/app-views/txs-result/pages/TransactionResult';
import { Actions } from '@/app-features/app-bot-connector/hooks';

interface InfoItemProps {
	title: string;
	info1: React.ReactNode;
	info2?: React.ReactNode;
}

export const InfoItem: React.FC<InfoItemProps> = ({ title, info1, info2 }) => {
	return (
		<Flex justifyContent="space-between">
			<TextSmall m={0} color="gray.400" whiteSpace={'nowrap'}>
				{title}
			</TextSmall>
			<Box>
				{info1}
				{info2}
			</Box>
		</Flex>
	);
};

export const TokenRow = ({
	amount,
	token,
	amountUsd,
}: {
	amount: string;
	token: TokenInfo;
	amountUsd: string | number;
}) => {
	const amountStr = token ? amount : '-';
	return (
		<Flex
			alignItems={'center'}
			borderRadius={'40px'}
			padding={'10px 16px'}
			justifyContent={'space-between'}
			gap={'4px'}
		>
			<Flex alignItems={'center'} gap={'12px'}>
				<TokenLogo size={30} logo={token?.logo} symbol={token?.symbol} chainId={token?.chainId} />
				<Flex flexDirection={'column'}>
					<Text
						fontSize={'14px'}
						fontWeight={'500'}
						flex={1}
						whiteSpace={'nowrap'}
						overflow={'hidden'}
						textOverflow={'ellipsis'}
					>
						{amountStr}
					</Text>
					<Text fontSize={'12px'} color={'gray.400'}>
						{formatUsd(amountUsd)}
					</Text>
				</Flex>
			</Flex>

			<Text fontSize={'small'} fontWeight={'500'} color={'gray'}>
				{token?.symbol}
			</Text>
		</Flex>
	);
};

export const ConfirmTransaction = ({
	quickSwap,
	onSwapSuccess,
}: {
	quickSwap?: boolean;
	onSwapSuccess?: ({ hash, chainId }: { hash: string; chainId: string }) => void;
}) => {
	const { setRouteExecuting, routeExecuting } = useSwapStore(false);
	const { selectedRoute, ...state } = useSwapStore(quickSwap);
	const swapType = getSwapType(selectedRoute?.tokenIn, selectedRoute?.tokenOut);
	const provider = selectedRoute?.provider;

	const wrapOrUnwrap = swapType === SwapType.WRAP_EVM || swapType === SwapType.UNWRAP_EVM;

	const { usdPriceTokenIn, usdPriceTokenOut, usdPriceNative } = useGetAllPriceSwap({
		tokenIn: selectedRoute?.tokenIn,
		tokenOut: selectedRoute?.tokenOut,
	});

	const {
		tokenIn,
		tokenOut,
		amountInUsd,
		amountOutUsd,
		amountOut,
		amountIn,
		gasUsd,
		gasDisplay,
		gasNative,
		minAmountOut,
		priceImpact,
		duration,
	} = SwapService.extractRouteInfo({
		route: selectedRoute,
		usdPriceIn: usdPriceTokenIn,
		usdPriceOut: usdPriceTokenOut,
		usdPriceNative,
	});
	const chainIdIn = tokenIn?.chainId;
	const native = getNativeToken(chainIdIn);

	const { slippage } = useUserSettingsStore();
	const [isPending, setIsPending] = useState(false);
	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!selectedRoute && !quickSwap) navigate(NAVIGATE_PATHS.Swap.Main, { replace: true });
	}, [navigate, selectedRoute, quickSwap]);

	const execSwap = useExecuteRoute(quickSwap);

	const [showTradeInfo, setShowTradeInfo] = useState(true);

	const { mutateAsync } = useMutationAddTokens();
	const watchTxs = useWatchTxsResult();

	const [status, setStatus] = useState<InternalStep>();

	const ref = useRef(routeExecuting);
	ref.current = routeExecuting;
	const onChangeStatus = (data: RouteExecuting) => {
		data?.status && setStatus(data.status);
		setRouteExecuting({ ...ref.current, ...data });
	};

	const totalStep = selectedRoute?.subRoutes?.length || 1;
	const step = useRef(1);

	const handleConfirmTransaction = async () => {
		if (isPending) return;
		dataDogAddAction(DATADOG_ACTIONS.TRADE_CONFIRM_TRANSACTION);
		trackingSwapDebugData(selectedRoute);
		onClose();
		try {
			const isFistStep = step.current === 1;
			setShowTradeInfo(false);
			setIsPending(true);
			onChangeStatus({ status: isFistStep ? InternalStep.SRC_SUBMIT : InternalStep.DES_SUBMIT });
			const data = await execSwap(provider, onChangeStatus);
			const hasAnotherStep = step.current < totalStep;

			onChangeStatus({
				status: hasAnotherStep
					? InternalStep.DES_WAITING_FOR_ACTION
					: isFistStep
					? InternalStep.SRC_CONFIRM
					: InternalStep.DES_CONFIRM,
			});

			if (quickSwap) {
				await onSwapSuccess?.({ hash: data?.hash, chainId: chainIdIn?.toString() });
			} else {
				if (hasAnotherStep) {
					setStatus(undefined); // reset state for step 2
				}
				watchTxs({
					chainId: selectedRoute?.subRoutes?.[step.current - 1]?.tokenIn?.chainId || chainIdIn,
					txHash: data?.hash,
					transactionType: TransactionType.Swap,
					onResponse: ({ status, msg }) => {
						if (status === TxStatus.Failed) {
							const error = parseErrorMessage(msg, t('transactionResult.swap.error'));
							toast(<Toast type="error" message={error} />);
							onChangeStatus({
								status: isFistStep ? InternalStep.SRC_CONFIRM : InternalStep.DES_CONFIRM,
								error,
							});
							return;
						}
						if (!hasAnotherStep) {
							onChangeStatus({ status: InternalStep.SUCCESS });
							toast(
								<Toast
									type="success"
									title="Success"
									message={msg || t('transactionResult.success')}
								/>,
							);
						}
					},
				});
			}
			step.current++;
			if (!compareChain(tokenIn.chainId, tokenOut.chainId)) {
				mutateAsync({ chainId: tokenOut.chainId, contractAddress: tokenOut.address, type: 'include' }).catch(
					() => {},
				);
			}
		} catch (e) {
			onChangeStatus({ error: parseErrorMessage(e) });
			dataDogAddError(e, {
				tags: {
					name: DATADOG_ERROR_TAGS.TRADE,
					function: 'confirmSwapTransaction',
				},
			});
			dataDogAddAction(DATADOG_ACTIONS.TRADE_SIGN_AND_BROADCAST_ERROR);
			console.error(e);
			toast(<Toast type="error" message={`Failed to swap: ${parseErrorMessage(e)}`} />);
		} finally {
			setIsPending(false);
		}
	};

	const priceImpactErrorWarning = getPriceImpactWarningMsg({
		swapType,
		amountInUsd,
		priceImpact,
	});

	const renderTradeInfo = () => {
		return (
			<Card
				flexDirection={'column'}
				background={'white'}
				gap={'10px'}
				borderRadius={'12px'}
				padding={'12px 16px'}
			>
				<InfoItem
					title="Max Slippage"
					info1={
						<Center gap={1}>
							<Text fontSize="sm">{displaySlippage(slippage)}</Text>
							<TooltipInfo label={t('tokenTrading.slippageTooltip')}></TooltipInfo>
						</Center>
					}
				/>

				{priceImpactErrorWarning && (
					<InfoItem
						title="Price Impact"
						info1={
							<Center gap={1}>
								<Text fontSize="sm" color={'orange.100'}>
									{formatNumber(priceImpact, { decimals: 2 })}%
								</Text>
								<TooltipInfo label={t('tokenTrading.priceImpactToolTip')}></TooltipInfo>
							</Center>
						}
					/>
				)}

				{minAmountOut && tokenOut && (
					<InfoItem
						title="Min Received"
						info1={
							<Center gap={1}>
								<Text fontSize="sm">
									{formatUnits(minAmountOut, tokenOut?.decimals)} {tokenOut?.symbol}
								</Text>
							</Center>
						}
					/>
				)}

				<InfoItem
					title="Estimated fee"
					info1={
						<Center gap={1}>
							<Text fontSize="sm" textAlign={'right'}>
								{renderFeeRoute({ gasDisplay, gasNative, gasUsd, native })}
							</Text>
							<TooltipInfo label={t('tokenTrading.gasFeeTooltip')}></TooltipInfo>
						</Center>
					}
				/>

				{duration && (
					<InfoItem
						title="Estimated time"
						info1={
							<Center gap={1}>
								<Text fontSize="sm">≈ {formatTimeDuration(duration)}</Text>
							</Center>
						}
					/>
				)}

				<InfoItem
					title="Provider"
					info1={
						<ProviderNote
							provider={
								selectedRoute?.subRoutes ? selectedRoute?.subRoutes?.map((e) => e.provider) : provider
							}
						/>
					}
				/>
			</Card>
		);
	};

	const needDoubleConfirm = priceImpact >= 50;

	const { isOpen, onOpen, onClose } = useDisclosure();

	const renderContent = () => {
		return (
			<Flex
				flexDirection={'column'}
				flex={1}
				gap={'16px'}
				padding={quickSwap ? 0 : '8px 6px'}
				p={quickSwap ? 4 : undefined}
			>
				<Card
					flexDirection={'column'}
					background={'white'}
					gap={'8px'}
					borderRadius={'12px'}
					position={'relative'}
				>
					<TokenRow
						token={tokenIn}
						amount={formatUnits(amountIn, tokenIn?.decimals)}
						amountUsd={amountInUsd}
					/>
					<Flex alignItems={'center'} px={5}>
						<ArrowUpIcon
							style={{
								transform: 'scaleY(-1)',
							}}
							height={18}
						/>
						<Box as="hr" flex={1} />
					</Flex>
					<TokenRow
						token={tokenOut}
						amount={formatUnits(amountOut, tokenOut?.decimals)}
						amountUsd={amountOutUsd}
					/>
				</Card>
				{selectedRoute?.subRoutes && (
					<Warning
						title={t('tokenTrading.specialDirectRoute')}
						msg={t('tokenTrading.specialDirectRouteDesc')}
						status={'success'}
					/>
				)}
				{!wrapOrUnwrap && showTradeInfo ? (
					<>
						{priceImpactErrorWarning && (
							<Warning
								msg={priceImpactErrorWarning?.msg}
								title={priceImpactErrorWarning?.title}
								status={priceImpactErrorWarning?.type}
							/>
						)}
						{renderTradeInfo()}
					</>
				) : (
					<TransactionSteps provider={provider} selectedRoute={selectedRoute} />
				)}

				<ConfirmModal
					{...{
						isOpen,
						onClose,
						onOk: handleConfirmTransaction,
						onReject: onClose,
						title: t(`tokenTrading.unusualPriceImpact`),
						okBtnLabel: t(`tokenTrading.iKnow`),
						okBtnProps: { colorScheme: 'orange' },
						rejectBtnLabel: t('button.cancel'),
					}}
				>
					<Flex flexDirection={'column'} alignItems={'center'} gap={'16px'} py={2}>
						{priceImpactErrorWarning && (
							<Warning
								msg={priceImpactErrorWarning?.msg}
								title={priceImpactErrorWarning?.title}
								status={priceImpactErrorWarning?.type}
							/>
						)}
						<Warning
							msg={
								<Trans
									i18nKey="tokenTrading.confirmSwapWithPriceImpact"
									values={{
										amount: `${formatUnits(amountOut, tokenOut?.decimals)} ${
											tokenOut?.symbol
										} (${formatUsd(amountOutUsd)})`,
									}}
									components={[<b />]}
								/>
							}
						/>
					</Flex>
				</ConfirmModal>
			</Flex>
		);
	};

	const onBackHome = () => {
		navigate('/', { replace: true });
	};

	const renderAction = () => (
		<Box p={4}>
			{(() => {
				const btnHome = (
					<CTAButton
						colorScheme="cyan"
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={() => {
							dataDogAddAction(DATADOG_ACTIONS.TRADE_GO_TO_HOME);
							onBackHome();
						}}
					>
						{t('button.goToHome')}
					</CTAButton>
				);
				const btnConfirm = (
					<CTAButton
						onClick={needDoubleConfirm ? onOpen : handleConfirmTransaction}
						colorScheme={priceImpactErrorWarning ? 'orange' : 'cyan'}
						size="lg"
						fontWeight="medium"
						width="100%"
						isDisabled={false}
					>
						{isPending ? (
							<Spinner size="sm" color="white" />
						) : priceImpactErrorWarning ? (
							t(`tokenTrading.iKnow`)
						) : (
							t('button.confirm')
						)}
					</CTAButton>
				);

				if (quickSwap) return btnConfirm;

				if (routeExecuting?.error)
					return (
						<>
							<ButtonSecondary
								mb={2}
								bg={colors.gray[100]}
								size="lg"
								fontWeight="medium"
								width="100%"
								onClick={() => navigate(-1)}
							>
								{t('button.tryAgain')}
							</ButtonSecondary>
							{btnHome}
						</>
					);

				switch (status) {
					case InternalStep.SRC_CONFIRM:
					case InternalStep.DES_CONFIRM:
						return btnHome;
					case InternalStep.SUCCESS:
						return (
							<>
								<Button
									mb={2}
									size="lg"
									fontWeight="medium"
									width="100%"
									onClick={() => {
										dataDogAddAction(DATADOG_ACTIONS.TRADE_GO_TO_ACTIVITY);
										navigate(`/?page=/&action=${Actions.ACTIVITIES}`);
									}}
									colorScheme="gray"
								>
									{t('button.viewActivity')}
								</Button>
								{btnHome}
							</>
						);
					default:
						return btnConfirm;
				}
			})()}
		</Box>
	);

	if (quickSwap)
		return (
			<PageTransition style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
				{renderContent()}
				{renderAction()}
			</PageTransition>
		);

	return (
		<TradeLayout header={<NavigateHeader tokenOut={state.tokenOut} />} action={renderAction()}>
			{renderContent()}
		</TradeLayout>
	);
};
