import { compareAddress, compareChain, isNativeToken } from '@/app-helpers/address';
import { SelectedRoute, SwapDisableType } from '@/app-store/swap';
import { useMutation } from '@tanstack/react-query';

import { getNativeToken, isEvmChain, isSolanaChain, isTonChain, isTronChain } from '@/app-helpers/token';
import { calculatePriceImpact, isRouteParamsEmpty } from '@/app-hooks/swap';
import {
	ArgsGetRoute,
	ExtractRouteInfo,
	MinAmountError,
	SwapAbstract,
	SwapProvider,
	UsdRouteInfo,
} from '@/app-hooks/swap/type';
import bs58 from 'bs58';
import { uniqueId } from '@/app-helpers/random';
import { ethers, parseUnits } from 'ethers';
import axios from 'axios';
import { ChainId } from '@/app-constants/chains';
import { calcRateSwap, getMyWalletAddressByChain } from '@/app-hooks/swap/helper';
import { sha256_sync } from '@ton/crypto';
import { Address, beginCell, storeStateInit } from '@ton/core';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { truncateToFixed } from '@/app-helpers/number';
import { estimateGasFeeSendToken, useSentEvmToken, useSentTonToken } from '@/app-hooks/transactions';
import { estimateTonGasFee } from '@/app-hooks/transactions/ton/useEstimateFeeSendTonAsset';
import { getSigner } from '@/app-helpers/web3';
import { TokenInfo } from '@/app-cores/api/bff';
import { getGasInfo } from '@/app-hooks/api/transactions/useQueryGasPrice';
import { estimateSolGasFee } from '@/app-hooks/transactions/sol/useEstimateFeeSendSolAsset';
import { useSentSolToken } from '@/app-hooks/transactions/sol/useMutationSentSolAsset';
import { TransactionType } from '@/app-types';
import { getMinimumBalanceForRentExemption } from '@/app-helpers/solana';
import { tronWallet } from '@/app-cores/mpc-wallet/tron';
import { useSentTronToken } from '@/app-hooks/transactions/tron/useMutationSendTronAsset';
import { estimateSendTronToken } from '@/app-hooks/transactions/tron/useEstimateTronGasFee';

interface ConnectProofPayload {
	timestamp: number;
	bufferToSign: Buffer;
	domainBuffer: Buffer;
	payload: string;
	origin: string;
	messageBuffer: Buffer;
}

const walletStateInitFromState = async () => {
	const { wallet } = await TonWallet.create('mainnet');
	return beginCell().storeWritable(storeStateInit(wallet.init)).endCell().toBoc().toString('base64');
};

const createTonProofItem = (signature: Uint8Array, proof: ConnectProofPayload, stateInit?: string) => {
	return {
		timestamp: proof.timestamp, // 64-bit unix epoch time of the signing operation (seconds)
		domain: {
			lengthBytes: proof.domainBuffer.byteLength, // AppDomain Length
			value: proof.domainBuffer.toString('utf8'), // app domain name (as url part, without encoding)
		},
		signature: Buffer.from(signature).toString('base64'), // base64-encoded signature
		payload: proof.payload, // payload from the request,
		stateInit: stateInit, // state init for a wallet
	};
};

const tonConnectProofPayload = (
	timestamp: number,
	origin: string,
	wallet: string,
	payload: string,
): ConnectProofPayload => {
	const timestampBuffer = Buffer.allocUnsafe(8);
	timestampBuffer.writeBigInt64LE(BigInt(timestamp));

	const domainBuffer = Buffer.from(new URL(origin).host);

	const domainLengthBuffer = Buffer.allocUnsafe(4);
	domainLengthBuffer.writeInt32LE(domainBuffer.byteLength);

	const address = Address.parse(wallet);

	const addressWorkchainBuffer = Buffer.allocUnsafe(4);
	addressWorkchainBuffer.writeInt32BE(address.workChain);

	const addressBuffer = Buffer.concat([addressWorkchainBuffer, address.hash]);

	const messageBuffer = Buffer.concat([
		Buffer.from('ton-proof-item-v2/', 'utf8'),
		addressBuffer,
		domainLengthBuffer,
		domainBuffer,
		timestampBuffer,
		Buffer.from(payload),
	]);

	const bufferToSign = Buffer.concat([
		Buffer.from('ffff', 'hex'),
		Buffer.from('ton-connect', 'utf8'),
		Buffer.from(sha256_sync(messageBuffer)),
	]);

	return {
		timestamp,
		bufferToSign,
		domainBuffer,
		payload,
		origin,
		messageBuffer,
	};
};

export type RouteRetroBridge = {
	amount_out: number; // Amount to receive on destination
	platform_fee: number; // Retrobridge fee value
	platform_fee_in_usd: number; // Retrobridge fee value in USD
	blockchain_fee: number; // Destination gas fee
	blockchain_fee_in_usd: number; // Destination gas fee in USD
	swap_fee: number; // Swap fee
	swap_fee_in_usd: number; // Swap fee in USD
	full_fee: number; // Total fee (platform_fee + swap_fee + blockchain_fee)
	fee_asset: string; // Asset in which fees are calculated
	// custom
	gas_fee: bigint;
};

const API_BASE_URL = 'https://backend.retrobridge.io';

type Network = {
	name: string;
	chainId: string;
	active: boolean;
};

type Token = {
	name: string;
	native: boolean;
	contract_address: string | null;
	active: boolean;
	pairs: Token[];
};

type MappingData = {
	tokenIn: Token;
	tokenOut: Token;
	chainIn: Network;
	chainOut: Network;
};

const getNetWorkType = (chainId: string) =>
	isTonChain(chainId)
		? 'TON'
		: isEvmChain(chainId)
		? 'EVM'
		: isSolanaChain(chainId)
		? 'SOLANA'
		: isTronChain(chainId)
		? 'TRON'
		: undefined;

const prefix = 'retrobridge_';
const setToken = (key: string, token: string) => {
	localStorage.setItem(prefix + key, token);
};
const getToken = (key: string) => {
	return localStorage.getItem(prefix + key);
};

class Retrobridge extends SwapAbstract<RouteRetroBridge> {
	#initTask: Promise<void>;
	#isReady: boolean = false;
	#supportChains: Network[] = [];
	#supportTokens: Record<string, Token[]> = {};

	async init() {
		try {
			if (this.#isReady) return;
			this.#initTask = this._getSupportChains();
			await this.#initTask;
			this.#isReady = true;
		} catch (error) {
			throw new Error('Retrobridge is not ready');
		}
	}

	private _validateSignedMessage = async (data: {
		wallet_address: string;
		network_type: string;
		signature: string;
	}) => {
		const response = await axios.post(`${API_BASE_URL}/api/wallet_auth/message`, data);
		setToken(data.network_type, response?.data?.data?.token ?? '');
	};

	private _isAuthWallet = async (address: string, chainId: string): Promise<boolean> => {
		try {
			const url = `${API_BASE_URL}/api/wallet_auth/wallet/${address}`;
			const network = getNetWorkType(chainId);
			await axios.get(url, {
				headers: { 'network-type': network, Authorization: getToken(network) },
			});
			return true;
		} catch (error) {
			return false;
		}
	};

	private async _getSignMessage() {
		const { data } = await axios.get(`${API_BASE_URL}/api/wallet_auth/message`);
		return { message: data?.data?.message?.value };
	}

	private async _authenWalletTon() {
		const walletAddress = Address.parse(MpcWallet.getWalletAddress().tonAddress).toRawString();

		const { message } = await this._getSignMessage();
		const proof = tonConnectProofPayload(
			Math.floor(Date.now() / 1000),
			window.location.href,
			walletAddress,
			message,
		);

		const signature = await MpcWallet.signEddsaMessage(sha256_sync(proof.bufferToSign));
		const proofItem = await createTonProofItem(signature, proof, await walletStateInitFromState());

		return this._validateSignedMessage({
			wallet_address: walletAddress,
			network_type: getNetWorkType(ChainId.TON),
			signature: JSON.stringify(proofItem),
		});
	}

	private async _authenWalletTron() {
		const walletAddress = MpcWallet.getWalletAddress().tronAddress;
		const { message } = await this._getSignMessage();
		const formatMsg = `${message}\n${walletAddress}`;
		const signature = await tronWallet.signMessage(formatMsg);

		return this._validateSignedMessage({
			wallet_address: walletAddress,
			network_type: getNetWorkType(ChainId.TRON),
			signature,
		});
	}

	private async _authenWalletSol() {
		const walletAddress = MpcWallet.getWalletAddress().solAddress;
		const { message } = await this._getSignMessage();
		const signature = await MpcWallet.signEddsaMessage(
			Buffer.from(new TextEncoder().encode(`${message}\n${walletAddress}`)),
		);
		return this._validateSignedMessage({
			wallet_address: walletAddress,
			network_type: getNetWorkType(ChainId.SOL),
			signature: bs58.encode(signature),
		});
	}

	private async _authenWalletEvm(chainId) {
		const walletAddress = MpcWallet.getWalletAddress().evmAddress;
		const { message } = await this._getSignMessage();

		const { signer } = await getSigner(chainId);
		const signature = await signer.signMessage(`${message}\n${walletAddress}`);

		return this._validateSignedMessage({
			wallet_address: walletAddress,
			network_type: getNetWorkType(chainId),
			signature,
		});
	}
	private async _authenWallet(chainId: string) {
		const wallet = getMyWalletAddressByChain(chainId);
		if (await this._isAuthWallet(isTonChain(chainId) ? Address.parse(wallet).toRawString() : wallet, chainId))
			return;

		if (isTonChain(chainId)) return this._authenWalletTon();
		if (isEvmChain(chainId)) return this._authenWalletEvm(chainId);
		if (isSolanaChain(chainId)) return this._authenWalletSol();
		if (isTronChain(chainId)) return this._authenWalletTron();

		throw new Error('Not support chain');
	}

	private async _getSupportChains() {
		const { data } = await axios.get(`${API_BASE_URL}/api/networks`);
		this.#supportChains = data?.data ?? [];
		return;
	}

	private async _getSupportToken(fromChain: string, toChain: string) {
		const key = `${fromChain}_${toChain}`;

		if (this.#supportTokens[key]?.length) return this.#supportTokens[key];
		const { data } = await axios.get(`${API_BASE_URL}/api/assets`, {
			params: { source_chain: fromChain, destination_chain: toChain },
		});

		const tokens: Token[] = data?.data ?? [];
		this.#supportTokens[key] = tokens;
		return tokens;
	}

	private async _mappingToken({ tokenIn, tokenOut }: ArgsGetRoute): Promise<MappingData> {
		const findChainFn = (e: Network, chainId) =>
			e.active && (isEvmChain(chainId) ? compareChain(e.chainId, chainId) : e.name === getNetWorkType(chainId));

		const chainIn = this.#supportChains.find((e) => findChainFn(e, tokenIn.chainId));
		const chainOut = this.#supportChains.find((e) => findChainFn(e, tokenOut.chainId));

		if (!chainIn || !chainOut) throw new Error('Not found chains ');
		const tokens = await this._getSupportToken(chainIn.name, chainOut.name);

		const findTokenFn = (e: Token, token) =>
			e.active && (isNativeToken(token.address) ? e.native : compareAddress(e.contract_address, token?.address));

		const findTokenIn = tokens.find((e) => findTokenFn(e, tokenIn));
		const findTokenOut = findTokenIn?.pairs?.find((e) => findTokenFn(e, tokenOut));

		if (!findTokenIn || !findTokenOut) throw new Error('Not found tokens ');

		return { tokenIn: findTokenIn, tokenOut: findTokenOut, chainIn, chainOut };
	}

	private async _estimateGas(tokenIn: TokenInfo, amount: string) {
		try {
			const { tonAddress, evmAddress, solAddress, tronAddress } = MpcWallet.getWalletAddress();
			if (isTonChain(tokenIn?.chainId)) {
				const payloadEstimateGas = {
					token: tokenIn,
					amount,
					to: tonAddress,
				};
				return estimateTonGasFee(payloadEstimateGas);
			}
			if (isEvmChain(tokenIn?.chainId)) {
				const { gasPrice, feeData } = await getGasInfo(tokenIn?.chainId);
				const { market } = await estimateGasFeeSendToken({ to: evmAddress, amount, token: tokenIn }, feeData);
				return market.gasLimit * gasPrice;
			}
			if (isSolanaChain(tokenIn?.chainId)) {
				const { gasFee, connection } = await estimateSolGasFee({
					token: tokenIn,
					amount,
					to: solAddress,
					autoDeductFee: true,
				});
				const minBalanceForRent = await getMinimumBalanceForRentExemption(connection);
				return BigInt(gasFee) + BigInt(minBalanceForRent);
			}
			if (isTronChain(tokenIn?.chainId)) {
				const totalFee = await estimateSendTronToken({
					token: tokenIn,
					amount,
					to: tronAddress,
				});
				return tronWallet.tronWeb.toSun(totalFee);
			}
		} catch (error) {
			console.log('estimateGas retrobridge error:', error);
			return 0n;
		}
	}

	async getRoute(
		params: ArgsGetRoute,
		signal: AbortSignal,
		retryWhenMinAmount = false,
	): Promise<SelectedRoute<RouteRetroBridge>> {
		try {
			await this.#initTask;
			if (!this.#isReady) throw new Error('Retrobridge is not ready');

			if (!params) return;

			const mappingData = await this._mappingToken(params);
			const transferParams = getRouteParams(params, mappingData);
			if (!transferParams) throw new Error('Invalid params');

			const [{ data }, gasFee] = await Promise.all([
				axios.get(`${API_BASE_URL}/api/bridge/quote`, { params: transferParams, signal }),
				this._estimateGas(params.tokenIn, transferParams.amount?.toString()),
			]);

			return formatRoute({ route: { ...data?.data, gas_fee: gasFee }, params, transferParams });
		} catch (error) {
			const { amountInDefault, ...rest } = params;
			const minErr = new MinAmountError({ err: error?.response?.data?.message });
			if (minErr.minAmountError && retryWhenMinAmount && amountInDefault) {
				const route: SelectedRoute<RouteRetroBridge> = await this.getRoute(
					{ ...rest, amountIn: amountInDefault },
					signal,
				);
				return { ...route, disabled: SwapDisableType.MIN_AMOUNT, disableReason: minErr };
			}
			throw error;
		}
	}

	async buildRoute({
		route,
	}: {
		route: SelectedRoute<RouteRetroBridge>;
		slippage: number;
	}): Promise<SelectedRoute<RouteRetroBridge>> {
		const { tokenIn, metadata, tokenOut } = route;
		await this._authenWallet(tokenIn?.chainId);
		const network = getNetWorkType(tokenIn?.chainId);
		const { data } = await axios.post(
			`${API_BASE_URL}/api/bridge/execute`,
			{
				...metadata?.transferParams,
				receiver_wallet: getMyWalletAddressByChain(tokenOut?.chainId),
			},
			{
				headers: {
					'api-key': 'Tobi',
					'network-type': network,
					Authorization: getToken(network),
				},
			},
		);
		route.metadata = { ...metadata, ...data?.data };
		return route;
	}

	extractRoute(params: SelectedRoute<RouteRetroBridge>, prices: UsdRouteInfo): ExtractRouteInfo {
		return getExtractRoute(params, prices);
	}

	async getStatus({
		txId,
	}: {
		txId: string;
	}): Promise<{ amount_out: number; status: 'Completed'; destination_tx_hash: string }> {
		try {
			const data = await axios.get(`${API_BASE_URL}/api/bridge/${txId}/info`);
			return data?.data?.data;
		} catch (error) {}
	}
}

export const RetroBridgeSwap = new Retrobridge();

const getRouteParams = (args: ArgsGetRoute, { chainIn, chainOut, tokenIn, tokenOut }: MappingData) => {
	const { amountIn } = args;
	if (isRouteParamsEmpty(args)) return;

	if (!chainIn || !chainOut) return;

	const formatAmount = truncateToFixed(
		ethers.formatUnits(amountIn, args?.tokenIn.decimals),
		Math.min(6, args?.tokenIn.decimals), // retrobridge require only max 6 digits
	); // Amount to transfer in token decimals

	if (formatAmount === '0') return;

	const transferParams = {
		source_chain: chainIn.name,
		asset_from: tokenIn.name,
		wallet_sender: getMyWalletAddressByChain(args?.tokenIn?.chainId), // Source chain wallet address
		amount: formatAmount,
		destination_chain: chainOut.name,
		asset_to: tokenOut.name,
	};
	return transferParams;
};

const getExtractRoute = (
	{ route, tokenIn, tokenOut, metadata }: SelectedRoute<RouteRetroBridge>,
	{ usdPriceIn, usdPriceOut, usdPriceNative }: UsdRouteInfo,
): ExtractRouteInfo => {
	const { amount_out, gas_fee } = route || {};
	const amount = metadata?.transferParams?.amount;

	const amountIn = amount ? parseUnits(amount?.toString(), tokenIn?.decimals)?.toString() : '0';
	const amountOut = amount_out ? parseUnits(amount_out?.toString(), tokenOut?.decimals)?.toString() : '0';

	const rate = calcRateSwap({ tokenIn, tokenOut, amountIn, amountOut });

	const amountInUsd =
		usdPriceIn && amountIn ? usdPriceIn * +ethers.formatUnits(amountIn, tokenIn?.decimals) : undefined;

	const amountOutUsd = usdPriceOut && amount_out ? usdPriceOut * amount_out : undefined;

	const native = getNativeToken(tokenIn?.chainId);

	return {
		amountInUsd,
		amountOutUsd,
		rate,
		duration: undefined,
		amountOut,
		amountIn,
		priceImpact: calculatePriceImpact(amountInUsd, amountOutUsd),
		gasUsd: gas_fee && usdPriceNative ? +ethers.formatUnits(gas_fee, native?.decimals) * usdPriceNative : undefined,
		gasNative: gas_fee,
		tokenIn,
		tokenOut,
		dappInfo: {
			logo: '',
			domain: 'app.retrobridge.io',
		},
	};
};

const formatRoute = ({
	route,
	transferParams,
	params: { tokenIn, tokenOut, amountIn },
}: {
	route: RouteRetroBridge;
	params: ArgsGetRoute;
	transferParams: any;
}): SelectedRoute<RouteRetroBridge> | undefined => {
	return {
		route,
		id: uniqueId(),
		tokenIn,
		tokenOut,
		routerAddress: '',
		provider: SwapProvider.RETROBRIDGE,
		timestamp: Date.now(),
		metadata: { amountIn, transferParams },
	};
};

export const useExecuteRouteRetroBridge = () => {
	const { sendTransaction } = useSentTonToken();
	const { sentTransaction: sendEvm } = useSentEvmToken();
	const { sendTransaction: sendSol } = useSentSolToken();
	const { sendTransaction: sendTron } = useSentTronToken();

	return useMutation({
		mutationKey: ['exe-retrobridge'],
		mutationFn: async ({
			route: routeData,
			skipAddPendingTxs,
		}: {
			route: SelectedRoute<RouteRetroBridge>;
			skipAddPendingTxs?: boolean;
		}): Promise<{ hash: string }> => {
			const {
				metadata: { hot_wallet_address, transferParams },
				tokenIn,
			} = routeData;

			if (!hot_wallet_address) throw new Error('Not found deposit address');

			if (isTonChain(tokenIn?.chainId)) {
				const payload = {
					token: tokenIn,
					to: Address.parse(hot_wallet_address).toString(),
					amount: transferParams.amount,
					autoDeductFee: false,
				};
				const gasFee = await estimateTonGasFee(payload);
				const { seqno } = await sendTransaction({
					...payload,
					gasFee,
					transactionType: TransactionType.Swap,
					metadata: routeData,
					skipAddPendingTxs,
				});
				return { hash: seqno.toString() };
			}
			if (isEvmChain(tokenIn?.chainId)) {
				const payload = { amount: transferParams.amount, to: hot_wallet_address, token: tokenIn };
				const { feeData } = await getGasInfo(tokenIn?.chainId);
				const { market } = await estimateGasFeeSendToken(payload, feeData);
				return sendEvm({
					...payload,
					maxFeePerGas: market?.maxFeePerGas,
					maxPriorityFeePerGas: market?.maxPriorityFeePerGas,
					skipAddPendingTxs,
				});
			}
			if (isSolanaChain(tokenIn?.chainId)) {
				const hash = await sendSol({
					token: tokenIn,
					amount: transferParams.amount,
					to: hot_wallet_address,
					autoDeductFee: false,
					transactionType: TransactionType.Swap,
					metadata: routeData,
					skipAddPendingTxs,
				});
				return { hash };
			}
			if (isTronChain(tokenIn?.chainId)) {
				const resp = await sendTron({
					token: tokenIn,
					to: hot_wallet_address,
					amount: transferParams.amount,
				});
				return { hash: resp.transaction.txID };
			}
			throw new Error('Not support chain');
		},
	});
};
