import {
	Button,
	ButtonProps,
	Flex,
	List,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	useDisclosure,
} from '@chakra-ui/react';

import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { CHART_CONFIG_DEFAULT, ChartInterval, ChartMode, useUserSettingsStore } from '@/app-store/settings';

import { isTonChain } from '@/app-helpers/token';
import { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@/assets/images/svg/chevron-down-icon';

export const RESOLUTIONS_V2 = [
	// default options
	{ label: '15m', value: ChartInterval.FIFTEEN_MIN, order: 2 },
	{ label: '1h', value: ChartInterval.ONE_HOUR, order: 3 },
	{ label: '4h', value: ChartInterval.FOUR_HOURS, order: 6 },
	{ label: '1d', value: ChartInterval.ONE_DAY, order: 9 },
	/**  */
	{ label: '1m', value: ChartInterval.ONE_MIN, order: 1 },
	{ label: '2h', value: ChartInterval.TWO_HOUR, order: 4 },
	{ label: '3h', value: ChartInterval.THREE_HOUR, order: 5 },
	{ label: '6h', value: ChartInterval.SIX_HOURS, order: 7 },
	{ label: '8h', value: ChartInterval.EIGHT_HOURS, order: 8 },
	{ label: '1W', value: ChartInterval.SEVEN_DAYS, order: 10 },
];

export default function ChartSetting() {
	const { chartConfig: { mode, interval } = CHART_CONFIG_DEFAULT, setChartConfig } = useUserSettingsStore();

	const [customResolution, setCustomResolution] = useState(RESOLUTIONS_V2[4]);
	const displayResolutions = RESOLUTIONS_V2.slice(0, 4).concat([customResolution]);

	const { isOpen, onToggle, onClose } = useDisclosure();

	useEffect(() => {
		setChartConfig({
			interval: ChartInterval.ONE_HOUR,
			mode: ChartMode.CANDLE,
		});
	}, [setChartConfig]);

	const btnProps: ButtonProps = {
		size: 'sm',
		color: colors.gray[400],
		borderRadius: '8px',
		variant: 'outline',
		lineHeight: 'unset',
	};
	return (
		<Flex
			align={'center'}
			borderTop={`1px solid ${BASE_BORDER_COLOR}`}
			borderBottom={`1px solid ${BASE_BORDER_COLOR}`}
			sx={{
				justifyContent: 'space-between',
			}}
			py={2}
			px={4}
		>
			<Button
				onClick={() => {
					setChartConfig({
						mode: ChartMode.LINE,
						interval: ChartInterval.ONE_MIN,
					});
				}}
				sx={{
					color: mode === ChartMode.LINE ? 'black' : colors.gray[400],
					borderColor: mode === ChartMode.LINE ? colors.cyan[400] : 'transparent',
				}}
				{...btnProps}
			>
				Time
			</Button>
			{displayResolutions.map((e) => {
				const selected = interval === e.value && mode === ChartMode.CANDLE;
				return (
					<Button
						key={e.value}
						onClick={() => {
							setChartConfig({
								interval: e.value,
								mode: ChartMode.CANDLE,
							});
						}}
						sx={{
							color: selected ? 'black' : colors.gray[400],
							borderColor: selected ? colors.cyan[400] : 'transparent',
						}}
						{...btnProps}
					>
						{e.label}
					</Button>
				);
			})}

			<Popover isOpen={isOpen} onClose={onClose}>
				<PopoverTrigger>
					<Button
						onClick={onToggle}
						pr={0}
						sx={{ display: 'flex', alignItems: 'center', gap: '2px', borderColor: 'transparent' }}
						{...btnProps}
					>
						More <ChevronDownIcon width={18} style={{ transform: 'translateY(-1px)' }} />
					</Button>
				</PopoverTrigger>
				<PopoverContent sx={{ ':focus-visible': { boxShadow: 'none !important' } }}>
					<PopoverArrow />
					<PopoverCloseButton />
					<PopoverHeader fontSize={'14px'}>Select Interval</PopoverHeader>
					<PopoverBody>
						<Flex flexWrap={'wrap'} gap={2} justify={'space-between'}>
							{[...RESOLUTIONS_V2]
								.sort((a, b) => a.order - b.order)
								.map((e) => {
									const selected = displayResolutions.some((el) => el.value === e.value);
									return (
										<Button
											key={e.value}
											onClick={() => {
												setChartConfig({
													interval: e.value,
													mode: ChartMode.CANDLE,
												});
												if (
													e.value !== customResolution.value &&
													!displayResolutions.some((el) => el.value === e.value)
												)
													setCustomResolution(e);
												onToggle();
											}}
											sx={{
												color: selected ? 'black' : colors.gray[400],
												borderColor: selected ? colors.cyan[400] : BASE_BORDER_COLOR,
												minWidth: '60px',
											}}
											{...btnProps}
										>
											{e.label}
										</Button>
									);
								})}
						</Flex>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Flex>
	);
}

export function ChartSettingBottom() {
	const { chartConfig: { showMa, showVolume } = CHART_CONFIG_DEFAULT, setChartConfig } = useUserSettingsStore();

	const btnProps: ButtonProps = {
		size: 'sm',
		color: colors.gray[400],
		borderRadius: '8px',
		variant: 'outline',
		minW: '70px',
	};
	return (
		<Flex
			align={'center'}
			borderTop={`1px solid ${BASE_BORDER_COLOR}`}
			borderBottom={`1px solid ${BASE_BORDER_COLOR}`}
			py={2}
			px={4}
			gap={4}
		>
			Indicators:
			<Button
				onClick={() => {
					setChartConfig({ showMa: !showMa });
				}}
				sx={{
					color: showMa ? 'black' : colors.gray[400],
					borderColor: showMa ? colors.cyan[400] : BASE_BORDER_COLOR,
				}}
				{...btnProps}
			>
				MA20
			</Button>
			<Button
				onClick={() => {
					setChartConfig({ showVolume: !showVolume });
				}}
				sx={{
					color: showVolume ? 'black' : colors.gray[400],
					borderColor: showVolume ? colors.cyan[400] : BASE_BORDER_COLOR,
				}}
				{...btnProps}
			>
				VOL
			</Button>
		</Flex>
	);
}
