import { Center, Text, Box } from '@chakra-ui/layout';
import { Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { FiCheckIcon, FiFrownIcon } from '@/assets/icons';
import { Spinner, Toast } from '@/app-components/common';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { buildEncryptUnsecuredKeyShare } from '@/app-helpers/backup';
import { useMutationSendBackupKeyToUsersTG } from '@/app-hooks/api/mpc-wallet';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useOnEventCallback } from '@/app-hooks/common';
import { TelegramCore } from '@/app-cores/telegram';
import { TOBI_SUPPORT_BOT_LINK } from '@/app-constants';
import { useMutationSetBackupType } from '@/app-hooks/api/user/useMutationSetBackupType';
import { RECOVERY_TYPE } from '@/app-constants/backup';

export const FILE_NAME_PREFIX = 'DO_NOT_DELETE';
interface SendTelegramProps {
	isOpen: boolean;
	onClose: () => void;
	encryptedPassword: string;
}

export const SendTelegram: React.FC<SendTelegramProps> = ({ isOpen, onClose, encryptedPassword }) => {
	const { mutateAsync: sendKeyshareToUsersTG, isSuccess, isPending } = useMutationSendBackupKeyToUsersTG();
	const { mutateAsync: setBackupType } = useMutationSetBackupType();

	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleSendKeyshareToUsersTg = useOnEventCallback(async () => {
		if (isPending) return;
		try {
			const wallet = MpcWallet.getWalletAddress().evmAddress;
			const fileName = `${FILE_NAME_PREFIX}_${wallet}.txt`;
			const keyShareStore = buildEncryptUnsecuredKeyShare(encryptedPassword);
			const formData = new FormData();
			formData.append(
				'file',
				new Blob([keyShareStore], {
					type: 'text/plain',
				}),
				fileName,
			);

			await sendKeyshareToUsersTG(formData);
			await setBackupType({
				type: RECOVERY_TYPE.TELEGRAM,
				email: TelegramCore.getUser()?.username || '',
			});
			dataDogAddAction(DATADOG_ACTIONS.BACKUP_SENT_TELEGRAM);
			MpcWallet.removeUnsecuredKeyShare2();
		} catch (error) {
			console.log('sent telegram error:', error);
			dataDogAddError(error, {
				tags: {
					name: DATADOG_ERROR_TAGS.CREATE_WALLET,
					function: 'sendTelegram',
				},
			});
			toast(
				<Toast
					title={t('storeBackup.toast.backupError.title')}
					type="error"
					message={t('storeBackup.toast.backupError.message')}
				/>,
			);
		}
	});
	useEffect(() => {
		handleSendKeyshareToUsersTg();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderContent = () => {
		if (isPending)
			return (
				<Box>
					<Center>
						<Spinner />
					</Center>
					<Text
						mt={5}
						mb={12}
						fontSize="28px"
						textAlign="center"
						fontWeight={700}
						lineHeight={'35px'}
						letterSpacing="0.28px"
					>
						{t('storeBackup.methods.telegram.status.pending.title')}
					</Text>
				</Box>
			);
		if (isSuccess)
			return (
				<Center flexDirection="column">
					<Center width={14} height={14} borderRadius="100%" backgroundColor="#00E9DB">
						<FiCheckIcon />
					</Center>

					<Text my={5} fontSize="28px" textAlign="center" fontWeight={700} lineHeight={'35px'}>
						{t('storeBackup.methods.telegram.status.success.title')}
					</Text>
					<Text fontSize="xs" lineHeight="18px">
						{t('storeBackup.methods.telegram.status.success.description')}
					</Text>
					<Button
						mt={5}
						colorScheme="cyan"
						mb={2}
						size="lg"
						fontWeight="medium"
						width="100%"
						onClick={() => navigate(NAVIGATE_PATHS.Home)}
					>
						{t('storeBackup.methods.telegram.exploreNow')}
					</Button>
				</Center>
			);
		return (
			<Center flexDirection="column">
				<Center width={14} height={14} borderRadius="100%" backgroundColor="red">
					<FiFrownIcon />
				</Center>

				<Text m={5} fontSize="28px" textAlign="center" fontWeight={700} lineHeight={'35px'}>
					{t('storeBackup.methods.telegram.status.error.title')}
				</Text>

				<Text fontSize="xs" lineHeight="18px">
					{t('storeBackup.methods.telegram.status.error.description')}{' '}
					<Box
						as="strong"
						textDecoration="underline"
						cursor="pointer"
						onClick={() => {
							TelegramCore.WebApp.openTelegramLink(TOBI_SUPPORT_BOT_LINK);
						}}
					>
						Contact support.
					</Box>
				</Text>
				<Button
					mt={5}
					colorScheme="cyan"
					mb={2}
					size="lg"
					fontWeight="medium"
					width="100%"
					onClick={() => handleSendKeyshareToUsersTg()}
				>
					{t('button.retry')}
				</Button>
			</Center>
		);
	};
	return (
		<Drawer placement="bottom" onClose={onClose} isOpen={isOpen} closeOnOverlayClick={!isPending && !isSuccess}>
			<DrawerOverlay />
			<DrawerContent borderTopLeftRadius={8} borderTopRightRadius={8}>
				<DrawerBody pt={8} pb={5}>
					{renderContent()}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
