import { TonCenterServiceAPI } from '@/app-cores/api/toncenter/service-toncenter';
import { TonWallet } from '@/app-cores/mpc-wallet/ton/TonWallet';
import { WalletContractV4 } from '@/app-cores/mpc-wallet/ton/WalletContractV4';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeTobiToken, isNativeToken } from '@/app-helpers/address';
import {
	getFinalTonGasFee,
	getTonCoinTransferBalance,
	jettonForwardPayload,
	jettonTransferBody,
	seeIfTransferBounceble,
} from '@/app-helpers/ton';
import { TSentTonTransaction, TransactionType } from '@/app-types';
import { useMutation } from '@tanstack/react-query';
import { Address, OpenedContract, SendMode, comment, internal, toNano } from '@ton/core';
import { useTransactionWatcherStore } from '../../../app-store';
import { ChainId } from '../../../app-constants/chains';
import { TxDetailTokenSend, TxStatus } from '@/app-cores/api/activities';
import { parseUnits } from 'ethers';
import { tryParseAmount } from '@/app-hooks/swap/helper';
import { getPendingSendTxs } from '@/app-store/transaction-watcher/evmWatcher';
import { getTokenInfo } from '@/app-helpers/token';

export const jettonTransferAmount = toNano('0.64');
export const jettonTransferForwardAmount = BigInt('1');

async function transferTon(
	contract: OpenedContract<WalletContractV4>,
	seqno,
	{ autoDeductFee = true, ...sendData }: TSentTonTransaction,
) {
	const { decimals } = sendData.token;
	const sendAmount = getTonCoinTransferBalance(sendData.amount, decimals);

	const totalUsed = sendAmount + getFinalTonGasFee(sendData.gasFee);

	if (!autoDeductFee && BigInt(sendData.token.balance) < totalUsed)
		throw new Error('Not enough balance to cover gas fee');

	const isMax = totalUsed >= BigInt(sendData.token.balance);

	const receiptAddressStatus = await TonCenterServiceAPI.getAddressState(sendData.to);
	const bounce = seeIfTransferBounceble(sendData.to, receiptAddressStatus.result);
	const payload = {
		seqno: seqno,
		messages: [
			internal({
				to: sendData.to,
				value: sendAmount,
				body: comment(sendData.message || ''),
				bounce: bounce,
			}),
		],
		sendMode: isMax
			? SendMode.CARRY_ALL_REMAINING_BALANCE + SendMode.IGNORE_ERRORS
			: SendMode.PAY_GAS_SEPARATELY + SendMode.IGNORE_ERRORS,
	};
	const transfer = contract.createTransfer(payload);
	const cell = await contract.sign(transfer);
	await contract.send(cell);
}
async function transferJetton(contract: OpenedContract<WalletContractV4>, seqno, sendData: TSentTonTransaction) {
	const { address, decimals } = sendData.token;
	const jettonWalletAddress = await TonWallet.getUserJettonWalletAddress(address);
	const walletAddress = Address.parse(MpcWallet.getTonWalletAddress());
	const destinationAddress = Address.parse(sendData.to);
	const sendAmount = getTonCoinTransferBalance(sendData.amount, decimals);
	const forwardPayload = jettonForwardPayload(sendData.message);

	const internalMessage = internal({
		to: jettonWalletAddress,
		value: toNano('0.05'),
		bounce: true,
		body: await jettonTransferBody({
			jettonAmount: sendAmount, // Jetton amount (in basic indivisible units)
			toAddress: destinationAddress, // recepient user's wallet address (not Jetton wallet)
			forwardAmount: jettonTransferForwardAmount,
			forwardPayload: forwardPayload, // text comment for Transfer notification message
			responseAddress: walletAddress, // return the TONs after deducting commissions back to the sender's wallet address
		}),
	});

	const transfer = contract.createTransfer({
		seqno: seqno,
		messages: [internalMessage],
		sendMode: SendMode.PAY_GAS_SEPARATELY + SendMode.IGNORE_ERRORS,
	});

	const cell = await contract.sign(transfer);
	await contract.send(cell);
}

export function useSentTonToken() {
	const { addPendingTonTransaction } = useTransactionWatcherStore();
	const { mutateAsync: sendTransaction, ...result } = useMutation({
		mutationKey: ['sent-ton-transaction'],
		mutationFn: async (params: TSentTonTransaction) => {
			console.log('sent ton', params);
			const { contract } = await TonWallet.create('mainnet');
			const seqno = await contract.getSeqno();
			if (isNativeToken(params.token.address)) {
				await transferTon(contract, seqno, params);
			} else {
				await transferJetton(contract, seqno, params);
			}
			return { seqno };
		},
		onSuccess: (
			{ seqno },
			{ to, token, amount, message, transactionType = TransactionType.Send, metadata, skipAddPendingTxs },
		) => {
			if (skipAddPendingTxs) return;
			addPendingTonTransaction({
				seqno,
				transaction: getPendingSendTxs({
					to,
					token,
					amount,
					memo: message,
					hash: seqno?.toString(),
					chainId: ChainId.TON,
					transactionType,
				}),
				trackingData: metadata,
			});
		},
	});
	return {
		...result,
		sendTransaction,
	};
}
