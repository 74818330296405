import { ButtonPrimary } from '@/app-components/common';
import { useUserSettingsStore } from '@/app-store/settings';
import SlippageSetting from '@/app-views/swap/components/SlippageSetting';
import { FiSettingIcon, FiShareIcon } from '@/assets/icons';
import {
	Box,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Text,
} from '@chakra-ui/react';
import { useState, useMemo } from 'react';

import { isIOS } from 'react-device-detect';

export const SwapSetting = () => {
	const [open, setOpen] = useState(false);

	const { slippage: slippageGlobal, setSlippage: saveSlippageGlobal } = useUserSettingsStore();
	const [slippage, setSlippage] = useState<string | number>(slippageGlobal);

	return (
		<>
			<FiSettingIcon
				style={{ minWidth: 20, height: 20, cursor: 'pointer' }}
				onClick={() => {
					setSlippage(slippageGlobal);
					setOpen(true);
				}}
			/>
			<Drawer isOpen={open} placement="bottom" onClose={() => setOpen(false)} trapFocus={false}>
				<DrawerOverlay />
				{open && (
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader borderBottomWidth="1px">Settings</DrawerHeader>

						<DrawerBody px={4}>
							<Flex flexDirection={'column'} gap={'14px'}>
								<SlippageSetting onChange={setSlippage} value={slippage} />

								<ButtonPrimary
									mb={isIOS ? 5 : 0}
									disabled={slippage === ''}
									width={'100%'}
									onClick={() => {
										setOpen(false);
										saveSlippageGlobal(slippage as number);
									}}
								>
									Save
								</ButtonPrimary>
							</Flex>
						</DrawerBody>
					</DrawerContent>
				)}
			</Drawer>
		</>
	);
};
