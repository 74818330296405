import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { toast } from 'react-toastify';
import { Box, Center, Container, Text } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Toast } from '@/app-components/common';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { ServiceGoogle, WalletType } from '@/app-cores/api';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { useOnEventCallback } from '@/app-hooks/common';
import { GDriveService } from '@/app-services/gdrive';
import CreateWalletAnimationData from '@/assets/animations/create-wallet.json';

import { restoreEncryptUnsecuredKeyShare, storeNewUnsecuredKeyShare } from '@/app-helpers/backup';
import { DATADOG_ACTIONS, DATADOG_ERROR_TAGS, dataDogAddAction, dataDogAddError } from '@/app-services/monitor/datadog';
import { useWalletStore } from '@/app-store';

interface RecoveryGDriveCallbackProps {}

const RecoveryGDriveCallback: React.FC<RecoveryGDriveCallbackProps> = () => {
	const { data: userProfile } = useQueryUserProfile();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const animateRef = useRef<LottieRefCurrentProps>(null);
	const { setIsNonWallet } = useWalletStore();

	// const onCancel = useOnEventCallback(() => {
	// 	TelegramCore.showConfirm(t('storeBackup.confirmCancel'), {
	// 		onOk: () => {
	// 			navigate(-1);
	// 		},
	// 	});
	// });

	const waitForAuthToken = useOnEventCallback(async (state: string) => {
		return new Promise<string>((resolve, reject) => {
			const _poll = async () => {
				try {
					const accessToken = await ServiceGoogle.getAccessTokenByAuthState(state);
					console.log('Access Token', accessToken);

					if (!accessToken) {
						await new Promise((resolve) => setTimeout(resolve, 2000));
						return requestAnimationFrame(_poll);
					}

					resolve(accessToken);
				} catch (e) {
					dataDogAddError(e, {
						tags: {
							name: DATADOG_ERROR_TAGS.RECOVERY_WALLET,
							function: 'authGoogleDrive',
						},
					});
					reject(e);
				}
			};
			_poll();
		});
	});

	const handleRecovery = useOnEventCallback(async () => {
		try {
			const wallets = userProfile.wallets;
			const mpcWallet = (wallets || []).find((wallet) => !wallet.isAccountAbstraction);
			const tonWallet = (wallets || []).find((wallet) => wallet.type === WalletType.TON);
			const state = searchParams.get('state');
			const secretKey = searchParams.get('secretKey');
			const importSecretKey = searchParams.get('importSecretKey');
			if (!mpcWallet) {
				throw new Error('MPC Wallet not found');
			}

			if (!state) {
				throw new Error('State not found');
			}
			const accessToken = await waitForAuthToken(state);
			animateRef.current?.play();
			const gdriveSession = await GDriveService.createSession(accessToken);
			console.log('GDrive Session', gdriveSession);

			const backupFound = await gdriveSession.findBackupFileByWalletAddress(mpcWallet.address);
			console.log('backupFound:', backupFound);
			if (!backupFound.length) {
				throw new Error('Backup file not found on drive');
			}
			const keyShareEncryptedData = await gdriveSession.exportFileContentTextPlain(backupFound[0].id);
			if (importSecretKey) {
				try {
					storeNewUnsecuredKeyShare(keyShareEncryptedData, importSecretKey);
				} catch (error) {
					toast(<Toast type="error" title="Import Failed" message={t('toast.keyshareInvalid')} />);
				} finally {
					navigate(NAVIGATE_PATHS.CreateWallet.StoreBackupKey);
				}
			} else {
				const keyShare = restoreEncryptUnsecuredKeyShare(keyShareEncryptedData, secretKey);
				await MpcWallet.recoverKeyShare(keyShare, {
					evmWallet: mpcWallet.address,
					tonWallet: tonWallet?.formatedAddress,
				});
				dataDogAddAction(DATADOG_ACTIONS.RECOVERY_BY_GG_DRIVER_SUCCESS);
				setIsNonWallet(false);
				navigate(NAVIGATE_PATHS.Home);
			}
		} catch (e) {
			dataDogAddError(e, {
				tags: {
					name: DATADOG_ERROR_TAGS.RECOVERY_WALLET,
					function: 'recoveryByGoogleDrive',
				},
			});
			console.error(e);
			toast(<Toast type="error" message={t('toast.backupFail')} />);
			navigate(-1);
		}
	});

	useEffect(() => {
		if (userProfile?.defaultWallet) {
			handleRecovery();
		}
		// eslint-disable-next-line
	}, [userProfile]);

	return (
		<Container>
			<Box height="100vh">
				<Center height="100%" flexDirection="column">
					<Lottie
						width={180}
						high={180}
						loop={false}
						autoplay={false}
						lottieRef={animateRef as any}
						animationData={CreateWalletAnimationData}
						style={{
							width: '180px',
							height: '180px',
						}}
					/>
					<Text fontSize="sm" mt={6}>
						Just one sec, Tobi is on its way back!
					</Text>
				</Center>
			</Box>
		</Container>
	);
};

export default RecoveryGDriveCallback;
