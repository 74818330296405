import { SessionCrypto } from '@tonconnect/protocol';
import queryString from 'query-string';
import { seeIfValidTonAddress } from '../utils/common';
import { ConnectRequest, TonConnectParams } from '../types';
import { ReturnStrategy } from '@tonconnect/ui-react';

export interface TonTransferParams {
	address: string;
	amount?: string;
	text?: string;
	jetton?: string;
}

export function parseTonConnect(options: { url: string }): TonConnectParams | string {
	try {
		const { query } = queryString.parseUrl(options.url);
		if (query.v !== '2') {
			throw Error(`Unknown protocol version: ${query.v}`);
		}
		if (typeof query.id !== 'string') {
			throw Error('missing id ' + options.url);
		}
		if (typeof query.r !== 'string') {
			throw Error('missing payload ' + options.url);
		}
		const protocolVersion = parseInt(query.v as string);
		const request = JSON.parse(decodeURIComponent(query.r as string)) as ConnectRequest;
		const clientSessionId = query.id as string;
		const platform = query.platform as string;
		const sessionCrypto = new SessionCrypto();
		const returnStrategy = query.ret as ReturnStrategy;
		return {
			protocolVersion,
			request,
			clientSessionId,
			platform: platform,
			sessionKeyPair: sessionCrypto.stringifyKeypair(),
			returnStrategy,
		};
	} catch (e) {
		throw Error(`Unknown Error`);
	}
}
