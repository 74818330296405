import { ChainId } from '@/app-constants/chains';
import { EvmChainFamily, SolanaChainFamily } from '@blowfishxyz/api-client/v20230605';

export enum ChainType {
	EVM,
	TON,
	SOLANA,
	TRON,
}

export type NetWorkChain = {
	kyberAggregatorSlug: string;
	id: ChainId;
	name: string;
	logo: string;
	explorerUrl: string;
	nativeToken: NativeCurrency;
	wrapNativeToken: { address: string }; // to get price usd for native token
	rpcUrls: RpcUrls;
	testnet: boolean;
	transakNetworkName?: string;
	isEIP1559?: boolean;
	smartAccountEnabled?: boolean;
	minForGas: number; // for only swap for now
	type: ChainType;
	blowFishNetworkName?: EvmChainFamily | SolanaChainFamily;
};

interface RpcUrls {
	default: RpcUrl;
}
interface RpcUrl {
	http: string;
	wss: string;
}
interface NativeCurrency {
	symbol: string;
	decimals: number;
	name: string;
	address: string;
	chainId: ChainId;
}
