import {
	EvmChainFamily,
	ScanDomain200ResponseInner,
	ScanMessageEvm200Response,
	ScanTransactionsEvm200Response,
	ScanTransactionsSolana200Response,
} from '@blowfishxyz/api-client/v20230605';
import { TEvmScanMessage, TEvmScanSignTypeData, TEvmScanTransaction, TScanDomain, TSolScanTransaction } from './type';
import { blowFishService } from '@/app-cores/api/axios';

const API_ENDPOINTS: Partial<Record<EvmChainFamily, string>> = {
	'ethereum': 'ethereum/v0/mainnet/scan',
	'arbitrum': 'arbitrum/v0/one/scan',
	'avalanche': 'avalanche/v0/mainnet/scan',
	'base': 'base/v0/mainnet/scan',
	'bnb': 'bnb/v0/mainnet/scan',
	'optimism': 'optimism/v0/mainnet/scan',
	'polygon': 'polygon/v0/mainnet/scan',
};
class BlowFistClient {
	public async scanSignMessage(payload: TEvmScanMessage): Promise<ScanMessageEvm200Response> {
		const simulateRes = await blowFishService.post(`/${API_ENDPOINTS[payload.network]}/message`, {
			message: {
				kind: 'SIGN_MESSAGE',
				rawMessage: payload.rawMessage,
			},
			userAccount: payload.userAccount,
			metadata: payload.metadata,
		});
		return simulateRes.data;
	}
	public async scanSignTypedData(payload: TEvmScanSignTypeData): Promise<ScanMessageEvm200Response> {
		const simulateRes = await blowFishService.post(
			`/${API_ENDPOINTS[payload.network]}/message?language=en&method=eth_signTypedData_v4`,
			{
				message: {
					kind: 'SIGN_TYPED_DATA',
					data: payload.typedData,
				},
				userAccount: payload.userAccount,
				metadata: payload.metadata,
			},
		);
		return simulateRes.data;
	}
	public async scanEvmTransaction(payload: TEvmScanTransaction): Promise<ScanTransactionsEvm200Response> {
		const simulateRes = await blowFishService.post(`/${API_ENDPOINTS[payload.network]}/transactions`, {
			txObjects: payload.txObjects,
			userAccount: payload.userAccount,
			metadata: payload.metadata,
		});
		return simulateRes.data;
	}
	public async scanDomain(payload: TScanDomain): Promise<ScanDomain200ResponseInner[]> {
		const simulateRes = await blowFishService.post(`/v0/domains`, {
			domains: payload,
		});
		return simulateRes.data;
	}
	public async scanSolTransaction(payload: TSolScanTransaction): Promise<ScanTransactionsSolana200Response> {
		const simulateRes = await blowFishService.post(`/solana/v0/mainnet/scan/transactions?language=en`, payload);
		return simulateRes.data;
	}
}
export const BlowFistClientAPI = new BlowFistClient();
