import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { METAMASK_QR_CODE_REGEX } from '@/app-constants/regex';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { getBlockChainNameByAddress } from '@/app-helpers/address';
import { useContactStore } from '@/app-store';
import { useTonConnect } from '@/app-features/app-ton-connect/hooks/useConnect';
import { FiScanIcon } from '@/assets/icons';
import { useScanWC } from '@/app-features/app-wallet-connect/hooks';
export const ScanWalletConnect = () => {
	const { onScan, setOpen, setOnScan } = useScanWC();
	const { onConnect: onTonConnect } = useTonConnect();
	const { setSearchKey, setOpen: setOpenContactPopup } = useContactStore();
	const navigate = useNavigate();
	const handleScan = (data: string) => {
		if (!data) return;
		const isMetamaskQrCode = METAMASK_QR_CODE_REGEX.test(data);
		const blockChainName = getBlockChainNameByAddress(data);

		if (isMetamaskQrCode || blockChainName) {
			const address = isMetamaskQrCode ? data.split(':')[1].split('@')[0] : data;
			setSearchKey(address);
			setOpenContactPopup(true);
			setOpen(false);
			setTimeout(() => {
				navigate(NAVIGATE_PATHS.Wallet.Sent.Sent);
			}, 100);
			return;
		}
		if ((data as string).startsWith('tc://')) {
			onTonConnect(data);
			return setOpen(false);
		}
		onScan(data);
	};

	return (
		// <Box cursor="pointer">
		<Button
			px={0}
			backgroundColor="transparent"
			_hover={{
				backgroundColor: 'transparent',
			}}
			minWidth={5}
			maxWidth={5}
			onClick={() => {
				setOpen(true);
				setOnScan(handleScan);
			}}
		>
			<FiScanIcon />
		</Button>
		// </Box>
	);
};
