import { AlertInfo, ButtonPrimary } from '@/app-components/common';
import { navigateOnboardingFromBotRedirect } from '@/app-components/common/CreateAccount';
import { CHAIN_CONFIG } from '@/app-constants/chains';
import { ITokenSearch } from '@/app-cores/api/bff';
import { useDepositStore } from '@/app-features/deposit/store';
import { useNavigateToSentPage, useNavigateToSwapPage } from '@/app-helpers/navigate';
import { getTokenInfo, selectTokenDefaultFromUniversalToken } from '@/app-helpers/token';
import { isSupportSignTxs } from '@/app-helpers/web3';
import { useSearchSingleToken } from '@/app-hooks/api/portfolio';
import { useTokenBalance, useTokensBalance } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { useOnEventCallback } from '@/app-hooks/common';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { colors } from '@/app-theme/theme';

import Activities from '@/app-views/wallet/components/Activities';
import { FiClockIcon, FiPlusIcon, FiSwapIcon, FiUpRightIcon } from '@/assets/icons';
import { Box, Text, useDisclosure, ButtonProps, Center, Badge } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

interface MenuActionProps {
	isBackedUp?: boolean;
	chainId: string;
	contractAddress: string;
	isNonWallet: boolean;
	data: ITokenSearch | undefined;
	tobiId: string;
}

interface MenuActionItemProps extends ButtonProps {
	text: string;
	to: string;
	onClick?: () => void;
	badgeMsg?: ReactNode;
	wrapperId?: string;
}
export const MenuActionItem: React.FC<React.PropsWithChildren<MenuActionItemProps>> = ({
	children,
	text,
	to,
	onClick,
	badgeMsg,
	wrapperId,
	isDisabled,
	...restBoxProps
}) => {
	return (
		<Link to={to} replace={to === null} onClick={() => !isDisabled && onClick()}>
			<Box
				display="flex"
				alignItems="center"
				flexDirection="column"
				justifyContent="center"
				position={'relative'}
				id={wrapperId}
				px={4}
				py={1}
			>
				<ButtonPrimary
					colorScheme="cyan.600"
					display="flex"
					justifyContent="center"
					alignItems="center"
					borderRadius="50%"
					width={'40px'}
					height={'40px'}
					padding={0}
					position={'relative'}
					isDisabled={isDisabled}
					{...restBoxProps}
				>
					{children}
				</ButtonPrimary>
				<Text fontSize="xs" fontWeight={600} mt={3}>
					{text}
				</Text>
				{!!badgeMsg && (
					<Badge
						colorScheme={'red'}
						borderRadius={'50%'}
						style={{ position: 'absolute', right: -4, top: -4, fontSize: '12px' }}
					>
						{badgeMsg}
					</Badge>
				)}
			</Box>
		</Link>
	);
};

export const MenuAction: React.FC<MenuActionProps> = ({ chainId, contractAddress, isNonWallet, data, tobiId }) => {
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data: balances } = useTokensBalance(tobiId, data);
	const tokenBalance = balances?.[0] || selectTokenDefaultFromUniversalToken(data);

	const { chainTokens, ...info } = getTokenInfo(data);
	const { onOpen: onOpenDeposit, setInitToken } = useDepositStore();
	const navigate = useNavigate();

	const navigateToSend = useNavigateToSentPage();

	const supportSendOrSwap = chainTokens.length > 0;
	const background = !supportSendOrSwap ? colors.gray[200] : undefined;

	const depositToken = useMemo(() => {
		if (tokenBalance) return tokenBalance;
		return selectTokenDefaultFromUniversalToken(data);
	}, [data, tokenBalance]);

	const onSendClick = useOnEventCallback(() => {
		if (isNonWallet) {
			navigate(navigateOnboardingFromBotRedirect);
		} else {
			navigateToSend({ token: tokenBalance });
		}
	});
	const navigateToSwap = useNavigateToSwapPage(chainId || info.chainId);
	const onSwapClick = () => {
		if (!supportSendOrSwap) return;
		navigateToSwap({ tokenOut: tokenBalance });
	};
	const onActivityClick = () => {
		if (isNonWallet) {
			navigate(navigateOnboardingFromBotRedirect);
		} else {
			onOpen();
		}
	};

	return (
		<Center>
			<Box maxWidth={'300px'} display="flex" flex={1} justifyContent="space-between" mt={4}>
				<MenuActionItem
					isDisabled={!supportSendOrSwap || !tokenBalance}
					background={background}
					text={t('home.wallet.send')}
					to={null}
					onClick={onSendClick}
				>
					<FiUpRightIcon />
				</MenuActionItem>
				<MenuActionItem
					background={background}
					text={t('home.wallet.deposit')}
					to={null}
					isDisabled={!supportSendOrSwap}
					onClick={() => {
						setInitToken(depositToken);
						onOpenDeposit();
						dataDogAddAction(DATADOG_ACTIONS.DEPOSIT);
					}}
				>
					<FiPlusIcon />
				</MenuActionItem>
				<MenuActionItem
					isDisabled={!supportSendOrSwap}
					text={'Trade'}
					to={null}
					onClick={onSwapClick}
					background={background}
				>
					<FiSwapIcon />
				</MenuActionItem>
				<MenuActionItem
					isDisabled={!supportSendOrSwap}
					text={'Activity'}
					to={null}
					onClick={onActivityClick}
					background={background}
				>
					<FiClockIcon fill="black" />
					<Activities isOpen={isOpen} onClose={onClose} chainId={chainId} tokenAddress={contractAddress} />
				</MenuActionItem>
			</Box>
		</Center>
	);
};
//
