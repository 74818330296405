/* eslint-disable no-mixed-spaces-and-tabs */
type RequiredRecursively<T> = Exclude<
	T extends string | number | boolean
		? T
		: {
				[P in keyof T]-?: T[P] extends (infer U)[]
					? RequiredRecursively<U>[]
					: T[P] extends Array<infer U>
					? RequiredRecursively<U>[]
					: RequiredRecursively<T[P]>;
		  },
	null | undefined
>;

export type AccessorFunction<T, R> = (object: RequiredRecursively<T>) => R;

export function get<T, R>(object: T, accessorFn: AccessorFunction<T, R>): R | undefined;
export function get<T, R>(object: T, accessorFn: AccessorFunction<T, R>, defaultValue: R, executeFn?: boolean): R;
export function get<T, R>(
	object: T,
	accessorFn: AccessorFunction<T, R>,
	defaultValue?: R,
	executeFn: boolean = true,
): R | undefined {
	try {
		const result = executeFn === true ? accessorFn(object as unknown as RequiredRecursively<T>) : defaultValue;
		return result === undefined || result === null ? defaultValue : result;
	} catch (e) {
		return defaultValue;
	}
}

export const getEnvironment = (env: 'dev' | 'stg' | 'prod') => {
	return import.meta.env.VITE_ENVIRONMENT === env;
};

export function delay(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
export function delayWithData<T>(ms: number, data: T): Promise<T> {
	return new Promise((resolve) => setTimeout(() => resolve(data), ms));
}

export const intervalCheck = (time: number, conditionFn) =>
	new Promise((resolve) => {
		const intervalId = setInterval(async () => {
			if (await conditionFn()) {
				clearInterval(intervalId);
				resolve('');
			}
		}, time);
	});
