import { TON_CENTER_API_KEY } from '@/app-cores/api/axios';
import keyShareManager from '../wallet/keyshare-manager';
import { WalletContractV4 } from './WalletContractV4';
import { TonClient4 } from './client/TonClient4';
import { Address } from '@ton/core';
import { JettonMaster, TonClient } from '@ton/ton';

enum NetWorkEndpoint {
	testnet = 'https://testnet-v4.tonhubapi.com',
	mainnet = 'https://mainnet-v4.tonhubapi.com',
}
export const TON_CENTER_RPC = 'https://toncenter.com/api/v2/jsonRPC';
export class TonWallet {
	static async create(network: 'testnet' | 'mainnet') {
		const client = new TonClient4({ endpoint: NetWorkEndpoint[network] });
		const wallet = WalletContractV4.create({
			workchain: 0,
			publicKey: Buffer.from(keyShareManager.keyShares.eddsaKeyShare.publicKey()),
		});
		const contract = await client.open(wallet);
		return { wallet, contract, client };
	}
	static async getUserJettonWalletAddress(jettonAddress: string) {
		const client = new TonClient({
			endpoint: TON_CENTER_RPC,
			apiKey: TON_CENTER_API_KEY,
		});
		const wallet = WalletContractV4.create({
			workchain: 0,
			publicKey: Buffer.from(keyShareManager.keyShares.eddsaKeyShare.publicKey()),
		});
		const userAddress = wallet.address;
		const jettonMasterAddress = Address.parse(jettonAddress); // for example EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE
		const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress));
		const userJettonWallet = await jettonMaster.getWalletAddress(userAddress);
		return userJettonWallet;
	}
	static async getTonSpaceJettonWalletAddress(tonSpaceWallet: Address, jettonAddress: string) {
		const client = new TonClient({
			endpoint: TON_CENTER_RPC,
			apiKey: TON_CENTER_API_KEY,
		});
		const jettonMasterAddress = Address.parse(jettonAddress); // for example EQBlqsm144Dq6SjbPI4jjZvA1hqTIP3CvHovbIfW_t-SCALE
		const jettonMaster = client.open(JettonMaster.create(jettonMasterAddress));
		const tonSpaceJettonWallet = await jettonMaster.getWalletAddress(tonSpaceWallet);
		return tonSpaceJettonWallet;
	}
}
