import { BlockHeader } from '@/app-components/common';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { NAVIGATE_PATHS } from '@/app-constants/router';

import { Box, Collapse, Flex, Skeleton, Tag, Text } from '@chakra-ui/react';
import React, { memo, useRef } from 'react';

import { formatNumber, formatUnits } from '@/app-helpers/number';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import { FiFarmIcon } from '@/assets/icons/fi-farm-icon';
import {
	FARM_IDS,
	TobiFarmType,
	useQueryDebridgePoint,
	useQueryKyberPoint,
	useQueryTobiFarm,
} from '@/app-hooks/api/dapps/useTobiFarm';
import { colors } from '@/app-theme/theme';
import TobiTag from '@/app-components/common/TobiTag';
import { TOUR_GUIDE_STEPS_TARGET } from '../TourGuide/stepConfigs';
import { TokenLabel } from '@/app-views/portfolio/components/ListCrypto';

const FarmInfoDebridge = () => {
	const { data, isFetching } = useQueryDebridgePoint();
	return (
		<Flex alignItems={'center'} gap={'4px'} lineHeight={'12px'}>
			{!isFetching ? (
				<Text fontSize={'10px'} fontWeight={500}>
					Points{' '}
					<Text as="span" color={colors.green[200]}>
						{formatNumber(data | 0)}
					</Text>
				</Text>
			) : (
				<Skeleton opacity="0.1" height={'12px'} width="40px" />
			)}
		</Flex>
	);
};

const FarmInfoKyber = () => {
	const { data, isFetching } = useQueryKyberPoint();
	return (
		<Flex alignItems={'center'} gap={'4px'} lineHeight={'12px'}>
			{!isFetching ? (
				<Text fontSize={'10px'} fontWeight={500}>
					Reward{' '}
					<Text as="span" color={colors.green[200]}>
						{formatUnits(data?.totalReward ?? '0', 18)} ARB
					</Text>
				</Text>
			) : (
				<Skeleton opacity="0.1" height={'12px'} width="40px" />
			)}
		</Flex>
	);
};

const FarmInfoBera = () => {
	return <TokenLabel type="testnet" />;
};

const mapComponent = {
	[FARM_IDS.BERA]: FarmInfoBera,
	[FARM_IDS.DEBRIDGE]: FarmInfoDebridge,
	[FARM_IDS.KYBER]: FarmInfoKyber,
};

const FarmItem = ({ data: farm, onClick }: { data: TobiFarmType; onClick: () => void }) => {
	const { title, logo, key } = farm;
	const component = mapComponent[key];
	return (
		<Box
			onClick={onClick}
			backgroundColor="gray.100"
			minWidth={'130px'}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			flexDirection="column"
			padding={3}
			height={'101px'}
			borderRadius="1rem"
			gap={'4px'}
			cursor={'pointer'}
			position={'relative'}
		>
			<TokenLogo logo={logo} size={32} />
			<Text fontSize="12px">{title}</Text>
			{component && React.createElement(component)}
		</Box>
	);
};

function TobiFarm() {
	const { data: farms = [], isFetching } = useQueryTobiFarm();
	const displayFarms = farms?.filter((e) => e.showAtHome);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const refList = useRef<HTMLDivElement>(null);
	useDragToScroll({ ref: refList });

	const onClick = () => {
		navigate(NAVIGATE_PATHS.TOBI_FARM);
	};

	return (
		<Collapse in={displayFarms?.length > 0 || isFetching}>
			<Box mt={7} id={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.TOBI_FARM}>
				<BlockHeader
					leftContent={
						<Flex justify={'center'} align={'center'} gap={2}>
							<FiFarmIcon />
							{t('tobiFarm.tobiFarm')}
						</Flex>
					}
					rightContent=""
					to=""
				/>
				<SnapList ref={refList} direction="horizontal" snapType="mandatory">
					{isFetching
						? new Array(3).fill('').map((_, el) => (
								<SnapItem key={el} snapAlign="center" margin={{ right: '8px' }}>
									<Skeleton
										endColor={'gray.200'}
										height="100px"
										width={'130px'}
										borderRadius="1rem"
									/>
								</SnapItem>
						  ))
						: displayFarms?.map((item) => {
								return (
									<SnapItem key={item.id} snapAlign="center" margin={{ right: '8px' }}>
										<FarmItem data={item} onClick={onClick} />
									</SnapItem>
								);
						  })}
				</SnapList>
			</Box>
		</Collapse>
	);
}

export default memo(TobiFarm);
