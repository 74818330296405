import { useEffect } from 'react';
import { TelegramCore } from '.';
import { formatTelegramStartParams } from '../../app-helpers/string';
import { Actions } from '@/app-features/app-bot-connector/hooks';

type Actor = 'tonconnect' | 'referral' | 'bot' | 'botconnect';

const mockParams = ''; //`act=botSw&toChain=1&fromChain=137&fromTok=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&toTok=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&inviter=5110018277&am=200`;

const getParams = (startParam) => {
	try {
		return new URLSearchParams(mockParams || atob(startParam));
	} catch (error) {
		return;
	}
};

export const parseStartParams = (): { actor?: Actor; data?: any } => {
	// Respect the order of condition
	const startParams = TelegramCore?.WebApp?.initDataUnsafe?.start_param;
	if (!startParams) {
		return {
			actor: undefined,
		};
	}

	if (startParams.startsWith('tonconnect')) {
		return {
			actor: 'tonconnect',
			data: formatTelegramStartParams(`tc://?platform=${startParams}`),
		};
	}
	if (startParams === 'TCAT') {
		return {
			actor: 'bot',
			data: new URLSearchParams(`act=${Actions.SOL_TCAT}&affiliateCode=SOLTCAT`),
		};
	}
	const data = getParams(startParams);

	if (!data) {
		return {
			actor: 'referral',
			data: startParams,
		};
	}

	if (data.get('protocol') === 'text_bot') {
		return {
			actor: 'botconnect',
			data: Object.fromEntries(data.entries()),
		};
	}

	return {
		actor: 'bot',
		data,
	};
};

export const useParseStartApp = (actor: Actor) => {
	const data = parseStartParams();
	return data?.actor === actor ? data : undefined;
};

export const useParseBotStartRedirectParams = () => {
	const params = useParseStartApp('bot');
	const action = params?.data?.get('act');
	const toChain = params?.data?.get('toChain') ?? '';
	const fromChain = params?.data?.get('fromChain') ?? '';
	const tobiId = params?.data?.get('tobiId') ?? '';

	const toToken = params?.data?.get('toTok') ?? '';
	const fromToken = params?.data?.get('fromTok') ?? '';
	const query = params?.data?.get('q');
	const amount = params?.data?.get('am');
	const amountUsd = params?.data?.get('amu');

	const requestId = params?.data?.get('requestId');

	const invitationCode = params?.data?.get('invitationCode');
	const affiliateCode = params?.data?.get('affiliateCode');
	const data = params?.data ? Object.fromEntries(params?.data.entries()) : {};

	return {
		action,
		data,
		// todo split func
		query,
		fromToken,
		toToken,
		toChain,
		tobiId,
		fromChain,
		amount,
		amountUsd,
		requestId,
		invitationCode,
		affiliateCode,
	};
};

export const toStartParam = (payload: Record<string, any>) => {
	return btoa(new URLSearchParams(payload).toString());
};
