import { Address } from '@ton/core';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { Actions, useBotRedirect } from '@/app-features/app-bot-connector/hooks';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { ServiceUser, WalletType } from '@/app-cores/api';
import { MpcWallet, MpcWalletStatus } from '@/app-cores/mpc-wallet/wallet';
import { TC_KEY_SHARE_METADATA } from '@/app-cores/mpc-wallet/wallet/abstract-keyshare-manager';
import { TGCloudStorage } from '@/app-cores/mpc-wallet/wallet/tg-cloud-storage';
import { useParseBotStartRedirectParams } from '@/app-cores/telegram';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { useOnEventCallback } from '@/app-hooks/common';
import { MainRouter } from '@/app-router';
import { dataDogAddUserInfo } from '@/app-services/monitor/datadog';
import { useTransactionWatcherStore, useWalletStore } from '@/app-store';
import { useWatchTransactions } from '@/app-store/transaction-watcher/evmWatcher';
import { useMutationUpdateAffiliateCode } from '@/app-hooks/api/user/useMutationUpdateAffiliateCode';
import { useShowNotification } from '@/app-hooks/api/notification';

interface BackgroundAppProps {
	walletStatus: MpcWalletStatus;
	isWalletExisted: boolean;
}

export const BackgroundApp: React.FC<BackgroundAppProps> = ({ isWalletExisted, walletStatus }) => {
	const { data: userProfile } = useQueryUserProfile();
	useWatchTransactions(true);
	useShowNotification();

	const { action, affiliateCode } = useParseBotStartRedirectParams();
	const { mutateAsync: updateAffiliateCode } = useMutationUpdateAffiliateCode();
	const checkRedirect = useBotRedirect();

	useEffect(() => {
		if (!affiliateCode || !MpcWallet.getWalletAddress().evmAddress) return;
		updateAffiliateCode({
			affiliateCode: affiliateCode,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [affiliateCode]);

	useEffect(() => {
		useTransactionWatcherStore.persist.rehydrate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isEmpty(userProfile) || (userProfile && userProfile.tgBackupVer)) return;
		async function syncBackupTgCloud() {
			const metadata = await TGCloudStorage.getItem<string>(TC_KEY_SHARE_METADATA);
			if (isEmpty(metadata)) return;
			const { version } = JSON.parse(metadata);
			try {
				await ServiceUser.syncBackupTgCloud({
					tgBackupVer: version?.toString(),
				});
				console.log('backup tgCloud successfully');
			} catch (error) {
				console.error('backup tgCloud error', error);
			}
		}
		syncBackupTgCloud();
	}, [userProfile]);

	const { setIsNonWallet } = useWalletStore();

	const onRedirect = useOnEventCallback(() => {
		const userTonAddress = userProfile.wallets?.find((w) => w.type === WalletType.TON)?.formatedAddress;
		const localTonWallet = MpcWallet.getWalletAddress().tonAddress;
		const isMissingTonWallet =
			userTonAddress &&
			Address.parse(userTonAddress)
				.toString({
					bounceable: false,
				})
				?.toLowerCase() !== localTonWallet?.toLowerCase();

		const isNonWallet = walletStatus !== MpcWalletStatus.VALIDATED || !isWalletExisted || isMissingTonWallet;
		setIsNonWallet(isNonWallet);

		if (action) {
			checkRedirect(isNonWallet);
		} else if (isNonWallet) {
			MainRouter.navigate(NAVIGATE_PATHS.CreateWallet.Onboarding, {
				replace: true,
			});
		}
	});

	useEffect(() => {
		onRedirect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const wallet = MpcWallet.getWalletAddress();
		dataDogAddUserInfo({
			evm: wallet.evmAddress,
			sol: wallet.solAddress,
			ton: wallet.tonAddress,
		});
	}, []);

	return null;
};
