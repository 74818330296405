import { Toast } from '@/app-components/common';

import { UIEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { BalanceResponse, ITokenSearch } from '@/app-cores/api/bff';
import { useDebounce } from '@/app-hooks/common';
import { countDecimals, formatUnits } from '@/app-helpers/number';
import { CHAIN_CONFIG, NATIVE_TOKEN_ADDRESS } from '@/app-constants/chains';

import { NAVIGATE_PATHS } from '@/app-constants/router';

import {
	useValidateInput,
	isShowGasWarning,
	trackingSwapDebugData,
	SwapService,
	useGetSuggestedRoutes,
	useFetchRoute,
	getRouteKey,
	getSwapParams,
} from '@/app-hooks/swap';
import { toast } from 'react-toastify';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { compareTobiToken, compareToken, isNativeTobiToken, isNativeToken } from '@/app-helpers/address';
import { InputMode, SelectedRoute } from '@/app-store/swap';
import { PortfolioBalance, useTokenBalance } from '@/app-hooks/api/portfolio/usePortfolioBalance';

import Header from '@/app-views/swap/components/Header';

import { useAllowance } from '@/app-hooks/wallet';
import { useSwapStore } from '@/app-store/swap';

import { AmountPayload, useChangeInputAmount, useGetAllPriceSwap, useGetSwapParam } from '@/app-hooks/swap';

import { useNavigate } from 'react-router';

import SwapForm from '@/app-views/swap/components/SwapForm';
import {
	getTokenId,
	getTokenInfo,
	isChainNeedApprove,
	isEvmChain,
	isTestnetChain,
	tokenHasBalance,
} from '@/app-helpers/token';
import SwapAction from '@/app-views/swap/components/SwapAction';
import SelectTokenGroup, { SelectTokenGroupProps } from '@/app-views/swap/components/SelectTokenGroup';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import { useSearchParams } from 'react-router-dom';
import { CreateAccount } from '@/app-components/common/CreateAccount';
import { useUserSettingsStore } from '@/app-store/settings';
import { SwapErrorType, SwapType } from '@/app-hooks/swap/type';
import { calcAmount, getSwapType, tryParseAmount } from '@/app-hooks/swap/helper';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import TradeLayout from '@/app-views/swap/components/TradeLayout';
import { queryClient } from '@/app-cores/query-client';
import { QUERY_KEYS } from '@/app-constants';
import { populateBalance, useFetchTokensForSwap } from '@/app-helpers/navigate';
import { TradeTourGuide } from '@/app-views/wallet/components/TourGuide/TradeTourGuide';
import { TOUR_GUIDE_STEPS_TARGET } from '@/app-views/wallet/components/TourGuide/stepConfigs';
import { FeatureConfigWrap } from '@/app-helpers/components';
import { FeatureConfig } from '@/app-hooks/api/configuration';
import { tourGuideManager } from '@/app-views/wallet/components/TourGuide/helper';
import { useWalletStore } from '@/app-store';
import { bufferGas } from '@/app-hooks/transactions';

const Swap = ({ quickSwap, onBuildRouteSuccess }: { quickSwap?: boolean; onBuildRouteSuccess?: () => void }) => {
	const [openApprove, setOpenApprove] = useState(false);

	const {
		setSelectedRoute: setSelectedRouteGlobal,
		setTokenIn,
		tokenIn,
		tokenOut,
		setTokenOut,
		amount,
		amountUsd,
		setPair,
		inputMode,
		setInputMode,
		tokenInfoIn,
		tokenInfoOut,
	} = useSwapStore(quickSwap);

	const chainIdIn = tokenInfoIn?.chainId;

	const navigate = useNavigate();

	const swapType = getSwapType(tokenInfoIn, tokenInfoOut);
	const isWrapOrUnwrap = swapType === SwapType.WRAP_EVM || swapType === SwapType.UNWRAP_EVM;

	const { slippage } = useUserSettingsStore();

	const amountInDebounced = useDebounce(amount, 300);
	const parsedAmount = useMemo(() => {
		return tryParseAmount(amountInDebounced, tokenInfoIn?.decimals);
	}, [amountInDebounced, tokenInfoIn]);

	const isEmptyInputAmount = !amount || amount === '0';
	const DEFAULT_AMOUNT_EMPTY_INPUT = 100;

	const amountInDefault = tryParseAmount(
		calcAmount({ amountUsd: DEFAULT_AMOUNT_EMPTY_INPUT, token: tokenIn, formatUsd: false }).amount,
		tokenInfoIn?.decimals,
	)?.toString();

	const getRouteParams = useGetSwapParam({
		amountIn: isEmptyInputAmount ? amountInDefault : tryParseAmount(amount, tokenInfoIn?.decimals)?.toString(),
		amountInDefault,
		tokenIn: tokenInfoIn,
		tokenOut: tokenInfoOut,
		slippage,
	});

	const containerRef = useRef(null);
	const scrollToActiveRoute = () => {
		const container = containerRef.current;
		if (!container) return;
		container.scrollTo({ top: 0 });
	};

	const setSelectedRoute = (data: SelectedRoute) => {
		queryClient.setQueryData([QUERY_KEYS.GET_ROUTE_SWAP, getRouteKey(getRouteParams)], () => data);
	};

	const onClickRoute = (route: SelectedRoute) => {
		dataDogAddAction(DATADOG_ACTIONS.TRADE_SELECT_ROUTE);
		const { amountIn, tokenIn: newTokenIn, tokenOut } = SwapService.extractRouteInfo({ route });
		if (!newTokenIn || !tokenOut || buildingRoute) return;

		const payload = {
			token: newTokenIn as any,
			amount: formatUnits(amountIn, newTokenIn?.decimals, { withFormat: false }),
		};

		if (!route.disabled) {
			onChangeAmount(
				!tokenAmountMode && compareTobiToken(newTokenIn as any, tokenIn)
					? {
							...payload,
							amountUsd: !parsedAmount ? DEFAULT_AMOUNT_EMPTY_INPUT : amountUsd, // to show exact amount usd user input
					  }
					: payload,
			);
		}

		const setSelectedRoute = (data: SelectedRoute) => {
			const getRouteParams = getSwapParams({
				tokenIn: data.tokenIn,
				tokenOut: data.tokenOut,
				slippage,
				amountIn: SwapService.extractRouteInfo({ route: data }).amountIn,
			});
			queryClient.setQueryData([QUERY_KEYS.GET_ROUTE_SWAP, getRouteKey(getRouteParams)], () => data);
		};

		setSelectedRoute(route);
		scrollToActiveRoute();
		setPair(newTokenIn as any, tokenOut as any);
	};

	// change in list route same pair
	const onChangeRoute = (route: SelectedRoute) => {
		if (route.id === selectedRoute?.id) return;
		let newRoute;
		if (selectedRoute?.allRoutes?.some((e) => e.id === route.id)) {
			newRoute = SwapService.changeRoute(selectedRoute, route);
			setSelectedRoute(newRoute);
		} else {
			setSuggestRoutes(
				suggestRoutes.map((e) => {
					if (e?.allRoutes?.some((r) => r.id === route.id)) {
						newRoute = SwapService.changeRoute(e, route);
						return newRoute;
					}
					return e;
				}),
			);
		}
		onClickRoute(newRoute);
	};

	useEffect(() => {
		SwapService.initRoute();
	}, []);

	const lastRoute = useRef<SelectedRoute>();
	const {
		data: selectedRoute,
		isFetching: gettingRoute,
		error: getRouteError,
		refetch,
	} = useFetchRoute({
		getRouteParams,
		refetchInterval: openApprove ? undefined : 60_000,
		lastRoute: lastRoute.current,
	});
	lastRoute.current = selectedRoute;

	const { isFetching: fetchingSuggestRoute, data } = useGetSuggestedRoutes({ tokenOut });
	const [suggestRoutes, setSuggestRoutes] = useState<SelectedRoute[]>([]);

	useEffect(() => {
		setSuggestRoutes(data?.routes ?? []);
	}, [data]);

	const {
		data: allowance,
		isFetching: isFetchingAllowance,
		error,
	} = useAllowance({
		spenderContract: selectedRoute?.routerAddress,
		tokenContract: tokenInfoIn?.address,
		chainId: chainIdIn,
	});

	const [buildingRoute, setBuildingRoute] = useState(false);

	const { usdPriceNative, usdPriceTokenIn, usdPriceTokenOut } = useGetAllPriceSwap({
		tokenIn: tokenInfoIn,
		tokenOut: tokenInfoOut,
	});

	const { priceImpact, amountInUsd, gasUsd, gasNative } = useMemo(
		() =>
			SwapService.extractRouteInfo({
				route: selectedRoute,
				usdPriceIn: usdPriceTokenIn,
				usdPriceOut: usdPriceTokenOut,
			}),
		[selectedRoute, usdPriceTokenOut, usdPriceTokenIn],
	);

	const { data: nativeBalance } = useTokenBalance({ tokenAddress: NATIVE_TOKEN_ADDRESS, chainId: chainIdIn });
	const { data: tokenInBalance } = useTokenBalance({ tokenAddress: tokenInfoIn?.address, chainId: chainIdIn });

	const balanceNativeUsd = useMemo(() => {
		const { decimals, balance } = getTokenInfo(nativeBalance);
		return usdPriceNative * (balance ? +formatUnits(balance, decimals, { withFormat: false }) : 0);
	}, [usdPriceNative, nativeBalance]);

	const _onChangeAmount = useChangeInputAmount(quickSwap);
	const onChangeAmount = useCallback(
		(payload: AmountPayload) => {
			if (buildingRoute) return;
			_onChangeAmount(payload);
		},
		[_onChangeAmount, buildingRoute],
	);

	const emptyInput = !tokenIn || !tokenOut || !parsedAmount;

	const tokenAmountMode = inputMode === InputMode.AMOUNT;

	const tokenAmountInfo = {
		amountIn: tokenAmountMode ? amount : amountUsd,
		usdAmount: tokenAmountMode ? (gettingRoute || !parsedAmount ? undefined : amountInUsd) ?? amountUsd : amount,
	};

	const toggleInputMode = () => {
		setInputMode(tokenAmountMode ? InputMode.USD : InputMode.AMOUNT);
	};

	const testnetChain = [tokenInfoIn, tokenInfoOut].filter((e) => isTestnetChain(e?.chainId))?.[0]?.chainId;
	const selectToken = useFetchTokensForSwap(testnetChain);
	const onSelectTokenIn = useCallback(
		async (token: ITokenSearch) => {
			if (compareTobiToken(token, tokenOut)) return;
			setTokenIn(token);
			if (isTestnetChain(token) || (!isTestnetChain(token) && isTestnetChain(tokenOut))) {
				const { tokenOut } = await selectToken({ tokenIn: token });
				setTokenOut(tokenOut);
			}
			onChangeAmount(inputMode === InputMode.USD ? { amountUsd, token } : { amount, token });
			dataDogAddAction(DATADOG_ACTIONS.TRADE_SELECT_TOKEN_IN);
		},
		[setTokenIn, tokenOut, onChangeAmount, amount, amountUsd, inputMode, selectToken, setTokenOut],
	);

	const [searchParams] = useSearchParams();
	const toTokenQuery = searchParams.get('toToken');
	const { isNonWallet } = useWalletStore();

	const onSelectTokenOut = useCallback(
		async (token: ITokenSearch) => {
			if (compareTobiToken(token, tokenIn)) return;
			setTokenOut(token);
			if (isTestnetChain(token) || (!isTestnetChain(token) && isTestnetChain(tokenIn))) {
				const { tokenIn } = await selectToken({ tokenOut: token });
				setTokenIn(tokenIn);
			}
			if (toTokenQuery) navigate(NAVIGATE_PATHS.Swap.Main, { replace: true });
			dataDogAddAction(DATADOG_ACTIONS.TRADE_SELECT_TOKEN_OUT);
		},
		[setTokenOut, tokenIn, navigate, toTokenQuery, selectToken, setTokenIn],
	);

	const onMaxBalance = () => {
		if (buildingRoute || !tokenInBalance) return;
		const { balance = 0n, decimals, address } = getTokenInfo(tokenInBalance);

		const skipFeeGasFee = !isNativeToken(address);
		const gasFee = (skipFeeGasFee ? 0n : gasNative) || 0n;

		let availBalance = BigInt(balance) - bufferGas(gasFee);
		availBalance = availBalance > 0n ? availBalance : 0n;
		const result = formatUnits(availBalance.toString(), decimals, { withFormat: false });
		onChangeAmount({ amount: result, token: tokenIn, formatUsd: true });
	};

	const parseNativeBalance = useMemo(
		() => (nativeBalance ? BigInt(nativeBalance?.balance) : undefined),
		[nativeBalance],
	);

	const parseTokenInBalance = useMemo(
		() => (tokenInBalance ? BigInt(tokenInBalance?.balance) : undefined),
		[tokenInBalance],
	);

	const showGasWarning = useMemo(
		() =>
			isShowGasWarning({
				compareUsd: selectedRoute?.checkGasByUsd,
				parseNativeBalance,
				gasNative,
				gasUsd,
				balanceNativeUsd,
				tokenOut,
			}),
		[parseNativeBalance, gasNative, tokenOut, selectedRoute?.checkGasByUsd, gasUsd, balanceNativeUsd],
	);

	const errorMsg = useValidateInput({
		amountInUsd: tokenAmountMode ? amountInUsd : amountUsd,
		tokenIn: tokenInfoIn,
		parseTokenInBalance,
		parsedAmount,
		parseNativeBalance,
		priceImpact,
		swapType,
		quickSwap,
		showGasWarning,
		gettingRoute,
		amountInDebounced,
		getRouteError,
		usdPriceTokenIn,
		hasAlternativeRoutes: suggestRoutes.length > 0,
		route: selectedRoute,
	});

	const needApprove = useMemo(() => {
		try {
			if (
				errorMsg.hasError ||
				isWrapOrUnwrap ||
				!parseTokenInBalance ||
				!parsedAmount ||
				isFetchingAllowance ||
				!isChainNeedApprove(chainIdIn) ||
				isNativeTobiToken(tokenIn)
			)
				return false;
			return allowance < parsedAmount;
		} catch (error) {
			return false;
		}
	}, [
		allowance,
		parsedAmount,
		tokenIn,
		errorMsg,
		isWrapOrUnwrap,
		parseTokenInBalance,
		isFetchingAllowance,
		chainIdIn,
	]);

	const disabledSwap =
		emptyInput ||
		needApprove ||
		!selectedRoute ||
		!!selectedRoute?.disabled ||
		buildingRoute ||
		gettingRoute ||
		errorMsg.hasError ||
		isFetchingAllowance ||
		showGasWarning;

	const onClickSwap = async () => {
		if (buildingRoute || !selectedRoute) return;
		try {
			// incase portfolio slow
			const cached = queryClient.getQueryCache().find({
				queryKey: [QUERY_KEYS.GET_PORTFOLIO_BALANCE],
			});
			const response = cached?.state?.data as BalanceResponse;
			populateBalance({
				newTokenIn: selectedRoute.tokenIn as any,
				newTokenOut: selectedRoute.tokenOut as any,
				tokenBalances: response?.balances,
			});
			setBuildingRoute(true);
			const data = await SwapService.buildRoute({ route: selectedRoute, slippage, userAmount: amount });
			setSelectedRouteGlobal(data);
			trackingSwapDebugData(selectedRoute);
			if (quickSwap) onBuildRouteSuccess?.();
			else navigate(NAVIGATE_PATHS.Swap.ConfirmTransaction);
		} catch (e) {
			toast(<Toast type="error" message={`Failed to build route: ${parseErrorMessage(e)}`} />);
		} finally {
			setBuildingRoute(false);
		}
	};

	const onSwitchTokenClick = () => {
		if (buildingRoute) return;
		tokenOut &&
			onChangeAmount(inputMode === InputMode.USD ? { amountUsd, token: tokenOut } : { amount, token: tokenOut });
		setPair(tokenOut, tokenIn);
	};

	const lackOfFund = errorMsg?.messages?.some(
		(e) => e.errorType === SwapErrorType.FUND || e.errorType === SwapErrorType.GAS,
	);
	const showBtnApprove = !lackOfFund && selectedRoute && needApprove && !gettingRoute && !selectedRoute?.disabled;

	const [showHeaderShadow, setShowHeader] = useState(false);
	const onPageScroll: UIEventHandler<HTMLDivElement> = (e) => {
		const container = e.target as HTMLDivElement;
		setShowHeader(container.scrollTop !== 0);
	};

	const showApproveModal = () => {
		setOpenApprove(true);
	};
	const hideApproveModal = () => {
		setOpenApprove(false);
	};

	const renderSwapAction = () => (
		<Box
			style={{ borderTop: quickSwap ? undefined : '1px solid rgba(0, 0, 0, 0.08)' }}
			px={quickSwap ? 4 : undefined}
			pb={quickSwap ? 4 : undefined}
		>
			<SwapAction
				{...{
					showApproveModal,
					showBtnApprove,
					openApprove,
					disabledSwap,
					tokenIn: tokenInfoIn,
					tokenOut: tokenInfoOut,
					onClickSwap,
					quickSwap,
					isNonWallet,
					label: isFetchingAllowance
						? 'Checking allowance...'
						: gettingRoute
						? 'Getting route...'
						: buildingRoute
						? 'Processing...'
						: isWrapOrUnwrap
						? swapType
						: `Swap${tokenOut && !showBtnApprove ? ` to ${tokenInfoOut.symbol}` : ``}`,
					swapButtonId: TOUR_GUIDE_STEPS_TARGET.TRADE.SWAP,
					buyButtonId: TOUR_GUIDE_STEPS_TARGET.TRADE.BUY,
				}}
			/>
			<CreateAccount {...{ bottom: '15vh' }} />
		</Box>
	);

	const renderSwapForm = () => (
		<SwapForm
			{...{
				tokenIn,
				tokenOut,
				tokenInfoIn,
				tokenInfoOut,
				amount,
				tokenAmountInfo,
				inputMode,
				toggleInputMode,
				onMax: onMaxBalance,
				usdPriceTokenIn,
				errorMsg,
				onChangeAmount: (values, source) => {
					if (source.source === 'prop') return;
					onChangeAmount({
						[inputMode === InputMode.USD ? 'amountUsd' : 'amount']: values.value,
						token: tokenIn,
					});
				},
				openApprove,
				gettingRoute,
				refetch,
				fetchingSuggestRoute,
				onChangeRoute,
				onClickRoute,
				suggestRoutes,
				selectedRoute,
				hideApproveModal,
				parsedAmount,
				quickSwap,
				isNonWallet,
				containerProps: {
					style: quickSwap ? { overflow: 'auto' } : { paddingBottom: '20px' },
					className: quickSwap ? 'hide-scrollbar' : undefined,
					onScroll: quickSwap ? onPageScroll : undefined,
					p: quickSwap ? 4 : undefined,
				},
			}}
		/>
	);

	const headerProps = {
		showHeaderShadow,
		onSelectTokenIn,
		onSelectTokenOut,
		onSwitchClick: onSwitchTokenClick,
		tokenIn,
		tokenOut,
		labelIn: 'Swap from',
		labelOut: 'Swap to',
		filterChainId: testnetChain,
	};

	if (quickSwap)
		return (
			<PageTransition
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					gap: '16px',
					flex: 1,
					maxHeight: '100%',
					overflow: 'hidden',
					paddingTop: '12px',
				}}
			>
				<SelectTokenGroup
					{...headerProps}
					style={{
						...(showHeaderShadow
							? { boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.08)`, paddingBottom: '8px' }
							: {}),
					}}
				/>
				{renderSwapForm()}
				{renderSwapAction()}
			</PageTransition>
		);

	return (
		<TradeLayout
			containerRef={containerRef}
			onPageScroll={onPageScroll}
			header={<Header {...headerProps} />}
			action={renderSwapAction()}
		>
			{renderSwapForm()}
			{tourGuideManager.isEnableTradePage() && (
				<FeatureConfigWrap feature={FeatureConfig.TOBI_TRADE_WORK_THROUGH}>
					<TradeTourGuide />
				</FeatureConfigWrap>
			)}
		</TradeLayout>
	);
};
export default Swap;
