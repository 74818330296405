import { Actions } from '@/app-features/app-bot-connector/hooks';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { useParseBotStartRedirectParams } from '@/app-cores/telegram';
import { FiHomeIcon } from '@/assets/icons';
import { Box, BoxProps, Button, Center, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router';

interface ButtonHomeProps extends BoxProps {
	showByAction?: boolean;
}
export const ButtonHome: React.FC<ButtonHomeProps> = ({ showByAction = false, children, background, ...rest }) => {
	const navigate = useNavigate();
	const { action } = useParseBotStartRedirectParams();
	const localWallet = MpcWallet.getWalletAddress().evmAddress;
	if (showByAction && (![Actions.SWAP, Actions.TOKEN_DETAIL].includes(action) || !localWallet)) {
		return children || null;
	}
	return (
		<Center gap={2} {...rest}>
			<Button width={10} background={background} height={10} onClick={() => navigate('/')}>
				<Box>
					<FiHomeIcon />
				</Box>
			</Button>
			{children && <Text color="rgba(0, 0, 0, 0.16)">/</Text>}
			{children}
		</Center>
	);
};
