import { useMutation } from '@tanstack/react-query';
import { AddRemoveTokenPayload, ServiceToken } from '../../../app-cores/api/bot/service-token';
import { queryClient } from '@/app-cores/query-client';
import { QUERY_KEYS } from '@/app-constants';

const onSuccess = () => {
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEYS.GET_USER_SETTING_TOKEN],
	});
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEYS.GET_PORTFOLIO_BALANCE],
	});
};

export const useMutationAddTokens = () => {
	const response = useMutation({
		mutationKey: ['mutation-add-user-tokens'],
		mutationFn: (payload: AddRemoveTokenPayload) => ServiceToken.addTokens(payload),
		onSuccess,
	});
	return response;
};

export const useMutationRemoveTokens = () => {
	const response = useMutation({
		mutationKey: ['mutation-remove-user-tokens'],
		mutationFn: (payload: AddRemoveTokenPayload) => ServiceToken.removeTokens(payload),
		onSuccess,
	});
	return response;
};
