import React from 'react';

interface IProps extends React.SVGAttributes<HTMLOrSVGElement> {}

export const FiRightIcon: React.FC<IProps> = ({ width = 16, height = 16, ...rest }) => {
	return (
		<svg width={width} height={height} {...rest} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.52876 4.02876C5.78911 3.76841 6.21122 3.76841 6.47157 4.02876L10.4716 8.02876C10.7319 8.28911 10.7319 8.71122 10.4716 8.97157L6.47157 12.9716C6.21122 13.2319 5.78911 13.2319 5.52876 12.9716C5.26841 12.7112 5.26841 12.2891 5.52876 12.0288L9.05735 8.50016L5.52876 4.97157C5.26841 4.71122 5.26841 4.28911 5.52876 4.02876Z"
				fill="black"
			/>
		</svg>
	);
};
