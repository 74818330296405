import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import WellCome from './pages/WellCome/WellCome';
import Keygen from './pages/Keygen/Keygen';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useOnEventCallback } from '@/app-hooks/common';
import { useBotRedirect } from '@/app-features/app-bot-connector/hooks/useBotRedirect';
import { useParseBotStartRedirectParams } from '@/app-cores/telegram/useStartAppParam';

type Page = 'WellCome' | 'Keygen';
const OnBoarding = () => {
	const [searchParams] = useSearchParams();
	const fromBotRedirect = searchParams.get('fromBotRedirect') == 'true';
	const { invitationCode } = useParseBotStartRedirectParams();

	const checkRedirect = useBotRedirect();
	const navigate = useNavigate();
	const [page, setPage] = useState<Page>('WellCome');

	const onConfirmButtonClick = useOnEventCallback((type: 'create' | 'import') => {
		if (type === 'create') {
			setPage('Keygen');
		} else if (type === 'import') {
			navigate(NAVIGATE_PATHS.Recovery.SelectMethod);
		}
	});

	const renderPage = () => {
		if (page === 'WellCome')
			return (
				<WellCome
					fromBotRedirect={fromBotRedirect}
					invitationCode={invitationCode}
					onClick={onConfirmButtonClick}
				/>
			);
		if (page === 'Keygen')
			return (
				<Keygen
					onSuccess={() => {
						if (fromBotRedirect) {
							checkRedirect(true);
						} else {
							navigate(NAVIGATE_PATHS.CreateWallet.StoreBackupKey);
						}
					}}
					onFailed={() => setPage('WellCome')}
				/>
			);
	};

	return renderPage();
};
export default OnBoarding;
