import { TransactionType } from '@/app-types';
import { TransactionResponse } from 'ethers';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { submitMetadataTxs } from './evmWatcher';
import { ONE_DAY } from '../../app-hooks/api/portfolio/constant';
import { IActivity, TxStatus } from '@/app-cores/api/activities';
import { waitForTonTransaction } from '@/app-store/transaction-watcher/tonWatcher';
import { ChainType } from '@/app-contexts/wallet-provider/type';
import { STORAGE_KEYS } from '@/app-constants/storage';

export type TransactionCallback = (receipt: { status: TxStatus; msg?: string }) => void;

export type MetadataEvm = TransactionResponse & {
	transactionType?: TransactionType;
	time?: number;
	activity?: IActivity; // to show in ui
};

export type TransactionWatcher<T extends MetadataEvm | IActivity> = {
	callbackFns: TransactionCallback[];
	metadata: T;
};

type PendingPayload = {
	hash: string;
	callback?: TransactionCallback;
	transaction?: IActivity;
	trackingData?: any;
};

export interface ITransactionWatcherStore {
	metadata: Record<string, any>; // hash => metadata (only for sol to tcat)
	saveMetadata: (hash: string, metadata: Record<string, any>) => void;

	pendingEvmTransactions: Record<string, TransactionWatcher<MetadataEvm>>;
	addPendingEvmTransaction: (data: {
		transaction: TransactionResponse;
		callback?: TransactionCallback;
		metadata: Partial<MetadataEvm>;
		trackingData?: any;
	}) => void;

	pendingTonTransactions: Record<string /** seqno */, TransactionWatcher<IActivity>>;
	addPendingTonTransaction: (data: {
		seqno: number | string;
		callback?: TransactionCallback;
		transaction?: IActivity;
		trackingData?: any;
	}) => void;

	pendingSolTransactions: Record<string, TransactionWatcher<IActivity>>;
	addPendingSolTransaction: (data: PendingPayload) => void;

	pendingTronTransactions: Record<string, TransactionWatcher<IActivity>>;
	addPendingTronTransaction: (data: PendingPayload) => void;

	removePendingTransaction: (data: { hash: string | number; type: ChainType }) => void;
}

const convertBigIntField = <T>(obj: T, level = 1): T => {
	if (obj === null || typeof obj !== 'object' || level >= 2) return obj;

	return Object.keys(obj || {}).reduce((rs, key) => {
		const value = obj[key];

		if (typeof value === 'bigint') {
			rs[key] = value.toString();
		} else if (typeof value === 'object' && value !== null) {
			rs[key] = Array.isArray(value)
				? value.map((item) => convertBigIntField(item, level + 1))
				: convertBigIntField(value, level + 1);
		} else {
			rs[key] = value;
		}

		return rs;
	}, {} as T);
};

export const useTransactionWatcherStore = create<ITransactionWatcherStore>()(
	persist(
		(set) => ({
			metadata: {},
			saveMetadata(hash, metadata) {
				set((state) => {
					return { metadata: { ...state.metadata, [hash]: { ...metadata, hash } } };
				});
			},
			pendingEvmTransactions: {},
			pendingTonTransactions: {},
			pendingSolTransactions: {},
			pendingTronTransactions: {},
			addPendingEvmTransaction: ({
				transaction: txResponse,
				callback,
				metadata: { transactionType, ...extra } = {} as MetadataEvm,
				trackingData,
			}) => {
				return set((state) => {
					if (!txResponse) {
						return {};
					}

					const { hash, nonce } = txResponse;
					const exitsInfo = state.pendingEvmTransactions[hash];

					const transactions = { ...state.pendingEvmTransactions };
					transactions[hash] = {
						metadata: convertBigIntField<MetadataEvm>({
							...exitsInfo?.metadata,
							...(txResponse?.toJSON?.() || txResponse),
							...extra,
							time: exitsInfo?.metadata.time || (Date.now() / 1000) | 0,
							transactionType: transactionType || exitsInfo?.metadata?.transactionType,
							nonce: nonce || exitsInfo?.metadata.nonce,
						}),
						callbackFns: [].concat(exitsInfo?.callbackFns || []).concat(callback || []),
					};

					if (!exitsInfo) {
						submitMetadataTxs({
							transaction: transactions[hash].metadata,
							type: ChainType.EVM,
							trackingData,
						});
					}

					return { pendingEvmTransactions: transactions };
				});
			},

			addPendingTonTransaction: ({ seqno, callback, transaction: metadata, trackingData }) =>
				set((state) => {
					const existInfo = state.pendingTonTransactions[seqno];
					const pendingTonTransaction = { ...state.pendingTonTransactions };

					pendingTonTransaction[seqno] = {
						metadata: {
							...(existInfo?.metadata || {}),
							...metadata,
							time: existInfo?.metadata?.time || metadata?.time || (Date.now() / 1000) | 0,
						},
						callbackFns: [].concat(existInfo?.callbackFns || []).concat(callback || []),
					};

					if (!existInfo) {
						waitForTonTransaction(seqno, () => {
							submitMetadataTxs({ transaction: metadata, type: ChainType.TON, trackingData });
						});
					}

					return { pendingTonTransactions: pendingTonTransaction };
				}),

			addPendingSolTransaction: ({ hash, callback, transaction: metadata = {} as IActivity, trackingData }) =>
				set((state) => {
					const existInfo = state.pendingSolTransactions[hash];

					const pendingSolTransactions = { ...state.pendingSolTransactions };

					pendingSolTransactions[hash] = {
						metadata: {
							hash,
							...existInfo?.metadata,
							...convertBigIntField(metadata),
							time: existInfo?.metadata?.time || metadata?.time || (Date.now() / 1000) | 0,
						},
						callbackFns: [].concat(existInfo?.callbackFns || []).concat(callback || []),
					};

					if (!existInfo) {
						submitMetadataTxs({
							transaction: pendingSolTransactions[hash].metadata,
							type: ChainType.SOLANA,
							trackingData,
						});
					}
					return { pendingSolTransactions };
				}),
			addPendingTronTransaction: ({ hash, callback, transaction: metadata = {} as IActivity, trackingData }) =>
				set((state) => {
					const existInfo = state.pendingTronTransactions[hash];

					const pendingTronTransactions = { ...state.pendingTronTransactions };

					pendingTronTransactions[hash] = {
						metadata: {
							hash,
							...existInfo?.metadata,
							...convertBigIntField(metadata),
							time: existInfo?.metadata?.time || (Date.now() / 1000) | 0,
						},
						callbackFns: [].concat(existInfo?.callbackFns || []).concat(callback || []),
					};

					if (!existInfo) {
						submitMetadataTxs({
							transaction: pendingTronTransactions[hash].metadata,
							type: ChainType.TRON,
							trackingData,
						});
					}
					return { pendingTronTransactions };
				}),
			removePendingTransaction: ({ hash: txHash, type }) =>
				set((state) => {
					switch (type) {
						case ChainType.SOLANA: {
							const { [txHash]: pendingTx, ...pendingSolTransactions } = state.pendingSolTransactions;
							return { pendingSolTransactions };
						}
						case ChainType.EVM: {
							const { [txHash]: pendingTx, ...pendingEvmTransactions } = state.pendingEvmTransactions;
							return { pendingEvmTransactions };
						}
						case ChainType.TRON: {
							const { [txHash]: pendingTx, ...pendingTronTransactions } = state.pendingTronTransactions;
							return { pendingTronTransactions };
						}
						case ChainType.TON: {
							const pendingTonTransactions = Object.fromEntries(
								Object.entries(state.pendingTonTransactions).filter((item) => item[0] > txHash),
							);
							return { pendingTonTransactions };
						}
						default:
							return state;
					}
				}),
		}),

		{
			name: STORAGE_KEYS.TOBI_TRANSACTION_WATCHER_STORAGE,
			storage: createJSONStorage(() => localStorage),
			skipHydration: true,
			onRehydrateStorage: () => {
				return (state, error) => {
					if (error) return;
					Object.entries(state.pendingEvmTransactions || {}).forEach(
						([
							hash,
							{
								metadata: { transactionType, time, ...receipt },
							},
						]) => {
							state.removePendingTransaction({ hash, type: ChainType.EVM });

							if (!time || Date.now() - time * 1000 < ONE_DAY) {
								state.addPendingEvmTransaction({
									transaction: receipt as TransactionResponse,
									metadata: { transactionType },
								});
							}
						},
					);

					Object.entries(state.pendingTonTransactions || {}).forEach(([hash, { metadata }]) => {
						state.removePendingTransaction({ hash, type: ChainType.TON });

						if (!metadata.time || Date.now() - metadata.time * 1000 < ONE_DAY) {
							state.addPendingTonTransaction({
								seqno: hash,
								transaction: metadata,
							});
						}
					});

					Object.entries(state.pendingSolTransactions || {}).forEach(([hash, { metadata }]) => {
						state.removePendingTransaction({ hash, type: ChainType.SOLANA });

						if (!metadata.time || Date.now() - metadata.time * 1000 < ONE_DAY) {
							state.addPendingSolTransaction({ hash, transaction: metadata });
						}
					});

					Object.entries(state.pendingTronTransactions || {}).forEach(([hash, { metadata }]) => {
						state.removePendingTransaction({ hash, type: ChainType.TRON });

						if (!metadata.time || Date.now() - metadata.time * 1000 < ONE_DAY) {
							state.addPendingTronTransaction({ hash, transaction: metadata });
						}
					});
				};
			},
		},
	),
);

export const usePendingEvmTransaction = (hash) =>
	useTransactionWatcherStore((state) => state.pendingEvmTransactions[hash]?.metadata);

export const usePendingTransaction = () =>
	useTransactionWatcherStore((state) => ({
		evm: state.pendingEvmTransactions,
		ton: state.pendingTonTransactions,
		sol: state.pendingSolTransactions,
		tron: state.pendingTronTransactions,
	}));
