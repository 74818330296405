import {
	PublicKey,
	Connection,
	clusterApiUrl,
	ConnectionConfig,
	Transaction,
	VersionedTransaction,
	BlockhashWithExpiryBlockHeight,
	VersionedTransactionResponse,
	TransactionExpiredBlockheightExceededError,
} from '@solana/web3.js';
import { MpcWallet } from '../wallet';
import { solana } from '@/app-constants/chains';
import bs58 from 'bs58';

import { delay, intervalCheck } from '@/app-helpers';
import { TSubmitSolTransaction } from '@/app-types';
import { TxStatus } from '@/app-cores/api/activities';

type TransactionSenderAndConfirmationWaiterArgs = {
	connection: Connection;
	serializedTransaction: Buffer;
	blockhashWithExpiryBlockHeight: BlockhashWithExpiryBlockHeight;
};
const SEND_OPTIONS = {
	skipPreflight: true,
};
// https://station.jup.ag/docs/apis/swap-api#:~:text=SOLANA%20NETWORK%20CONGESTION
export async function transactionSenderAndConfirmationWaiter({
	connection,
	serializedTransaction,
	blockhashWithExpiryBlockHeight,
}: TransactionSenderAndConfirmationWaiterArgs) {
	const txid = await connection.sendRawTransaction(serializedTransaction, SEND_OPTIONS);

	const controller = new AbortController();
	const abortSignal = controller.signal;

	const watchTxs = async (callback?: (status: TxStatus) => void) => {
		try {
			const lastValidBlockHeight = blockhashWithExpiryBlockHeight.lastValidBlockHeight - 150;
			await Promise.race([
				connection.confirmTransaction(
					{
						...blockhashWithExpiryBlockHeight,
						lastValidBlockHeight,
						signature: txid,
						abortSignal,
					},
					'confirmed',
				),
				new Promise(async (resolve) => {
					// in case ws socket died
					while (!abortSignal.aborted) {
						await delay(2_000);
						const tx = await connection.getSignatureStatus(txid, {
							searchTransactionHistory: false,
						});
						if (tx?.value?.confirmationStatus === 'confirmed') {
							resolve(tx);
						}
					}
				}),
			]);
			callback?.(TxStatus.Success);
		} catch (e) {
			console.log('error', e);
			callback?.(TxStatus.Failed);
		} finally {
			controller.abort();
		}
	};

	const abortableResender = () =>
		intervalCheck(2000, async () => {
			if (abortSignal.aborted) return true;
			try {
				await connection.sendRawTransaction(serializedTransaction, SEND_OPTIONS);
			} catch (e) {
				console.warn(`Failed to resend transaction: ${e}`);
			}
			return false;
		});

	abortableResender();
	return { hash: txid, watchTxs };
}
export class SolWallet {
	static async init(network: 'devnet' | 'testnet' | 'mainnet-beta', commitmentOrConfig: ConnectionConfig) {
		const fromPubKey = new PublicKey(MpcWallet.getSolanaWalletAddress());
		const rpcUrl = network === 'mainnet-beta' ? solana.rpcUrls.default.http : clusterApiUrl(network);
		const wssUrl = network === 'mainnet-beta' ? solana.rpcUrls.default.wss : clusterApiUrl(network);
		const connection = new Connection(rpcUrl, {
			...commitmentOrConfig,
			wsEndpoint: wssUrl,
		});
		const blockHash = (await connection.getLatestBlockhash('finalized')).blockhash;
		return { fromPubKey, connection, blockHash };
	}

	static async sendTransaction(params: TSubmitSolTransaction, callback?: (status: TxStatus) => void) {
		console.log('submit-sol-txs', params);
		const { data, signer } = params;
		let lastValidBlockHeight = params.lastValidBlockHeight;

		const { connection, fromPubKey } = await SolWallet.init('mainnet-beta', {
			commitment: 'confirmed',
		});
		const transaction = VersionedTransaction.deserialize(data);
		let blockHash = transaction.message.recentBlockhash;

		if (!lastValidBlockHeight) {
			const data = await connection.getLatestBlockhash();
			transaction.message.recentBlockhash = data.blockhash;
			blockHash = data.blockhash;
			lastValidBlockHeight = data.lastValidBlockHeight;
			if (signer) transaction.sign([signer]);
		}

		transaction.addSignature(
			fromPubKey,
			await MpcWallet.signEddsaMessage(Buffer.from(transaction.message.serialize())),
		);

		const { hash, watchTxs } = await transactionSenderAndConfirmationWaiter({
			connection,
			serializedTransaction: transaction.serialize() as any,
			blockhashWithExpiryBlockHeight: {
				blockhash: blockHash,
				lastValidBlockHeight,
			},
		});
		watchTxs(callback);
		return hash;
	}
}
