import { Token } from '@/app-cores/api';
import { useUserTokenSettings } from '@/app-hooks/api/tokens/useUserTokenSettings';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import sumBy from 'lodash/sumBy';
import { useMemo } from 'react';
import { BalanceResponse, BffServiceAPI, ITokenSearch } from '../../../app-cores/api/bff';
import { ONE_MINUTE } from './constant';
import { QUERY_KEYS } from '@/app-constants';
import { compareAddress, compareChain, compareTobiToken, compareToken, isNativeToken } from '@/app-helpers/address';
import { ChainId, NATIVE_TOKEN_ADDRESS, TOBI_CHAIN_ID_MAP, TOBI_CHAIN_NAME } from '@/app-constants/chains';
import { getTokenInfo, isTestnetChain, tokenHasBalance } from '@/app-helpers/token';
import useChainList from '@/app-hooks/wallet/useChainList';
import { formatUnits } from '@/app-helpers/number';
import { getEnvironment } from '@/app-helpers';

const getSketeton = ({ tobiId, chainId }): ITokenSearch => {
	return {
		'tobiId': tobiId,
		'chainId': chainId,
		balance: '0',
		'tokenMutableDataDto': {
			'name': tobiId || `Unknown`,
			'idTobi': tobiId,
			'isScam': false,
			'platforms': {
				[chainId]: '0x',
			},
		},
		'tokenImmutableDataDto': {
			'idTobi': tobiId,
			'symbol': '??',
			'decimals': 18,
		},
		'tokenMarketDataDto': {},
	} as any;
};

// all tokens
export type PortfolioBalance = {
	tokenBalances: ITokenSearch[];
	totalUsd: number;
	totalUsd24hChangeUsd: number;
	totalUsd24hChangePercent: number;
};

export const usePortfolioBalance = (options?: Partial<UseQueryOptions<BalanceResponse>>) => {
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_PORTFOLIO_BALANCE],
		queryFn: async () => {
			return BffServiceAPI.getPortfolioBalance();
		},
		gcTime: ONE_MINUTE * 10,
		staleTime: ONE_MINUTE * 10,
		refetchInterval: 20_000,
		retry: false,
		...options,
	});

	const { chainsSupport } = useChainList();

	const data: PortfolioBalance = useMemo(() => {
		if (!response.data) return undefined;

		const wrongTokens: any[] = response.data?.notFoundTokens || [];
		const tokenBalances: ITokenSearch[] = response.data?.balances
			.filter((e) => {
				const value = chainsSupport.some((chain) =>
					getTokenInfo(e).chainTokens.some((token) => compareChain(token.chainId, chain.id)),
				);
				if (!value) wrongTokens.push(e);
				return value;
			})
			.map((item) => {
				const { priceUsd, decimals } = getTokenInfo(item);
				const usdValue = +(formatUnits(item.balance ?? '0', decimals, { withFormat: false }) || 0) * priceUsd;
				return { ...item, usdValue };
			})
			.sort((a, b) => b.usdValue - a.usdValue);

		if (wrongTokens.length) {
			getEnvironment('dev') && console.log({ wrongTokens });
			wrongTokens.forEach((item) => {
				const { chainId, idTobi } = getTokenInfo(item);
				tokenBalances.push(getSketeton({ tobiId: idTobi, chainId: chainId }));
			});
		}

		const totalUsd = sumBy(tokenBalances, (item) =>
			isTestnetChain(getTokenInfo(item).chainId) ? 0 : item.usdValue,
		);

		const totalUsd24hChangeUsd = sumBy(tokenBalances, (item: ITokenSearch) => {
			const { percentChange24h, chainId } = getTokenInfo(item);
			return isTestnetChain(chainId) ? 0 : ((percentChange24h || 0) / 100) * item.usdValue;
		});

		const totalUsd24hChangePercent =
			totalUsd - totalUsd24hChangeUsd ? (totalUsd24hChangeUsd * 100) / (totalUsd - totalUsd24hChangeUsd) || 0 : 0;

		return {
			tokenBalances,
			totalUsd,
			totalUsd24hChangeUsd,
			totalUsd24hChangePercent,
		};
	}, [response.data, chainsSupport]);

	return { ...response, data };
};

// token by list, filter out token spam/hidden
export const usePortfolioBalanceByCategories = (options?: Partial<UseQueryOptions<BalanceResponse>>) => {
	const { data, isFetching: isFetchingBalance, isFetched, ...rest } = usePortfolioBalance(options);
	const { data: tokenData, isFetching } = useUserTokenSettings();

	const filterBalances = useMemo(() => {
		const balances = data?.tokenBalances || [];

		const filterTokens = (list: Token[] | undefined = []) => {
			return balances.filter((e) => list.some((token) => compareToken(getTokenInfo(e), token)));
		};

		const testnetTokens = balances.filter((e) => isTestnetChain(getTokenInfo(e).chainId));
		const importTokens = filterTokens(tokenData?.includedTokens);
		const spamTokens = balances.filter((e) =>
			tokenData?.spamTokens?.some((token) => compareToken(token, getTokenInfo(e))),
		);
		const hiddenTokens = filterTokens(tokenData?.excludedTokens);

		const mainTokens = balances.filter(
			(e) =>
				!hiddenTokens.some((token) => compareTobiToken(e, token)) &&
				!spamTokens.some((token) => compareTobiToken(e, token)),
		);

		return {
			allTokens: balances, // all from BE
			mainTokens: mainTokens.filter((e) => !testnetTokens.some((token) => compareTobiToken(e, token))), // all - spam - hidden - tesnet
			mainTokensHasBalance: mainTokens.filter((e) => tokenHasBalance(e)),
			importTokens,
			hiddenTokens,
			spamTokens,
			testnetTokens,
		};
	}, [tokenData, data]);

	return {
		...rest,
		data: filterBalances,
		isFetching: isFetchingBalance || isFetching,
		isFetched: isFetched && !!data,
	};
};

const maxBalanceFn = (rs, token) =>
	!rs || +token.balanceFormatted * token?.usdPrice > +rs?.balanceFormatted * rs?.usdPrice ? token : rs;
export const useTokenMaxBalancePortfolio = () => {
	const {
		data: { mainTokensHasBalance },
	} = usePortfolioBalanceByCategories();

	return useMemo(() => {
		return mainTokensHasBalance?.filter((e) => !isTestnetChain(getTokenInfo(e).chainId)).reduce(maxBalanceFn, null);
	}, [mainTokensHasBalance]);
};

export const useTokensBalance = (tobiId: string, tokenInfo: ITokenSearch | undefined) => {
	const { data: balance, ...rest } = usePortfolioBalance();
	const data: ITokenSearch[] = useMemo(() => {
		return balance?.tokenBalances?.filter((token) => {
			const info = getTokenInfo(token);
			return (
				(tobiId && info.idTobi === tobiId) ||
				getTokenInfo(tokenInfo).chainTokens.some(
					({ address }) => !isNativeToken(address) && compareAddress(info.address, address),
				)
			);
		});
	}, [balance, tobiId, tokenInfo]);
	return { ...rest, data };
};

// single token
export const useTokenBalance = ({
	tokenAddress,
	chainId,
	tobiId,
}: {
	tokenAddress: string;
	chainId: ChainId | string;
	tobiId?: string;
}) => {
	const { data: balance, ...rest } = usePortfolioBalance();
	const data: ITokenSearch = useMemo(() => {
		return balance?.tokenBalances?.find((token) => {
			const info = getTokenInfo(token);
			const value = tobiId ? info.idTobi === tobiId : true;
			const valueAddress = tokenAddress
				? compareAddress(info.address, isNativeToken(tokenAddress) ? NATIVE_TOKEN_ADDRESS : tokenAddress)
				: true;
			return value && valueAddress && compareChain(chainId, info.chainId);
		});
	}, [balance, chainId, tokenAddress, tobiId]);

	return { ...rest, data };
};

export const usePortfolioChart = (options?: Partial<UseQueryOptions<any>>) => {
	const response = useQuery({
		queryKey: ['portfolio-chart'],
		queryFn: () => BffServiceAPI.getPortfolioChart(),
		gcTime: ONE_MINUTE * 5,
		staleTime: ONE_MINUTE * 5,
		...options,
	});
	return response;
};
