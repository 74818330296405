import { STORAGE_KEYS } from '@/app-constants/storage';
import { ConnectEventSuccess, ConnectEventError } from '@tonconnect/protocol';
import { ReturnStrategy } from '@tonconnect/ui-react';
export declare enum CONNECT_ITEM_ERROR_CODES {
	UNKNOWN_ERROR = 0,
	METHOD_NOT_SUPPORTED = 400,
}
export const MNEMONIC =
	'life height supreme huge pass pigeon brass half urge small unfold stem speak soap exhibit situate file isolate joke borrow borrow clay dry market';
export declare enum WalletVersion {
	V3R1 = 0,
	V3R2 = 1,
	V4R1 = 2,
	V4R2 = 3,
	W5 = 4,
}
export enum SIGN_DATA_ERROR_CODES {
	UNKNOWN_ERROR = 0,
	BAD_REQUEST_ERROR = 1,
	UNKNOWN_APP_ERROR = 100,
	USER_REJECTS_ERROR = 300,
	METHOD_NOT_SUPPORTED = 400,
}
export enum DISCONNECT_ERROR_CODES {
	UNKNOWN_ERROR = 0,
	BAD_REQUEST_ERROR = 1,
	UNKNOWN_APP_ERROR = 100,
	METHOD_NOT_SUPPORTED = 400,
}
export const TON_CONNECT_KEY = {
	CONNECTIONS: STORAGE_KEYS.TOBI_T_APP_CONNECTIONS,
	LAST_EVENT_ID: STORAGE_KEYS.TOBI_TON_CONNECT_LAST_EVENT_ID,
};
export interface TonAddressItem {
	name: 'ton_addr';
}
export interface TonProofItem {
	name: 'ton_proof';
	payload: string;
}
export type ConnectItem = TonAddressItem | TonProofItem;

export interface ConnectRequest {
	manifestUrl: string;
	items: ConnectItem[];
}
export interface KeyPair {
	publicKey: string;
	secretKey: string;
}

export interface TonConnectParams {
	protocolVersion: number;
	request: ConnectRequest;
	clientSessionId: string;
	sessionKeyPair: KeyPair;
	platform?: string;
	returnStrategy: ReturnStrategy;
}
export interface DAppManifest {
	url: string;
	name: string;
	iconUrl: string;
	termsOfUseUrl?: string;
	privacyPolicyUrl?: string;
}
export interface TonAddressItemReply {
	name: 'ton_addr';
	address: string;
	network: string;
	walletStateInit: string;
	publicKey: string;
}
export type ConnectItemReplyError<T> = {
	name: T;
	error: {
		code: CONNECT_ITEM_ERROR_CODES;
		message?: string;
	};
};
export type TonProofItemReplyError = ConnectItemReplyError<TonProofItemReplySuccess['name']>;
export interface TonProofItemReplySuccess {
	name: 'ton_proof';
	proof: {
		timestamp: number;
		domain: {
			lengthBytes: number;
			value: string;
		};
		payload: string;
		signature: string;
	};
}
export type TonProofItemReply = TonProofItemReplySuccess | TonProofItemReplyError;

export type ConnectItemReply = TonAddressItemReply | TonProofItemReply;
export interface WalletAddress {
	friendlyAddress: string;
	rawAddress: string;
	version: WalletVersion;
}
export type ConnectEvent = ConnectEventSuccess | ConnectEventError;

export interface DeviceInfo {
	platform: 'iphone' | 'ipad' | 'android' | 'windows' | 'mac' | 'linux' | 'browser';
	appName: string;
	appVersion: string;
	maxProtocolVersion: number;
	features: Feature[];
}
export type SendTransactionFeatureDeprecated = 'SendTransaction';
export type SendTransactionFeature = {
	name: 'SendTransaction';
	maxMessages: number;
};
export type SignDataFeature = {
	name: 'SignData';
};
export type Feature = SendTransactionFeatureDeprecated | SendTransactionFeature | SignDataFeature;
export interface TAppConnection {
	manifest: DAppManifest;
	sessionKeyPair: KeyPair;
	clientSessionId: string;
}

export interface TonConnectRequest {
	from: string;
	message: string;
}

export interface SendTransactionRpcRequest {
	method: 'sendTransaction';
	params: [string];
	id: string;
}

export interface SignDataRpcRequest {
	method: 'signData';
	params: [
		{
			schema_crc: number;
			cell: string;
		},
	];
	id: string;
}
export interface DisconnectRpcRequest {
	method: 'disconnect';
	params: [];
	id: string;
}
export type RpcRequests = {
	sendTransaction: SendTransactionRpcRequest;
	signData: SignDataRpcRequest;
	disconnect: DisconnectRpcRequest;
};
export type RpcMethod = 'disconnect' | 'sendTransaction' | 'signData';
export type TAppRequest<T extends RpcMethod> = RpcRequests[T];

export interface TonConnectTAppRequest {
	request: TAppRequest<RpcMethod>;
	connection: TAppConnection;
	clientSessionId: string;
	message?: string;
}
export interface TonConnectMessageRequest {
	message: string;
	from: string;
	connection: TAppConnection;
}
export interface DisconnectEvent {
	event: 'disconnect';
	id: number | string;
	payload: Record<string, never>;
}
export type WalletResponse<T extends RpcMethod> = WalletResponseSuccess<T> | WalletResponseError<T>;

export type WalletResponseError<T extends RpcMethod> = RpcResponses[T]['error'];

export type WalletResponseSuccess<T extends RpcMethod> = RpcResponses[T]['success'];

export type RpcResponses = {
	sendTransaction: {
		error: SendTransactionRpcResponseError;
		success: SendTransactionRpcResponseSuccess;
	};
	signData: {
		error: SignDataRpcResponseError;
		success: SignDataRpcResponseSuccess;
	};
	disconnect: {
		error: DisconnectRpcResponseError;
		success: DisconnectRpcResponseSuccess;
	};
};
export interface SendTransactionRpcResponseSuccess {
	result: string;
	id: string;
}
export interface SendTransactionRpcResponseError {
	error: {
		code: SIGN_DATA_ERROR_CODES;
		message: string;
		data?: unknown;
	};
	id: string;
}
export interface SignDataRpcResponseError {
	error: {
		code: SIGN_DATA_ERROR_CODES;
		message: string;
		data?: unknown;
	};
	id: string;
}
export interface SignDataRpcResponseSuccess {
	id: string;
	result: {
		signature: string;
		timestamp: string;
	};
}
export interface DisconnectRpcResponseError {
	error: {
		code: DISCONNECT_ERROR_CODES;
		message: string;
		data?: unknown;
	};
	id: string;
}
export interface DisconnectRpcResponseSuccess {
	id: string;
	result: Record<string, never>;
}
