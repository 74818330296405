import { TransactionReceipt, ethers } from 'ethers';
import { QUERY_KEYS } from '../../app-constants';
import { CHAIN_CONFIG, ChainId } from '../../app-constants/chains';
import {
	ActivitiesServiceAPI,
	IActivity,
	ObserverTransaction,
	TxDetailTokenContractInteraction,
	TxDetailTokenSend,
	TxStatus,
} from '../../app-cores/api/activities';
import { queryClient } from '../../app-cores/query-client';
import { compareAddress } from '@/app-helpers/address';
import { ONE_DAY } from '@/app-hooks/api/portfolio/constant';
import { MetadataEvm, TransactionWatcher, useTransactionWatcherStore } from '@/app-store/transaction-watcher';
import { useRef } from 'react';
import { useInterval } from '@chakra-ui/react';
import { SelectedRoute } from '@/app-store/swap';
import { getPayloadSwapTracking, SwapService } from '@/app-hooks/swap';
import { TransactionType } from '@/app-types';
import { TokenInfo } from '@/app-cores/api/bff';
import { getMyWalletAddressByChain, tryParseAmount } from '@/app-hooks/swap/helper';
import { truncateToFixed } from '@/app-helpers/number';
import { ChainType } from '@/app-contexts/wallet-provider/type';
import { handleTonActivities } from '@/app-store/transaction-watcher/tonWatcher';
// todo: use activity
export function watchSingleTransaction({
	txHash,
	chainId,
}: {
	txHash: string;
	chainId: string;
}): Promise<TransactionReceipt> {
	return new Promise((resolve, reject) => {
		try {
			const provider = new ethers.WebSocketProvider(CHAIN_CONFIG[chainId].rpcUrls.default.wss);
			provider.once(txHash, (receipt) => resolve(receipt));
		} catch (error) {
			reject(error);
		}
	});
}

export const submitMetadataTxs = async ({
	transaction,
	type,
	trackingData,
}: {
	transaction: MetadataEvm | IActivity;
	type: ChainType;
	trackingData: any;
}) => {
	try {
		let payload: ObserverTransaction;
		const { hash, transactionType, chainId } = transaction;
		if (!hash) throw new Error('Empty hash');

		switch (type) {
			case ChainType.EVM: {
				const chain = CHAIN_CONFIG[chainId.toString()];
				payload = {
					chainId: `${chain.id}`,
					hash,
				};
				break;
			}
			case ChainType.SOLANA:
				payload = { chainId: ChainId.SOL, hash };
				break;
			case ChainType.TRON:
				payload = { chainId: ChainId.TRON, hash };
				break;
			case ChainType.TON:
				payload = await handleTonActivities(transaction as IActivity);
				break;
			default:
				break;
		}
		if (transactionType === TransactionType.Swap && trackingData) {
			payload = { metadata: getPayloadSwapTracking(trackingData), ...payload };
		}

		payload && (await ActivitiesServiceAPI.observerTransaction(payload));
	} catch (error) {}
};

export const useWatchTransactions = async (enable: boolean) => {
	const {
		pendingEvmTransactions,
		pendingSolTransactions,
		pendingTonTransactions,
		pendingTronTransactions,
		removePendingTransaction,
	} = useTransactionWatcherStore();

	const hasPending = [
		pendingEvmTransactions,
		pendingSolTransactions,
		pendingTonTransactions,
		pendingTronTransactions,
	].some((e) => {
		return !!Object.keys(e ?? {}).length;
	});

	const checking = useRef(false);
	const checkTransactions = async () => {
		if (checking.current) return;
		try {
			checking.current = true;
			const activities = await ActivitiesServiceAPI.getWalletActivities({});

			const checkSingleTxsFn = (
				{ hash, type }: { type: ChainType; hash: string },
				originTxs: TransactionWatcher<any>,
			) => {
				const {
					metadata: { time },
					callbackFns,
				} = originTxs;

				const transaction =
					type === ChainType.TON
						? activities?.reverse()?.find((e) => +e.time >= time - 10) // frontend data vs on chain data may diff.
						: activities?.find((e) => compareAddress(hash, e.transactionHash));

				const callback = (status: TxStatus) => {
					[
						QUERY_KEYS.GET_TOKEN_ALLOWANCE,
						QUERY_KEYS.GET_PORTFOLIO_BALANCE,
						QUERY_KEYS.GET_WALLET_ACTIVITY,
						QUERY_KEYS.GET_TOBI_FARM_POINT,
						QUERY_KEYS.GET_NOTIFICATIONS,
					].forEach((key) =>
						queryClient.invalidateQueries({
							queryKey: [key],
						}),
					);
					callbackFns?.forEach((fn) => fn({ status }));
					removePendingTransaction({ hash, type });
				};

				const handlePending = () => {
					if (Date.now() - time * 1000 > ONE_DAY) {
						callback(TxStatus.Failed);
					}
					console.log('txs pending', hash);
				};

				if (!transaction || transaction.transactionStatus === TxStatus.Pending) {
					handlePending();
					return;
				}

				// const isCrossChain =
				// 	transaction &&
				// 	(transaction?.crossChainTx || transaction.metadata?.swapType === TransactionType.SwapCrossChain);

				// if (isCrossChain) {
				// 	const isPending =
				// 		!transaction?.crossChainTx || transaction?.crossChainTx?.status === TxStatus.Pending;
				// 	if (isPending) {
				// 		handlePending();
				// 		return;
				// 	}
				// 	callback(transaction?.crossChainTx.status);
				// 	return;
				// }

				callback(transaction.transactionStatus);
			};

			const checkListTxs = (data: Record<string, TransactionWatcher<any>>, type: ChainType) => {
				Object.keys(data || {}).forEach((hash) => {
					checkSingleTxsFn({ hash, type }, data[hash]);
				});
			};

			checkListTxs(pendingEvmTransactions, ChainType.EVM);
			checkListTxs(pendingSolTransactions, ChainType.SOLANA);
			checkListTxs(pendingTonTransactions, ChainType.TON);
			checkListTxs(pendingTronTransactions, ChainType.TRON);
		} catch (error) {
			console.log('watch txs err', error);
		} finally {
			checking.current = false;
		}
	};

	useInterval(checkTransactions, hasPending && enable ? 5000 : null);
};

const getCommonPayload = (chainId: ChainId | string) => ({
	id: Date.now().toString(),
	gasUsd: 0,
	walletAddress: getMyWalletAddressByChain(chainId),
	chainId,
	gas: '',
	status: TxStatus.Pending,
	time: (Date.now() / 1000) | 0,
});

export const getPendingSwapTxs = (
	route: SelectedRoute,
	{ contract, hash, chainId }: { contract: string; hash: string; chainId: ChainId | string },
	metadata?: any,
): IActivity => {
	try {
		const { tokenIn, tokenOut, amountIn, amountOut } = SwapService.extractRouteInfo({ route });
		return {
			hash,
			...getCommonPayload(chainId),
			transactionType: TransactionType.Swap,
			details: [
				{
					contracts: [{ name: route.provider, address: contract }],
					type: TransactionType.Swap,
					sent: [
						{
							tokenAddress: tokenIn.address,
							tokenSymbol: tokenIn.symbol,
							tokenLogo: tokenIn.logo,
							tokenDecimals: tokenIn.decimals,
							amount: amountIn,
						},
					],
					received: [
						{
							tokenAddress: tokenOut.address,
							tokenSymbol: tokenOut.symbol,
							tokenLogo: tokenOut.logo,
							tokenDecimals: tokenOut.decimals,
							amount: amountOut,
						},
					],
				} as TxDetailTokenContractInteraction,
			],
			metadata,
		};
	} catch (error) {
		console.warn('get pending swap err', error);
		return;
	}
};

export const getPendingSendTxs = ({
	to,
	hash,
	chainId,
	memo,
	token,
	amount,
	transactionType,
}: {
	to: string;
	hash: string;
	chainId: ChainId | string;
	memo: string;
	token: TokenInfo;
	amount: string;
	transactionType: TransactionType;
}): IActivity => {
	const { decimals, address, symbol, imageUrl } = token;
	return {
		hash,
		...getCommonPayload(chainId),
		transactionType,
		details: [
			{
				type: TransactionType.Send,
				sent: [
					{
						tokenAddress: address,
						toAddress: to,
						tokenLogo: imageUrl,
						tokenSymbol: symbol,
						tokenDecimals: decimals,
						amount: tryParseAmount(truncateToFixed(amount, decimals), decimals)?.toString(),
					},
				],
				received: [],
			} as TxDetailTokenSend,
		],
		memo,
	};
};

export const getPendingContractInteractionTxs = ({
	contract,
	hash,
	chainId,
}: {
	contract: string;
	hash: string;
	chainId: ChainId | string;
}): IActivity => {
	return {
		hash,
		...getCommonPayload(chainId),
		details: [
			{
				type: TransactionType.ContractInteraction,
				contracts: [{ address: contract }],
				sent: [],
				received: [],
			} as TxDetailTokenContractInteraction,
		],
	};
};
