import { TokenInfo } from '@/app-cores/api/bff';
import { Keypair } from '@solana/web3.js';
import { Token } from '@swing.xyz/sdk';
import { ethers } from 'ethers';

export enum DepositType {
	Visa = 'Credit/Debit Card',
	ApplePay = 'Apple Pay',
	Paypal = 'Paypal',
	BankWise = 'Bank Wise',
	Crypto = 'Crypto',
}

export enum TransactionType {
	ContractInteraction = 'contractInteraction',
	Approve = 'approve',
	Send = 'send',
	Receive = 'receive',
	Swap = 'swap',
	SwapCrossChain = 'swap_crosschain', // fe custom
	// Wrap = 'wrap',
	// Unwrap = 'unwrap',
	// Burn = 'burn',
	// Mint = 'mint',
}

export interface IEstimateGasFeeItem {
	maxPriorityFeePerGas: bigint;
	maxFeePerGas: bigint;
	gasLimit: bigint;
	gasFeeInNativeToken: string;
}
export interface INetworkStatus {
	networkCongestion: number;
	baseFee: number;
	latestPriorityFeeRange: string[];
}
export interface IEstimateGasFee {
	low: IEstimateGasFeeItem;
	market: IEstimateGasFeeItem;
	aggressive: IEstimateGasFeeItem;
	networkStatus: INetworkStatus;
}
export type TGasFeeType = 'low' | 'aggressive' | 'market';

export interface TSentTransaction extends ethers.TransactionRequest {
	message?: string;
	token: TokenInfo;
	amount: string;
	transactionType?: TransactionType; // swap can use transfer token
	metadata?: any;
	skipAddPendingTxs?: boolean;
}

export interface TTransactionRequest extends ethers.TransactionRequest {
	chainId: string;
	gasLevel: TGasFeeType;
	transactionType: TransactionType;
	metadata?: any;
	skipAddPendingTxs?: boolean;
}

export enum ITransactionStatus {
	PROCESSING = 'processing',
	ERROR = 'error',
	SUCCESS = 'success',
	CANCELING = 'canceling',
	CANCEL_SUCCESS = 'cancel_success',
	CANCEL_ERROR = 'cancel_error',
}

export interface IEstimateGasFeeParams extends ethers.TransactionRequest {
	token: TokenInfo;
	amount: string;
}

export type TSentTonTransaction = {
	message?: string;
	token: TokenInfo;
	amount: string; // decimal amount
	to: string;
	gasFee?: bigint;
	autoDeductFee?: boolean;
	transactionType?: TransactionType; // swap can use transfer token
	metadata?: any;
	skipAddPendingTxs?: boolean;
};

export type TSentSolTransaction = {
	message?: string;
	token: TokenInfo;
	amount: string;
	to: string;
	gasFee?: bigint;
	autoDeductFee: boolean;
	transactionType?: TransactionType; // Send or Swap : swap can be call transfer token txs
	metadata?: any;
	skipAddPendingTxs?: boolean;
};
export type TSentTronTransaction = {
	message?: string;
	token: TokenInfo;
	amount: string; // decimal amount
	to: string;
	gasFee?: number;
	transactionType?: TransactionType; // Send or Swap : swap can be call transfer token txs
	metadata?: any;
	skipAddPendingTxs?: boolean;
};

export type TSubmitSolTransaction = {
	data: Buffer;
	lastValidBlockHeight?: number;
	signer?: Keypair;
	transactionType: TransactionType;
	metadata?: any;
};
