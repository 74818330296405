import { ButtonSecondary, CTAButton } from '@/app-components/common';
import { navigateOnboardingFromBotRedirect } from '@/app-components/common/CreateAccount';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TokenInfo } from '@/app-cores/api/bff';
import { useBuyOnRamp, useBuyOnTransak } from '@/app-hooks/common';
import { useAppExpansion } from '@/app-hooks/common/useAppExpansion';
import { Flex } from '@chakra-ui/react';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router';
import { FeatureConfigWrap } from '../../../app-helpers/components';
import { FeatureConfig } from '../../../app-hooks/api/configuration';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';

const ctaProps = {
	height: '50px',
	minHeight: '50px',
	fontSize: '14px',
};

export default function SwapAction({
	showBtnApprove,
	openApprove,
	disabledSwap,
	tokenIn,
	tokenOut,
	label,
	style,
	onClickSwap,
	showApproveModal,
	quickSwap,
	isNonWallet,
	enabledBuyBtn = true,
	swapButtonId,
	buyButtonId,
}: {
	showBtnApprove: boolean;
	openApprove: boolean;
	disabledSwap: boolean;
	tokenIn: TokenInfo | undefined;
	tokenOut: TokenInfo | undefined;
	label: string;
	onClickSwap: () => void;
	showApproveModal: () => void;
	style?: CSSProperties;
	quickSwap?: boolean;
	isNonWallet: boolean;
	enabledBuyBtn?: boolean;
	swapButtonId?: string;
	buyButtonId?: string;
}) {
	const navigate = useNavigate();
	const [isTelegramExpanded] = useAppExpansion();
	const { buildTransakURL } = useBuyOnTransak();
	const { isAllowBuy } = useBuyOnRamp(tokenOut?.chainId, tokenOut?.symbol, NAVIGATE_PATHS.Swap.Main);
	const showBuyBtn = isAllowBuy && enabledBuyBtn && !showBtnApprove && !quickSwap;
	if (openApprove) return null;
	return (
		<Flex gap={'8px'} p={quickSwap ? 0 : 4} style={style}>
			{showBtnApprove && (
				<CTAButton flex={1} onClick={showApproveModal} {...ctaProps} colorScheme={'cyan'}>
					Approve {tokenIn?.symbol}
				</CTAButton>
			)}
			{showBtnApprove && !isTelegramExpanded ? null : (
				<>
					<CTAButton
						flex={1}
						{...ctaProps}
						colorScheme={!disabledSwap ? 'cyan' : 'gray'}
						isDisabled={disabledSwap}
						onClick={() => {
							onClickSwap();
							dataDogAddAction(DATADOG_ACTIONS.TRADE_SWAP);
						}}
						id={swapButtonId}
					>
						{label}
					</CTAButton>
					{showBuyBtn && (
						<FeatureConfigWrap feature={FeatureConfig.TRANSAK_DEPOSIT}>
							<ButtonSecondary
								id={buyButtonId}
								onClick={() => {
									if (isNonWallet) {
										navigate(navigateOnboardingFromBotRedirect);
									} else {
										dataDogAddAction(DATADOG_ACTIONS.DEPOSIT_TRADE.BUY_WITH_TRANSAK);
										navigate(
											buildTransakURL({
												chainId: tokenOut?.chainId,
												tokenSymbol: tokenOut?.symbol,
												callbackUrl: NAVIGATE_PATHS.Swap.Main,
											}),
										);
									}
								}}
								{...ctaProps}
							>
								Buy {tokenOut?.symbol}
							</ButtonSecondary>
						</FeatureConfigWrap>
					)}
				</>
			)}
		</Flex>
	);
}
