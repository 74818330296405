import { Box, Center, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isNativeTobiToken, isNativeToken } from '@/app-helpers/address';
import { useNavigateToSentPage, useNavigateToSwapPage, useNavigateToSwapPageFromUrl } from '@/app-helpers/navigate';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { useOnEventCallback } from '@/app-hooks/common';
import { MenuActionItem } from '@/app-views/portfolio/pages/token/components/MenuAction';
import { FiClockIcon, FiDownLeftIcon, FiPlusIcon, FiSwapIcon, FiUpRightIcon } from '@/assets/icons';
import { DATADOG_ACTIONS, dataDogAddAction } from '@/app-services/monitor/datadog';
import { useDepositStore } from '@/app-features/deposit/store';
import { toQueryString } from '@/app-helpers/url';
import { ChainId, ID_TOBI_NATIVE_TOKENS } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { TOUR_GUIDE_STEPS_TARGET } from '../TourGuide/stepConfigs';
import { FeatureConfigWrap } from '@/app-helpers/components';
import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { useQueryGetRequestFund } from '@/app-hooks/api/payment/useClaimFund';
import { Badge } from '@/app-components/common';
import { useCountSol2Tcat } from '@/app-hooks/swap/sol_2_tcat';
import Activities from '../Activities';
import { useEffect } from 'react';
import { useParseBotStartRedirectParams } from '@/app-cores/telegram';
import { Actions } from '@/app-features/app-bot-connector/hooks';

interface MenuActionProps {}

let showedActity = false;
export const MenuAction: React.FC<MenuActionProps> = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onOpen: onOpenDeposit, setInitToken } = useDepositStore();

	const {
		data: { mainTokens },
	} = usePortfolioBalanceByCategories();
	const navigateToSentPage = useNavigateToSentPage();

	const onSendClick = useOnEventCallback(() => {
		const firstNativeBalance = mainTokens?.find((e) => isNativeTobiToken(e)) || mainTokens?.[0];
		navigateToSentPage({ token: firstNativeBalance });
	});

	const navigateToSwap = useNavigateToSwapPageFromUrl(
		toQueryString(NAVIGATE_PATHS.Swap.Main, {
			tokenIn: ID_TOBI_NATIVE_TOKENS.SOL,
			tokenOut: ID_TOBI_NATIVE_TOKENS.TON,
			chainIn: ChainId.SOL,
			chainOut: ChainId.TON,
		}),
	);
	const onSwapClick = useOnEventCallback(() => {
		dataDogAddAction(DATADOG_ACTIONS.TRADE);
		navigateToSwap();
	});

	const onDepositClick = useOnEventCallback(() => {
		dataDogAddAction(DATADOG_ACTIONS.DEPOSIT);
		setInitToken(null);
		onOpenDeposit();
	});

	const onRequestFundClick = useOnEventCallback(() => {
		navigate(NAVIGATE_PATHS.RequestFund.Main);
	});

	const onHistoryClick = useOnEventCallback(() => {
		onOpen();
	});

	const { action } = useParseBotStartRedirectParams();
	const [searchParams] = useSearchParams();
	const actionParam = searchParams.get('action');
	useEffect(() => {
		if ((action === Actions.ACTIVITIES && !showedActity) || actionParam === Actions.ACTIVITIES) {
			onOpen();
			showedActity = true;
			navigate(NAVIGATE_PATHS.Home, { replace: true });
		}
	}, [action, actionParam, onOpen, navigate]);

	const isSupportRequestFund = useQueryFeatureConfig(FeatureConfig.REQUEST_FUND);
	const { data } = useQueryGetRequestFund(isSupportRequestFund);

	const { totalContinue } = useCountSol2Tcat();
	const totalBadge = totalContinue + (data?.totalToPaid ?? 0);
	return (
		<Center width={'100%'}>
			<Box display="flex" maxWidth={'350px'} justifyContent="center" mt={4} flex={1}>
				<MenuActionItem
					text={t('home.wallet.send')}
					to={null}
					onClick={onSendClick}
					wrapperId={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.SEND}
				>
					<FiUpRightIcon />
				</MenuActionItem>
				<FeatureConfigWrap feature={FeatureConfig.REQUEST_FUND}>
					<MenuActionItem text={t('home.wallet.requestFund')} to={null} onClick={onRequestFundClick}>
						<FiDownLeftIcon />
					</MenuActionItem>
				</FeatureConfigWrap>
				<MenuActionItem
					text={t('home.wallet.deposit')}
					to={null}
					onClick={onDepositClick}
					wrapperId={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.DEPOSIT}
				>
					<FiPlusIcon />
				</MenuActionItem>
				<MenuActionItem
					text={t('home.wallet.swap')}
					to={null}
					onClick={onSwapClick}
					wrapperId={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.TRADE}
				>
					<FiSwapIcon />
				</MenuActionItem>

				<MenuActionItem
					id="activityHome" // todo refactor
					text={t('home.wallet.activity')}
					to={null}
					onClick={onHistoryClick}
					wrapperId={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.ACTIVITY}
				>
					<FiClockIcon fill="black" />
					{!!totalBadge && (
						<Badge type="danger" sx={{ position: 'absolute', right: '-10px', top: '-4px' }}>
							{totalBadge}
						</Badge>
					)}
					<Activities isOpen={isOpen} onClose={onClose} />
				</MenuActionItem>
			</Box>
		</Center>
	);
};
