import { useCallback, useMemo, useState } from 'react';
import {
	Card,
	Center,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Image,
	Text,
} from '@chakra-ui/react';
import { approveEIP155Request, rejectEIP155Request } from '../utils/EIP155RequestHandlerUtil';
import { getChainId, getTransactionFee } from '../utils/HelperUtil';
import { web3wallet } from '../utils/WalletConnectUtil';
import { useModalStore } from '../store/ModalStore';
import { toast } from 'react-toastify';
import { CHAIN_CONFIG } from '@/app-constants/chains';
import { useGasFeeData } from '@/app-hooks/wallet';
import { Loading, Toast } from '@/app-components/common';
import { useScanEvmTransaction } from '../hooks/blow-fish/useScanEvmTransaction';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { BlowFishWaning } from './BlowFishWaning';
import { TokenAmount } from '../components/TokenAmount';
import { CommonTransactionInfo } from '../components/CommonTransactionInfo';
import { isEmpty } from 'lodash';
import { ActionConfirm } from '../components/ActionConfirm';
import { TransactionItemInfo } from '../components/TransactionItemInfo';
import { RawTransaction } from '../components/RawTransaction';

export default function SessionSendTransactionModal() {
	const [isLoadingApprove, setIsLoadingApprove] = useState(false);
	const [isLoadingReject, setIsLoadingReject] = useState(false);
	const { data, open, onClose } = useModalStore();
	const requestEvent = data?.requestEvent;
	const requestSession = data?.requestSession;
	const { topic, params } = requestEvent;
	const chainId = getChainId(params.chainId);
	const { data: gasFeeData } = useGasFeeData(chainId);
	const request = params?.request;
	const transaction = request?.params[0];
	const { peer } = requestSession;
	const network = CHAIN_CONFIG[chainId]?.blowFishNetworkName;
	const walletAddress = MpcWallet.getWalletAddress().evmAddress;
	const { data: scanTransactionData, isLoading: isScanningTransaction } = useScanEvmTransaction(
		data.requestEvent.id,
		{
			txObjects: request?.params,
			userAccount: MpcWallet.getWalletAddress().evmAddress,
			metadata: {
				origin: requestEvent.verifyContext.verified.origin,
			},
			network: network as any,
		},
	);
	// Handle approve action
	const onApprove = useCallback(async () => {
		if (isLoadingApprove) return;
		if (requestEvent && topic) {
			setIsLoadingApprove(true);
			try {
				const response = await approveEIP155Request(requestEvent);
				await web3wallet.respondSessionRequest({
					topic,
					response,
				});
				if (!(response as any)?.error?.message) {
					toast(
						<Toast type="success" title="Approval Success" message="Approval transaction successfully" />,
					);
				}
			} catch (e) {
				setIsLoadingApprove(false);
				toast(<Toast type="error" message={(e as Error).message} />);
			} finally {
				setIsLoadingApprove(false);
				onClose();
			}
		}
		// eslint-disable-next-line
	}, [requestEvent, topic]);

	// Handle reject action
	const onReject = useCallback(async () => {
		if (isLoadingReject) return;
		if (requestEvent && topic) {
			setIsLoadingReject(true);
			const response = rejectEIP155Request(requestEvent);
			try {
				await web3wallet.respondSessionRequest({
					topic,
					response,
				});
				// toast(<Toast type="success" message="Reject transaction successfully" />);
			} catch (e) {
				setIsLoadingReject(false);
			}
			setIsLoadingReject(false);
			onClose();
		}
		// eslint-disable-next-line
	}, [requestEvent, topic]);
	const aggregated = scanTransactionData?.simulationResults?.aggregated;
	const stateChange = aggregated?.expectedStateChanges?.[aggregated?.userAccount];

	const assetView = useMemo(() => {
		if (isEmpty(stateChange)) return null;
		return stateChange?.map((asset, i) => {
			switch (asset.rawInfo.kind) {
				case 'NATIVE_ASSET_TRANSFER':
				case 'ERC20_TRANSFER': {
					const data = asset.rawInfo.data;
					const amountChange = +data.amount.after - +data.amount.before;
					return (
						<TokenAmount
							key={i}
							amount={amountChange > 0 ? amountChange : amountChange * -1}
							decimals={data?.asset?.decimals}
							image={data?.asset?.imageUrl}
							symbol={data?.asset?.symbol}
							type={amountChange > 0 ? 'IN' : 'OUT'}
							dollarValuePerToken={data?.asset?.price?.dollarValuePerToken}
						/>
					);
				}
				case 'ERC20_APPROVAL': {
					return (
						<>
							<TransactionItemInfo
								title="Message"
								value={<Text fontSize="xs">{asset.humanReadableDiff}</Text>}
							/>
							<TransactionItemInfo
								title="Assets"
								value={
									<Center gap={2}>
										<Text>{asset?.rawInfo?.data?.asset?.symbol}</Text>
										<Image
											alt=""
											src={asset?.rawInfo?.data?.asset?.imageUrl}
											width={6}
											height={6}
										/>
									</Center>
								}
							/>
						</>
					);
				}

				default:
					break;
			}
		});
	}, [stateChange]);

	if (isScanningTransaction) return <Loading />;

	return request && requestSession ? (
		<Drawer isOpen={open} placement="bottom" onClose={onClose} trapFocus={false} closeOnOverlayClick={false}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader borderBottomWidth="1px" borderColor="rgba(0, 0, 0, 0.08)">
					<Center>
						<Text fontSize={'16px'} fontWeight={'500'}>
							Confirm Transaction
						</Text>
					</Center>
				</DrawerHeader>

				<DrawerBody>
					<Flex mt={2} flexDirection={'column'} alignItems={'center'} gap={'20px'}>
						<Flex flexDirection="column" width="100%">
							<Card px={4} py={2} width={'100%'}>
								<Center gap={2} borderRadius={52} px={2} py={1} mb={2}>
									<Image
										width={8}
										height={8}
										src={peer?.metadata?.icons?.[0]}
										alt=""
										borderRadius="100%"
										fallbackSrc="/assets/images/dapp-fallback.svg"
									/>
									<Text fontSize="sm">{peer?.metadata?.url}</Text>
								</Center>
								<Divider borderBottom="1px dashed rgba(0, 0, 0, 0.16)" />
								{assetView && assetView}
								<CommonTransactionInfo
									chainId={chainId}
									gasFee={getTransactionFee(transaction?.gas, gasFeeData?.gasPrice, chainId)}
									walletAddress={walletAddress}
								>
									<RawTransaction
										rawData={JSON.stringify(
											scanTransactionData?.simulationResults?.perTransaction || transaction,
											null,
											2,
										)}
									/>
								</CommonTransactionInfo>
							</Card>
						</Flex>
						{scanTransactionData?.action === 'WARN' && (
							<BlowFishWaning warnings={scanTransactionData.warnings} />
						)}
					</Flex>
				</DrawerBody>

				<DrawerFooter display="flex" gap={2}>
					<ActionConfirm
						onApprove={onApprove}
						onReject={onReject}
						isLoadingApprove={isLoadingApprove}
						isLoadingReject={isLoadingReject}
					/>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	) : null;
}
