import { ChainId, ID_TOBI_NATIVE_TOKENS, NATIVE_TOKEN_ADDRESS, STABLE_COINS } from '@/app-constants/chains';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { BffServiceAPI, ITokenSearch, QueryTokenParam } from '@/app-cores/api/bff';
import { compareAddress, compareChain, compareTobiToken, compareToken, isNativeTobiToken } from '@/app-helpers/address';
import { getTobiChainName, getTokenInfo, isTestnetChain } from '@/app-helpers/token';
import { parseUrlSearchParams, toQueryString } from '@/app-helpers/url';
import { useSearchListToken } from '@/app-hooks/api/portfolio';
import {
	PortfolioBalance,
	usePortfolioBalance,
	usePortfolioBalanceByCategories,
} from '@/app-hooks/api/portfolio/usePortfolioBalance';
import { SwapService } from '@/app-hooks/swap';
import { calcAmount } from '@/app-hooks/swap/helper';
import Sol2TcatSwap from '@/app-hooks/swap/sol_2_tcat';
import { MainRouter } from '@/app-router';
import { useSentTokenStore } from '@/app-store';
import { useUserSettingsStore } from '@/app-store/settings';
import { SelectedRoute, useSwapStore } from '@/app-store/swap';
import { useLimitStore } from '@/app-store/swap/limit';
import { RouterNavigateOptions } from '@remix-run/router';
import { parseUnits } from 'ethers';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export type StartAppParamSend = {
	token?: ITokenSearch | string;
	usdAmount?: string; // deprecated
	amountUsd?: string;
	amount?: string;
	teleId?: string;
	chainId?: string;
};

export const useNavigateToSentPage = () => {
	const { setTokenSent } = useSentTokenStore();
	return useCallback(
		({ token = '', usdAmount = '', amountUsd = '', amount = '', teleId = '', chainId = '' }: StartAppParamSend) => {
			if (token && typeof token === 'object') setTokenSent(token); // don't need to call api
			MainRouter.navigate(
				toQueryString(NAVIGATE_PATHS.Wallet.Sent.Sent, {
					token: typeof token === 'string' ? token : '',
					chainId,
					amount,
					userTelegramId: teleId,
					amountUsd: amountUsd || usdAmount,
				}),
			);
		},
		[setTokenSent],
	);
};

export const useSelectTokenMaxPortfolio = () => {
	const {
		data: { mainTokensHasBalance },
	} = usePortfolioBalanceByCategories();

	const getTokenIn = useCallback(
		(tokenOut: ITokenSearch, chainId: string, fallbackToken?: ITokenSearch) => {
			if (!isNativeTobiToken(tokenOut)) {
				const { USDC, USDT } = STABLE_COINS[chainId] || {};

				const tokenSameChain = mainTokensHasBalance.filter(
					(e) => !compareTobiToken(tokenOut, e) && compareChain(getTokenInfo(e).chainId, chainId),
				);

				const native = tokenSameChain.find((e) =>
					compareAddress(getTokenInfo(e).address, NATIVE_TOKEN_ADDRESS),
				);
				const usdc = tokenSameChain.find((e) => compareAddress(getTokenInfo(e).address, USDC));
				const usdt = tokenSameChain.find((e) => compareAddress(getTokenInfo(e).address, USDT));

				const sort = [native, usdc, usdt].filter(Boolean).sort((a, b) => +b.usdValue - +a.usdValue);

				return sort[0] || fallbackToken;
			}

			const maxBalanceFn = (rs: ITokenSearch, token: ITokenSearch) =>
				compareTobiToken(token, tokenOut) ? rs : !rs || token?.usdValue > +rs?.usdValue ? token : rs;

			if (isTestnetChain(tokenOut)) {
				return (
					mainTokensHasBalance.filter((e) => isTestnetChain(e)).reduce(maxBalanceFn, null) || fallbackToken
				);
			}

			const nativeMaxBalance = mainTokensHasBalance
				.filter((e) => !isTestnetChain(e) && isNativeTobiToken(e))
				.reduce(maxBalanceFn, null);

			return nativeMaxBalance || fallbackToken;
		},
		[mainTokensHasBalance],
	);

	return getTokenIn;
};

export const populateBalance = ({
	newTokenIn,
	newTokenOut,
	tokenBalances,
}: {
	newTokenIn: ITokenSearch | undefined;
	newTokenOut: ITokenSearch | undefined;
	tokenBalances: ITokenSearch[];
}) => {
	const populateIn = tokenBalances?.find((e) => compareTobiToken(e, newTokenIn));
	const populateOut = tokenBalances?.find((e) => compareTobiToken(e, newTokenOut));
	if (populateIn && newTokenIn) newTokenIn.balance = populateIn.balance;
	if (populateOut && newTokenOut) newTokenOut.balance = populateOut.balance;
};

const getQuery = (defaultChain: string | ChainId) => {
	return {
		query: defaultChain
			? [
					{
						tobiId: ID_TOBI_NATIVE_TOKENS[getTobiChainName(defaultChain)],
						chainId: defaultChain?.toString(),
					},
					{ tobiId: ID_TOBI_NATIVE_TOKENS.SOL, chainId: ChainId.SOL },
					{
						address: STABLE_COINS[defaultChain]?.USDC || STABLE_COINS[defaultChain]?.USDT,
						chainId: defaultChain?.toString(),
					},
			  ]
			: [],
		withPrice: true,
	};
};
// select token by rules: https://www.notion.so/tobilabs/Swap-Routes-Fees-9a368e74ca214d6a9ef6aed611d09609?pvs=4#245b629eedc84e6b99382915b211e208
export const useFetchTokensForSwap = (defaultChain: string | ChainId) => {
	const { data: portfolioBalance } = usePortfolioBalance();

	// that is preload data to reduce latency
	const queryData = useMemo(() => getQuery(defaultChain), [defaultChain]);

	const { data: tokens } = useSearchListToken(queryData);
	const selectTokenFromPortfolio = useSelectTokenMaxPortfolio();

	return useCallback(
		async (data: { tokenOut: ITokenSearch } | { tokenIn: ITokenSearch }) => {
			const tokenParam = data['tokenOut'] || data['tokenIn'];

			const findTokenFn = (tokens: ITokenSearch[]) => {
				const [nativeToken, sol, stable] = tokens || [];

				const targetToken: ITokenSearch = tokenParam;
				const findToken = selectTokenFromPortfolio(
					targetToken,
					getTokenInfo(targetToken)?.chainId,
					compareTobiToken(nativeToken, targetToken) ? stable || sol : nativeToken,
				);

				return { findToken, targetToken };
			};

			let tokenList = tokens || [];
			if (!tokenList?.length || tokenList?.every((e) => !e)) {
				try {
					// preload slow, call api instead
					tokenList = await BffServiceAPI.searchExactListTokens(
						getQuery(defaultChain || getTokenInfo(tokenParam)?.chainId),
					);
				} catch (error) {}
			}

			const { findToken, targetToken } = findTokenFn(tokenList);
			const isFindTokenIn = data['tokenOut'];
			const newTokenIn = isFindTokenIn ? findToken : targetToken;
			const newTokenOut = isFindTokenIn ? targetToken : findToken;

			populateBalance({ newTokenIn, newTokenOut, tokenBalances: portfolioBalance?.tokenBalances });
			return { tokenIn: newTokenIn, tokenOut: newTokenOut };
		},
		[selectTokenFromPortfolio, portfolioBalance, tokens, defaultChain],
	);
};

type QueryParams = {
	queryTokenOut?: string;
	amount?: string;
	amountUsd?: string;
};
const useNavigateToSwap = () => {
	return useCallback(async ({ queryTokenOut }: QueryParams = {}, replace = false) => {
		const queryParams = {};
		if (queryTokenOut) queryParams['toToken'] = queryTokenOut;
		MainRouter.navigate(toQueryString(NAVIGATE_PATHS.Swap.Main, queryParams), { replace });
	}, []);
};

// from token detail ,... select token by another token
export const useNavigateToSwapPage = (defaultChain: string | ChainId) => {
	const { setPair } = useSwapStore(false);
	const getTokens = useFetchTokensForSwap(defaultChain);
	const navigate = useNavigateToSwap();

	return useCallback(
		async ({ tokenOut, ...rest }: { tokenOut: ITokenSearch } & QueryParams, replace?: boolean) => {
			const { tokenIn: newTokenIn, tokenOut: newTokenOut } = await getTokens({ tokenOut });
			setPair(newTokenIn, newTokenOut);
			navigate(rest, replace);
		},
		[setPair, getTokens, navigate],
	);
};

// select exact pair
// url must be format: '/swap?tokenIn=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&chainIn=1&tokenOut=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&chainOut=137',
export const useNavigateToSwapPageFromUrl = (url: string) => {
	const { setPair, setAmount } = useSwapStore(false);
	const navigate = useNavigateToSwap();
	const { data: portfolioBalance } = usePortfolioBalance();

	// that is preload data to reduce latency
	const queryData = useMemo(() => {
		const { tokenIn, tokenOut, chainIn, chainOut } = parseUrlSearchParams(url);
		return {
			query:
				url.startsWith(NAVIGATE_PATHS.Swap.Main) && [tokenIn, tokenOut, chainIn, chainOut].some((e) => !e)
					? []
					: [
							{ tobiId: tokenIn, chainId: chainIn },
							{ tobiId: tokenOut, chainId: chainOut },
					  ],
			withPrice: true,
		};
	}, [url]);

	const { data: tokens } = useSearchListToken(queryData);

	return async (params: QueryParams = {}, replace = false) => {
		const paramsFromUrl = parseUrlSearchParams(url);

		let [newTokenIn, newTokenOut] = tokens || [];
		if (!newTokenIn && !newTokenOut) {
			try {
				// preload slow, call api instead
				const data = await BffServiceAPI.searchExactListTokens(queryData);
				[newTokenIn, newTokenOut] = data || [];
			} catch (error) {}
		}
		populateBalance({ newTokenIn, newTokenOut, tokenBalances: portfolioBalance?.tokenBalances });
		setPair(newTokenIn, newTokenOut);
		const amount = params.amount || paramsFromUrl.amount;
		const amountUsd = params.amountUsd || paramsFromUrl.amountUsd;
		if (newTokenIn && (amount || amountUsd)) {
			const resp = calcAmount({ amount, amountUsd, token: newTokenIn });
			setAmount(resp.amount, resp.amountUsd);
		}
		navigate(params, replace);
	};
};

export const useNavigateToLimitPage = () => {
	const { data: portfolioBalance } = usePortfolioBalance();
	const { setPair } = useLimitStore();
	const queryData: { query: QueryTokenParam[] } = {
		query: [
			{ tobiId: ID_TOBI_NATIVE_TOKENS.SOL, chainId: ChainId.SOL },
			{ address: STABLE_COINS[ChainId.SOL]?.USDC, chainId: ChainId.SOL },
		],
	};
	// that is preload data to reduce latency
	const { data: dataLimit } = useSearchListToken(queryData);

	return async ({ replace }: { replace?: boolean }) => {
		let newTokenIn = dataLimit?.[0];
		let newTokenOut = dataLimit?.[1];
		if (!newTokenIn && !newTokenOut) {
			try {
				// preload slow, call api instead
				const data = await BffServiceAPI.searchExactListTokens(queryData);
				[newTokenIn, newTokenOut] = data;
			} catch (error) {}
		}
		populateBalance({ newTokenIn, newTokenOut, tokenBalances: portfolioBalance?.tokenBalances });
		setPair(newTokenIn, newTokenOut);
		MainRouter.navigate(
			toQueryString(NAVIGATE_PATHS.Limit.Main, {
				transition: 0,
			}),
			{ replace },
		);
	};
};

export const useNavigateToUrl = (url: string, parentUrl: string) => {
	const navigate = useNavigate();
	const navigateToSwap = useNavigateToSwapPageFromUrl(url);

	return () => {
		if (url.startsWith(NAVIGATE_PATHS.Swap.Main)) {
			navigateToSwap();
			return;
		}
		navigate(url.startsWith('/') ? url : `${NAVIGATE_PATHS.Dapp.TobiDapp}/?dappUrl=${url}&parentUrl=${parentUrl}`);
	};
};

export const useNavigateToConfirmSwap = () => {
	const { setAmount, setPair, setSelectedRoute } = useSwapStore(false);
	return (route: SelectedRoute, amountInDecimal: string) => {
		const { tokenIn, tokenOut } = route;
		const { amount, amountUsd } = calcAmount({ amount: amountInDecimal, token: tokenIn as any });
		setAmount(amount, amountUsd);
		setPair(tokenIn as any, tokenOut as any);
		const isSolToTcat = Sol2TcatSwap.isTon2Tcat(route);
		if (isSolToTcat) {
			route.metadata ||= {};
			route.metadata.isSolToTcat = isSolToTcat;
		}
		setSelectedRoute(route);
		setTimeout(() => {
			MainRouter.navigate(NAVIGATE_PATHS.Swap.ConfirmTransaction);
		}, 500);
	};
};

export const useFetchRouteAndRedirectToSwapConfirm = () => {
	const { slippage } = useUserSettingsStore();
	const navigateToSwapConfirm = useNavigateToConfirmSwap();
	return async ({ toChain, toTobiId, fromTobiId, fromChain, amount }) => {
		const [tokenOut, tokenIn] = await BffServiceAPI.searchExactListTokens({
			query: [
				{ chainId: toChain, tobiId: toTobiId },
				{ chainId: fromChain, tobiId: fromTobiId },
			].filter((e) => e.tobiId && e.chainId),
		});

		const parsedAmount = parseUnits(amount, getTokenInfo(tokenIn).decimals);
		const formatAmount = Sol2TcatSwap.isTon2Tcat({ tokenIn, tokenOut })
			? Sol2TcatSwap.deductTonGas(parsedAmount)
			: parsedAmount;
		const route = await SwapService.fetchRouteBySwapType({
			tokenIn: getTokenInfo(tokenIn),
			tokenOut: getTokenInfo(tokenOut),
			amountIn: formatAmount.toString(),
			slippage,
		});
		navigateToSwapConfirm(route, amount);
	};
};

export const navigateToTokenDetail = ({ tobiId }: { tobiId: string }, options?: RouterNavigateOptions) => {
	MainRouter.navigate(
		toQueryString(NAVIGATE_PATHS.Portfolio.asset, {
			tobiId: tobiId || '',
		}),
		options,
	);
};
