import { useQuery } from '@tanstack/react-query';
import { TEvmScanMessage } from '../../services/BlowFish/type';
import { BlowFistClientAPI } from '../../services/BlowFish/browser';

export const useScanSignMessage = (requestId: number, payload: TEvmScanMessage) => {
	const res = useQuery({
		queryKey: ['blowfish-scan-sign-message-data', requestId],
		queryFn: async () => {
			const data = await BlowFistClientAPI.scanSignMessage(payload);
			return data;
		},
	});
	return res;
};
