import { Actions } from '@/app-features/app-bot-connector/hooks';
import { Badge } from '@/app-components/common';
import { useSharingStore } from '@/app-components/common/Sharing';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { ITokenSearch } from '@/app-cores/api/bff';
import { TelegramCore, toStartParam } from '@/app-cores/telegram';
import { formatNumber, formatUsd } from '@/app-helpers/number';
import { toQueryString } from '@/app-helpers/url';

import { FeatureConfig, useQueryFeatureConfig } from '@/app-hooks/api/configuration';
import { useTokenDetail } from '@/app-hooks/api/portfolio';
import { useQueryUserInviteCodes } from '@/app-hooks/api/user/useQueryUserInviteCodes';
import { useGetTotalLimitOrders } from '@/app-hooks/limit';

import { SwapSetting } from '@/app-views/swap/components/SwapSetting';
import { FiShareIcon } from '@/assets/icons';
import { FiBookIcon } from '@/assets/icons/fi-book-icon';

import { Box, Flex, Tag } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useWalletStore } from '@/app-store';
import { getTokenInfo } from '@/app-helpers/token';

export default function HeaderActions({ tokenOut, isLimitPage }: { tokenOut: ITokenSearch; isLimitPage: boolean }) {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { data: inviteData, isLoading } = useQueryUserInviteCodes();
	const { chainId, address } = getTokenInfo(tokenOut);

	const { onOpen } = useSharingStore();
	const { data } = useTokenDetail({ chainId: chainId, address }, { enabled: !!tokenOut });
	const { isNonWallet } = useWalletStore();
	const onShareToken = () => {
		if (!data || isLoading) {
			return;
		}

		const { name, symbol, priceUsd, fullyDilutedValueUsd, volume24hUsd, marketCapUsd, idTobi } = getTokenInfo(data);
		const message = t('sharing.swapDetail', {
			symbol: symbol.toUpperCase(),
			name,
			usdPrice: priceUsd ? formatUsd(priceUsd) : 'Unknown',
			marketCapUsd: marketCapUsd ? formatNumber(marketCapUsd) : 'Unknown',
			fdvUsd: fullyDilutedValueUsd ? formatNumber(fullyDilutedValueUsd) : 'Unknown',
			volume24hUsd: volume24hUsd ? formatNumber(volume24hUsd) : 'Unknown',
		});

		const startParams = toStartParam({
			act: Actions.SWAP,
			toChain: chainId,
			toTok: address,
			inviter: !isNonWallet ? TelegramCore.getUserId() : undefined,
			invitationCode: inviteData?.code?.code,
			tobiId: idTobi,
		});

		onOpen({
			link: `https://t.me/${import.meta.env.VITE_BOT_USERNAME}/wallet?startapp=${startParams}`,
			message,
		});
	};

	const { data: { total: totalOrder } = { total: 0 } } = useGetTotalLimitOrders(isLimitPage);
	const isSupportLimitOrder = useQueryFeatureConfig(FeatureConfig.LIMIT_ORDER);
	return (
		<Flex gap={'12px'} alignItems={'center'}>
			{isLimitPage ? (
				isSupportLimitOrder && (
					<Box
						cursor={'pointer'}
						onClick={() => navigate(toQueryString(NAVIGATE_PATHS.Limit.LimitHistory, { transition: 0 }))}
					>
						<Flex
							sx={
								totalOrder
									? {
											position: 'relative',
											justifyContent: 'center',
											alignItems: 'center',
											width: '32px',
											height: '32px',
											px: 1,
											borderRadius: '100%',
											background: 'cyan.600',
									  }
									: undefined
							}
						>
							<FiBookIcon height={'19px'} />
							{!!totalOrder && (
								<Badge type="danger" sx={{ position: 'absolute', right: '-10px', top: '-8px' }}>
									{totalOrder}
								</Badge>
							)}
						</Flex>
					</Box>
				)
			) : (
				<SwapSetting />
			)}
			<Box cursor={'pointer'} onClick={onShareToken}>
				<FiShareIcon height={'18px'} />
			</Box>
		</Flex>
	);
}
