import { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { TelegramCore } from '@/app-cores/telegram';
import { formatTelegramStartParams } from '@/app-helpers/string';
import { useTonConnect } from './useConnect';
import { httpBridgeService } from '../services/httpBridgeService';
import { useTAppConnections } from '../store/ConnectionStore';
import { useHandlerTAppRequest } from './useHandlerTAppRequest';

export const useTonConnectEventsManager = () => {
	const { onConnect } = useTonConnect();
	const { connections } = useTAppConnections();
	const handlerTAppRequest = useHandlerTAppRequest();

	useEffect(() => {
		const startParams = TelegramCore?.WebApp?.initDataUnsafe?.start_param;
		console.log('🚀 useTonConnectEventsManager startParams:', startParams);
		if (!startParams || !startParams.startsWith('tonconnect')) return;
		const connectUrl = formatTelegramStartParams(`tc://?platform=${startParams}`);
		onConnect(connectUrl);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isEmpty(connections)) return;
		const closeSSE = httpBridgeService.subscribeEventTonConnect({
			connections: connections,
			handleMessage: handlerTAppRequest,
		});
		return () => closeSSE();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connections]);
};
