import { ButtonSecondary, CopyToClipboard, Toast } from '@/app-components/common';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { QUERY_KEYS } from '@/app-constants';
import { ChainType } from '@/app-contexts/wallet-provider/type';
import { TxStatus } from '@/app-cores/api/activities';
import { queryClient } from '@/app-cores/query-client';
import { parseErrorMessage } from '@/app-helpers/error-handling';
import { formatCurrency, formatUnits, removeTrailingZeros } from '@/app-helpers/number';
import { isEvmChain } from '@/app-helpers/token';
import { getExplorerLink } from '@/app-helpers/url';
import { getShortTxHash } from '@/app-helpers/web3';
import { ONE_DAY } from '@/app-hooks/api/portfolio/constant';
import { invalidateLimitOrder, useMutationCancelOrder } from '@/app-hooks/limit';
import { calcRateOrder, getOrderTokensLabel } from '@/app-hooks/limit/helper';
import { FormatOrder, LimitOrderStatus } from '@/app-hooks/limit/type';
import { useLimitStore } from '@/app-store/swap/limit';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import CancelOrderModal from '@/app-views/swap/components/LimitOrder/CancelOrderModal';
import {
	Box,
	Card,
	CardFooter,
	Divider,
	Flex,
	Progress,
	Spinner,
	Text,
	TextProps,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { CSSProperties, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const Highlight = ({
	children,
	color,
	style,
	...props
}: { children: ReactNode; color?: string; style?: CSSProperties } & TextProps) => (
	<Text as="div" fontSize={'12px'} fontWeight={'600'} color={color} style={style} {...props}>
		{children}
	</Text>
);

const Column = ({ label, value, style }: { label: ReactNode; value: ReactNode; style?: CSSProperties }) => (
	<Box flex={1} style={style}>
		<Text fontSize={'10px'}>{label}</Text>
		<Highlight style={{ lineHeight: '18px' }}>{value}</Highlight>
	</Box>
);

const mapStatusColor = {
	[LimitOrderStatus.FILLED]: 'green.200',
	[LimitOrderStatus.CANCELLED]: 'red.200',
};
export default function OrderItem({ order, forceHideCancel }: { order: FormatOrder; forceHideCancel?: boolean }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		amountIn,
		amountOut,
		tokenIn,
		tokenOut,
		expiredAt,
		status,
		id,
		createdAt,
		txHash,
		chainId,
		fillPercent = 0,
	} = order;
	const isOpenOrder = [LimitOrderStatus.PARTIALLY_FILLED, LimitOrderStatus.OPEN].includes(status);
	const { t } = useTranslation();
	const { mutateAsync, isPending } = useMutationCancelOrder(chainId);
	const { cancellingOrders, setCancellingOrders } = useLimitStore();

	const requestCancelOrder = async (isGasLess: boolean) => {
		try {
			await mutateAsync({
				data: order,
				isGasLess,
				callback: (status) => {
					if (status === TxStatus.Failed) {
						toast(
							<Toast
								type="error"
								title={t('limit.createOrderFailed')}
								message={t('limit.createOrderFailedDesc')}
							/>,
						);
					} else {
						invalidateLimitOrder();
					}
					setCancellingOrders({ id: order.id, cancelling: false });
				},
			});
			setCancellingOrders({ id, cancelling: true });
			toast(<Toast type="success" title="Success" message={t('limit.cancelOrderSubmitted')} />);
		} catch (error) {
			console.log('cancel order error', error);
			toast(<Toast type="error" message={`Failed to process: ${parseErrorMessage(error)}`} />);
		}
	};

	const { labelIn, labelOut } = getOrderTokensLabel(true);
	const showPercent = isEvmChain(chainId);
	const showCancel = isOpenOrder && !cancellingOrders[id];

	const chainStyle = { width: '14px', height: '14px' };

	return (
		<Card borderRadius={'10px'} padding={'12px 16px'} gap={'12px'} background={'white'} w={'100%'}>
			<Flex justify={'space-between'}>
				<Flex align={'center'} gap={2}>
					<Flex flex={1}>
						<TokenLogo
							logo={tokenIn?.logo}
							size={24}
							symbol={tokenIn?.symbol}
							chainId={tokenIn?.chainId}
							chainStyle={chainStyle}
						/>
						<TokenLogo
							logo={tokenOut?.logo}
							size={24}
							symbol={tokenOut?.symbol}
							chainId={tokenOut?.chainId}
							chainStyle={chainStyle}
						/>
					</Flex>
					{/* <Highlight style={{ flex: 1 }}>
						{tokenIn?.symbol}/{tokenOut?.symbol}
					</Highlight> */}
					<Divider
						width={'1px'}
						height={'18px'}
						variant={'dashed'}
						orientation="vertical"
						borderColor={colors.gray[200]}
					/>
					<Text fontSize={'12px'}>
						Expiry:{' '}
						<Highlight as={'span'}>
							{!expiredAt || (expiredAt - Date.now()) / ONE_DAY > 300
								? 'Never'
								: dayjs(expiredAt).format('MM-DD-YYYY HH:mm')}
						</Highlight>
					</Text>
				</Flex>
				<Highlight color={mapStatusColor[status] || 'orange.100'} textTransform={'capitalize'}>
					{cancellingOrders[id] || isPending ? 'Cancelling' : status.replace('_', ' ')}
				</Highlight>
			</Flex>
			<Divider variant={'dashed'} borderColor={colors.gray[200]} />
			<Flex gap={'24px'}>
				<Column label={labelIn} value={`${formatUnits(amountIn, tokenIn?.decimals)} ${tokenIn?.symbol}`} />
				<Column label={labelOut} value={`${formatUnits(amountOut, tokenOut?.decimals)} ${tokenOut?.symbol}`} />
				<Column
					label={`Price (${tokenIn?.symbol}/${tokenOut?.symbol})`}
					value={`${calcRateOrder(order)} ${tokenOut?.symbol}`}
				/>
			</Flex>
			{/* <Flex gap={'24px'}>
				{createdAt && (
					<Column
						label={'Created at'}
						style={{ flexGrow: 2, marginRight: '24px' }}
						value={dayjs(createdAt).format('MM-DD-YYYY HH:mm')}
					/>
				)}
				{txHash && (
					<Column
						label={`Transaction`}
						value={
							<CopyToClipboard
								text={getExplorerLink(chainId, txHash, 'transaction')}
								copyText="Copy transaction hash"
							>
								<Text display={'inline'} fontSize={'12px'} as="span">
									{getShortTxHash(txHash)}
								</Text>
							</CopyToClipboard>
						}
					/>
				)}
			</Flex> */}

			{!forceHideCancel && (showCancel || showPercent) && (
				<>
					<Divider variant={'dashed'} borderColor={colors.gray[200]} />
					<CardFooter p={0} justifyContent={'space-between'}>
						{showPercent && (
							<Flex
								direction={'column'}
								gap={1}
								fontSize={'12px'}
								color={colors.orange[100]}
								fontWeight={'500'}
							>
								Filled {removeTrailingZeros(fillPercent?.toFixed(2))}%
								<Progress
									background={BASE_BORDER_COLOR}
									value={fillPercent}
									width={'100px'}
									size="xs"
									colorScheme={'cyan'}
								/>
							</Flex>
						)}
						{showCancel && (
							<ButtonSecondary
								size={'sm'}
								onClick={onOpen}
								display={'flex'}
								alignItems={'center'}
								gap={'6px'}
								disabled={isPending}
							>
								{isPending && <Spinner size={'sm'} />}
								{isPending ? t('limit.cancelling') : t('limit.cancelOrder')}
							</ButtonSecondary>
						)}
					</CardFooter>
				</>
			)}
			<CancelOrderModal
				{...{
					isOpen,
					onClose,
					cancelOrder: () => {
						onClose();
						requestCancelOrder(false);
					},
					gasLessCancel: () => {
						onClose();
						requestCancelOrder(true);
					},
					order,
				}}
			/>
		</Card>
	);
}
