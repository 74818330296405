import { ChainId, NATIVE_TOKEN_ADDRESS, TOBI_CHAIN_ID_MAP, TOBI_CHAIN_NAME } from '@/app-constants/chains';
import { axiosBotAPI } from '@/app-cores/api/axios';
import { compareToken, isNativeToken } from '@/app-helpers/address';
import { BlockIPInfo, ITokenBalance, ITokenCatalogDetail, ITokenSearch } from './types';
import { getNativeTobiId, getTobiChainName, getTokenId, getTokenInfo } from '@/app-helpers/token';

// chainId is real chain id, not tobi chain id
export type QueryTokenParam = { tobiId?: string; chainId?: string | ChainId; address?: string };
export type BalanceResponse = {
	balances: ITokenSearch[];
	notFoundTokens: { tobiId: any; chainId: string; amount: string; idTobi: string }[];
};

class BffService {
	cachedToken: Record<string, ITokenSearch> = {};
	async getPortfolioBalance(isRefresh?: boolean): Promise<BalanceResponse> {
		const response = await axiosBotAPI.post('v2/wallet/portfolio/balance', {
			params: { isRefresh },
		});
		const balances: { amount: string; chainId: string; idTobi: string }[] =
			response.data.data?.filter((e) => e.amount && e.amount !== '0') ?? [];

		const formattedBalances = balances.map((e) => ({
			...e,
			tobiId: e.idTobi,
			chainId: e.chainId, // tobi chain id
		}));

		const getKey = (e) => e.tobiId + e.chainId;

		const query = formattedBalances
			.filter((e) => !this.cachedToken[getKey(e)])
			.map((e) => ({ tobiId: e.tobiId, chainId: e.chainId }));

		const findTokens = await this.searchExactListTokens({ query });
		findTokens.forEach((e) => {
			const { idTobi, chainId } = getTokenInfo(e);
			this.cachedToken[idTobi + getTobiChainName(chainId)] = e;
		});

		const notFoundTokens = [];
		const result = formattedBalances
			.map((e) => {
				const tokenInfo = this.cachedToken[getKey(e)];
				if (tokenInfo) return { ...e, ...tokenInfo, balance: e?.amount || '0' };
				notFoundTokens.push(e);
			})
			.filter(Boolean);
		return { balances: result, notFoundTokens };
	}

	async getTokenDetail(payload: QueryTokenParam): Promise<ITokenSearch> {
		if (payload.address) payload.address = isNativeToken(payload.address) ? NATIVE_TOKEN_ADDRESS : payload.address;
		const response = await axiosBotAPI.get(`v2/token/detail`, {
			params: payload,
		});
		return response.data?.data ?? null;
	}
	async searchTokens({
		chainId,
		chainIds,
		...payload
	}: {
		query: string;
		limit: number;
		chainId?: ChainId | string;
		chainIds?: ChainId[];
	}): Promise<ITokenSearch[]> {
		const params: Record<string, any> = { ...payload, chainNameOnTobi: getTobiChainName(chainId) };
		if (!chainId && chainIds?.length) {
			params.chainNameOnTobi = chainIds.map((e) => getTobiChainName(e));
		}
		const response = await axiosBotAPI.get(`v2/token/search`, { params });
		return response.data?.data ?? [];
	}

	async searchExactListTokens({ query }: { query: QueryTokenParam[] }): Promise<Array<ITokenSearch>> {
		if (!query.length) return [];
		const formatQuery = query.map((e) => ({ ...e, chainId: getTobiChainName(e.chainId) || e.chainId }));
		const response = await axiosBotAPI.post(`v2/token/details`, { tokens: formatQuery });
		const findTokens = response.data?.data?.filter(Boolean) ?? [];
		return findTokens;
	}

	async searchExactSingleToken(payload: QueryTokenParam): Promise<ITokenSearch> {
		payload.chainId = getTobiChainName(payload.chainId);
		const tokens = await this.searchExactListTokens({ query: [payload] });
		return tokens?.[0] ?? null;
	}

	async getTrendingTokens(): Promise<ITokenSearch[]> {
		const response = await axiosBotAPI.get(`/v2/token/trending`);
		return response.data.data;
	}

	async getPortfolioChart(): Promise<Array<{ timestamp: number; totalUsd: number }>> {
		const response = await axiosBotAPI.get(`/api/v1/balances/me/histories`);
		return response.data?.data?.balances?.reverse();
	}

	async getBlockIPInfo(): Promise<BlockIPInfo> {
		const response = await axiosBotAPI.get(`/trading/ips/me`);
		return response.data;
	}

	async getJupiterAta(tokenAddress: string): Promise<string> {
		const response = await axiosBotAPI.post(`/trading/solana/ata`, { tokenAddress }, { timeout: 5000 });
		return response.data?.data?.ataAddress;
	}
}

export const BffServiceAPI = new BffService();
