import { fromNano } from '@ton/core';
import { useMutation } from '@tanstack/react-query';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeToken } from '@/app-helpers/address';
import { buildSLPTransaction, buildSolTransaction, getMinimumBalanceForRentExemption } from '@/app-helpers/solana';
import { TSentSolTransaction, TSubmitSolTransaction, TransactionType } from '@/app-types';
import { TransactionMessage, VersionedTransaction } from '@solana/web3.js';
import { useTransactionWatcherStore } from '../../../app-store';
import i18n from '@/app-cores/i18n';
import { getPendingSendTxs, getPendingSwapTxs } from '@/app-store/transaction-watcher/evmWatcher';
import { ChainId } from '@/app-constants/chains';
import { SelectedRoute } from '@/app-store/swap';

const INSUFFICIENT_FUNDS_FOR_RENT = 'insufficient funds for rent';
async function sendSol(sendData: TSentSolTransaction) {
	const { connection, fromPubKey, blockHash } = await SolWallet.init('mainnet-beta', {
		commitment: 'confirmed',
	});
	try {
		const transferTransaction = await buildSolTransaction({
			connection: connection,
			fromPubKey: fromPubKey,
			sendData,
		});
		const messageV0 = new TransactionMessage({
			payerKey: fromPubKey,
			recentBlockhash: blockHash,
			instructions: transferTransaction.instructions,
		}).compileToV0Message();

		const tx = new VersionedTransaction(messageV0);
		tx.addSignature(fromPubKey, await MpcWallet.signEddsaMessage(Buffer.from(tx.message.serialize())));
		const txnSignature = await connection.sendRawTransaction(tx.serialize());
		console.log('sol sign txSignature', txnSignature);
		return txnSignature;
	} catch (error) {
		if ((error?.message as string)?.includes(INSUFFICIENT_FUNDS_FOR_RENT)) {
			const minBalanceForRent = await getMinimumBalanceForRentExemption(connection);
			const errorMgs = i18n.t('errors.send.insufficientFundsForRent', {
				amount: fromNano(minBalanceForRent),
			});
			error.message = errorMgs;
			throw error;
		}
		throw error;
	}
}

async function sendSLPToken(sendData: TSentSolTransaction) {
	const { connection, fromPubKey, blockHash } = await SolWallet.init('mainnet-beta', {
		commitment: 'confirmed',
	});
	const transferTransaction = await buildSLPTransaction({
		connection: connection,
		fromPubKey: fromPubKey,
		sendData: sendData,
	});
	const messageV0 = new TransactionMessage({
		payerKey: fromPubKey,
		recentBlockhash: blockHash,
		instructions: transferTransaction.instructions,
	}).compileToV0Message();

	const tx = new VersionedTransaction(messageV0);
	tx.addSignature(fromPubKey, await MpcWallet.signEddsaMessage(Buffer.from(tx.message.serialize())));
	const txnSignature = await connection.sendRawTransaction(tx.serialize());
	console.log('sol sign txSignature', txnSignature);
	return txnSignature;
}

// for transfer token
export function useSentSolToken() {
	const { addPendingSolTransaction } = useTransactionWatcherStore();
	const { mutateAsync: sendTransaction, ...result } = useMutation({
		mutationKey: ['sent-sol-transaction'],
		mutationFn: async (params: TSentSolTransaction) => {
			console.log('sent-sol-transaction', params);
			if (isNativeToken(params.token.address)) {
				return await sendSol(params);
			} else {
				return await sendSLPToken(params);
			}
		},
		onSuccess: (
			hash,
			{ to, token, message, amount, metadata, transactionType = TransactionType.Send, skipAddPendingTxs },
		) => {
			if (skipAddPendingTxs) return;
			// todo will update for ton/evm, refactor if possible
			addPendingSolTransaction({
				hash,
				transaction:
					transactionType === TransactionType.Swap
						? getPendingSwapTxs(metadata, { contract: to, hash, chainId: ChainId.SOL })
						: getPendingSendTxs({
								memo: message,
								to,
								token,
								hash: hash,
								chainId: ChainId.SOL,
								amount,
								transactionType,
						  }),
				trackingData: metadata,
			});
		},
	});
	return {
		...result,
		sendTransaction,
	};
}

// submit txs with data
export function useSubmitSolTransaction() {
	const { addPendingSolTransaction } = useTransactionWatcherStore();
	const { mutateAsync: sendTransaction, ...result } = useMutation({
		mutationKey: ['submit-sol-transaction'],
		mutationFn: async (params: TSubmitSolTransaction): Promise<string> => {
			return SolWallet.sendTransaction(params);
		},
		onSuccess: (data, { metadata, transactionType }) => {
			const isSwap = transactionType === TransactionType.Swap;
			addPendingSolTransaction({
				hash: data,
				transaction: isSwap
					? getPendingSwapTxs(metadata, { chainId: ChainId.SOL, hash: data, contract: '' })
					: undefined,
				trackingData: metadata,
			});
		},
	});
	return {
		...result,
		sendTransaction,
	};
}
