import { TransactionType } from '@/app-types';
import { TransactionResponse } from 'ethers';
import { useMemo } from 'react';
import { create } from 'zustand';
import { GetActivityParams, GetWalletActivityParams, IActivity } from '../../../../app-cores/api/activities';
import { useQueyWalletActivities, useWalletActivities } from '../../../../app-hooks/api/activities';
import { usePendingTransaction } from '../../../../app-store';
import { getPendingContractInteractionTxs } from '@/app-store/transaction-watcher/evmWatcher';
import useChainList from '@/app-hooks/wallet/useChainList';
import { compareChain } from '@/app-helpers/address';
import { isTestnetChain } from '@/app-helpers/token';

export interface IActivitiesStore {
	currentTx: TransactionResponse | undefined;
	currentTxType: TransactionType | undefined;
	cancelActivity: (activity?: Partial<TransactionResponse>, type?: TransactionType) => void;
}

export const useActivitiesState = create<IActivitiesStore>((set) => ({
	currentTx: undefined,
	currentTxType: undefined,
	cancelActivity: (currentTx?: TransactionResponse, currentTxType?: TransactionType) => {
		return set(() => ({ currentTx, currentTxType }));
	},
}));

export const useActivities = (enabled: boolean, payload: GetActivityParams = {}) => {
	const { evm, ton, sol } = usePendingTransaction();

	const { isFetching, refetch, data } = useWalletActivities(payload, { enabled });
	const { chainsSupport } = useChainList();

	const pendingEvm: IActivity[] = useMemo(
		() =>
			Object.values(evm || []).map(({ metadata: item }) => {
				return {
					...(item.activity ||
						getPendingContractInteractionTxs({
							hash: item.hash,
							chainId: item.chainId.toString(),
							contract: item.to,
						})),
					rawTransaction: item,
					transactionType: item.transactionType,
				};
			}),
		[evm],
	);

	const pendingTon = useMemo(() => Object.entries(ton || {}).map(([, { metadata }]) => metadata), [ton]);
	const pendingSol = useMemo(() => Object.entries(sol || {}).map(([, { metadata }]) => metadata), [sol]);

	const pendingActivities: IActivity[] = useMemo(
		() =>
			[]
				.concat(pendingEvm, pendingSol, pendingTon)
				.filter((e) => (payload?.includeTestnet ? true : !isTestnetChain(e.chainId))),
		[pendingEvm, pendingSol, pendingTon, payload?.includeTestnet],
	);

	return useMemo(() => {
		return {
			isFetching: isFetching && !data,
			refetch,
			completeActivities: (data || [])
				.filter((e) => chainsSupport.some((chain) => compareChain(chain.id, e.chainId)))
				.filter((e) => {
					// if it's cross-chain swap , dont show txs if it's not fully completed to prevent the confused
					const isCrossChain = e?.metadata?.swapType === TransactionType.SwapCrossChain;
					if (isCrossChain && pendingActivities.some((tx) => tx.hash === e.hash)) {
						return !!e.crossChainTx;
					}
					return true;
				})
				.slice(0, 50),
			pendingActivities: pendingActivities
				.filter((e) => e && chainsSupport.some((chain) => compareChain(chain.id, e.chainId)))
				.sort((a, b) => b.time - a.time),
		};
	}, [data, isFetching, pendingActivities, refetch, chainsSupport]);
};

export const useActivitiesV2 = (enabled: boolean, payload: GetWalletActivityParams = {}) => {
	// const { evm, ton, sol } = usePendingTransaction();
	const { isFetching, refetch, data, error } = useQueyWalletActivities(payload, { enabled });
	// const pendingEvm: IActivity[] = useMemo(
	// 	() =>
	// 		Object.values(evm || []).map(({ metadata: item }) => {
	// 			return {
	// 				...(item.activity ||
	// 					getPendingContractInteractionTxs({
	// 						hash: item.hash,
	// 						chainId: item.chainId.toString(),
	// 						contract: item.to,
	// 					})),
	// 				rawTransaction: item,
	// 				transactionType: item.transactionType,
	// 			};
	// 		}),
	// 	[evm],
	// );

	// const pendingTon = useMemo(() => Object.entries(ton || {}).map(([, { metadata }]) => metadata), [ton]);
	// const pendingSol = useMemo(() => Object.entries(sol || {}).map(([, { metadata }]) => metadata), [sol]);
	// const pendingActivities: IActivity[] = useMemo(
	// 	() =>
	// 		[]
	// 			.concat(pendingEvm, pendingSol, pendingTon)
	// 			.filter((e) => (isEmpty(payload?.excludeChainIds) ? true : !isTestnetChain(e.chainId))),
	// 	[pendingEvm, pendingSol, pendingTon, payload?.excludeChainIds],
	// );

	return {
		completeActivities: data ?? [],
		// pendingActivities,
		isFetching: isFetching && !data,
		refetch,
		error: error,
	};
};
