import { BlockHeader } from '@/app-components/common';
import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { ITokenCatalogDetail, ITokenSearch } from '@/app-cores/api/bff';

import { Box, Center, Collapse, Flex, Select, Skeleton, Text } from '@chakra-ui/react';
import { memo, useRef, useState } from 'react';

import { formatUsd } from '@/app-helpers/number';
import { useGetTrendingTokens } from '@/app-hooks/api/portfolio';
import { PriceChange } from '@/app-views/portfolio/pages/token/components/Balance';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { SnapItem, SnapList, useDragToScroll } from 'react-snaplist-carousel';
import { FiHotIcon } from '@/assets/icons/fi-hot-icon';
import ChainSelect from '@/app-components/common/Select/ChainSelect';
import { ChainId } from '@/app-constants/chains';
import { BASE_BORDER_COLOR } from '@/app-theme/theme';
import { DefinedFiResolution } from '@/app-store/settings';
import NoData from '@/app-components/common/NoData';
import { getTokenInfo, isTonChain } from '@/app-helpers/token';
import { TOUR_GUIDE_STEPS_TARGET } from '../TourGuide/stepConfigs';
import BaseSelect from '@/app-components/common/BaseSelect';
import { navigateToTokenDetail } from '@/app-helpers/navigate';

const TrendingItem = ({ onClick, balance }: { balance: ITokenSearch; onClick: () => void }) => {
	const {
		symbol,
		percentChange24h: usdPrice24hPercentChange,
		priceUsd: usdPrice,
		chainId,
		logo,
	} = getTokenInfo(balance);

	return (
		<Box
			onClick={onClick}
			backgroundColor="gray.100"
			minWidth={'130px'}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			flexDirection="column"
			padding={3}
			height={'101px'}
			borderRadius="1rem"
			gap={'4px'}
			cursor={'pointer'}
			position={'relative'}
		>
			<TokenLogo chainId={chainId} logo={logo} size={32} />
			<Text fontSize="12px">{symbol}</Text>
			<Flex alignItems={'center'} gap={'4px'} lineHeight={'12px'}>
				{usdPrice ? (
					<>
						<Text fontSize={'10px'} fontWeight={500}>
							{formatUsd(usdPrice)}
						</Text>
						{!!usdPrice24hPercentChange && <PriceChange price={usdPrice24hPercentChange} fontSize="10px" />}
					</>
				) : (
					<Skeleton opacity="0.1" height={'12px'} width="40px" />
				)}
			</Flex>
		</Box>
	);
};

const RESOLUTIONS = [
	{ label: '1h', value: DefinedFiResolution.ONE_HOUR, order: 5, trending: true },
	{ label: '4h', value: DefinedFiResolution.FOUR_HOURS, order: 6, trending: true },
	{ label: '1d', value: DefinedFiResolution.ONE_DAY, order: 8, trending: true },
	{ label: '12h', value: DefinedFiResolution.TWELVE_HOURS, order: 7, trending: true },
];

const options = RESOLUTIONS.filter((e) => e.trending).sort((a, b) => a.order - b.order);

function TrendingTokens() {
	const [chainId, setChain] = useState<ChainId>();
	const [resolution, setResolution] = useState<DefinedFiResolution>(DefinedFiResolution.ONE_DAY);
	const { data: trendingTokens = [], isFetching } = useGetTrendingTokens({
		chainId,
		resolution,
	});

	const { t } = useTranslation();
	const refList = useRef<HTMLDivElement>(null);
	useDragToScroll({ ref: refList });

	const onSelectToken = (el: ITokenSearch) => {
		const { idTobi } = getTokenInfo(el);
		navigateToTokenDetail({ tobiId: idTobi });
	};

	return (
		<Box mt={7} id={TOUR_GUIDE_STEPS_TARGET.HOME_PAGE.TRENDING_TOKENS}>
			<BlockHeader
				leftContent={
					<Flex justify={'center'} align={'center'} gap={2}>
						<FiHotIcon />
						{t('cryptos.trendingTokens')}
					</Flex>
				}
				// rightContent={
				// 	<Flex gap={2}>
				// 		{!isTonChain(chainId) && (
				// 			<BaseSelect
				// 				fontSize={'sm'}
				// 				width={'74px'}
				// 				size={'sm'}
				// 				value={resolution}
				// 				borderRadius={'16px'}
				// 				onChange={(e) => setResolution(e.target.value as DefinedFiResolution)}
				// 			>
				// 				{options.map((el) => (
				// 					<option value={el.value} key={el.value}>
				// 						{el.label}
				// 					</option>
				// 				))}
				// 			</BaseSelect>
				// 		)}
				// 		<ChainSelect
				// 			logoSize="18px"
				// 			style={{ border: `1px solid ${BASE_BORDER_COLOR}` }}
				// 			menuStyle={{ maxHeight: '300px' }}
				// 			value={chainId}
				// 			onChange={(e) => setChain(e as ChainId)}
				// 		/>
				// 	</Flex>
				// }
			/>
			<SnapList ref={refList} direction="horizontal" snapType="mandatory">
				{isFetching ? (
					new Array(3).fill('').map((_, el) => (
						<SnapItem key={el} snapAlign="center" margin={{ right: '8px' }}>
							<Skeleton endColor={'gray.200'} height="100px" width={'130px'} borderRadius="1rem" />
						</SnapItem>
					))
				) : !trendingTokens?.length ? (
					<Center width={'100%'}>
						<NoData msg={t('cryptos.notFoundToken')} />
					</Center>
				) : (
					trendingTokens?.map((item, i) => {
						return (
							<SnapItem key={i} snapAlign="center" margin={{ right: '8px' }}>
								<TrendingItem
									balance={item}
									onClick={() => {
										onSelectToken(item);
									}}
								/>
							</SnapItem>
						);
					})
				)}
			</SnapList>
		</Box>
	);
}

export default memo(TrendingTokens);
