import { ImportTokenInfo, ServiceToken } from '@/app-cores/api/bot/service-token';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { MpcWallet } from '../../../app-cores/mpc-wallet/wallet';
import { ITokenSearch, PffTokenDetailArgs } from '@/app-cores/api/bff';
import { useCallback, useMemo } from 'react';
import { compareAddress, compareChain } from '@/app-helpers/address';
import { QUERY_KEYS } from '@/app-constants';
import { getTokenInfo, isTestnetChain } from '@/app-helpers/token';
import { usePortfolioBalanceByCategories } from '@/app-hooks/api/portfolio/usePortfolioBalance';

export const useUserTokenSettings = (options?: UseQueryOptions<ImportTokenInfo>) => {
	const address = MpcWallet.getWalletAddress();
	const response = useQuery({
		queryKey: [QUERY_KEYS.GET_USER_SETTING_TOKEN, address],
		queryFn: () => {
			return ServiceToken.getListTokens();
		},
		...options,
		enabled: false, // temp off
	});

	return response;
};

export type TokenLabelType = 'spam' | 'hidden' | 'imported' | 'testnet';

export const useGetTokenLabels = () => {
	const { data } = usePortfolioBalanceByCategories();
	return useCallback(
		({ address, chainId }: PffTokenDetailArgs): TokenLabelType[] => {
			const { spamTokens = [], importTokens, hiddenTokens } = data || {};
			const findToken = (list: ITokenSearch[] = []) =>
				!!list?.find((e) => {
					const info = getTokenInfo(e);
					return compareAddress(info.address, address) && compareChain(info.chainId, chainId);
				});
			const labels = [];
			if (findToken(spamTokens)) labels.push('spam');
			if (findToken(hiddenTokens)) labels.push('hidden');
			if (findToken(importTokens)) labels.push('imported');
			if (isTestnetChain(chainId)) labels.push('testnet');
			return labels;
		},
		[data],
	);
};

export const useTokenLabels = (payload: PffTokenDetailArgs) => {
	const { address, chainId } = payload;
	const getTokenLabels = useGetTokenLabels();
	return useMemo(() => getTokenLabels({ address, chainId }), [getTokenLabels, address, chainId]);
};
