import { ChainId } from '@/app-constants/chains';

import { TokenInfo } from '@/app-cores/api/bff';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';
import { isNativeToken } from '@/app-helpers/address';
import { omit } from 'lodash-es';
import {
	ArgsCreateOrder,
	ArgsGetOrders,
	CancelParams,
	FormatOrder,
	LimitOrderAbstract,
	LimitOrderStatus,
	LimitProvider,
	ListOrderResponse,
} from '@/app-hooks/limit/type';
import { FEE_ACCOUNTS, SWAP_FEE_PERCENT } from '@/app-hooks/swap/type';
import { TransactionType, TTransactionRequest } from '@/app-types';
import axios from 'axios';
import { formatUnits } from 'ethers';
import { formatExpiredTime, getLimitTokenAddress } from '@/app-hooks/limit/helper';
import { TxStatus } from '@/app-cores/api/activities';

export type OrderKyber = {
	id: string;
	nonce: number;
	chainId: ChainId;
	makerAsset: string;
	takerAsset: string;
	makerAssetSymbol: string;
	takerAssetSymbol: string;
	makerAssetLogoURL: string;
	takerAssetLogoURL: string;
	makerAssetDecimals: number;
	takerAssetDecimals: number;
	makingAmount: string;
	takingAmount: string;
	filledMakingAmount: string;
	filledTakingAmount: string;
	status: LimitOrderStatus;
	createdAt: number; // timestamp in seconds
	expiredAt: number;
	transactions: Array<{
		id: number;
		txTime: number;
		txHash: string;
		makingAmount: string;
		takingAmount: string;
	}>;
	contractAddress: string;
	operatorSignatureExpiredAt?: number;
};

const API_DOMAIN = 'https://limit-order.kyberswap.com';

type Config = {
	latest: string;
	features: {
		[key: string]: {
			supportDoubleSignature: boolean;
		};
	};
};

class Kyber extends LimitOrderAbstract {
	#configMap: Partial<Record<ChainId, Config>> = {};

	async getLimitOrderContract(chainId: string) {
		const existData = this.#configMap[chainId];
		if (existData) return existData;
		const { data } = await axios.get(`${API_DOMAIN}/read-ks/api/v1/configs/contract-address`, {
			params: { chainId },
		});
		this.#configMap[chainId] = data?.data;
		return this.#configMap[chainId];
	}

	async getActiveMakingAmount(tokenIn: TokenInfo) {
		const { data } = await axios.get(`${API_DOMAIN}/read-ks/api/v1/orders/active-making-amount`, {
			params: {
				chainId: tokenIn.chainId,
				makerAsset: tokenIn.address,
				maker: MpcWallet.getWalletAddress().evmAddress,
			},
		});
		return BigInt(data?.data?.activeMakingAmount ?? 0n);
	}

	private async _signMessageCreateOrder(payload) {
		const { data: messagePayload } = await axios.post(`${API_DOMAIN}/write/api/v1/orders/sign-message`, payload);
		const { domain, types, message: data } = messagePayload?.data || {};
		const signature = await MpcWallet.signTypedData(domain, omit(types, ['DSOrder', 'EIP712Domain']), data);
		return { signature, message: data };
	}
	private async _signMessageCancelOrder(payload) {
		const { data: messagePayload } = await axios.post(`${API_DOMAIN}/write/api/v1/orders/cancel-sign`, payload);
		const { domain, types, message } = messagePayload?.data || {};
		const signature = await MpcWallet.signTypedData(domain, omit(types, ['EIP712Domain']), message);
		return { signature, message };
	}

	async createOrder({ tokenIn, tokenOut, amountIn, amountOut, expiredAt }: ArgsCreateOrder): Promise<any> {
		const payload = {
			'chainId': tokenIn.chainId,
			'makerAsset': getLimitTokenAddress(tokenIn),
			'takerAsset': getLimitTokenAddress(tokenOut),
			'maker': MpcWallet.getWalletAddress().evmAddress,
			'makingAmount': amountIn,
			'takingAmount': amountOut,
			'expiredAt': formatExpiredTime(expiredAt, LimitProvider.KYBER),
			feeRecipient: FEE_ACCOUNTS.EVM,
			makerTokenFeePercent: SWAP_FEE_PERCENT * 100, // todo check
		};
		const { signature, message } = await this._signMessageCreateOrder(payload);
		const response = await axios.post(`${API_DOMAIN}/write/api/v1/orders`, {
			...payload,
			signature,
			salt: message.salt,
		});
		return { orderId: response?.data?.data?.orderId };
	}

	private _formatOrders(orders: OrderKyber[]): FormatOrder[] {
		return orders
			.map((order) => {
				try {
					const {
						makingAmount,
						takingAmount,
						expiredAt,
						makerAsset,
						takerAsset,
						makerAssetLogoURL,
						takerAssetLogoURL,
						makerAssetSymbol,
						makerAssetDecimals,
						takerAssetDecimals,
						takerAssetSymbol,
						filledTakingAmount,
						chainId,
					} = order;
					return {
						...order,
						amountIn: makingAmount,
						amountOut: takingAmount,
						tokenIn: {
							address: makerAsset,
							decimals: makerAssetDecimals,
							symbol: makerAssetSymbol,
							logo: makerAssetLogoURL,
							chainId,
						} as any,
						tokenOut: {
							address: takerAsset,
							decimals: takerAssetDecimals,
							symbol: takerAssetSymbol,
							logo: takerAssetLogoURL,
							chainId,
						} as any,
						expiredAt: expiredAt * 1000,
						fillPercent:
							(+formatUnits(filledTakingAmount, takerAssetDecimals) /
								+formatUnits(takingAmount, takerAssetDecimals)) *
							100,
						txHash: '',
						rawOrder: order,
						provider: LimitProvider.KYBER,
					} as FormatOrder;
				} catch (error) {
					console.log('error mapping order', error);
				}
			})
			.filter(Boolean);
	}

	async getOrders({ status, pageSize, page, chainId }: ArgsGetOrders): Promise<ListOrderResponse> {
		const { data } = await axios.get(`${API_DOMAIN}/read-ks/api/v1/orders`, {
			params: {
				maker: MpcWallet.getWalletAddress().evmAddress,
				status: status === LimitOrderStatus.OPEN ? 'active' : 'closed',
				page,
				pageSize,
				chainId,
			},
		});
		const orders = data?.data?.orders ?? [];
		const total = data?.data?.pagination?.totalItems ?? 0;
		return { total, orders: this._formatOrders(orders), chainId };
	}

	async cancelOrder({ data, isGasLess, callback }: CancelParams): Promise<TTransactionRequest | undefined> {
		const { id, contractAddress, chainId } = data.rawOrder;
		const orderIds = [id];
		if (isGasLess) {
			const payload = {
				chainId,
				orderIds,
				maker: MpcWallet.getWalletAddress().evmAddress,
			};
			const { signature } = await this._signMessageCancelOrder(payload);
			const { data: response } = await axios.post(`${API_DOMAIN}/write/api/v1/orders/cancel`, {
				...payload,
				signature,
			});
			const operatorSignatureExpiredAt = response?.data?.orders?.[0]?.operatorSignatureExpiredAt;
			const deltaTime = operatorSignatureExpiredAt
				? Math.max(Date.now() - operatorSignatureExpiredAt * 1000, 0)
				: 0;
			setTimeout(() => {
				callback(TxStatus.Success);
			}, deltaTime);
			return;
		}
		const { data: resp } = await axios.post(`${API_DOMAIN}/read-ks/api/v1/encode/cancel-batch-orders`, {
			orderIds,
		});
		return {
			data: resp?.data?.encodedData,
			chainId,
			to: contractAddress,
			transactionType: TransactionType.ContractInteraction,
			gasLevel: 'market',
		};
	}
}

export const KyberLimitOrder = new Kyber();
