import { ChainId } from '@/app-constants/chains';
import { TxStatus } from '@/app-cores/api/activities';
import { BffServiceAPI, ITokenSearch } from '@/app-cores/api/bff';
import { SolWallet } from '@/app-cores/mpc-wallet/solana/SolWallet';
import { getTokenInfo } from '@/app-helpers/token';
import { formatExpiredTime, getLimitTokenAddress } from '@/app-hooks/limit/helper';
import {
	ArgsCreateOrder,
	ArgsGetOrders,
	CancelParams,
	FormatOrder,
	LimitOrderAbstract,
	LimitOrderStatus,
	LimitProvider,
	ListOrderResponse,
} from '@/app-hooks/limit/type';
import { JUPITER_REFERRAL_PUBLIC_KEY } from '@/app-hooks/swap/type';
import { useTransactionWatcherStore } from '@/app-store';
import { getPendingContractInteractionTxs } from '@/app-store/transaction-watcher/evmWatcher';
import { TransactionType } from '@/app-types';
import { LimitOrderProvider, ownerFilter, OrderHistoryItem } from '@jup-ag/limit-order-sdk';
import { Connection, Keypair, PublicKey } from '@solana/web3.js';
import axios from 'axios';
import { formatUnits } from 'ethers';

export type OrderJupiter = OrderHistoryItem;
const referralName = 'Tobi';
const JUPITER_API_DOMAIN = 'https://jup.ag/api/limit';

// just want to get some info like logo, symbol, safe to cached
const tokenCached: Record<string, ITokenSearch> = {};

class Jupiter extends LimitOrderAbstract {
	#limitOrder: LimitOrderProvider;
	#fromPubKey: PublicKey;
	#connection: Connection;

	async init() {
		try {
			const { connection, fromPubKey } = await SolWallet.init('mainnet-beta', {
				commitment: 'confirmed',
			});
			this.#connection = connection;
			this.#fromPubKey = fromPubKey;
			this.#limitOrder = new LimitOrderProvider(
				connection,
				new PublicKey(JUPITER_REFERRAL_PUBLIC_KEY),
				referralName,
			);
		} catch (error) {
			console.log('err init jupiter lo', error);
			throw new Error('Jupiter sdk is not ready');
		}
	}
	private async _checkReadyAndInit(isRetry = false) {
		if (!this.#limitOrder) {
			if (!isRetry) {
				await this.init();
				return this._checkReadyAndInit(true);
			}
			throw new Error('Sdk this not ready');
		}
	}

	private async _submitOrderTxs(tx: string, base?: Keypair, callback?: (status: TxStatus) => void) {
		const transactionBuf = Buffer.from(tx, 'base64');
		const hash = await SolWallet.sendTransaction(
			{
				data: transactionBuf,
				signer: base,
				transactionType: TransactionType.ContractInteraction,
			},
			callback,
		);
		useTransactionWatcherStore.getState().addPendingSolTransaction({
			hash,
			transaction: getPendingContractInteractionTxs({
				hash,
				chainId: ChainId.SOL,
				contract: '',
			}),
		});
		return hash;
	}

	async createOrder(
		{ tokenIn, tokenOut, amountIn, amountOut, expiredAt }: ArgsCreateOrder,
		callback: (status: TxStatus) => void,
	): Promise<any> {
		await this._checkReadyAndInit();

		// Base key are used to generate a unique order id
		const base = Keypair.generate();

		const { data: transactions } = await axios.post(`${JUPITER_API_DOMAIN}/v1/createOrder`, {
			owner: this.#fromPubKey.toString(),
			inAmount: amountIn, // 1000000 => 1 USDC if inputToken.address is USDC mint
			outAmount: amountOut,
			inputMint: getLimitTokenAddress(tokenIn),
			outputMint: getLimitTokenAddress(tokenOut),
			expiredAt: formatExpiredTime(expiredAt),
			base: base.publicKey.toString(),
			referralAccount: JUPITER_REFERRAL_PUBLIC_KEY,
			referralName,
		});

		const { tx, orderPubkey } = transactions;
		const hash = await this._submitOrderTxs(tx, base, callback);
		return { hash, orderId: orderPubkey };
	}

	private _formatOpenOrders(orders: OrderHistoryItem): FormatOrder[] {
		return orders
			.map((order) => {
				try {
					const {
						account: { makingAmount, takingAmount, inputMint, outputMint, expiredAt },
						publicKey,
					} = order;

					const tokenOut = getTokenInfo(tokenCached[outputMint.toString().toLowerCase()]);
					const tokenIn = getTokenInfo(tokenCached[inputMint.toString().toLowerCase()]);
					return {
						id: publicKey.toString(),
						amountIn: makingAmount.toString(),
						amountOut: takingAmount.toString(),
						tokenIn,
						tokenOut,
						expiredAt: expiredAt ? expiredAt.toString() * 1000 : null,
						status: LimitOrderStatus.OPEN,
						rawOrder: order,
						chainId: ChainId.SOL,
						provider: LimitProvider.JUPITER,
					} as FormatOrder;
				} catch (error) {
					console.log('error mapping order', error);
				}
			})
			.filter(Boolean);
	}
	private _formatClosedOrders(orders: OrderHistoryItem): FormatOrder[] {
		return orders
			.map((order) => {
				try {
					const {
						id,
						outAmount,
						state,
						inputMint,
						outputMint,
						expiredAt,
						oriInAmount,
						oriOutAmount,
						createdAt,
						cancelTxid,
						createTxid,
					} = order;

					const tokenOut = getTokenInfo(tokenCached[outputMint.toString().toLowerCase()]);
					const tokenIn = getTokenInfo(tokenCached[inputMint.toString().toLowerCase()]);

					const fillPercent =
						(+formatUnits(outAmount, tokenOut?.decimals) / +formatUnits(oriOutAmount, tokenOut?.decimals)) *
						100;

					return {
						id,
						amountIn: oriInAmount.toString(),
						amountOut: oriOutAmount.toString(),
						tokenIn,
						tokenOut,
						expiredAt: expiredAt ? expiredAt?.toString() * 1000 : null,
						status: state,
						rawOrder: order,
						createdAt: new Date(createdAt).getTime(),
						chainId: ChainId.SOL,
						txHash: cancelTxid || createTxid,
						fillPercent,
						provider: LimitProvider.JUPITER,
					} as FormatOrder;
				} catch (error) {
					console.log('error mapping order', error);
				}
			})
			.filter(Boolean);
	}

	private async _fetchTokensInfoByOrders(orders: OrderHistoryItem[]) {
		const addresses: string[] = [
			...new Set(
				orders
					.map((e) =>
						[e.account?.inputMint || e.inputMint, e.account?.outputMint || e.outputMint].map((e) =>
							e.toString(),
						),
					)
					.flat(),
			),
		];
		const notFoundAddress = addresses.filter((e) => !tokenCached[e.toLowerCase()]);
		if (notFoundAddress.length) {
			try {
				const tokenInfo = await BffServiceAPI.searchExactListTokens({
					query: addresses.map((e) => ({ chainId: ChainId.SOL, address: e })),
				});
				tokenInfo.forEach((el) => {
					if (!el) return;
					const { address } = getTokenInfo(el);
					tokenCached[address.toLowerCase()] = el;
				});
			} catch (error) {}
		}
	}

	async getOrders({ status, pageSize, cursor }: ArgsGetOrders): Promise<ListOrderResponse> {
		await this._checkReadyAndInit();
		if (!this.#limitOrder) return { total: 0, orders: [], chainId: ChainId.SOL };

		let orders: OrderHistoryItem[] = [];
		let total = 0;

		switch (status) {
			case LimitOrderStatus.OPEN:
				orders = await this.#limitOrder.getOrders([ownerFilter(this.#fromPubKey)]);

				await this._fetchTokensInfoByOrders(orders);
				orders = this._formatOpenOrders(orders);
				total = orders.length;
				break;
			case LimitOrderStatus.CLOSED: {
				[orders, total] = await Promise.all([
					this.#limitOrder.getOrderHistory({
						wallet: this.#fromPubKey.toBase58(),
						take: pageSize, // optional, default is 20, maximum is 100
						lastCursor: cursor || undefined, // optional, for pagination, order id
					}),
					this.#limitOrder.getOrderHistoryCount({
						wallet: this.#fromPubKey.toBase58(),
					}),
				]);
				console.log(123, orders);
				await this._fetchTokensInfoByOrders(orders);
				orders = this._formatClosedOrders(orders);

				break;
			}
		}

		return {
			total,
			orders: orders.sort((a, b) => {
				if (status === LimitOrderStatus.OPEN) return b.id > a.id ? -1 : 1;
				return new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime();
			}),
			chainId: ChainId.SOL,
		};
	}

	async cancelOrder({ data, callback }: CancelParams): Promise<any> {
		const {
			data: { tx },
		} = await axios.post(`${JUPITER_API_DOMAIN}/v1/cancelOrders`, {
			owner: this.#fromPubKey.toString(),
			orders: [data.id],
			feePayer: this.#fromPubKey.toString(),
		});
		await this._submitOrderTxs(tx, undefined, callback);
	}
}

export const JupiterLimitOrder = new Jupiter();
