import { CHAIN_CONFIG, ChainId } from '@/app-constants/chains';
import { EIP155GasPrice } from '@/app-cores/api/infura/type';
import { MpcWalletProvider } from '@/app-cores/mpc-wallet/wallet';
import { isNativeToken } from '@/app-helpers/address';
import { showTransactionError } from '@/app-helpers/error-handling';
import { getDecimalCount, getTokenInfo, parseValueToGwei, roundDownToDecimal, toGwei } from '@/app-helpers/token';
import { getSigner } from '@/app-helpers/web3';
import { useQueryGasPrice } from '@/app-hooks/api/transactions/useQueryGasPrice';
import { setDefaultGasFeeData } from '@/app-hooks/transactions/useEstimateGasFee';
import { useTransactionWatcherStore } from '@/app-store';
import { TGasFeeType, TSentTransaction, TTransactionRequest, TransactionType } from '@/app-types';
import { useMutation } from '@tanstack/react-query';
import { TransactionRequest, TransactionResponse, ethers } from 'ethers';
import { isEmpty } from 'lodash';
import { getErc20Contract, useWallet } from '../wallet';
import { InfuraServiceAPI } from '@/app-cores/api/infura';
import { getPendingSendTxs, getPendingSwapTxs } from '@/app-store/transaction-watcher/evmWatcher';

export function bufferGas(gasLimit: bigint = 1n, bufferPercent = 20n) {
	return gasLimit + (gasLimit * bufferPercent) / 100n;
}

export function useSentEvmToken() {
	const { addPendingEvmTransaction } = useTransactionWatcherStore();
	const { mutateAsync: sentTransaction, ...result } = useMutation({
		mutationKey: ['sent-erc20-transaction'],
		mutationFn: async (params: TSentTransaction) => {
			try {
				const { decimals, chainId: chain, address } = params.token;
				const chainId = +chain;
				let transactionRs: TransactionResponse | null = null;
				const decimal = getDecimalCount(params.amount, decimals);
				const amountRounded = roundDownToDecimal(params.amount, decimal);
				const amountInWei = parseValueToGwei(amountRounded, decimals);
				const { isEIP1559 } = CHAIN_CONFIG[chainId];
				const { signer, provider } = getSigner(chainId);
				const [gas, nonce] = await Promise.all([
					provider.getFeeData(),
					provider.getTransactionCount(signer.address),
				]);
				const transferToken = async () => {
					const erc20Contract = getErc20Contract(chainId, address);
					let gasLimit = 500000n;
					try {
						gasLimit = await erc20Contract.transfer.estimateGas(params.to, amountInWei ?? 0);
					} catch (error) {
						console.error('estimateGas error:', error);
					}

					transactionRs = await erc20Contract.transfer(params.to, amountInWei, {
						gasLimit: bufferGas(gasLimit),
						nonce: nonce,
						...(isEIP1559 && {
							maxPriorityFeePerGas: bufferGas(
								(params.maxPriorityFeePerGas as bigint) || gas.maxPriorityFeePerGas,
								10n,
							),
							maxFeePerGas: bufferGas((params.maxFeePerGas as bigint) || gas.maxFeePerGas, 10n),
						}),
						...(!isEIP1559 && {
							gasPrice: gas.gasPrice,
						}),
						chainId,
					});
				};
				const transferNativeToken = async () => {
					const gasLimit = await provider.estimateGas({
						to: params.to,
						value: amountInWei,
						chainId,
						data: params.message ? ethers.hexlify(ethers.toUtf8Bytes(params.message)) : '',
					});
					const transactionRaw = await signer.signTransaction({
						to: params.to,
						value: amountInWei,
						gasLimit: bufferGas(gasLimit) || 21000,
						nonce,
						chainId,
						...(isEIP1559 && {
							maxPriorityFeePerGas: bufferGas(
								(params.maxPriorityFeePerGas as bigint) || gas.maxPriorityFeePerGas,
								10n,
							),
							maxFeePerGas: bufferGas((params.maxFeePerGas as bigint) || gas.maxPriorityFeePerGas, 10n),
							type: 2,
						}),
						...(!isEIP1559 && {
							gasPrice: gas.gasPrice,
						}),
						data: params.message ? ethers.hexlify(ethers.toUtf8Bytes(params.message)) : '',
					});
					transactionRs = await provider.broadcastTransaction(transactionRaw);
				};
				if (!isNativeToken(address)) {
					await transferToken();
				} else {
					await transferNativeToken();
				}
				return transactionRs;
			} catch (error) {
				console.error('send transaction error:', error);
				throw new Error(error);
			}
		},
		onSuccess: (
			transactionRs,
			{ message, to, token, transactionType = TransactionType.Send, amount, metadata, skipAddPendingTxs },
		) => {
			if (skipAddPendingTxs) return;
			const { chainId } = token;
			addPendingEvmTransaction({
				transaction: transactionRs,
				trackingData: metadata,
				metadata: {
					transactionType,
					activity: getPendingSendTxs({
						to: to.toString(),
						memo: message,
						token,
						chainId: chainId?.toString(),
						hash: transactionRs.hash,
						amount,
						transactionType,
					}),
				},
			});
		},
	});
	return {
		...result,
		sentTransaction,
	};
}

export const populateGasInfo = async ({
	gasPriceData,
	provider,
	payload,
	chainId,
	gasLevel,
}: {
	gasPriceData: EIP155GasPrice;
	provider: MpcWalletProvider;
	payload: TransactionRequest;
	chainId: number | string;
	gasLevel: TGasFeeType;
}) => {
	const chainConfig = CHAIN_CONFIG[chainId];
	if (chainConfig.isEIP1559) {
		if (isEmpty(gasPriceData)) {
			// refetch again
			gasPriceData = await InfuraServiceAPI.getEIP155GasPrice(+chainId);
			if (isEmpty(gasPriceData)) {
				// Incase api infura error will use default by provider
				gasPriceData ||= {} as EIP155GasPrice;
				const gas = await provider.getFeeData();
				setDefaultGasFeeData(gasPriceData, gas);
			}
		}
		delete payload.gasPrice;
		const gasInfo =
			gasLevel === 'aggressive'
				? gasPriceData.high
				: gasLevel === 'market'
				? gasPriceData.medium
				: gasPriceData.low;
		payload = {
			...payload,
			maxPriorityFeePerGas: toGwei(gasInfo.suggestedMaxPriorityFeePerGas),
			maxFeePerGas: toGwei(gasInfo.suggestedMaxFeePerGas),
			type: 2,
		};
	} else if (!payload.gasPrice) {
		const gas = await provider.getFeeData();
		payload.gasPrice = gas.gasPrice;
	}
	return payload;
};

export const estimateGasEvm = async (
	{ gasLevel, ...params }: TTransactionRequest,
	gasPriceData?: EIP155GasPrice,
	showLog = true,
) => {
	const chainId = +params.chainId;
	const { provider } = getSigner(chainId);
	let payload: TransactionRequest = {
		...params,
		chainId,
	};
	payload = await populateGasInfo({ payload, provider, gasPriceData, chainId, gasLevel });

	if (!params.gasLimit) {
		let gasLimit = 1_000_000n;
		try {
			gasLimit = await provider.estimateGas(payload);
			gasLimit = bufferGas(gasLimit);
		} catch (error) {
			showLog && console.log('failed to estimate gas', error, gasLimit);
		}
		payload.gasLimit = gasLimit;
	}
	return payload;
};

// this hook is generic, can submit any kind of txs: swap/send/....
export function useSubmitEVMTransaction(chainId: ChainId | string) {
	const { addPendingEvmTransaction } = useTransactionWatcherStore();
	const { data: gasPriceData } = useQueryGasPrice(+chainId);

	const { mutateAsync: sentTransaction } = useMutation({
		mutationKey: ['submit-transaction'],
		mutationFn: async (params: TTransactionRequest): Promise<TransactionResponse> => {
			const { signer } = getSigner(params.chainId);
			const payload = await estimateGasEvm(params, gasPriceData);
			console.log('submit txs info:', payload);
			const transactionRaw = await signer.sendTransaction(payload).catch((e) => {
				if (e?.toString?.()?.includes('intrinsic gas too low')) {
					console.log('retry with hight gas');
					return signer.sendTransaction({ ...payload, gasLimit: BigInt(payload.gasLimit) * 2n });
				}
				throw e;
			});
			console.log('🚀 submit transactionRs:', transactionRaw);
			return transactionRaw;
		},
		onSuccess: (
			txResponse,
			{ metadata, transactionType = TransactionType.ContractInteraction, to, chainId, skipAddPendingTxs },
		) => {
			if (skipAddPendingTxs) return;
			addPendingEvmTransaction({
				transaction: txResponse,
				trackingData: metadata,
				metadata: {
					transactionType,
					activity:
						transactionType === TransactionType.Swap && metadata
							? getPendingSwapTxs(metadata, {
									contract: to?.toString(),
									chainId: chainId?.toString(),
									hash: txResponse.hash,
							  })
							: undefined,
				},
			});
		},
	});
	return { sentTransaction };
}
