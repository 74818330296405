import TokenLogo from '@/app-components/common/Avatar/TokenLogo';
import { Card, Collapse, Flex, ListItem, Tag, Text } from '@chakra-ui/react';
import { truncate } from 'lodash-es';
import { CSSProperties, useState } from 'react';
import { ITokenSearch } from '../../../app-cores/api/bff';
import { formatUnits, formatUsd } from '../../../app-helpers/number';
import { TokenLabelType } from '@/app-hooks/api/tokens/useUserTokenSettings';
import { BASE_BORDER_COLOR, colors } from '@/app-theme/theme';
import { TooltipInfo } from '@/app-components/common';
import { useTranslation } from 'react-i18next';
import { ChainId } from '@/app-constants/chains';
import { getTokenId, getTokenInfo, isTestnetChain } from '@/app-helpers/token';
import TobiTag from '@/app-components/common/TobiTag';
import { ChevronDownIcon } from '@/assets/images/svg/chevron-down-icon';
import { compareTobiToken } from '@/app-helpers/address';

export interface IListCryptoItemProps {
	data: ITokenSearch;
	onClick?: (item: ITokenSearch) => void;
	style?: CSSProperties;
	getTokenLabels?: (val: { tokenAddress: string; chainId: ChainId | string }) => TokenLabelType[];
	px?: number;
	showChildren?: boolean;
	isChildren?: boolean;
	selectedToken?: ITokenSearch;
}

export const TokenLabel = ({ type, fontSize }: { type: TokenLabelType; fontSize?: string }) => {
	const isTestnet = type === 'testnet';
	const { t } = useTranslation();
	return (
		<TobiTag
			fontSize={fontSize || '10px'}
			color={type === 'spam' ? colors.red[200] : 'black'}
			background={type === 'spam' ? colors.red[100] : 'rgba(0, 0, 0, 0.08)'}
		>
			{type} {isTestnet && <TooltipInfo label={t('testnet.info')} />}
		</TobiTag>
	);
};

const getLabelLocal = ({ chainId }: { chainId: ChainId | string }): TokenLabelType[] => {
	return isTestnetChain(chainId) ? ['testnet'] : [];
};

const ListToken = ({ token, onClick, selectedToken }: { token: ITokenSearch; onClick; selectedToken }) => {
	const children = token?.children ?? [];
	if (!children?.length) return null;

	return (
		<Card
			as={'ul'}
			sx={{
				borderRadius: '12px',
				background: colors.gray[100],
				display: 'flex',
				gap: 2,
				flexDirection: 'column',
				userSelect: 'none',
			}}
			my={4}
		>
			{children?.map((newToken, i) => {
				const oneToken = children.length === 1;
				const isSelected = compareTobiToken(newToken, selectedToken);
				return (
					<ListCryptoItem
						onClick={() => onClick(newToken)}
						data={newToken}
						key={getTokenId(newToken)}
						isChildren
						style={{
							paddingTop: '8px',
							paddingBottom: '8px',
							paddingLeft: '24px',
							paddingRight: '24px',
							...(i === 0
								? {
										borderRadius: oneToken ? '12px' : '12px 12px 0 0',
										background: 'rgba(0, 0, 0, 0.08)',
								  }
								: {}),
							background: isSelected ? 'rgba(0, 0, 0, 0.08)' : undefined,
						}}
					/>
				);
			})}
		</Card>
	);
};

export const ListCryptoItem: React.FC<IListCryptoItemProps> = ({
	data,
	onClick,
	style,
	getTokenLabels,
	showChildren,
	isChildren,
	selectedToken,
	...rest
}) => {
	const {
		chainId,
		balance,
		usdValue,
		decimals,
		priceUsd: usdPrice,
		address,
		chainTokens,
		symbol,
		imageUrl,
		name,
		percentChange24h: value24hChange,
	} = getTokenInfo(data);

	const myTokenBalance = balance && formatUnits(balance, decimals);
	const labels = (getTokenLabels || getLabelLocal)?.({ tokenAddress: address, chainId });

	const myBalanceUsd = usdValue ? formatUsd(usdValue, { testnet: labels.includes('testnet') }) : null;
	const usdPriceText = formatUsd(usdPrice);
	const hasBalance = myTokenBalance !== '0';

	const [expand, setExpand] = useState(false);
	const showChildrenData = showChildren && data?.children?.length > 0;
	const isSelected = false; // compareTobiToken(selectedToken, data);

	return (
		<ListItem
			cursor={'pointer'}
			onClick={showChildrenData ? () => setExpand(!expand) : () => onClick(data)}
			style={style}
			maxWidth={'100%'}
			background={isSelected ? colors.gray[100] : undefined}
			borderTop={isSelected ? `1px dashed ${BASE_BORDER_COLOR}` : undefined}
			borderBottom={isSelected ? `1px dashed ${BASE_BORDER_COLOR}` : undefined}
			userSelect={'none'}
			{...rest}
		>
			<Flex justifyContent="space-between" align={'center'} height={'100%'}>
				<Flex gap={'12px'} alignItems={'center'} maxWidth={'60%'}>
					<TokenLogo
						tokenStyle={{ background: colors.gray[100] }}
						size={33}
						symbol={symbol}
						logo={imageUrl}
						chainId={data?.children?.length > 1 ? undefined : chainId}
					/>
					<Flex
						gap={'4px'}
						flexDirection={'column'}
						height={'42px'}
						justifyContent={usdPriceText ? 'space-between' : 'center'}
					>
						<Flex alignItems={'center'} gap={'6px'}>
							<Text
								as={'span'}
								fontWeight={600}
								fontSize={'13px'}
								lineHeight={'18px'}
								whiteSpace={'nowrap'}
							>
								{truncate(labels.length ? symbol : name || symbol || '--', { length: 24 })}
							</Text>
							{labels.map((e) => (
								<TokenLabel type={e} key={e} />
							))}
						</Flex>
						{!!usdPriceText && (
							<Flex fontSize="xs" whiteSpace={'nowrap'} gap={'4px'} color={'gray.400'} fontWeight={'500'}>
								{usdPriceText}
								{!!value24hChange && (
									<Flex
										as="span"
										fontSize={'12px'}
										gap={'4px'}
										color={
											!value24hChange ? undefined : value24hChange > 0 ? 'green.200' : 'red.600'
										}
									>
										{value24hChange > 0 ? '+' : '-'}
										{Math.abs(value24hChange).toFixed(2)}%
									</Flex>
								)}
							</Flex>
						)}
					</Flex>
				</Flex>
				<Flex
					flex={1}
					flexDirection={'column'}
					alignItems={'flex-end'}
					gap={'4px'}
					fontWeight={'500'}
					justifyContent={hasBalance ? 'space-between' : 'center'}
				>
					{showChildrenData ? (
						<Flex gap={'4px'} align={'center'}>
							<Text fontSize="xs" whiteSpace={'nowrap'} color={'gray.400'}>
								{truncate(symbol, { length: 20 })}
							</Text>
							<ChevronDownIcon
								style={{ transform: `rotate(${!expand ? '0deg' : '180deg'})` }}
								width={20}
								height={20}
							/>
						</Flex>
					) : (
						<>
							<Text as="span" fontWeight={500} fontSize="12px">
								{myBalanceUsd}
							</Text>
							<Text fontSize="xs" whiteSpace={'nowrap'} color={'gray.400'}>
								{hasBalance ? myTokenBalance : null} {truncate(symbol, { length: 14 })}
							</Text>
						</>
					)}
				</Flex>
			</Flex>

			{showChildrenData && (
				<Collapse in={expand}>
					<ListToken token={data} onClick={onClick} selectedToken={selectedToken} />
				</Collapse>
			)}
		</ListItem>
	);
};
