import { useNavigate, useParams } from 'react-router';

import React, { CSSProperties, useEffect, useState } from 'react';
import { TokenBalance } from './components/Balance';
import { BasicInformation } from './components/BasicInformation';
import { PriceChartV2 } from './components/PriceChartV2';
import { TokenInformation } from './components/TokenInformation';
import { MenuAction } from './components/MenuAction';
import {
	Box,
	Button,
	Center,
	Container,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
} from '@chakra-ui/react';
import WhitelistedInfo from '@/app-views/portfolio/pages/token/components/WhitelistedInfo';
import { useTokenDetail } from '@/app-hooks/api/portfolio';
import { Loading, LocalLoader } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';

import { TokenInfo } from '@/app-cores/api/bff';
import { CreateAccount } from '@/app-components/common/CreateAccount';

import { CHART_CONFIG_DEFAULT, useUserSettingsStore } from '@/app-store/settings';
import { getTokenInfo, isTonChain } from '@/app-helpers/token';
import { useWalletStore } from '@/app-store';
import { useSearchParams } from 'react-router-dom';

export const TokenDetailInSwap = ({
	onClose,
	token,
	isNonWallet,
	isOpen,
}: {
	onClose: () => void;
	token: TokenInfo | undefined;
	isNonWallet: boolean;
	isOpen: boolean;
}) => {
	const { chainId, address: contractAddress, idTobi: tobiId } = token || {};
	const { isFetching, data } = useTokenDetail(tobiId ? { tobiId } : { chainId, address: contractAddress });

	const commonProps = {
		chainId,
		contractAddress,
		data,
		tobiId,
	};

	return (
		<Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader fontSize={'16px'} textAlign={'center'} borderBottomWidth={'1px'} py={3}>
					{token?.symbol} Information
				</DrawerHeader>
				<DrawerBody style={{ maxHeight: '80vh', minHeight: '80vh', overflowX: 'hidden' }}>
					<Container style={{ padding: 0 }}>
						{isFetching ? (
							<LocalLoader height={'80vh'} />
						) : (
							<>
								<BasicInformation {...commonProps} showSetting={false} />
								<WhitelistedInfo {...commonProps} isNonWallet={isNonWallet} />
								<Chart commonProps={commonProps} style={{ width: '100vw', marginLeft: -6 }} />
								<TokenBalance {...commonProps} />
								<TokenInformation {...commonProps} />
							</>
						)}
					</Container>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

const Chart = ({ commonProps, style }) => {
	const { chartConfig: { mode, showMa, showVolume } = CHART_CONFIG_DEFAULT } = useUserSettingsStore();
	const { idTobi } = getTokenInfo(commonProps?.data ?? {});
	if (!idTobi) return null;
	return (
		<Flex direction={'column'} gap={0} sx={style}>
			<PriceChartV2 {...commonProps} mode={mode} showMa={showMa} showVolume={showVolume} />
		</Flex>
	);
};

const TokenDetail = ({ style }: { style?: CSSProperties }) => {
	const [searchParams] = useSearchParams();
	const { chainId, contractAddress, tobiId } = Object.fromEntries(searchParams.entries());
	const { isFetching, data } = useTokenDetail(tobiId ? { tobiId } : { chainId, address: contractAddress });

	const { isNonWallet } = useWalletStore();

	const commonProps = {
		chainId,
		contractAddress,
		data,
		tobiId,
	};

	if (isFetching) return <Loading />;
	return (
		<PageTransition>
			<PageNavigation>
				<Container style={style} className="overflow-y-auto hide-scrollbar">
					<BasicInformation {...commonProps} showSetting={!isNonWallet} />
					<WhitelistedInfo {...commonProps} isNonWallet={isNonWallet} />
					<Chart
						commonProps={commonProps}
						style={{
							width: '100vw',
							marginLeft: '-16px',
						}}
					/>
					<MenuAction {...commonProps} isNonWallet={isNonWallet} />
					<TokenBalance {...commonProps} />
					<TokenInformation {...commonProps} />
					<CreateAccount {...{ bottom: '2vh' }} />
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
export default TokenDetail;
