
			const isProd = import.meta.env.VITE_ENVIRONMENT === 'prod';
			window?.Telegram?.WebApp?.expand();
			if (!isProd && window.location.host !== 'telifi.local:3001') {
				import('eruda')
					.then((module) => {
						const eruda = module.default;
						eruda?.init();
					})
					.catch((err) => {
						console.error('Failed to load the eruda library:', err);
					});
			}
		