import { axiosTonCenterServiceAPI } from '@/app-cores/api/axios';
import { GetTransactionPayload, TonEstimateFee, TonEstimateFeeRes, TonGetAddressState, TonTransaction } from './type';

class TonCenterService {
	async estimateFee(payload: TonEstimateFee): Promise<{ data: TonEstimateFeeRes }> {
		const response = await axiosTonCenterServiceAPI.post('/estimateFee', payload);
		return response.data;
	}
	async getAddressState(address: string): Promise<TonGetAddressState> {
		const response = await axiosTonCenterServiceAPI.get('/getAddressState', {
			params: {
				address: address,
			},
		});
		return response.data;
	}

	async getTransactions({ address, archival = false, limit = 10, ...rest }: GetTransactionPayload) {
		const response = await axiosTonCenterServiceAPI.get<{
			ok: boolean;
			result: TonTransaction[];
		}>('/getTransactions', {
			params: {
				address,
				archival,
				limit,
				...rest,
			},
		});

		return response.data.result;
	}
}

export type ITonCenterServiceAPI = typeof TonCenterService;

export const TonCenterServiceAPI = new TonCenterService();
