import { Badge } from '@/app-components/common';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { useConnectionManager } from '@/app-features/app-wallet-connect/hooks';
import { FiLink2Icon } from '@/assets/icons';
import { Center, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const DappConnections = () => {
	const { t } = useTranslation();
	const { sessionCount } = useConnectionManager();
	return (
		<Link to={NAVIGATE_PATHS.Wallet.WCconnection} className="w-100">
			<Center justifyContent="space-between">
				<Center>
					<FiLink2Icon />
					<Text ml={3} py={4} fontSize="sm">
						{t('dapp.dAppConnections')}
					</Text>
				</Center>
				{!!sessionCount && (
					<Badge color="black" type="success" width={6} height={6}>
						{sessionCount}
					</Badge>
				)}
			</Center>
		</Link>
	);
};
