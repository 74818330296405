import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Avatar, Box, Button, Center, Container, Flex, List, ListItem, Text } from '@chakra-ui/react';
import { Badge, Loading } from '@/app-components/common';
import PageTransition from '@/app-components/layout/PageTransition/PageTransition';
import PageNavigation from '@/app-components/layout/PageWrapper/PageWrapper';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { displayUserName } from '@/app-helpers/display';
import { useQueryInviteCodesHistory } from '@/app-hooks/api/user/useQueryUserInviteCodes';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { FiBuoyIcon, FiContactIcon, FiFileIcon, FiFileMinusIcon, FiGiftIcon } from '@/assets/icons';
import { DappConnections } from './components/DappConnnections';
import { FeatureConfigWrap } from '../../app-helpers/components';
import { FeatureConfig } from '../../app-hooks/api/configuration';
import { getEnvironment } from '@/app-helpers';
import { TelegramCore } from '@/app-cores/telegram';
import { TOBI_SUPPORT_BOT_LINK } from '@/app-constants';
import { FiContactSupportIcon } from '@/assets/icons/fi-support-icon';

export const MAPPING_BACKUP_TYPE = {
	'drive': 'storeBackup.backupViaDrive',
	'email': 'storeBackup.backupViaEmail',
	'telegram': 'storeBackup.backupTelegram',
};
const AccountSetting = () => {
	const { t } = useTranslation();
	const { data: userProfile } = useQueryUserProfile();
	const { data: { totalWalletUser } = {} } = useQueryInviteCodesHistory();

	if (!userProfile) {
		return <Loading />;
	}
	const backupIdentifier = userProfile?.backupType === 'telegram' ? '@' : '';
	const backupInfo = userProfile?.backupEmail ? `: ${backupIdentifier}${userProfile.backupEmail}` : '';

	{
		backupInfo;
	}
	return (
		<PageTransition>
			<PageNavigation>
				<Container py={4} backgroundColor="gray.100" height="100vh" position="relative">
					<Flex
						backgroundColor="white"
						border="1px solid rgba(255, 255, 255, 0.08)"
						display="flex"
						borderRadius={12}
						gap={3}
						px={4}
						py={3}
					>
						<Avatar
							name={userProfile.firstName || userProfile.lastName}
							src={userProfile.avatar}
							// size="sm"
							width={10}
							height={10}
						/>
						<Box>
							<Text fontSize="sm" fontWeight={400}>
								{userProfile.firstName} {userProfile.lastName}
							</Text>
							<Text fontSize="xs" color="gray.400">
								{displayUserName(userProfile.username)}
							</Text>
						</Box>
					</Flex>
					<List backgroundColor="white" px={4} borderRadius={12} mt={2}>
						<FeatureConfigWrap feature={FeatureConfig.INVITATION_CODE}>
							<ListItem display="flex" alignItems="center">
								<Link to={NAVIGATE_PATHS.AccountSettings.InvitationCode} className="w-100">
									<Center justifyContent="space-between">
										<Center>
											<FiGiftIcon />
											<Text ml={3} py={4} fontSize="sm">
												{t('appSetting.invitationCodes')}
											</Text>
										</Center>
										{totalWalletUser > 0 && (
											<Badge type="success" color="black" width={6} height={6}>
												{totalWalletUser}
											</Badge>
										)}
									</Center>
								</Link>
							</ListItem>
						</FeatureConfigWrap>

						<FeatureConfigWrap feature={FeatureConfig.DAPP_SETTING}>
							<ListItem display="flex" alignItems="center">
								<DappConnections />
							</ListItem>
						</FeatureConfigWrap>

						{!userProfile.backupType ? (
							<Link
								to={`${NAVIGATE_PATHS.CreateWallet.StoreBackupKey}?fromSettings=true`}
								className="w-100"
							>
								<ListItem display="flex" alignItems="center">
									<Center>
										<FiBuoyIcon />
										<Text ml={3} py={4} fontSize="sm">
											{t('appSetting.backupYourKey')}
										</Text>
									</Center>
								</ListItem>
							</Link>
						) : (
							<ListItem display="flex" alignItems="center">
								<Center flex={1} justifyContent="space-between">
									<Center>
										<FiBuoyIcon />
										<Box ml={3} py={4}>
											<Text fontSize="sm">{t('appSetting.accountBackup')}</Text>
											<Text fontSize="xs" color="gray.400" fontWeight={400}>
												{t(MAPPING_BACKUP_TYPE[userProfile?.backupType])}
												{backupInfo}
											</Text>
										</Box>
									</Center>
								</Center>
							</ListItem>
						)}

						<Link to={NAVIGATE_PATHS.AccountSettings.TermOfService}>
							<ListItem display="flex" alignItems="center">
								<Center>
									<FiFileMinusIcon />
									<Text ml={3} py={4} fontSize="sm">
										{t('appSetting.termsOfService')}
									</Text>
								</Center>
							</ListItem>
						</Link>
						<Link to={NAVIGATE_PATHS.AccountSettings.PrivacyPolicy}>
							<ListItem display="flex" alignItems="center">
								<Center>
									<FiFileIcon />
									<Text ml={3} py={4} fontSize="sm">
										{t('appSetting.privacyPolicy')}
									</Text>
								</Center>
							</ListItem>
						</Link>
						<FeatureConfigWrap feature={FeatureConfig.CANCEL_PENDING_TRANSACTIONS}>
							<Link to={NAVIGATE_PATHS.CancelPendingTransactions}>
								<ListItem display="flex" alignItems="center">
									<Center>
										<FiFileIcon />
										<Text ml={3} py={4} fontSize="sm">
											Cancel Pending Transactions
										</Text>
									</Center>
								</ListItem>
							</Link>
						</FeatureConfigWrap>

						<ListItem
							display="flex"
							alignItems="center"
							onClick={() => {
								TelegramCore.WebApp.openTelegramLink(TOBI_SUPPORT_BOT_LINK);
							}}
						>
							<Center>
								<FiContactSupportIcon />
								<Text ml={3} py={4} fontSize="sm">
									Contact Support
								</Text>
							</Center>
						</ListItem>

						{getEnvironment('dev') && (
							<>
								<Link to={NAVIGATE_PATHS.Testing.AppIcon}>
									<ListItem display="flex" alignItems="center">
										<Center>
											<FiFileIcon />
											<Text ml={3} py={4} fontSize="sm">
												Icons list
											</Text>
										</Center>
									</ListItem>
								</Link>
							</>
						)}
					</List>
					<Button
						size="md"
						fontWeight="medium"
						colorScheme="cyan"
						position="absolute"
						right={4}
						bottom={4}
						onClick={() => {
							TelegramCore.WebApp.openTelegramLink(TOBI_SUPPORT_BOT_LINK);
						}}
					>
						<FiContactIcon />
						<Text ml={1}>{t('help')}</Text>
					</Button>
				</Container>
			</PageNavigation>
		</PageTransition>
	);
};
export default AccountSetting;
