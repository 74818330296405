import { CTAButton, Toast } from '@/app-components/common';
import { useTranslation } from 'react-i18next';
import { NAVIGATE_PATHS } from '@/app-constants/router';
import { toQueryString } from '@/app-helpers/url';
import { useQueryUserProfile } from '@/app-hooks/api/user/useQueryUserProfile';
import { FiArrowRightIcon } from '@/assets/icons';
import { Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { TelegramCore, useParseBotStartRedirectParams } from '@/app-cores/telegram';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { Actions } from '@/app-features/app-bot-connector/hooks';
import { queryClient } from '@/app-cores/query-client';
import { useMutationUpdateAffiliateCode } from '@/app-hooks/api/user/useMutationUpdateAffiliateCode';
import { MpcWallet } from '@/app-cores/mpc-wallet/wallet';

export const navigateOnboardingFromBotRedirect = toQueryString(NAVIGATE_PATHS.CreateWallet.Onboarding, {
	fromBotRedirect: 'true',
});

export const CreateAccount = ({ bottom }: { bottom: string }) => {
	const { data: user, isLoading: isProfileLoading } = useQueryUserProfile();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { action, affiliateCode } = useParseBotStartRedirectParams();
	const { mutateAsync: updateAffiliateCode, isPending } = useMutationUpdateAffiliateCode();

	const handleCreateWallet = async () => {
		if (action !== Actions.SOL_TCAT) return navigate(navigateOnboardingFromBotRedirect);
		try {
			await updateAffiliateCode({
				affiliateCode: affiliateCode,
			});
			await queryClient.invalidateQueries({
				queryKey: ['query-user-profile', TelegramCore.getUserId()],
			});
			navigate(NAVIGATE_PATHS.CreateWallet.Onboarding);
		} catch (error) {
			console.error('Claim referral code error', error);
			toast(<Toast title="Error" type="error" message="Claim referral code error" />);
		}
	};
	if (isProfileLoading || user.defaultWallet) return null;

	return (
		<Flex
			style={{
				left: 0,
				right: 0,
				position: 'fixed',
				bottom: bottom ?? '15vh',
				justifyContent: 'center',
				width: '100vw',
			}}
		>
			<CTAButton
				flex={1}
				onClick={handleCreateWallet}
				colorScheme={'cyan'}
				style={{ maxWidth: '170px', height: '56px', fontSize: '16px' }}
				isLoading={isPending}
			>
				{t('button.createAccount')} &nbsp; <FiArrowRightIcon style={{ height: '16px' }} />
			</CTAButton>
		</Flex>
	);
};
